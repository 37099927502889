import { Link } from "react-router-dom";
import { title, logo } from "../config/config";

// type Servicecard = {
//     Crdimg: string,
//     CrdHeading: string,
//     CrdInfo: string,
//     CrdBtn: string,
//     CrdBtnImg: string,
// }
const SikkaService = () => {
    function ServiceCard(props: any) {
        return (<>
            <div className="card">
                <img src={require(`../Assets/Icons/${props.Crdimg}.svg`)} alt="" />
                <h3 className="card_heading t-primary-color">
                    {props.CrdHeading}
                </h3>
                <p className="card_info t-normal-color">
                    {props.CrdInfo}
                </p>
                <Link to="/" className="f-primary-color" >
                    {props.CrdBtn}
                    <img src={require(`../Assets/Icons/${props.CrdBtnImg}.svg`)} alt="" />
                </Link>
            </div>
        </>)
    }
    const CardData = [
        {
            Crdimg: "service-card-icon1",
            CrdHeading: "24/7 Customer Service",
            CrdInfo: `Contact ` +  title  +  ` customer support with your questions at any time.`,
            CrdBtn: "Get in Touch",
            CrdBtnImg: "service-card-arrow",
        },
        {
            Crdimg: "service-card-icon2",
            CrdHeading: "Community",
            CrdInfo: `The ` + title +  `  Global Community covers 205 countries and 19 languages.`,
            CrdBtn: "Explore Community",
            CrdBtnImg: "service-card-arrow",
        },
        {
            Crdimg: "service-card-icon3",
            CrdHeading: title + ` News`,
            CrdInfo: "Keeping you up to date with the latest news in crypto.",
            CrdBtn: "Visit the News",
            CrdBtnImg: "service-card-arrow",
        },
    ]
    return (
        <>
            <section className="sec_sikka__service sec-py ">
                <div className="container">
                    <h2 className="sec_heading">
                        {title} by Your Side
                    </h2>
                    <div className="service__card">
                        {CardData.map(function (value, index) {
                            return (
                                <ServiceCard
                                    key={index}
                                    Crdimg={value.Crdimg}
                                    CrdHeading={value.CrdHeading}
                                    CrdInfo={value.CrdInfo}
                                    CrdBtn={value.CrdBtn}
                                    CrdBtnImg={value.CrdBtnImg}
                                />
                            )
                        })
                        }

                    </div>
                </div>
            </section>
        </>
    )
}
export default SikkaService;