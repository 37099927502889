import ReferralBanner from "../Sections/Referral-banner"
import ReferralSlider from "../Sections/Referral-slider"
import LetestCoin from "../Sections/Letest-coin"
import Hightlights from "../Sections/Highlights"
import CommonCoinData from "../Sections/popular-coin"
import JoinSikka from "../Sections/Join-sikka"
import { useEffect } from "react"
import { title } from "../config/config"

const Sikkareferral = (props:any) => {
    useEffect(() =>{
        document.title = props.title;

    },[])
    return (
        <>
            {/* Referral Banner Section Code */}
            <ReferralBanner />

            {/* Referral Slider Section Code */}
            <ReferralSlider />

            {/* Letest Coin Section Code */}
            <LetestCoin />

            {/* Common Coin Data Section Code */}
            <CommonCoinData />

            {/* Hightlights Section Code */}
            <Hightlights />

            {/* Join Sikka Section Code */}
            <JoinSikka />
        </>
    )
}
export default Sikkareferral;