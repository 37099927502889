import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CommonCard from "../Snippets/Slider-card";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { title } from "../config/config";

const refrralData = [
    {
        Cardheading: "Polkadex Crowdloan Carnival!",
        Cardbodyheading: "Enjoy An",
        Crdprice: "860,000",
        Crdtokam: "Toko",
        cardfooterheading: "Prize Pool!",
        ctabutton: "Join Now",
        image: "referral-slider-icon"
    },
    {
        Cardheading: "Polkadex Crowdloan Carnival!",
        Cardbodyheading: "Enjoy An",
        Crdprice: "860,000",
        Crdtokam: "Toko",
        cardfooterheading: "Prize Pool!",
        ctabutton: "Join Now",
        image: "referral-slider-icon"
    },
    {
        Cardheading: "Polkadex Crowdloan Carnival!",
        Cardbodyheading: "Enjoy An",
        Crdprice: "860,000",
        Crdtokam: "Toko",
        cardfooterheading: "Prize Pool!",
        ctabutton: "Join Now",
        image: "referral-slider-icon"
    },
    {
        Cardheading: "Polkadex Crowdloan Carnival!",
        Cardbodyheading: "Enjoy An",
        Crdprice: "860,000",
        Crdtokam: "Toko",
        cardfooterheading: "Prize Pool!",
        ctabutton: "Join Now",
        image: "referral-slider-icon"
    },
    {
        Cardheading: "Polkadex Crowdloan Carnival!",
        Cardbodyheading: "Enjoy An",
        Crdprice: "860,000",
        Crdtokam: "Toko",
        cardfooterheading: "Prize Pool!",
        ctabutton: "Join Now",
        image: "referral-slider-icon"
    },
    {
        Cardheading: "Polkadex Crowdloan Carnival!",
        Cardbodyheading: "Enjoy An",
        Crdprice: "860,000",
        Crdtokam: "Toko",
        cardfooterheading: "Prize Pool!",
        ctabutton: "Join Now",
        image: "referral-slider-icon"
    },
    {
        Cardheading: "Polkadex Crowdloan Carnival!",
        Cardbodyheading: "Enjoy An",
        Crdprice: "860,000",
        Crdtokam: "Toko",
        cardfooterheading: "Prize Pool!",
        ctabutton: "Join Now",
        image: "referral-slider-icon"
    }
]
const ReferralSlider = () => {
    return (
        <>
            <section className="market_slider referral_slider sec-py s-normal-bg">
                <div className="container">
                    <div className="sec_content">
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={30}
                            navigation={true}
                            modules={[Navigation]}
                            breakpoints={{
                                1440: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                280: {
                                    slidesPerView: 1.42,
                                    spaceBetween: 10,
                                },
                            }}
                            className="mySwiper">
                            {
                                refrralData.map((elem, index) => {
                                    return (

                                        <SwiperSlide key={index}>
                                            <CommonCard
                                                marketSlidercondition={true}
                                                Cardheading={elem.Cardheading}
                                                Cardbodyheading={elem.Cardbodyheading}
                                                Crdprice={elem.Crdprice}
                                                Crdtokam={elem.Crdtokam}
                                                cardfooterheading={elem.cardfooterheading}
                                                ctabutton={elem.ctabutton}
                                                image={elem.image}
                                            />
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    </div>
                    <div className="subscribe_sikka">
                        <h2 className="sec_heading">
                            Subscribe {title}
                        </h2>
                        {/* Subscribe Card */}
                        <ul className="subscribe_card">
                            <li className="card">
                                <h4 className="card_heading">
                                    Burning Drop
                                </h4>
                                <p className="card_info">
                                    Stake popular coins to earn
                                </p>
                                <button>
                                    <Link to="/" className="f-primary-color subscribe">
                                        Subscribe
                                        <img src={require("../Assets/Icons/service-card-arrow.svg").default} alt="" />
                                    </Link>
                                </button>
                            </li>
                            <li className="card">
                                <h4 className="card_heading">
                                    Polkadot
                                </h4>
                                <p className="card_info">
                                    Participate in the on-chain eco..
                                </p>
                                <button>
                                    <Link to="/" className="f-primary-color subscribe">
                                        Subscribe
                                        <img src={require("../Assets/Icons/service-card-arrow.svg").default} alt="" />
                                    </Link>
                                </button>
                            </li>
                            <li className="card">
                                <h4 className="card_heading">
                                    ETH 2.0
                                </h4>
                                <p className="card_info">
                                    Join the ETH 2.0 Era
                                </p>
                                <button>
                                    <Link to="/" className="f-primary-color subscribe">
                                        Subscribe
                                        <img src={require("../Assets/Icons/service-card-arrow.svg").default} alt="" />
                                    </Link>
                                </button>
                            </li>
                            <li className="card">
                                <h4 className="card_heading">
                                    Exchange
                                </h4>
                                <p className="card_info">
                                    Liquidity Trade
                                </p>
                                <button>
                                    <Link to="/" className="f-primary-color subscribe" >
                                        Subscribe
                                        <img src={require("../Assets/Icons/service-card-arrow.svg").default} alt="" />
                                    </Link>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )

}
export default ReferralSlider;





