import React, { useEffect, useState } from "react";
import { title } from "../config/config";

const AccountForm = (props:any) => {
  const [isActive, setIsActive] = useState(false);
  const [isShow, setShow] = useState(false);
  const [isShow2, setShow2] = useState(false);
  const [exchangeInputVlaue, setExchangeInputVlaue] = useState("");
  const [exchangeInputVlaue2, setExchangeInputVlaue2] = useState("");

  const rowReverse = (event: any) => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
  };

  const getValue = (data: any) => {
    setExchangeInputVlaue(data)
  }
  const getValue2 = (data: any) => {
    setExchangeInputVlaue2(data)
  }
  useEffect(() => {
    document.title = props.title ;

  },[])

  return (
    <section className="form_wrapper">
      <div className="form_sec">
        <div className="heading_icon">
          <h3>Main Account</h3>
        </div>
        <form className="trade_form">
          <p className="form_brief">Direction:</p>
          <div className="direction_form" id={isActive ? 'rowReverse' : ''} >
            <div className="main_accounts">
              <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 8C2.5 4.691 5.191 2 8.5 2C11.809 2 14.5 4.691 14.5 8C14.5 11.309 11.809 14 8.5 14C5.191 14 2.5 11.309 2.5 8ZM18.207 16.293L14.812 12.897C15.865 11.543 16.5 9.846 16.5 8C16.5 3.589 12.911 0 8.5 0C4.089 0 0.5 3.589 0.5 8C0.5 12.411 4.089 16 8.5 16C10.346 16 12.043 15.365 13.397 14.312L16.793 17.707C16.988 17.902 17.244 18 17.5 18C17.756 18 18.012 17.902 18.207 17.707C18.598 17.316 18.598 16.684 18.207 16.293Z" fill="#8C9298" />
              </svg>
              <input type="text" className="input_Account exchangeInput" placeholder="Main Account"
                onFocus={() => { setShow(current => !current) }}
                onBlur={() => { setShow(current => !current) }}
                value={exchangeInputVlaue} />
              <div className="account_wrapper" id={isShow ? 'show' : ''} >
                <ul>
                  <li onClick={() => { getValue("Main Account") }}>Main Account</li>
                  <li onClick={() => { getValue("Funding Account") }}>Funding Account</li>
                </ul>
              </div>
            </div>
            <button type="button" id="exchange_btn" onClick={rowReverse}>
              <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.4996 14L12.4996 1.00002C12.4996 0.448021 12.9466 2.08828e-05 13.4996 2.0907e-05C14.0526 2.09311e-05 14.4996 0.448021 14.4996 1.00002L14.4996 14.082L15.7096 12.524C16.0486 12.088 16.6776 12.009 17.1126 12.348C17.5496 12.687 17.6286 13.316 17.2896 13.751L14.2896 17.613C14.0986 17.86 13.8036 18.002 13.4906 18C13.1796 17.998 12.8866 17.85 12.6996 17.6L9.69962 13.6C9.56462 13.42 9.49962 13.209 9.49962 13.001C9.49962 12.697 9.63762 12.396 9.89962 12.2C10.3416 11.869 10.9686 11.958 11.2996 12.4L12.4996 14ZM5.49962 4.00002L5.49961 17C5.49961 17.552 5.05262 18 4.49961 18C3.94661 18 3.49961 17.552 3.49961 17L3.49962 3.91802L2.28962 5.47602C1.95062 5.91202 1.32161 5.99102 0.886616 5.65202C0.632616 5.45502 0.499614 5.16002 0.499615 4.86202C0.499615 4.64702 0.567615 4.43102 0.709616 4.24902L3.70962 0.38702C3.90062 0.14002 4.19562 -0.00197963 4.50862 2.03795e-05C4.81962 0.00202039 5.11262 0.15002 5.29962 0.40002L8.29962 4.40002C8.63062 4.84302 8.54162 5.46902 8.09962 5.80002C7.65762 6.13102 7.03062 6.04202 6.69962 5.60002L5.49962 4.00002Z" fill="#6A718A" />
              </svg>
            </button>
            <div className="main_accounts">
              <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.5 8C2.5 4.691 5.191 2 8.5 2C11.809 2 14.5 4.691 14.5 8C14.5 11.309 11.809 14 8.5 14C5.191 14 2.5 11.309 2.5 8ZM18.207 16.293L14.812 12.897C15.865 11.543 16.5 9.846 16.5 8C16.5 3.589 12.911 0 8.5 0C4.089 0 0.5 3.589 0.5 8C0.5 12.411 4.089 16 8.5 16C10.346 16 12.043 15.365 13.397 14.312L16.793 17.707C16.988 17.902 17.244 18 17.5 18C17.756 18 18.012 17.902 18.207 17.707C18.598 17.316 18.598 16.684 18.207 16.293Z" fill="#8C9298" />
              </svg>
              <input type="text" className="input_Account" placeholder="Trading Account"
                onFocus={() => { setShow2(current => !current) }}
                onBlur={() => { setShow2(current => !current) }}
                value={exchangeInputVlaue2}
              />
              <div className="account_wrapper" id={isShow2 ? 'show' : ''} >
                <ul>
                  <li onClick={() => { getValue2("Main Account") }}>Main Account</li>
                  <li onClick={() => { getValue2("Funding Account") }}>Funding Account</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="main_accounts account--modifier">
            <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M2.5 8C2.5 4.691 5.191 2 8.5 2C11.809 2 14.5 4.691 14.5 8C14.5 11.309 11.809 14 8.5 14C5.191 14 2.5 11.309 2.5 8ZM18.207 16.293L14.812 12.897C15.865 11.543 16.5 9.846 16.5 8C16.5 3.589 12.911 0 8.5 0C4.089 0 0.5 3.589 0.5 8C0.5 12.411 4.089 16 8.5 16C10.346 16 12.043 15.365 13.397 14.312L16.793 17.707C16.988 17.902 17.244 18 17.5 18C17.756 18 18.012 17.902 18.207 17.707C18.598 17.316 18.598 16.684 18.207 16.293Z" fill="#8C9298" />
            </svg>
            <input type="text" id="selectCoin" className="input_Account" placeholder="Select Coin" />
          </div>
          <p className="form_brief">Amount:</p>
          <input type="text" className="input_Account input--Account" placeholder="Account" />
          <div className="btn">
            <button type="submit" className="tmb_btn">Submit</button>
          </div>
        </form>
      </div>
    </section>
  )
}
export default AccountForm;