import React, { useEffect } from 'react'
import { title } from '../config/config'

const Privacy = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, []);
  return (
    <>
    <div className='privacy___section'>
        <div className='a_container'>
            <div className='privacy_header'>
                <h1> Privacy Notice - {title}</h1>
                <p>Last updated: 29 September 2022 </p>
            </div>
            <div className='card-privacy_body'>
                <p>{title} ({title}, “we”, or “us”) is committed to protecting the privacy of our customers, and we take our data protection responsibilities with the utmost seriousness.</p>

                <p>
                This Privacy Notice describes how {title} collects and processes your personal information through the {title} websites and applications that reference this Privacy Notice. {title} refers to an ecosystem comprising {title} websites (whose domain names include but are not limited to www.{title}.com/en), mobile applications, clients, applets and other applications that are developed to offer {title} Services, and includes independently-operated platforms, websites and clients within the ecosystem (e.g., {title}’s Open Platform, {title} Launchpad, {title} Labs, {title} Charity, {title} DEX, {title} X, JEX, and fiat gateways). “{title} Operators” refer to all parties that run {title}, including but not limited to legal persons, unincorporated organizations and teams that provide {title} Services and are responsible for such services. “{title}” as used in this Privacy Notice includes {title} Operators.
                </p>

                <p>This Privacy Notice applies to all Personal Information processing activities carried out by us, across platforms, websites, and departments of {title} and {title} Operators.</p>

                <p> To the extent that you are a customer or user of our services, this Privacy Notice applies together with any terms of business and other contractual documents, including but not limited to any agreements we may have with you.</p>
                <p> To the extent that you are not a relevant stakeholder, customer or user of our services, but are using our website, this Privacy Notice also applies to you together with our Cookie Notice.</p>
                <p> This Notice should therefore be read together with our Cookie Notice, which provides further details on our use of cookies on the website. Our Cookie Notice can be accessed here.</p>
                <div className='list___parivacy'>
                    <div className='list___privacy_header'>
                        <h3> 1. {title} Relationship with you</h3>
                    </div>
                    <div className='list___privacy_body'>
                        <p>{title} (Services Holdings) Limited, a company registered at 6th Floor, South Bank House, Barrow Street, 4 Dublin, Ireland, is the data controller for personal information collected in connection with provision of {title} services in India.</p>

                        <p>However, depending on your place of legal residence certain other {title} entities could be involved in processing activities such as Know Your Customer (“KYC”) activities which are necessary for us to provide Services to you. For example, if you are resident in France, {title} France SAS, is responsible for running KYC checks. These {title} entities could act as Controllers of your personal information and use it in accordance with this Privacy Notice.</p>

                    </div>

                </div>

                <div className='list___parivacy'>
                    <div className='list___privacy_header'>
                        <h3> 2. What Personal Information does {title} collect and process? Why does {title} process my personal information ? What are the legal basis for our use of personal information?</h3>
                    </div>
                    <div className='list___privacy_body'>
                        <div className='row'>
                            <div className='col-4'>
                                <h4> What personal information does {title} collect and process?  </h4>

                                <div className='card___coulm_privacy'>
                                    <p> - email address;</p>
                                    <p>  - name; </p>
                                    <p> - gender;  </p>
                                    <p>  - date of birth; </p>
                                    <p> - home address;  </p>
                                    <p>- phone number;   </p>
                                    <p> - nationality;  </p>
                                    <p>  - device ID; </p>
                                    <p>  - a video recording of you and a photographic image; </p>
                                    <p>  - transactional information; </p>
                                    <p> - the Internet protocol (IP) address used to connect your computer to the Internet;  </p>
                                    <p> - login, e-mail address, password and location of your device or computer;  </p>
                                    <p> - {title} Services metrics (e.g., the occurrences of technical errors, your interactions with service features and content, and your settings preferences);  </p>
                                    <p>  - version and time zone settings; </p>
                                    <p> - transaction history;  </p>
                                    <p>  - Information from other sources: we may receive information about you from other sources such as credit history information from credit bureaus; </p>
                                    <p>- Information about your activity we may process information about you on your behaviour and your activity for marketing and advertising purposes.   </p>
                                </div>
                            </div>

                            <div className='col-4'>                             
                                <h4> Why does {title} process my personal information? </h4>
                                <div className='card___coulm_privacy'>
                                    <p>- Transaction services. We use your personal information to process your orders, and to communicate with you about orders and services;</p>
                                    <p> - Communicate with you. We use your personal information to communicate with you in relation to {title} Services; </p>
                                    <p> - We collect and process identity information and Sensitive Personal Data (as detailed in section I) to comply with our Know Your Customer (“KYC”) obligations under applicable laws and regulations, and Anti-Money Laundering laws and regulations; </p>
                                    <p>- Provide, troubleshoot, and improve {title} Services. We use your personal information to provide functionality, analyse performance, fix errors, and improve the usability and effectiveness of {title} Services.  </p>
                                    <p> Fraud prevention and credit risks. We process personal information to prevent and detect fraud and abuse in order to protect the security of our users, {title} Services and others. We may also use scoring methods to assess and manage credit risks. </p>
                                    <p>- Improve our services. We process personal information to improve our services and for you to have a better user experience;  </p>
                                    <p> - Recommendations and personalisation. We use your personal information to recommend features and services that might be of interest to you, identify your preferences, and personalise your experience with {title} Services; </p>
                                </div>
                            </div>

                            <div className='col-4'>                             
                                <h4> Legal Basis for our use of personal information (EU and UK GDPR) </h4>
                                <div className='card___coulm_privacy'>
                                    <p>Performance of a contract when we provide you with products or services, or communicate with you about them. This includes when we use your personal information to take and handle orders, and process payments.</p>
                                   <p> Legal obligation; to comply with our legal obligations under applicable laws and regulations, and Anti-Money Laundering laws and regulations. </p>
                                   <p>  Your consent when we ask for your consent to process your personal information for a specific purpose that we communicate to you. When you consent to processing your personal information for a specified purpose, you may withdraw your consent at any time and we will stop processing your personal information for that purpose. The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal. </p>
                                   <p> Our legitimate interests and the interests of our users when, for example, we detect and prevent fraud and abuse in order to protect the security of our users, ourselves, or others; </p>
                                   <p>Performance of a contract when we provide you with products or services, or communicate with you about them. This includes when we use your personal information to take and handle orders, and process payments.  </p>
                                   <p> Legal obligation; to comply with our legal obligations under applicable laws and regulations, and Anti-Money Laundering laws and regulations </p>
                                   <p>Our legitimate interests and the interests of our users when, for example, we detect and prevent fraud and abuse in order to protect the security of our users, ourselves, or others;  </p>
                                   <p> Our legitimate interest to improve our services; </p>
                                   <p> Your consent when we ask for your consent to process your personal information for a specific purpose that we communicate to you. When you consent to processing your personal information for a specified purpose, you may withdraw your consent at any time and we will stop processing your personal information for that purpose. The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal </p>
                                </div>
                            </div>


                        </div>


                    </div>

                </div>



                <div className='list___parivacy'>
                    <div className='list___privacy_header'>
                        <h3> 3. Can Children Use {title} Services?</h3>
                    </div>
                    <div className='list___privacy_body'>
                        <p>{title} does not allow anyone under the age of 18 to use {title} Services and does not knowingly collect personal information from children under 18.</p>
                    </div>

                </div>

                <div className='list___parivacy'>
                    <div className='list___privacy_header'>
                        <h3> 4. What About Cookies and Other Identifiers?</h3>
                    </div>
                    <div className='list___privacy_body'>
                        <p>We use cookies and similar tools to enhance your user experience, provide our services, enhance our marketing efforts and understand how customers use our services so we can make improvements. Depending on applicable laws in the region you are located in, the cookie banner on your browser will tell you how to accept or refuse cookies. A copy of our cookie policy is available here.</p>
                    </div>

                </div>


                <div className='list___parivacy'>
                    <div className='list___privacy_header'>
                        <h3> 5. Does {title} Share My Personal Information?</h3>
                    </div>
                    <div className='list___privacy_body'>
                        <p>We may share your Personal Data with third parties (including other {title} entities) if we believe that sharing your Personal Data is in accordance with, or required by, any contractual relationship with you or us, applicable law, regulation or legal process. When sharing your Personal Information with other {title} entities, we will use our best endeavours to ensure that such entity is either subject to this Privacy Notice, or follow practices at least as protective as those described in this Privacy Notice. For example, if you are resident in France, {title} France SAS, is responsible for running KYC checks.</p>
                        <p> We may also share personal information with the following persons:</p>

                        <div className='ul_list'>
                            <li>   Third party service providers: We employ other companies and individuals to perform functions on our behalf. Examples include analysing data, providing marketing assistance, processing payments, transmitting content, and assessing and managing credit risk. These third-party service providers only have access to personal information needed to perform their functions, but may not use it for other purposes. Further, they must process the personal information in accordance with our contractual agreements and only as permitted by applicable data protection laws.</li>
                            <li> Legal Authorities: We may be required by law or by Court to disclose certain information about you or any engagement we may have with you to relevant regulatory, law enforcement and/or other competent authorities. We will disclose information about you to legal authorities to the extent we are obliged to do so according to the law. We may also need to share your information in order to enforce or apply our legal rights or to prevent fraud. </li>
                            <li> Business transfers: As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, user information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Notice (unless, of course, the user consents otherwise). Also, in the unlikely event that {title} or substantially all of its assets are acquired by a third party, user information will be one of the transferred assets.</li>
                            <li> Protection of {title} and others: We release accounts and other personal information when we believe release is appropriate to comply with the law or with our regulatory obligations; enforce or apply our Terms of Use and other agreements; or protect the rights, property or safety of {title}, our users or others. This includes exchanging information with other companies and organisations for fraud protection and credit risk reduction.</li>
                        </div>
                    </div>

                </div>




            </div>

        </div>
    </div>
    </>
  )
}

export default Privacy