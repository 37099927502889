import { Link } from "react-router-dom";
import { title } from "../config/config";

const JoinSikka = () => {
    return (
        <>
            <section className="join_sikka sec-py s-secondary-bg">
                <div className="container">
                    <div className="sec_content">
                        <div className="sec_text">
                            <h2 className="sec_heading s-normal-color">
                                Join The <span className="f-cta-color">{title}</span>  Community
                            </h2>
                            <p className="sec_info">
                                to share ideas with other investors
                            </p>
                            <Link to="/" className="sec_button">
                                <img src={require("../Assets/Icons/telegram-icon.svg").default} alt="" />
                                Join Now
                            </Link>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default JoinSikka;