import React, { useEffect, useState } from 'react'

import { logo, title } from '../config/config';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
const Transfer = (props:any) => {
  const onCloseModal = () => setOpenT(false);
  // Tranfer button Modal 
  const [openT, setOpenT] = useState(false);
  const onOpenModalT = () => setOpenT(true);
  const onCloseModalT = () => setOpenT(false);
  useEffect(() => {
		document.title = props.title ;

  },[])


  return (
    <>
      <div className='container-max'>
        <div className="tranfer__table" >
          <div className="table-section-1" style={{ padding: '0 0 20px' }} >
            <div className="table-body">
              <div className="table-responsive">
                <table className="table tranfer-table__modal-s">
                  <thead>
                    <tr>
                      <th>	COIN </th>
                      <th>	LAST PRICE	</th>
                      <th>24H CHANGE	</th>
                      <th>	MARKETS</th>
                      <th> 	TRADE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="img__td">
                          <img src={logo} alt="Icon" width="30px" />
                        </div>
                        <div className="Text__td">
                          <p>{title}</p>
                          <small>{title}</small>
                        </div>
                      </td>
                      <td>
                        <p> 0 {title}</p>
                        <small> <img src={require("../Assets/Icons/lock.svg").default} alt="lock-Icon" width="15px" />  0 {title}</small>
                      </td>
                      <td>
                        <p>0 {title}</p>
                      </td>
                      <td>
                        <p> -</p>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <div className="cta_wrapper">
                          <button className="trade_btn active" onClick={() => setOpenT(true)} > Transfer </button>
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={openT} onClose={onCloseModalT}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal_S',
        }} center  >
        <div className="card-header">
          <h2 className="title-modal"> Funding Account </h2>
        </div>
        <div className="modail-data">
          <div className="modal-body">
            <form>
              <div className="form-group" id="dropS_a">
                <label htmlFor="usr">Direction:</label>
                <div className="input-area dropdown">
                  <span>
                    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="1">
                        <circle cx="8.71795" cy="8.71795" r="7.96795" stroke="#03DFF9" strokeWidth="1.5" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.0201 13.9795C14.2844 13.7152 14.2348 13.2367 14.4991 13.501L20.8014 19.8457C21.0657 20.1099 21.0657 20.5384 20.8014 20.8027C20.5371 21.067 20.1086 21.067 19.8443 20.8027L13.542 14.458C13.2777 14.1938 13.7558 14.2438 14.0201 13.9795Z" fill="#03DFF9" />
                        <rect opacity="0.04" x="6.35" y="4.35" width="6.3" height="6.3" rx="3.15" stroke="#03DFF9" strokeWidth="0.7" />
                      </g>
                    </svg>
                  </span>
                  <input list="Direction" name="browser" id="browser" />
                  <datalist id="Direction">
                    <option value="Funding Account" />
                    <option value="Trading Account " />
                  </datalist>
                </div>
              </div>
              <div className="form-group" id="dropS_ab" >
                <label htmlFor="accountName">Account:</label>
                <div className="input-area dropdown">
                  <span >
                    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="1">
                        <circle cx="8.71795" cy="8.71795" r="7.96795" stroke="#03DFF9" strokeWidth="1.5" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.0201 13.9795C14.2844 13.7152 14.2348 13.2367 14.4991 13.501L20.8014 19.8457C21.0657 20.1099 21.0657 20.5384 20.8014 20.8027C20.5371 21.067 20.1086 21.067 19.8443 20.8027L13.542 14.458C13.2777 14.1938 13.7558 14.2438 14.0201 13.9795Z" fill="#03DFF9" />
                        <rect opacity="0.04" x="6.35" y="4.35" width="6.3" height="6.3" rx="3.15" stroke="#03DFF9" strokeWidth="0.7" />
                      </g>
                    </svg>
                  </span>
                  <input list="Direction" name="browser" id="browser" />
                  <datalist id="Direction">
                    <option value="Funding Account" />
                    <option value="Trading Account " />
                  </datalist>
                </div>
              </div>
              <div className="form-group-12 form-group">
                <label htmlFor="coin_name">Coin</label>
                <div className="input-area">
                  <span>
                    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="1">
                        <circle cx="8.71795" cy="8.71795" r="7.96795" stroke="#03DFF9" strokeWidth="1.5" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.0201 13.9795C14.2844 13.7152 14.2348 13.2367 14.4991 13.501L20.8014 19.8457C21.0657 20.1099 21.0657 20.5384 20.8014 20.8027C20.5371 21.067 20.1086 21.067 19.8443 20.8027L13.542 14.458C13.2777 14.1938 13.7558 14.2438 14.0201 13.9795Z" fill="#03DFF9" />
                        <rect opacity="0.04" x="6.35" y="4.35" width="6.3" height="6.3" rx="3.15" stroke="#03DFF9" strokeWidth="0.7" />
                      </g>
                    </svg>
                  </span>
                  <input type="text" className="form-control" id="coin_name" />
                </div>
              </div>
              <div className="form-group-12 form-group">
                <div className="input-area">
                  <input type="text" className="form-control" id="usr" placeholder="Amount" />
                </div>
              </div>
              <div className="btn__form">
                <button type="submit" className="btn trade_btn active"> Submit</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Transfer