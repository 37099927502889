import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import BG_imgs from '../Assets/Images/about.png'
import { logo, title } from '../config/config'
import InfoIcon from '@mui/icons-material/Info';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import fast_img from "../Assets/Icons/NewCoins/fast.svg"

import tron from "../Assets/Images/Footer_IMG/tron.png"


const BuyTron = (props:any) => {
    useEffect(() => {
        document.title = props.title ;
    }, []);
    const coin_A = 'Tron';   /* full coin name   */
    const short_n = 'Tron';  /* short coin name   */

    return (
    <>
     <div className='coin__E__main'>
        {/* Banenr  */}
        <div className='career__banenr' style={{ backgroundImage: `url(${BG_imgs})` }}>
            <div className='career__banenr__container'>
                <div className=''>
                    <img src={tron} alt="coin_img" width="100px" />
                    <h1 className='heading_career'>How to Buy {coin_A} Guide at </h1>
                    <p className='pragraph__career'>Update: 2023-01-29 19:30:00 on {title} Market</p>
                    <Link to='/login' className='btn'>Register now</Link>

                </div>
                {/* <img className='coin__E_icon' src={logo} alt="icon/> */}

            </div>
        </div>

        <div className='values_container'>
            <div className='row'>
                <div className='col-7'>
                    <div className='left__E_section'>

                        <div className='coumn__E left__coin_E'>
                            <div className='card_title_e'>
                                <p>Want to buy crypto but not sure how? No worries! {title} offers many options. You can easily buy {coin_A} ({short_n}) with the lowest fees and highest security anywhere {title} is available. Learn how to buy {coin_A} ({short_n}) with the {title} app now.</p>
                            </div>

                            <div className='list__E_1'>
                                <div className='list__E_title'>
                                    <h2> <span>1</span>  Create a free account on the {title} website or the app.</h2>
                                </div>
                                <div className='list_E_body'>
                                    <p>Your {title} account acts as a gateway into buying crypto. But before you can buy {coin_A} ({short_n}), you’ll need to open an account and verify your identification.</p>
                                    <p>Register via the <Link to="/">{title}</Link> App</p>
                                    <p>Register via the website using your  <Link to="/faq">email</Link> App</p>
                                    <p>Register via the website using your  <Link to="/faq">mobail number</Link> App</p>
                                </div>
                            </div>
                        </div> 


                        <div className='coumn__E left__coin_E'>
                            <div className='list__E_1'>
                                <div className='list__E_title'>
                                    <h2> <span>1</span> Choose how you want to buy the {coin_A} ({short_n}) asset.</h2>
                                </div>
                                <div className='list_E_body'>
                                    <p>Click on the “Buy Crypto” link on the top left of the {title} website navigation, which will show the available options in your country.</p>

                                    <div className='list_alert'>
                                        <p><InfoIcon/>  For better coin compatibility, you consider buying a stablecoin like USDT or BUSD first, and then use that coin to buy {coin_A} ({coin_A}).</p>
                                    </div>

                                    <div className='inner_list_s'>
                                        <h4>A. Credit Card and Debit Card</h4>
                                        <p> If you are a new user, this is the easiest option to purchase {coin_A} ({short_n}). {title} supports both Visa and MasterCard.</p>
                                        {/* <div className='videos___section'></div> */}
                                    </div>

                                    <div className='inner_list_s'>
                                        <h4>B. Bank Deposit</h4>
                                        <p> Transfer fiat currency from your bank account to {title}, and then use the amount to buy {coin_A} ({short_n}).</p>
                                        {/* <div className='videos___section'></div> */}
                                    </div>

                                    <div className='inner_list_s'>
                                        <h4>C. P2P Trading</h4>
                                        <p> Buy {coin_A} ({short_n}) directly from other users with {title}’s peer-to-peer service.</p>
                                        {/* <div className='videos___section'></div> */}
                                    </div>

                                    <div className='inner_list_s'>
                                        <h4>D. Third Party Payment</h4>
                                        <p> There are multiple options for third-party payment channels. Please, visit the <Link to="/faq">{title} FAQ </Link> to check which ones are available in your region.</p>
                                        {/* <div className='videos___section'></div> */}
                                    </div>
                                
                                </div>
                            </div>
                        </div> 

                        <div className='coumn__E left__coin_E'>
                            <div className='list__E_1'>
                                <div className='list__E_title'>
                                    <h2> <span>3</span> Create a free account on the {title} website or the app.</h2>
                                </div>
                                <div className='list_E_body'>
                                    <p>You have 1 minute to confirm your order at the current price. After 1 minute, your order will be recalculated based on the current market price. You can click Refresh to see the new order amount.</p>
                                </div>
                            </div>
                        </div> 

                        <div className='coumn__E left__coin_E'>
                            <div className='list__E_1'>
                                <div className='list__E_title'>
                                    <h2> <span>4</span> Store or use your {coin_A} ({coin_A}) in {title}.</h2>
                                </div>
                                <div className='list_E_body'>
                                    <p>Now that you bought your crypto, you can store it in your personal crypto wallet or simply hold it in your {title} account. You can also trade for other crypto or stake it on {title} Earn for passive income. If you would like to trade your {coin_A} ({short_n}) to a decentralized exchange you may want to check Trust Wallet which supports millions of assets and blockchains.</p>

                                    <div className='list_alert'>
                                        <p><InfoIcon/>  Want to keep tabs on coin prices? Visit our coin price directory to add to bookmark.</p>
                                    </div>

                                </div>
                            </div>
                        </div> 


                        <div className='New__coins_section'>
                            <div className='New_sec_header_coin'>
                                <h1>{title}: Where The World Trades {coin_A} ({short_n})</h1>
                            </div>
                            <div className='New_coin_body'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='card'>
                                            <img src={fast_img} alt="icon"/>
                                            <h4> Fast</h4>
                                            <p>{title}’s world-class matching engine supports up to 1,400,000 orders per second. This ensures that your crypto trading experience is quick and reliable.</p>
                                        </div>
                                    </div>

                                    <div className='col-6'>
                                        <div className='card'>
                                            <img src={fast_img} alt="icon"/>
                                            <h4> Simple</h4>
                                            <p>Trading crypto on {title} is easy and intuitive. You only need a few steps to buy {coin_A} ({short_n}).</p>
                                        </div>
                                    </div>

                                    <div className='col-6'>
                                        <div className='card'>
                                            <img src={fast_img} alt="icon"/>
                                            <h4> Secure & Trusted</h4>
                                            <p>Millions of global crypto users trust us. Our multi-tier & multi-cluster system architecture and SAFU (Secure Assets Fund for Users) insurance fund protect your account.</p>
                                        </div>
                                    </div>

                                    <div className='col-6'>
                                        <div className='card'>
                                            <img src={fast_img} alt="icon"/>
                                            <h4> Liquid</h4>
                                            <p>{title} is the world’s most liquid cryptocurrency exchange, with the biggest volumes across multiple crypto pairs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='New__coins_section'>
                            <div className='New_sec_header_coin'>
                                <h1>Start buying {coin_A}</h1>
                            </div>
                            <div className='New_coin_body'>
                                <p>If you’re ready to take the next step and want to buy some {coin_A}, take the next step with {title}!</p>
                                <Link className='btn' to="/login">Start Now </Link>

                                <div className='last__END'>
                                    <p>Cryptocurrency prices are subject to high market risk and price volatility. You should only invest in products that you are familiar with and where you understand the associated risks. The content expressed on this page is not intended to be and shall not be construed as an endorsement by {title} about the reliability or accuracy of such content. You should carefully consider your investment experience, financial situation, investment objectives and risk tolerance and consult an independent financial adviser prior to making any investment. This material should not be construed as financial advice. Past performance is not a reliable indicator of future performance. The value of your investment can go down as well as up, and you may not get back the amount you invested. You are solely responsible for your investment decisions. {title}  is not responsible for any losses you may incur. For more information, please refer to our Terms of Use and Risk Warning.</p>
                                    <p> Please also note that data relating to the above-mentioned cryptocurrency presented here (such as its current live price) are based on third party sources. They are presented to you on an “as is” basis and for informational purposes only, without representation or warranty of any kind. Links provided to third-party sites are also not under {title}’s control. {title} is not responsible for the reliability and accuracy of such third-party sites and their contents.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-3'>
                    <div className='right_E_sec'>
                        <div className='card'>
                            <div className='right_E-header'>
                                <img src={tron} alt="bitcoin-icon"/>
                                <h2>{coin_A}</h2>
                            </div>
                            <div className='card__right_body'>
                                <div className='price___coin'>
                                    <h3>$ 23,576.32 USD </h3>
                                    <p>+2.64% <span>(24H)</span></p>
                                </div>
                                <div className='card__right_alet'>
                                    <p>The live price of {coin_A} is $ 23,576.32 per ({short_n} / USD) today with a current market cap of $ 454.46B USD. 24-hour trading volume is $ 21.59B USD. {coin_A} to USD price is updated in real-time. {coin_A} is +2.64% in the last 24 hours. It has a circulating supply of 19.28M.</p>
                                </div>

                                <Link to="/" className='btn'> Buy {coin_A}</Link>
                            </div>

                            <div className='more__coins'>
                                <h3>How to Buy Other Coins</h3>
                                <div className='coin__sec_12'>
                                    <div className='coin__sec_data'>
                                        <img src={tron} alt="bitcoin-icon"/>
                                        <h3>Shiba Inu (SHIB) </h3>
                                   </div>
                                    <ChevronRightIcon/>
                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>









    </div>
    </>
  )
}

export default BuyTron