import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import { base_url, logo, title } from '../config/config';
// import StarsIcon from '@mui/icons-material/Stars';
// import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Public_Policy = (props:any) => {


    useEffect(() => {
        document.title = props.title;
    }, [])


   const ADD_security = (id: any) => {
    const a: HTMLElement | any = document.getElementById(id);
    a.classList.toggle("page__a");
 }
 const remove_security = (id: any) => {
    const a: HTMLElement | any = document.getElementById(id);
    a.classList.remove("page__a");
 }



  return (
    <>
      <div className='public_security_section___bg__A'>
        <div className='public_security_section main___desktop pt__spacing'>
                <div className=''>
                    <div className='section__anyone' id='section_body_A' >
                    <div className='sec__header__a'>
                        <div className=''>
                            <div className='section_header_security'>
                                <p>Product News </p>
                                <div className='secion___bacn_icon' onClick={() => remove_security('section_body_A')} >  <ArrowBackIcon/> </div>


                                <div className='search__bar_a'>
                                    <form>
                                        <div className='form_search'>
                                            <SearchIcon/>
                                            <input type="search" className='input__section' placeholder='Search'/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                        <div className='section_body_A' >
                        <div className=''>
                            <Tabs>
                                    <div className='row' onClick={() => ADD_security('section_body_A')}>
                                        <div className='col-4'>
                                            <div className="security-header_vc"  >
                                                <TabList>
                                                    <Tab>Deposit/Withdraw</Tab>
                                                    <Tab>Fiat Transaction</Tab>
                                                    <Tab>Policies</Tab>
                                                    <Tab>Download/Register/Log in</Tab>

                                                </TabList>
                                            </div>
                                        </div>


                                        <div className='col-8'>
                                            <div className='column__8_security'>
                                                {/* ================  */}
                                                <TabPanel>
                                                    <div className='security-card'>
                                                        <div className='security-card_header__demo'>
                                                            <h2 className='card__title_security'> 
                                                                Deposit/Withdraw
                                                            </h2>
                                                            <p>Dear {title} users:</p>

                                                        </div>

                                                            

                                                        <div className='logo__security'>
                                                            <img src={logo} alt="logo_icon"/>
                                                            <div className=''>
                                                                <h2>{title}</h2>
                                                                <h6> 10 day ago</h6>
                                                            </div>
                                                        </div>

                                                        <h4 className='title__mb'>Please read the Privacy Policy carefully.</h4>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Parties</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                {title} operates the Website {base_url} (hereinafter referred to as 'this Website' or 'the Website'), which is a platform dedicated to the transaction of digital assets and the provision of related services (hereinafter referred to as 'the Service'). For the convenience of wording in this Agreement, the Company and the Website are referred to collectively as 'we' or other applicable forms of first person pronouns in this Agreement.

                                                                </li>
                                                                <li>  All natural persons or other subjects who log onto this Website shall be users of this Website. For the convenience of wording in this Agreement, the users are referred to as 'you' or any other applicable forms of the second-person pronouns. </li>
                                                                <li> For the convenience of wording in this Agreement, you and we are collectively referred to as “both parties”, and individually as “one party”.  </li>
                                                            </ul>
                                                        </div>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Purposes of the Privacy Policy</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                The Privacy Policy stipulates the types of information of yours that we may collect through your log into this Website, your registration with this Website, and/or use the services we offer, as well as how we shall use and protect the information so collected.
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Your consent</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                To ensure that you have full confidence in our handling of personal data, you are advised to read and understand the terms of this Privacy Policy in detail. In particular, upon your log into our Website, regardless of whether you register with this Website, you shall be deemed to accept, agree, undertake and confirm that:
                                                                </li>
                                                            </ul>
                                                        </div>


                                                        <div className='help_full'>
                                                            <p>Was this article helpful?</p>
                                                            <button type='button' className='btn'> <CheckIcon/>  Yes </button>
                                                            <button type='button' className='btn'> <CloseIcon/> No</button>
                                                        </div>

                                                    </div>
                                                </TabPanel>
                                                {/* ================  */}

                                                {/* ================  */}
                                                <TabPanel>
                                                    <div className='security-card'>
                                                        <div className='security-card_header__demo'>
                                                            <h2 className='card__title_security'> 
                                                            Fiat Transaction
                                                            </h2>
                                                            <p>Dear {title} users:</p>

                                                        </div>

                                                            

                                                        <div className='logo__security'>
                                                            <img src={logo} alt="logo_icon"/>
                                                            <div className=''>
                                                                <h2>{title}</h2>
                                                                <h6> 10 day ago</h6>
                                                            </div>
                                                        </div>

                                                        <h4 className='title__mb'>Please read the Privacy Policy carefully.</h4>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Parties</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                {title} operates the Website {base_url} (hereinafter referred to as 'this Website' or 'the Website'), which is a platform dedicated to the transaction of digital assets and the provision of related services (hereinafter referred to as 'the Service'). For the convenience of wording in this Agreement, the Company and the Website are referred to collectively as 'we' or other applicable forms of first person pronouns in this Agreement.

                                                                </li>
                                                                <li>  All natural persons or other subjects who log onto this Website shall be users of this Website. For the convenience of wording in this Agreement, the users are referred to as 'you' or any other applicable forms of the second-person pronouns. </li>
                                                                <li> For the convenience of wording in this Agreement, you and we are collectively referred to as “both parties”, and individually as “one party”.  </li>
                                                            </ul>
                                                        </div>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Purposes of the Privacy Policy</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                The Privacy Policy stipulates the types of information of yours that we may collect through your log into this Website, your registration with this Website, and/or use the services we offer, as well as how we shall use and protect the information so collected.
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Your consent</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                To ensure that you have full confidence in our handling of personal data, you are advised to read and understand the terms of this Privacy Policy in detail. In particular, upon your log into our Website, regardless of whether you register with this Website, you shall be deemed to accept, agree, undertake and confirm that:
                                                                </li>
                                                            </ul>
                                                        </div>


                                                        <div className='help_full'>
                                                            <p>Was this article helpful?</p>
                                                            <button type='button' className='btn'> <CheckIcon/>  Yes </button>
                                                            <button type='button' className='btn'> <CloseIcon/> No</button>
                                                        </div>

                                                    </div>
                                                </TabPanel>
                                                {/* ================  */}


                                                {/* ================  */}
                                                <TabPanel>
                                                    <div className='security-card'>
                                                        <div className='security-card_header__demo'>
                                                            <h2 className='card__title_security'> 
                                                                Privacy Policy
                                                            </h2>
                                                            <p>Dear {title} users:</p>

                                                        </div>

                                                            

                                                        <div className='logo__security'>
                                                            <img src={logo} alt="logo_icon"/>
                                                            <div className=''>
                                                                <h2>{title}</h2>
                                                                <h6> 10 day ago</h6>
                                                            </div>
                                                        </div>

                                                        <h4 className='title__mb'>Please read the Privacy Policy carefully.</h4>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Parties</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                {title} operates the Website {base_url} (hereinafter referred to as 'this Website' or 'the Website'), which is a platform dedicated to the transaction of digital assets and the provision of related services (hereinafter referred to as 'the Service'). For the convenience of wording in this Agreement, the Company and the Website are referred to collectively as 'we' or other applicable forms of first person pronouns in this Agreement.

                                                                </li>
                                                                <li>  All natural persons or other subjects who log onto this Website shall be users of this Website. For the convenience of wording in this Agreement, the users are referred to as 'you' or any other applicable forms of the second-person pronouns. </li>
                                                                <li> For the convenience of wording in this Agreement, you and we are collectively referred to as “both parties”, and individually as “one party”.  </li>
                                                            </ul>
                                                        </div>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Purposes of the Privacy Policy</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                The Privacy Policy stipulates the types of information of yours that we may collect through your log into this Website, your registration with this Website, and/or use the services we offer, as well as how we shall use and protect the information so collected.
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Your consent</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                To ensure that you have full confidence in our handling of personal data, you are advised to read and understand the terms of this Privacy Policy in detail. In particular, upon your log into our Website, regardless of whether you register with this Website, you shall be deemed to accept, agree, undertake and confirm that:
                                                                </li>
                                                            </ul>
                                                        </div>


                                                        <div className='help_full'>
                                                            <p>Was this article helpful?</p>
                                                            <button type='button' className='btn'> <CheckIcon/>  Yes </button>
                                                            <button type='button' className='btn'> <CloseIcon/> No</button>
                                                        </div>

                                                    </div>
                                                </TabPanel>
                                                {/* ================  */}



                                                {/* ================  */}
                                                <TabPanel>
                                                    <div className='security-card'>
                                                        <div className='security-card_header__demo'>
                                                            <h2 className='card__title_security'> 
                                                                Download/Register/Log in
                                                            </h2>
                                                            <p>Dear {title} users:</p>

                                                        </div>

                                                            

                                                        <div className='logo__security'>
                                                            <img src={logo} alt="logo_icon"/>
                                                            <div className=''>
                                                                <h2>{title}</h2>
                                                                <h6> 10 day ago</h6>
                                                            </div>
                                                        </div>

                                                        <h4 className='title__mb'>Please read the Privacy Policy carefully.</h4>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Parties</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                {title} operates the Website {base_url} (hereinafter referred to as 'this Website' or 'the Website'), which is a platform dedicated to the transaction of digital assets and the provision of related services (hereinafter referred to as 'the Service'). For the convenience of wording in this Agreement, the Company and the Website are referred to collectively as 'we' or other applicable forms of first person pronouns in this Agreement.

                                                                </li>
                                                                <li>  All natural persons or other subjects who log onto this Website shall be users of this Website. For the convenience of wording in this Agreement, the users are referred to as 'you' or any other applicable forms of the second-person pronouns. </li>
                                                                <li> For the convenience of wording in this Agreement, you and we are collectively referred to as “both parties”, and individually as “one party”.  </li>
                                                            </ul>
                                                        </div>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Purposes of the Privacy Policy</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                The Privacy Policy stipulates the types of information of yours that we may collect through your log into this Website, your registration with this Website, and/or use the services we offer, as well as how we shall use and protect the information so collected.
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className='security__data'>
                                                            <h4 className='pri__list__title'>Your consent</h4>
                                                            <ul className='pri__list'>
                                                                <li className="pri___list">
                                                                To ensure that you have full confidence in our handling of personal data, you are advised to read and understand the terms of this Privacy Policy in detail. In particular, upon your log into our Website, regardless of whether you register with this Website, you shall be deemed to accept, agree, undertake and confirm that:
                                                                </li>
                                                            </ul>
                                                        </div>


                                                        <div className='help_full'>
                                                            <p>Was this article helpful?</p>
                                                            <button type='button' className='btn'> <CheckIcon/>  Yes </button>
                                                            <button type='button' className='btn'> <CloseIcon/> No</button>
                                                        </div>

                                                    </div>
                                                </TabPanel>
                                                {/* ================  */}

                                            </div>
                                        </div>




                                    </div>
                                
                                </Tabs>
                        </div>
                        </div>


                    </div>
                </div>
            </div>
      </div>

    </>
  )
}

export default Public_Policy