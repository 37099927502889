import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import { useParams } from "react-router";
import { useEffect, useState } from "react";
// import AccessibleIcon from '@mui/icons-material/Accessible';
import Person2Icon from '@mui/icons-material/Person2';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import ReportIcon from '@mui/icons-material/Report';
import LockIcon from '@mui/icons-material/Lock';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";
import { api_url, title } from "../config/config";
import { _fetch } from "../config/api";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import toasted from "../config/toast";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import Modal from "react-responsive-modal";
import WarningIcon from '@mui/icons-material/Warning';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { CSVLink } from "react-csv";
import LoaderCom from "../Sections/loader/LoaderCom";



const AccountSettings = (props:any) => {
   var storedNames = JSON.parse(localStorage.getItem("user") || "");
   const [emptyData, setemptyData] = useState([]);

   let navigate = useNavigate();
   const [username, setUserName] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');

   const [name, setName] = useState('');
   const [ChangePassword, setChangePassword] = useState('');
   const [newPassword, setnewPassword] = useState()
   const [ConformPassword, setConformPassword] = useState()

   const [log, setLog] = useState([]);
   const [log2, setLog2] = useState({ results: 0, total: 0 });
   const [currentPage, setcurrentPage] = useState(1);
   const [perPage, setPerPage] = useState(10);


   useEffect(() => {
      document.title =  props.title ;

      getUserInfo();
      getKycInfo();
      activityLog();
      getAccountDetails();

   }, []);

   //////////////////////////////////////2fa/////////////////////////////////////////////
   const [timer, settimer] = useState('00:00');
   const [otp2fa, setotp2fa] = useState();

   const [sec2faImg, setsec2faImg] = useState('');
   const [sec2faCode, setsec2faCode] = useState('');

   async function security2fa() {
      var d = await _fetch(`${api_url}/auth/generateQrCode`, 'GET', {}, {});
      if (d?.status === 'success') {
         //   setChartData(d.data.data)
         setsec2faImg(d.data.qr_url);
         setsec2faCode(d.data.base32);
         setOpenQR(true);
         //   console.log('chart',d.data.qr_url)
         if (timer === "00:00") {
            var seconds: any = '120';
            var intervalId = window.setInterval(function () {
               seconds--;

               // if (seconds > 1) {
               let minutes: any = Math.floor(seconds / 60);
               let extraSeconds: any = seconds % 60;
               minutes = minutes < 10 ? "0" + minutes : minutes;
               extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
               var leftData = minutes + ":" + extraSeconds;

               // console.log(leftData);
               settimer(leftData)
               // var a: HTMLElement | any = document.getElementById("timer1").innerHTML = a;
               // var z:HTMLElement | any =  document.getElementById('timer').value=a;

               // }
               // if (seconds == 1) {
               //    settimer(0)
               // }

               if (seconds == 0) {
                  security2fa();
                  stop();
                  //  dispatch(getUserDetails());
                  // history.push('/vender/dashboard');
                  // window.location.reload(true);
                  //  const navDash = document.getElementById("myModal2").style.display = "none";

               }
               //  random   data solve
               // var randomresult = '';
               // var chars ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
               // for (var i = 0; i < 100; i++){
               //     randomresult += chars.charAt(Math.floor(Math.random() * chars.length));
               // }
               // document.getElementById("randomresult").innerHTML = randomresult;

            }, 1000);
         }
         let stop = () => clearInterval(intervalId);
      } else {
         toasted.error(d.message);

      }
   }

   const submit2Fa = async (e: any) => {
      e.preventDefault();
      const data = {
         "base32": sec2faCode,
         "otp": otp2fa

      }
      var d = await _fetch(`${api_url}/auth/activate2FA`, 'POST', data, {});
      if (d?.status === 'success') {
         toasted.success(d.message);
         setOpenQR(false);
         getUserInfo()
      } else {
         toasted.error(d.message);

      }
   }

   const [removeOtp, setremoveOtp] = useState();

   const submitRemove2Fa = async (e: any) => {
      e.preventDefault();
      const data = {
         "otp": removeOtp

      }
      var d = await _fetch(`${api_url}/auth/deactivateAuth`, 'POST', data, {});
      if (d?.status === 'success') {
         toasted.success(d.message);

         setOpenN3(false);
         if (faStop === true) {
            if (userInfo?.security_type === '2fa') {

               getphone2faOtp(true)
            } else if (userInfo?.security_type === 'other') {
               security2fa();
            }
         }
         getUserInfo()
         set2faStop(true)
         // else if (storedNames?.security_type === '2fa') {
         //    security2fa()
         // }
      } else {
         toasted.error(d.message);

      }
   }

   async function getphone2faOtp(val: any) {
      console.log('val', val);


      var d = await _fetch(`${api_url}/auth/generateOTP?check=2fa authentication&email_type=2fa authentication`, 'GET', {}, {});
      if (d?.status === 'success') {
         if (val == true) {
            setOpenN(true);
         }
         toasted.success(d.message);
         // console.log(d)

      }
   }


   const submitPhone2Fa = async (e: any) => {
      e.preventDefault();
      const data = {
         "otp": otp2fa

      }
      var d = await _fetch(`${api_url}/auth/activate2Auth`, 'POST', data, {});
      if (d?.status === 'success') {
         toasted.success(d.message);
         setOpenN(false);
         getUserInfo();
      } else {
         toasted.error(d.message);

      }
   }
   const [openN, setOpenN] = useState(false);
   const onOpenModalN = () => setOpenN(true);
   const onCloseModalN = () => setOpenN(false);

   const [openN2, setOpenN2] = useState(false);
   const onOpenModalN2 = () => setOpenN2(true);
   const onCloseModalN2 = () => setOpenN2(false);

   const [openQR, setOpenQR] = useState(false);
   const onOpenModalQR = () => setOpenQR(true);
   const onCloseModalQR = () => setOpenQR(false);
   const [openN3, setOpenN3] = useState(false);
   const onOpenModalN3 = () => setOpenN3(true);
   const onCloseModalN3 = () => setOpenN3(false);


   const [openN_a, setopenN_a] = useState(false);
   const onOpenModal_a = () => setopenN_a(true);
   const onCloseModal_a = () => setopenN_a(false);


   const [openN_Email, setopenN_Email] = useState(false);
   const onOpenModal_Email = () => setopenN_Email(true);
   const onCloseModal_Email = () => setopenN_Email(false);

   const handlePageChange = async (e: any) => {
      // console.log('hello');
      const newOffset = (e.selected++);
      setcurrentPage(newOffset);
      console.log(newOffset);

      setPerPage(10)
      activityLog();



   };
   const [userInfo, setUserInfo] = useState({ 'phone': '', 'security_type': '', 'email': '' });
   async function getUserInfo() {
      var d = await _fetch(`${api_url}/user/userInfo`, 'GET', {}, {});
      if (d?.status === 'success') {
         // console.log(d)
         setUserInfo(d?.data?.data)
         setUserName(d?.data.data.username);
         setEmail(d?.data.data.email);
         setPhone(d?.data.data.phone);
      }
   }


   async function getKycInfo() {
      var d = await _fetch(`${api_url}/kyc/userKycInfo`, 'GET', {}, {});
      if (d?.status === 'success') {
         // console.log(d)
         setName(d?.data.data.first_name + ' ' + d?.data.data.last_name);
         // setEmail(d?.data.data.email);
         // setPhone(d?.data.data.phone);
      }
   }
   const [csv, setcsv] = useState([]);

   async function activityLog() {
      setemptyData([])
      var d = await _fetch(`${api_url}/user/logs?fields=ip_address,description,type,created_at,source&page=${currentPage}&limit=${perPage}`, 'GET', {}, {});
      var csv1 = await _fetch(`${api_url}/user/logs?fields=ip_address,description,type,created_at,source`, 'GET', {}, {});
      console.log('csv', csv1?.data.data);
      var val: any = [['#', 'DATE', 'IP', 'SOURCE', 'ACTIVITY']];
      csv1?.data.data?.map((valData: any, key: any) => {

         val[key + 1] = [`${key + 1}`,`${dateFormat(valData.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}`, `${valData.ip_address}`, `${valData.source}`, `${valData.description}`];

         setcsv(val)

      })
      if (d?.status === 'success') {
         setLog(d?.data.data);
         setemptyData(d)
         setLog2(d);
      }
   }
   const csvData = csv;

   var tabIndex = 0;


   const addClass = (event: any) => {
      // event.target.classList.toggle("show");
      let Dropdown = document.querySelector(".tmb--modifier");
      let body = document.querySelector("body");
      Dropdown?.classList.toggle("show");
      body?.classList.toggle("show");
   };
   // var icon1 = require("../Assets/Images/profile.png");
   // var icon2 = require("../Assets/Images/exchange-currency.png");
   // var  icon1 = require("../Assets/Images/profile.png");
   // var  icon1 = require("../Assets/Images/profile.png");
   // var  icon1 = require("../Assets/Images/profile.png");
   // var  icon1 = require("../Assets/Images/profile.png");
   // var  icon1 = require("../Assets/Images/profile.png");
   // var  icon1 = require("../Assets/Images/profile.png");

   let tabMenus = [
      { name: "profile", label: "PROFILE", image: <Person2Icon /> },
      { name: "Change-Password", label: "CHANGE PASSWORD", image: <SettingsSuggestIcon /> },
      { name: "currency", label: "CURRENCY PREFERENCE", image: <CurrencyExchangeIcon /> },
      { name: "payment-options", label: "PAYMENT OPTIONS", image: <AddCardIcon /> },
      { name: "kyc", label: "VERIFY KYC", image: <RecentActorsIcon />, Linkcon: <OpenInNewIcon />, link: true },
      { name: "2fa", label: "TWO FACTOR AUTHENTICATION", image: <AddModeratorIcon /> },
      { name: "activity-logs", label: "ACTIVITY LOGS", image: <PlagiarismIcon /> },
      // { name: "download-reports", label: "DOWNLOAD REPORTS", image: <ReportIcon /> },
      // { name: "privacy-control", label: "PRIVACY CONTROL", image: <LockIcon /> },


   ];

   // const { name } = useParams();

   tabMenus.filter((item: any, i) => {
      if (item.name === name) {
         tabIndex = i;
      }
   });

   const changeUrl = (e: any) => {
      e.preventDefault();
      let name = e.target.name;
      window.history.pushState(null, "New Page Title", "/Dashboard/account-settings/" + name);
   };

   const showContent = (e: any) => {
      // console.log(e.currentTarget);
      var sibling = e.currentTarget.nextElementSibling;
      var siblingHeight = sibling.scrollHeight;

      let main = e.currentTarget.classList.toggle("show");

      if (main) {
         sibling.setAttribute("style", `height:${siblingHeight}px`);
      } else {
         sibling.removeAttribute("style");
      }
   }

   const changePassword = async (e: any) => {
      e.preventDefault();
      const loginData = {
         'current_password': ChangePassword,
         'new_password': newPassword,
         'confirm_new_password': ConformPassword,

      }
      var d = await _fetch(`${api_url}/user/changePassword/`, 'POST', loginData, {});

      if (d?.status === 'success') {

         toasted.success(d?.message);

      } else {
         toasted.error(d?.message);

      }

   }

   // useEffect(()=>{
   //    console.log("Component Mount ");
   //    var activeContent = document.querySelector('.account_detail');
   //    // console.log(activeContent);
   //    var sibling =  activeContent?.nextElementSibling;
   //    var siblingHeight = sibling?.scrollHeight;
   //    // console.log(siblingHeight)
   //    sibling?.setAttribute("style",`height:${siblingHeight}px`);
   // })


   const add__ds = (id: any) => {
      const a: HTMLElement | any = document.getElementById(id);
      a.classList.toggle("page__a");
   }
   const remove__ds = (id: any) => {
      const a: HTMLElement | any = document.getElementById(id);
      a.classList.remove("page__a");
   }



   const addAccount = (id: any) => {
      const a: HTMLElement | any = document.getElementById(id);
      a.classList.toggle("add_show");
   }
   /////////////////////////////////////payment-options////////////////////////////////
   ////////////////////////////////////Update bank detail////////////////////////////////////

   const [bankHolderName, setbankHolderName] = useState();
   const [bankAccountNumber, setbankAccountNumber] = useState();
   const [bankIfseCode, setbankIfseCode] = useState();
   const [bankAccountType, setbankAccountType] = useState('Saving');
   const [getSecrityOtp, setgetSecrityOtp] = useState();

   const onBankSubmit = async (e: any) => {
      e.preventDefault();

      const data = {
         "account_holder_name": bankHolderName,
         "account_number": bankAccountNumber,
         "ifsc_code": bankIfseCode,
         "type": "bank",
         "account_type": bankAccountType,
         "otp": getSecrityOtp
      }

      var d = await _fetch(`${api_url}/payments/addBank/`, 'POST', data, {});
      if (d?.status === 'success') {
         toasted.success(d.message);
         getAccountDetails();
      } else {
         toasted.error(d.message);

      }
   }
   ////////////////////////////////////Update upi and others////////////////////////////////////
   const [accountType, setaccountType] = useState('upi');
   const [upilabel, setupilabel] = useState();
   const [upiAccountDetail, setupiAccountDetail] = useState();
   const [faStop, set2faStop] = useState(true);

   const onUpiSubmit = async (e: any) => {
      e.preventDefault();
      const data = {
         "upi_id": upiAccountDetail,
         "label": upilabel,
         "type": accountType,
         "otp": getSecrityOtp

      }
      var d = await _fetch(`${api_url}/payments/addUPI/`, 'POST', data, {});
      if (d?.status === 'success') {
         toasted.success(d.message);
         getAccountDetails();
      } else {
         toasted.error(d.message);

      }
   }
   ///////////////////////payment-options details/////////////////////////////////////////////////////
   const [allAccountdetail, setallAccountdetail] = useState([])
   async function getAccountDetails() {
      let d = await _fetch(`${api_url}/payments/paymentDetails`, 'GET', {}, {});
      if (d?.status === 'success') {
         setallAccountdetail(d.data.data)
      }
   }
   ///////////////////////payment-options delete/////////////////////////////////////////////////////
   const deleteDetail = async (value: any) => {
      var data = {
         "account_id": value,
      }
      var d = await _fetch(`${api_url}/payments/removePayments/`, 'DELETE', data, {});
      if (d?.status === 'success') {
         toasted.success(d.message);
         getAccountDetails();

      } else {
         toasted.error(d.message);

      }
   }

   function protect_email(user_email: any) {
      var avg, splitted, part1, part2;
      splitted = user_email.split("@");
      part1 = splitted[0];
      avg = part1.length / 2;
      part1 = part1.substring(0, (part1.length - avg));
      part2 = splitted[1];
      return part1 + "...@" + part2;
   };

   const openRmoveModal2fa = (val: any) => {
      setOpenN3(true);
      getphone2faOtp(false)
   }
   const openModal2fa = () => {
      setOpenN(true);
      getphone2faOtp(true)
   }

   const [profileMobTimer, setprofileMobTimer] = useState('00:00');
   const [profileEmailTimer, setprofileEmailTimer] = useState('00:00');
   const [profileEmail, setprofileEmail] = useState();
   const [profilePhone, setprofilePhone] = useState();
   const [profileType, setprofileType] = useState("");
   const [profileOtp, setprofileOtp] = useState("");

   async function generateProfileUpdateOtp(val: any) {
      if (val === 'phone') {
         var passData: any = `&sendTo=${profilePhone}&check=phone`;
      } else {
         var passData: any = `&sendTo=${profileEmail}&check=email`;

      }

      var d = await _fetch(`${api_url}/auth/generateAuthOTP?email_type=Update Profile${passData}`, 'GET', {}, {});
      if (d?.status == 'success') {
         toasted.success(d?.message);

         if (timer === "00:00") {
            var seconds: any = '180';
            var intervalId = window.setInterval(function () {
               seconds--;

               // if (seconds > 1) {
               let minutes: any = Math.floor(seconds / 60);
               let extraSeconds: any = seconds % 60;
               minutes = minutes < 10 ? "0" + minutes : minutes;
               extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
               var leftData = minutes + ":" + extraSeconds;

               // console.log(leftData);
               setprofileEmailTimer(leftData);

               if (seconds == 0) {
                  stop();

               }

            }, 1000);
         }
         let stop = () => clearInterval(intervalId);
      } else {
         localStorage.clear();
         toasted.error(d?.message);

      }
   }

   const updateProfile = async (e: any) => {
      e.preventDefault();
      if (profileType === 'Phone') {
         data = {
            "type": profileType,
            "update": profilePhone,
            "otp": profileOtp
         }
      } else {
         var data = {
            "type": profileType,
            "update": profileEmail,
            "otp": profileOtp
         }
      }

      var d = await _fetch(`${api_url}/user/updateProfile`, 'post', data, {});
      if (d?.status === 'success') {
         toasted.success(d.message);
         onCloseModal_a();
         getUserInfo();
      } else {
         toasted.error(d.message);

      }
   }
   return (
      <>
         <section className="tmb_sec_bg">
            <div className="container-pro-max">
               <Tabs className="main_sec d-flex" defaultIndex={tabIndex} id="profile_wrapper">
                  <div className="tabList_wrapper" >
                     <h3 className="secHeaing">Account Settings </h3>
                     <TabList className="sidebar d-flex" >
                        {tabMenus.map((item) => {

                           return (
                              <Tab key={item.name}>
                                 <div className="" style={{ width: "100%" }} onClick={() => add__ds('profile_wrapper')}  >
                                    {item.link
                                       ? <button
                                          className="sidebar_item"
                                          name={item.name}
                                          onClick={(e: any) => {
                                             e.preventDefault();
                                             navigate(`/Dashboard/${item.name}`);
                                          }}>

                                          <span className="set_icon" > {item.image}</span>
                                          {item.label}
                                          <i className="share__icon" > {item.Linkcon}</i>
                                       </button>
                                       :
                                       <button
                                          className="sidebar_item"
                                          name={item.name}
                                          onClick={(e) => {
                                             changeUrl(e);
                                          }}
                                       >
                                          {/* <img className="set_icon" src={item.image} alt="" /> */}
                                          <span className="set_icon" > {item.image}</span>

                                          {/* <span className="set_icon" > {item.image}</span> */}

                                          {item.label}
                                       </button>
                                    }

                                 </div>

                                 {/* {
                                    item.link
                                       ?
                                       <button
                                          className="sidebar_item"
                                          name={item.name}
                                          onClick={(e: any) => {
                                             e.preventDefault();
                                             navigate(`/Dashboard/${item.name}`);
                                          }}>
                                          <img className="set_icon" src={require(`../Assets/Images/svg/${item.name}.svg`)} alt="profile" />
                                          {item.label}
                                       </button>
                                       :

                                       <button
                                          className="sidebar_item"
                                          name={item.name}
                                          onClick={(e) => {
                                             changeUrl(e);
                                          }}>
                                          <img className="set_icon" src={require(`../Assets/Images/svg/${item.name}.svg`)} alt="profile" />
                                          {item.label}
                                       </button>
                                 } */}

                              </Tab>
                           );
                        })}
                     </TabList>
                  </div>

                  <div className="data__tabs" style={{ width: '100%' }} >
                     {/* PROFILE */}
                     <TabPanel>
                        <div className="profile_wrapper"  >
                           <div className="profile_head">
                              <div className="prifile_icon d-flex">
                                 <span className="back__button" onClick={() => remove__ds('profile_wrapper')}> <KeyboardBackspaceIcon />  </span>
                                 <span className="set_icon " >     <Person2Icon /> </span>
                              </div>
                              <h3 className="secHeaingtmb">Profile</h3>
                           </div>
                           <div className="profile_content">
                              {username ? <div className="content_inner_item">
                                 <p className="name_hed"> USERNAME</p>
                                 <p className="main_name">{username}</p>
                              </div> : ''}

                              {name ? <div className="content_inner_item">
                                 <p className="name_hed"> NAME</p>
                                 <p className="main_name">{name}</p>
                              </div> : ''}

                              <div className="content_inner_item">
                                 <p className="name_hed"> EMAIL <span>  </span></p>
                                 <div className="d___values_acount">
                                    {email ? <p className="main_name">{email} </p> : ''}
                                    {email === undefined || email === '' &&
                                       <div className="btncheck">
                                          <form>
                                             <input type="email" id="dfd" placeholder="Update Your Email" onChange={(e: any) => { setprofileEmail(e.target.value) }} />
                                             <div className="btn__update" onClick={() => { onOpenModal_a(); generateProfileUpdateOtp('email'); setprofileType('Email') }}  >update</div>
                                          </form>
                                       </div>}
                                 </div>
                              </div>

                              <div className="content_inner_item">
                                 <p className="name_hed"> MOBILE</p>
                                 <div className="d___values_acount">
                                    {phone ? <p className="main_name">+{phone}</p> : ''}
                                    {phone === undefined || phone === '' &&
                                       <div className="btncheck">
                                          <form>
                                             <PhoneInput
                                                inputStyle={{ padding: '0px 20px 0px 50px', border: '1px solid #e7c14ba6', outline: 'none', color: '$txt-primary-color', fontSize: '13px' }}
                                                containerStyle={{}}
                                                searchClass="search-class"
                                                disableSearchIcon={false}
                                                enableTerritories
                                                countryCodeEditable={true}
                                                placeholder='Phone Number'
                                                buttonStyle={{ width: '47px' }}
                                                dropdownStyle={{ height: '150px' }}
                                                country={"in"}
                                                enableSearch={true}
                                                onChange={(newValue: any) => setprofilePhone(newValue)}

                                             />

                                             <div className="btn__update" onClick={() => { onOpenModal_a(); generateProfileUpdateOtp('phone'); setprofileType('Phone') }} >update</div>
                                          </form>
                                       </div>}
                                 </div>
                              </div>




                           </div>
                        </div>
                     </TabPanel>

                     {/* Change-password CONTROL */}
                     <TabPanel>
                        <div className="profile_head">
                           <div className="prifile_icon d-flex">
                              <span className="back__button" onClick={() => remove__ds('profile_wrapper')}  > <KeyboardBackspaceIcon />  </span>
                              <span className="set_icon" > <SettingsSuggestIcon /> </span>
                           </div>
                           <h3 className="secHeaingtmb">Change Password</h3>
                        </div>
                        <div className="elp_content">
                           <div className="elp_content_inner">
                              <h3 className="elp_content_heading">Change Your Account Password .</h3>

                              <div className="Change___area">
                                 <form onSubmit={changePassword}>
                                    <div className="form-group">
                                       <label htmlFor="usr">Current Password</label>
                                       <input type="password" className="form-control" id="c-password" onChange={(e: any) => { setChangePassword(e.target.value) }} placeholder='Current Password' />
                                    </div>
                                    <div className="form-group">
                                       <label htmlFor="usr">Confirm Password</label>
                                       <input type="password" className="form-control" id="Confirm-psd" onChange={(e: any) => { setnewPassword(e.target.value) }} placeholder='Confirm Password' />

                                    </div>
                                    <div className="form-group">
                                       <label htmlFor="usr">Re-Confirm Password</label>
                                       <input type="password" className="form-control" id="r-Confirm-psd" onChange={(e: any) => { setConformPassword(e.target.value) }} placeholder='Re-Confirm Password' />
                                    </div>
                                    <div className="btn___cha">
                                       <button type="submit" className="btn active">  Change Password </button>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </TabPanel>

                     {/* CURRENCY PREFERENCE */}
                     <TabPanel>


                        <div className="currency_wrapper">
                           <div className="profile_head">
                              <div className="prifile_icon d-flex">
                                 <span className="back__button" onClick={() => remove__ds('profile_wrapper')}  > <KeyboardBackspaceIcon />  </span>
                                 <span className="set_icon" > <CurrencyExchangeIcon /> </span>
                              </div>
                              <h3 className="main_heading">Currency Preference </h3>
                           </div>
                           <div className="Currency_content">
                              <div className="sub_hed">
                                 <h3 className="inner_heading"> Select your Preferred Display currency for all markets</h3>

                              </div>

                              <div className="Currency_inner_item">
                                 <AccountCircleIcon />
                                 <label htmlFor="tether" className="main_name" >USD (USDT)</label>
                                 <div className="che_box">
                                    <input type="checkbox" id="tether" defaultChecked={true} />
                                    <span></span>
                                 </div>
                              </div>

                              {/* <div className="Currency_inner_item">
                                 <AccountCircleIcon />
                                 <label htmlFor="checkbox" className="main_name" >Bition(BTC)</label>
                                 <div className="che_box">
                                    <input type="checkbox" id="checkbox" />
                                    <span></span>
                                 </div>
                              </div>
                              <div className="Currency_inner_item">
                                 <AccountCircleIcon />
                                 <label htmlFor="Rupee" className="main_name" >Rupee (INR)</label>
                                 <div className="che_box">
                                    <input type="checkbox" id="Rupee" />
                                    <span></span>
                                 </div>
                              </div> */}

                           </div>

                        </div>
                     </TabPanel>

                     {/* PAYMENT OPTIONS*/}
                     <TabPanel>
                        <div className="profile_head">
                           <div className="prifile_icon d-flex">
                              <span className="back__button" onClick={() => remove__ds('profile_wrapper')}  > <KeyboardBackspaceIcon />  </span>
                              <span className="set_icon" > <AddCardIcon /> </span>
                           </div>
                           <h3 className="secHeaingtmb">PAYMENT OPTIONS</h3>
                        </div>
                        <Tabs className="elp_nested_tabs">
                           <TabList className="nested_tmb_tab">
                              <Tab>
                                 <button>
                                    BANK ACCOUNT
                                 </button>
                              </Tab>
                              <Tab>
                                 <button>
                                    UPI/OTHERS
                                 </button>
                              </Tab>
                           </TabList>

                           <TabPanel className="tab_wrapper">
                              <div className="account_detail_wrapper">
                                 <div className="account_detail_inner ">
                                    <div className="add-account  d-none" id="add_details">
                                       <div className="add-account-title">
                                          <h2> Bank Account Verify 👏🏼 </h2>
                                          <p> You can now make depoits, withdrawals, and use P2P</p>
                                       </div>
                                       <div className="add-account-body">
                                          <form onSubmit={onBankSubmit}>
                                             <div className="form-group">
                                                <label> BANK HOLDER NAME </label>
                                                <input type="text" className="form-control" value={bankHolderName} onChange={(e: any) => setbankHolderName(e.target.value)} placeholder="0000-0000-0000"  />
                                             </div>
                                             <div className="form-group">
                                                <label> ACCOUNT NUMBER </label>
                                                <input type="number" className="form-control" value={bankAccountNumber} onChange={(e: any) => setbankAccountNumber(e.target.value)} placeholder="0000-0000-0000"  />
                                             </div>
                                             <div className="form-group">
                                                <label> IFSE CODE</label>
                                                <input type="text" onChange={(e: any) => setbankIfseCode(e.target.value)} value={bankIfseCode} className="form-control" placeholder="IFSE-CODE" />
                                             </div>

                                             {userInfo.security_type === '2fa' && <div className="form-group">
                                                <label> OTP </label>
                                                <input type="text" onChange={(e: any) => setgetSecrityOtp(e.target.value)} value={getSecrityOtp} className="form-control" placeholder="Enter your google authenticator  OTP" />
                                             </div>}

                                             {userInfo.security_type === 'other' && <div className="form-group">
                                                <label> Verify OTP</label>
                                                <label >{timer !== '00:00' ? 'OTP Expire in: ' + timer : ''}</label>

                                                <div className="otp_section">
                                                   <input type="number" onChange={(e: any) => setgetSecrityOtp(e.target.value)} value={getSecrityOtp} className="form-control" placeholder="OTP" />
                                                   {/* <div className="" */}
                                                   <button type="button" className="otp-btn" onClick={() => { getphone2faOtp(false) }}>Send OTP</button>
                                                </div>
                                             </div>}

                                             <div className="form-group-1">
                                                <label style={{ display: 'block', margin: '10px 0' }}> ACCOUNT TYPE</label>
                                                <div className="form-group_innner" >
                                                   <div className="account-type-1">
                                                      <label htmlFor="Savings" className="che_box__1">
                                                         <input type="radio" id="Savings" name="fav_language" value="Saving" onChange={(e: any) => setbankAccountType(e.target.value)} />
                                                         <span></span> Savings
                                                      </label>
                                                      <label htmlFor="current" className="che_box__1">
                                                         <input type="radio" id="current" name="fav_language" value="Current" onChange={(e: any) => setbankAccountType(e.target.value)} />
                                                         <span></span>Current
                                                      </label>
                                                   </div>

                                                </div>
                                             </div>
                                             <div className="form-group-btn">
                                                <button type="submit" className="verify-btn"> VERIFIED </button>
                                             </div>
                                          </form>
                                       </div>
                                    </div>
                                    {allAccountdetail?.map((accountData: any) => {
                                       if (accountData.type === 'bank') {
                                          return (
                                             <div className="account_detail___UPI_d">
                                                <div className="added-acount-verified">
                                                   <div className="account_detail" onClick={(e) => { showContent(e) }} style={{ flexDirection: 'row' }} >
                                                      <div className="detail_inner">
                                                         <p className="log_heading">BANK ACCOUNT - {accountData?.account_number}</p>
                                                      </div>
                                                      <div className="detail_inner cta_wrapper">
                                                         {/* <button className="detail_cta">DEFAULT</button> */}
                                                         <button className="detail_cta verified">VERIFIED</button>
                                                         <button className="arrow"><img src={require("../Assets/Images/arrow-down-thumb.png")} alt="error" /></button>
                                                      </div>
                                                   </div>
                                                   <div className="account_content " >
                                                      <div className="account_content_inner">
                                                         {/* <div className="account_varified">
                                                            <h4 className="varified_heading">Bank Account Varified 👏🏼</h4>
                                                            <p className="varified_info">You can now make depoits, withdrawals, and use P2P</p>
                                                         </div> */}


                                                         <div className="imps_payments">
                                                            <h4 className="varified_heading">Your bank account details for IMPS payments</h4>
                                                            <div className="imps_details">
                                                               <div className="elp_details">
                                                                  <p className="elp_info">ACCOUNT NUMBER </p>
                                                                  <p className="elp_info_2"> {accountData.account_number}</p>
                                                               </div>
                                                               <div className="elp_details">
                                                                  <p className="elp_info">IFSE CODE</p>
                                                                  <p className="elp_info_2">{accountData?.account_ifsc}</p>
                                                               </div>
                                                               <div className="elp_details">
                                                                  <p className="elp_info">ACCOUNT TYPE</p>
                                                                  <p className="elp_info_2">{accountData.type}</p>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="elp_cta_wrapper">
                                                            <button className="remove_cta" onClick={() => deleteDetail(accountData._id)}>REMOVE</button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          )
                                       }
                                    })}

                                 </div>

                                 <div className="account_detail_inner">
                                    <div className="elp_cta_wrapper_2" onClick={() => addAccount('add_details')}>
                                       <button className="add_payment_option" >ADD A NEW PAYMENT OPTION</button>
                                    </div>
                                 </div>
                              </div>
                           </TabPanel>
                           <TabPanel className="tab_wrapper">
                              <div className="account_detail_wrapper">
                                 <div className="account_detail_inner">
                                    <div className="add-account  d-none" id="add_details-upi">
                                       <div className="add-account-title">
                                          <h2> UPI Account Verify 👏🏼 </h2>
                                          <p> You can now make depoits, withdrawals, and use P2P</p>
                                       </div>
                                       <div className="add-account-body">
                                          <form onSubmit={onUpiSubmit}>
                                             <div className="form-group">
                                                <label htmlFor="">Select Account Type</label>
                                                <select name="type" className="form-control" onChange={(e: any) => setaccountType(e.target.value)}>
                                                   <option value="upi">UPI</option>
                                                   <option value="phonepe">Phone Pay</option>
                                                   <option value="gpay">Google Pay</option>
                                                </select>
                                             </div>
                                             <div className="form-group">
                                                <label> Name</label>
                                                <input type="text" onChange={(e: any) => setupilabel(e.target.value)} value={upilabel} className="form-control" placeholder="Name" />
                                             </div>
                                             <div className="form-group">
                                                <label> UPI/OTHER</label>
                                                <input type="text" onChange={(e: any) => setupiAccountDetail(e.target.value)} value={upiAccountDetail} className="form-control" placeholder="UPI/OTHER" />
                                             </div>

                                             {userInfo.security_type === '2fa' && <div className="form-group">
                                                <label> OTP </label>
                                                <input type="text" onChange={(e: any) => setgetSecrityOtp(e.target.value)} value={getSecrityOtp} className="form-control" placeholder="Enter your google authenticator  OTP" />
                                             </div>}


                                             {userInfo.security_type === 'other' && <div className="form-group">
                                                <label> Verify OTP</label>
                                                <div className="otp_section">
                                                   <input type="number" onChange={(e: any) => setgetSecrityOtp(e.target.value)} value={getSecrityOtp} className="form-control" placeholder="OTP" />
                                                   {/* <div className="" */}
                                                   <button type="button" className="otp-btn" onClick={() => { getphone2faOtp(false) }}>Send OTP</button>
                                                </div>
                                             </div>}

                                             <div className="form-group-btn">
                                                <button type="submit" className="verify-btn" style={{ width: "20%" }}> VERIFIED </button>
                                             </div>
                                          </form>
                                       </div>
                                    </div>
                                    {allAccountdetail?.map((accountData: any) => {
                                       if (accountData.type !== 'bank') {
                                          return (
                                             <div className="account_detail___UPI_d">
                                                <div className="account_detail" onClick={(e) => { showContent(e) }} style={{ flexDirection: 'row' }}>
                                                   <div className="detail_inner">
                                                      <p className="log_heading">{accountData.upi_id}</p>
                                                   </div>
                                                   <div className="detail_inner cta_wrapper">
                                                      <button className="detail_cta">DEFAULT</button>
                                                      <button className="detail_cta verified">VERIFIED</button>
                                                      <button className="arrow"><img src={require("../Assets/Images/arrow-down-thumb.png")} alt="error" /></button>
                                                   </div>
                                                </div>
                                                <div className="account_content">
                                                   <div className="account_content_inner">
                                                      <div className="account_varified">
                                                         <h4 className="varified_heading">UPI ID Varified </h4>
                                                         <p className="varified_info">You can now make depoits, withdrawals, and use P2P</p>
                                                      </div>
                                                      <div className="imps_payments">
                                                         <h4 className="varified_heading">Your UPI/VPU ID for UPI payments</h4>
                                                         <div className="imps_details">
                                                            <div className="elp_details">
                                                               <p className="elp_info">NAME</p>
                                                               <p className="elp_info_2"> {accountData.label}</p>
                                                            </div>
                                                            <div className="elp_details">
                                                               <p className="elp_info">UPI/VPA ID</p>
                                                               <p className="elp_info_2"> {accountData.upi_id}</p>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="elp_cta_wrapper">
                                                         <button className="remove_cta" onClick={() => deleteDetail(accountData._id)}>REMOVE</button>
                                                      </div>
                                                   </div>

                                                </div>
                                             </div>
                                          )
                                       }
                                    })}
                                 </div>
                                 <div className="account_detail_inner">
                                    <div className="elp_cta_wrapper_2" onClick={() => addAccount('add_details-upi')}>
                                       <button className="add_payment_option">ADD A NEW PAYMENT OPTION</button>
                                    </div>
                                 </div>
                              </div>
                           </TabPanel>
                        </Tabs>

                     </TabPanel>
                     {/* CURRENCY PREFERENCE */}
                     <TabPanel>
                     </TabPanel>
                     {/* KYC*/}


                     {/* 2FA*/}
                     <TabPanel>
                        <div className="profile_head">
                           <div className="prifile_icon d-flex">
                              <span className="back__button" onClick={() => remove__ds('profile_wrapper')}  > <KeyboardBackspaceIcon />  </span>
                              <span className="set_icon" > <AddModeratorIcon /> </span>
                           </div>
                           <h3 className="secHeaingtmb">TWO FACTOR AUTHENTICATION </h3>
                        </div>
                        <div className="Currency_content">
                           <div className="Currency_inner_item">
                              <label htmlFor="tether" className="main_name" >
                                 <span>Authenticator App <span className="recommend">Recommended</span> </span>
                                 <span>Highly secure</span>
                              </label>
                              <div className="che_box">
                                 <input type="checkbox" id="tether" checked={userInfo?.security_type === '2fa' ? true : false} onClick={() => { userInfo?.security_type === 'other' ? openRmoveModal2fa(true) : security2fa() }} />
                                 <span></span>
                              </div>
                           </div>

                           <div className="Currency_inner_item">
                              <label htmlFor="checkbox" className="main_name" >
                                 <span>Mobile SMS</span>
                                 <span>Moderately secure</span>
                                 <span>2FA enabled on {userInfo?.phone !== '' ? `******${userInfo?.phone.slice(-4)},` : ''} {protect_email(userInfo?.email)}</span>
                              </label>
                              <div className="che_box">
                                 {/* <input type="checkbox" id="checkbox" checked={userInfo?.security_type === 'other' ? true : false} onClick={() => { setOpenN(true); }} /> */}

                                 <input type="checkbox" id="checkbox" checked={userInfo?.security_type === 'other' ? true : false} onClick={() => { userInfo?.security_type === '2fa' ? setOpenN3(true) : userInfo?.security_type === 'other' ? toasted.error('Mobile/Phone 2fa already activated') : openModal2fa(); }} />
                                 <span></span>
                              </div>
                           </div>
                           <div className="Currency_inner_item">
                              <label htmlFor="none" className="main_name" > <span>None</span><span>None</span>  </label>
                              <div className="che_box">
                                 <input type="checkbox" id="none" checked={userInfo?.security_type === 'none' ? true : false} onClick={() => { set2faStop(false); userInfo?.security_type === '2fa' ? setOpenN3(true) : userInfo?.security_type === 'other' ? openRmoveModal2fa(false) : toasted.error(' 2fa already Deactivated') }} />
                                 <span></span>
                              </div>
                           </div>

                        </div>





                     </TabPanel>

                     {/* ACTIVITY LOGS */}
                     <TabPanel>
                        <div className="profile_head profile_head___last_A__CV">
                           <div className="profile_head___last_A">
                              <div className="prifile_icon d-flex">
                                 <span className="back__button" onClick={() => remove__ds('profile_wrapper')}  > <KeyboardBackspaceIcon />  </span>
                                 <span className="set_icon" > <PlagiarismIcon /> </span>
                              </div>
                              <h3 className="secHeaingtmb">ACTIVITY LOGS</h3>
                           </div>
                              <CSVLink data={csvData}   filename={"gtc-exchange.csv"}   className="cv__btn_thdc">Download CSV</CSVLink>

                        </div>
                        <div className="tmb_tab_content">
                           <div className="tab_content_inner">
                           <LoaderCom condition={emptyData} text={'Getting latest values...'} />

                              {log?.map((value2: any, index: any) =>
                                 <ul key={index}>
                                    <li className="log_items">
                                       <div className="log date">
                                          <p className="log_heading">DATE:</p>
                                          <p className="log_brief"> {dateFormat(value2.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")}</p>
                                       </div>
                                       <div className="log ip_add">
                                          <p className="log_heading">IP:</p>
                                          <p className="log_brief"> {value2.ip_address}</p>
                                       </div>
                                       <div className="log date">
                                          <p className="log_heading">SOURCE:</p>
                                          <p className="log_brief"> {value2.source}</p>
                                       </div>
                                       <div className="log activity">
                                          <p className="log_heading">ACTIVITY:</p>
                                          <p className="log_brief"> {value2.description}</p>
                                       </div>
                                    </li>


                                 </ul>
                                 //  )
                              )}
                              <div className="pagination__area__main">
                                 <div className="pagination__area">

                                    <ReactPaginate
                                       // log2?.total > 0 ? Math.ceil(log2?.total / perPage) : 1
                                       pageCount={log2?.total > 0 ? Math.ceil(log2?.total / perPage) : 1}
                                       onPageChange={handlePageChange}
                                       pageRangeDisplayed={2}
                                       previousLabel={<ChevronLeftIcon />}
                                       nextLabel={<ChevronRightIcon />}
                                       containerClassName={"pagination"}
                                       pageClassName={"page-item"}
                                       pageLinkClassName={"page-link"}
                                       previousClassName={"page-item"}
                                       previousLinkClassName={"page-link"}
                                       nextClassName={"page-item"}
                                       nextLinkClassName={"page-link"}
                                       breakClassName={"page-item"}
                                       breakLinkClassName={"page-link"}
                                       activeClassName={"active"}
                                       marginPagesDisplayed={2}
                                    >

                                    </ReactPaginate>

                                 </div>

                              </div>
                           </div>
                        </div>

                     </TabPanel>

                     {/* DOWNLOAD REPORTS */}
                     <TabPanel>
                        <div className="profile_head">
                           <div className="prifile_icon d-flex">
                              <span className="back__button" onClick={() => remove__ds('profile_wrapper')}  > <KeyboardBackspaceIcon />  </span>
                              <span className="set_icon" > <ReportIcon /> </span>
                           </div>
                           <h3 className="secHeaingtmb">DOWNLOAD REPORTS</h3>
                        </div>
                     </TabPanel>

                     {/* PRIVACY CONTROL */}
                     <TabPanel>
                        <div className="profile_head">
                           <div className="prifile_icon d-flex">
                              <span className="back__button" onClick={() => remove__ds('profile_wrapper')}  > <KeyboardBackspaceIcon />  </span>
                              <span className="set_icon" > <LockIcon /> </span>
                           </div>
                           <h3 className="secHeaingtmb">PRIVACY CONTROL</h3>
                        </div>
                        <div className="elp_content">
                           <div className="elp_content_inner">
                              <h3 className="elp_content_heading">You Control your data,and we respect that.</h3>
                              <p>For request regarding:</p>
                              <ul>
                                 <li>
                                    <p>Copy of your data</p>
                                 </li>
                                 <li>
                                    <p>Data export</p>
                                 </li>
                                 <li>
                                    <p>Data correction</p>
                                 </li>
                              </ul>
                              <p>Please reach out to us.Our team will be happy to help you out.</p>

                              <button className="trade_btn active" > CONTACT US</button>
                           </div>
                        </div>
                     </TabPanel>
                  </div>

               </Tabs>
            </div>
         </section>


         <Modal open={openQR} onClose={onCloseModalQR}
            classNames={{
               overlay: 'TwoFactor-Overlay',
               modal: 'Two-Factor-modal',
            }} center  >
            <div className="card-header">
               <h3 className="title-modal"> Enable Authenticator</h3>
            </div>
            <div className="modail-data">
               <div className="modal-body">
                  <div className="qr__img">
                     {/* <QrCode2Icon /> */}
                     <img src={sec2faImg} alt='2fa qr image' />
                  </div>
                  <p> OTP</p>
                  <form onSubmit={submit2Fa}>
                     <div className="input-group">
                        <input type="number" value={otp2fa} onChange={(e: any) => setotp2fa(e.target.value)} />
                     </div>
                     <div className="btn__otp">
                        <button type="submit" className="trade_btn active">Next</button>

                     </div>
                  </form>
                  <p className="time_S">Expire in {timer}</p>
               </div>

            </div>
         </Modal>



         <Modal open={openN} onClose={onCloseModalN}
            classNames={{
               overlay: 'TwoFactor-Overlay',
               modal: 'Two-Factor-modal',
            }} center  >
            <div className="card-header">
               <h3 className="title-modal">Enable Mobile/E-mail SMS </h3>
            </div>
            <div className="modail-data">
               <div className="modal-body">
                  <h3> Enable Mobile/E-mail SMS</h3>
                  <p> Enter OTP sent to your mobile</p>
                  <form  >
                     <div className="input-group">
                        <input type="number" onChange={(e: any) => setotp2fa(e.target.value)} value={otp2fa} />
                     </div>
                     <div className="btn__otp">
                        <button type="submit" className="trade_btn active" onClick={(e: any) => submitPhone2Fa(e)}>Next</button>

                     </div>
                  </form>
                  <p className="time_S" >
                     Didn't receive OTP yet?<br />

                  </p>

               </div>

            </div>
         </Modal>


         <Modal open={openN2} onClose={onCloseModalN2}
            classNames={{
               overlay: 'TwoFactor-Overlay',
               modal: 'Two-Factor-modal',
            }} center  >
            <div className="card-header">
               <h3 className="title-modal"> Confirmation</h3>
            </div>
            <div className="modail-data">
               <div className="modal-body">
                  <div className="modal-body__1">
                     <h3><WarningIcon /> </h3>
                     <p>Are you sure you want to proceed without securing you account ? </p>
                     <button type="button" className="btn"> Yes </button>
                     <button type="button" className="btn btn-2"> No, go back </button>
                  </div>

               </div>

            </div>
         </Modal>

         <Modal open={openN3} onClose={onCloseModalN3}
            classNames={{
               overlay: 'TwoFactor-Overlay',
               modal: 'Two-Factor-modal',
            }} center  >
            <div className="card-header">
               <h3 className="title-modal"> Remove Authentication</h3>
            </div>
            <div className="modail-data">
               <div className="modal-body">
                  <h3>Remove Authentication </h3>
                  <p> Enter OTP sent to your mobile</p>
                  <form >
                     <div className="input-group">
                        <input type="number" onChange={(e: any) => setremoveOtp(e.target.value)} value={removeOtp} />
                     </div>
                     <div className="btn__otp">
                        <button type="button" onClick={submitRemove2Fa} className="trade_btn active">Submit</button>

                     </div>
                  </form>
                  <p className="time_S" >
                     Didn't receive OTP yet?<br />
                     Resend in 53 seconds
                  </p>

               </div>

            </div>
         </Modal>


         <Modal open={openN_a} onClose={onCloseModal_a}
            classNames={{
               overlay: 'TwoFactor-Overlay',
               modal: 'Two-Factor-modal',
            }} center  >
            <div className="card-header">
               <h3 className="title-modal"> Enter OTP {profileEmailTimer}</h3>
            </div>
            <div className="modail-data">
               <div className="modal-body">
                  <form >
                     <div className="input-group">
                        <input type="number" onChange={(e: any) => setprofileOtp(e.target.value)} value={profileOtp} />
                     </div>
                     <div className="btn__otp">
                        <button type="button" onClick={(e: any) => { updateProfile(e) }} className="trade_btn active">Submit</button>

                     </div>
                  </form>


               </div>

            </div>
         </Modal>




         <Modal open={openN_Email} onClose={onCloseModal_Email}
            classNames={{
               overlay: 'TwoFactor-Overlay',
               modal: 'Two-Factor-modal',
            }} center  >
            <div className="card-header">
               <h3 className="title-modal"> Remove Authentication</h3>
            </div>
            <div className="modail-data">
               <div className="modal-body">
                  <h3>Remove Authentication </h3>
                  <p> Enter OTP sent to your mobile</p>
                  <form >
                     <div className="input-group">
                        <input type="number" onChange={(e: any) => setremoveOtp(e.target.value)} value={removeOtp} />
                     </div>
                     <div className="btn__otp">
                        <button type="button" onClick={submitRemove2Fa} className="trade_btn active">Submit</button>

                     </div>
                  </form>
                  <p className="time_S" >
                     Didn't receive OTP yet?<br />
                     Resend in 53 seconds
                  </p>

               </div>

            </div>
         </Modal>

      </>
   );
};
export default AccountSettings;