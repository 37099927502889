import React, { useEffect } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import plane from '../Assets/Images/bannerRight.png'
import ukreferral from '../Assets/Images/ukreferral.png'


const Referral = (props:any) => {

    const Referral = (id: any) => {
        const a: HTMLElement | any = document.body
    ;
        a.classList.toggle("Referral_page");
     }
     useEffect(() => {
        document.title = props.title;
    }, [])

  return (
    <>
     <div className="b_container pt__spacing">
        <div className="referral-main">

            <section className='referral__banner'>
                <div className='referral____container'>
                    <div className='card__banner__left'>
                        <h1>Invite Friends and Earn Up to <span>2,802 USDT</span></h1>
                        <button className='btn' type='submit'>Invest Now</button>
                    </div>
                    {/* <div className='card__banner__right'>
                            <img src={BAnner__img} alt="icon" />

                        </div> */}
                    </div>
            </section>


            <div className="second_sec">
                <div className='referral____container'>
                    <h1>Earn with your friends together</h1>
                    <div className="main-sec">
                        <img src={ukreferral} alt="" />
                        <div className="row">
                            <div className="col-4">
                                <div className="cards">
                                    <h2>Invite friends</h2>
                                    <p>Copy your referral link and send it to your friends.</p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="cards">
                                    <h2>Claim your rewards</h2>
                                    <p>You will receive a referral reward of 3 USDT after the friend you invite completes KYC, makes their first deposit, and completes the required trading volume. Rewards can be up to 2,802 USDT.</p>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="cards">
                                    <h2>Earn together</h2>
                                    <p>After your friend creates an account, he/she can claim a trading bonus of up to 5,005 USDT.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add_class">
                <div className='referral____container'>
                    <div className="btn">
                        <button onClick={Referral} className='add-btn' type='submit'>Expand All <KeyboardArrowDownIcon/></button>
                    </div>
                    <div className="display">
                        <div className="heading">
                            <h3>Referral Rewards</h3>
                            <a href="#">Detailed Rules</a>
                        </div>
                        <div className="boxes">
                            <div className="left">
                            <h2>Trading Bonus</h2>
                            <p>Complete any valid invitation and receive a trading bonus reward equivalent to 3 USDT. The maximum reward for a single person is <span> 2,802 $</span> for a year.</p>
                            </div>
                            <div className="right">
                                <h1><span>Up to </span>2,802$</h1>
                            </div>

                        </div>
                        <div className="boxes">
                            <div className="left">
                            <h2>Rewards</h2>
                            <p>Inviters can get rewards proportional to the futures transaction volume generated by their invitees. The maximum reward for a valid invitation is <span>13$</span>.</p>
                            </div>
                            <div className="right">
                                <h1><span>Up to</span> 13$</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
    </>
  )
}

export default Referral
