import { useState, useEffect } from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { _fetch } from "../config/api";
import { title, logo, api_url } from "../config/config";
import toasted from "../config/toast";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

type formTypes = {
    setUserLogin: any
    formType: "verificationID-email" | "verificationID-number",

}

const ForgotPass = (props: any) => {


    const [phone, setphone] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState()

    const location = useLocation();
    let navigate = useNavigate();


    useEffect(() => {
        document.title = props.title;



    }, [])

    const [forgotmail, setforgotmail] = useState('')
    const [forgotphone, setforgotphone] = useState('')



    const forgotSubmit = async (e: any) => {
        // e.preventDefault();
        var loginData: any;
        if (e === 'email') {
            loginData = {
                email: forgotmail,
                forget_type:'email'
            }
        } else {
            loginData = {
                phone: forgotphone,
                forget_type:'phone'

            }
        }
        var d = await _fetch(`${api_url}/auth/forgotPassword`, 'POST', loginData, {});
        if (d?.status === 'success') {
            // localStorage.setItem("auth", 'true');
            // localStorage.setItem("accessToken", d?.token);
            // if (d.status == "success") {
            //     localStorage.setItem("user", JSON.stringify(d.data.user));
            // }
            // if (d?.data.kyc.kyc_status === 'Not Applied' || d?.data.kyc.kyc_status === 'Reject') {
            //     navigate("Dashboard/Kyc");

            // } else {
            //     navigate("/Dashboard");
            // }
            toasted.success(d?.message);
        } else {
            // localStorage.clear();
            toasted.error(d?.message);

        }

    }


    return (
        <>
            <section className="account_form account_for__new">
                <div className="row">
                    <div className="col-left">
                        <div className="card__bg__s">

                        </div>
                    </div>

                    <div className="col-right">
                        <div className="account_form_inner sec-py">
                            <div className="account_form_head txt-center">
                                <div className="web_logo line-height-0">
                                    <img src={logo} alt="" />
                                </div>
                                {/* <h1 className="form_web_name">
                                {title}
                            </h1> */}
                                {/* {props.formType === "signup" ?
                                // signup Page
                                <p className="account_form_head_txt">
                                    Already have an account?
                                    &nbsp;
                                    <Link to="/login" className="account_form_head_link">
                                        Log in
                                    </Link>
                                </p>
                                :
                                // Login Page
                                <p className="account_form_head_txt">
                                    Haven’t registered?
                                    &nbsp;
                                    <Link to="/signup" className="account_form_head_link">
                                        Sign up now
                                    </Link>
                                </p>
                            } */}
                            </div>
                            {/* Forms */}
                            <div className="account_form_content">
                                <Tabs>
                                    <TabList>
                                        <Tab onClick={() => setphone('')}>Email</Tab>
                                        {/* &nbsp; | &nbsp;
                                        <Tab onClick={() => setemail('')}>Phone Number</Tab> */}
                                    </TabList>

                                    <TabPanel>
                                        <form id="forgotpassword">
                                            <div className="form_group_wrap">
                                                <div className="form_group form_group-flex">
                                                    <input type="email" className='form_control' placeholder='Enter Your  Email..' required onChange={(e: any) => setforgotmail(e.target.value)} />
                                                    {/* <button className='optSendbtn' type='button'>Send Code</button> */}
                                                </div>
                                                {/* <div className="form_group">
                                            <input type="text" className='form_control' placeholder='Phone Or Email Verification Code' required />
                                        </div> */}
                                                <div className="form_group ">
                                                </div>
                                                <div className="form_group txt-right forgot_password-dektop">
                                                    <button type='button' className='forgot_password f-cta-color'>Cannot receive SMS? numsd</button>
                                                </div>
                                            </div>
                                            <div className="form_group_btn_wrap">
                                                <button type='button' onClick={() => { forgotSubmit('email') }} className='form_cta_button'>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </TabPanel>


                                    <TabPanel>
                                        <form id="forgotpassword">
                                            <div className="form_group_wrap">
                                                <div className="form_group form_group-flex">
                                                    <PhoneInput
                                                        inputStyle={{ height: '50px', padding: '0px 20px 0px 50px', border: '1px solid rgba(99, 111, 125, 0.3)', borderRadius: '5px 0 0 5px', outline: 'none', color: '$txt-primary-color', width: '100%', fontSize: '15px' }}
                                                        containerStyle={{}}
                                                        searchClass="search-class"
                                                        disableSearchIcon={false}
                                                        enableTerritories
                                                        countryCodeEditable={true}
                                                        placeholder='Phone Number'
                                                        buttonStyle={{ width: '47px' }}
                                                        dropdownStyle={{ height: '150px' }}
                                                        country={"in"}
                                                        value={forgotphone}
                                                        enableSearch={true}
                                                        onChange={(newValue: any) => setforgotphone(newValue)} />

                                                    {/* <input type="email" className='form_control' placeholder='Phone Or Email..' required onChange={(e: any) => setforgotphone(e.target.value)} /> */}
                                                    {/* <button className='optSendbtn' type='button'>Send Code</button> */}
                                                </div>
                                                {/* <div className="form_group">
                                            <input type="text" className='form_control' placeholder='Phone Or Email Verification Code' required />
                                        </div> */}
                                                <div className="form_group ">
                                                </div>
                                                <div className="form_group txt-right forgot_password-dektop">
                                                    <button type='button' className='forgot_password f-cta-color'>Cannot receive SMS? numsd</button>
                                                </div>
                                            </div>
                                            <div className="form_group_btn_wrap">
                                                <button type='button' onClick={() => { forgotSubmit('phone') }} className='form_cta_button'>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </TabPanel>



                                </Tabs>


                                {/* forgot-password */}



                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default ForgotPass;
