import React, { useEffect, useState } from "react";
import {Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import creditCard from "../../Assets/Images/img/atm-card.png";
import p2pIS from "../../Assets/Icons/p2p.png"
import Select from "react-select";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Modal from "react-responsive-modal";
import wallet from "../../Assets/Icons/wallet.png"
import UPI from "../../Assets/Icons/upi.png"
import _fetch from "../../config/api";
import { api_url, title } from "../../config/config";
import toasted from "../../config/toast";


const Add_post = (props) => {
  const [InrPrice, setInrPrice] = useState(87)

  const [elpMarket, setElpMarket] = useState([])
  const [AvalableelpMarket, setAvalableElpMarket] = useState([])

  async function activityLog() {
    let d = await _fetch(`${api_url}/markets/getAllElpMarketbyType?type=funding_account`, 'GET', {}, {});
    if (d?.status === 'success') {
      d?.market?.map((val) => {
        var newVal = {
          value: val?.symbol,
          text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
          icon: <img src={val?.image} className="option-img-a" />,
          current_price: val?.current_price,
          coinID: val?.coin_id,
        };
        setElpMarket(elpMarket => [...elpMarket, newVal]);
        if (val.total_coin > 0) {
          var newVal = {
            value: val?.symbol,
            text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
            icon: <img src={val?.image} className="option-img-a" />,
            total_coin: val?.total_coin,
            current_price: val?.current_price,
            coinID: val?.coin_id,

          };
          setAvalableElpMarket(AvalableelpMarket => [...AvalableelpMarket, newVal]);
        }
      })
    }
  }
  const [open, setOpen] = useState(false);
  

  /////////////////////////////////////////buy //////////////////////////////////////////////////
  const [buyPrice, setbuyPrice] = useState(0);
  const [buyQuantity, setbuyQuantity] = useState(0);
  const [selectedBuyOption, setSelectedBuyOption] = useState(null);
  const [buyChain, setbuyChain] = useState(null);

  const handleBuyChange = (e) => {
    var newPrice = InrPrice *e?.current_price ;
    setbuyPrice(newPrice)
    setbuyChain(e.coinID)

    setSelectedBuyOption(e);
  }
  const buySubmit = async (e) => {
    e.preventDefault();
    var data = {
      "amount": buyQuantity,
      "price": buyPrice,
      "coinID": buyChain,
      "currency": selectedBuyOption.value,
      // "currency_type" : 'buy_currency',

    }

    var d = await _fetch(`${api_url}/trade/p2p-create-buy-trade`, 'POST', data, {});
    if (d?.status === 'success') {
      toasted.success(d.message);
      //  setOpenQR(false);

    } else {
      toasted.error(d.message);

    }
  }
  const onCloseModal = () => setOpen(false);
  const third = (id) => {
    const a = document.getElementById(id);
    a.classList.toggle("show-option");
    // console.log("okay => show dropdown");
  };

  const [open3, setOpen3] = useState(false);
  const onOpenModal3 = () => setOpen3(true);

  ///////////////////////payment-options details/////////////////////////////////////////////////////
  const [allAccountdetail, setallAccountdetail] = useState([])
  async function getAccountDetails() {
    let d = await _fetch(`${api_url}/payments/paymentDetails`, 'GET', {}, {});
    if (d?.status === 'success') {
      setallAccountdetail(d.data.data)
    }
  }

  ////////////////////////////////select multiple method ////////////////

  useEffect(() => {
    document.title =  props.title;

    activityLog()
    getAccountDetails();
  }, [])

  return (
    <>


      <div className="sub-header-p2p" >
        <div className="a_container">
          <Tabs>
            <div className="tab-sub-header">
             
            </div>

              <div className="p2p_new ">
                <div className="" >
                  <div className="ad-row add__new__row">
                    {/* <div className="col-6 first-column--6"></div> */}
                    <div className="col-6">
                      <div className="card border-0 card-tabs_new">
                          <div className="card-header">
                            <h2> Buy </h2>
                          </div>
                          <div className="card-body">
                              <form onSubmit={buySubmit}>
                                <div className="form-group mb-p2p2" id="p2p_show-drop" >
                                  <div className="p-input">
                                    <label htmlFor="spend-buy">PRICE</label>
                                    <div className="in">
                                     
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="10 -- 1000"
                                        id="receive"
                                        onChange={(e) => setbuyPrice(e.target.value)}
                                        value={buyPrice}
                                      />
                                      {/* <button type="button" className="btn" >
                                MAX
                              </button> */}
                                    </div>
                                  </div>


                                  <Select
                                    placeholder="Coins"
                                    value={selectedBuyOption}
                                    className="select-option-s"
                                    options={elpMarket}
                                    onChange={handleBuyChange}
                                    getOptionLabel={(e) => (
                                      <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                        {e.icon}
                                        <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                      </div>
                                    )}
                                  />

                                </div>
                                <div className="form-group" id="sell-drop-2">
                                  <div className="p-input">
                                    <label htmlFor="receive">QUANTITY</label>
                                    <div className="in">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="10 -- 1000"
                                        id="spend-buy"
                                        onChange={(e) => { setbuyQuantity(e.target.value) }}
                                        value={buyQuantity}
                                      />
                                    </div>
                                  </div>


                                </div>

                                {/* <div className="form-group" id="sell-drop-2">
                          <div className="p-input">
                            <label htmlFor="receive">Receive ≈</label>
                            <div className="in">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="10 -- 1000"
                                id="receive"
                              />
                            </div>
                          </div>
                          <Select
                              placeholder="Coins"
                              value={selectedOption2}
                              className="select-option-s"
                              options={data}
                              onChange={handleChange2}
                              getOptionLabel={(e) => (
                                <div style={{ display: 'flex', alignItems: 'center' ,background: 'transparent' }} className="mian-select-2p2p" >
                                  {e.icon}
                                  <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                </div>
                              )}
                            />

                        </div> */}
                                <p className=" title-p2p mb-p2p2">Total: {buyPrice * buyQuantity} </p>

                                {/* <div className="">
                                  <p className="title-p2p">Payment Methods</p>
                                  <div className="credit-card" onClick={onOpenModal}>
                                    <div className="card-credit">
                                      <img src={creditCard} alt="icon" />
                                      <p> Credit card</p>
                                    </div>
                                    <ArrowDropDownIcon />
                                  </div>
                                </div> */}

                                <button type="submit" className="btn trade_btn active">
                                  Add Card
                                </button>
                              </form>
                            
                          </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

           



          </Tabs>
        </div>
      </div>





      <Modal open={open} onClose={onCloseModal}
        classNames={{
          overlay: 'P2P-PayOverlay',
          modal: 'P2P-Pay',
        }} center  >
        <div className="modal-p2p2">
          <h3>Select Payment Method</h3>
          <div className="all-options">
            <ul>
              <p className="p2p-modal-title">Recommended</p>
              <li className="p2p-mb-5">
                <div className="card-credit">
                  <img src={creditCard} alt="icon" />
                  <p> Credit card</p>
                </div>
                <div className="rateing-bill">
                  <span>87.89</span><br />
                  <span> ≈ 1 USDT </span><br />
                  <span>From P2P</span>
                </div>

              </li>

              <p className="p2p-modal-title">P2PMatch with sellers in just one click</p>
              <li className="" onClick={() => { onCloseModal(false); onOpenModal3(true) }} >
                <div className="card-credit" >
                  <img src={p2pIS} alt="icon" />
                  <p> UPI</p>
                </div>
                <div className="rateing-bill">
                  <span>87.89</span><br />
                  <span> ≈ 1 USDT </span><br />
                  <span>From P2P</span>
                </div>

              </li>
              <li className="">
                <div className="card-credit">
                  <img src={p2pIS} alt="icon" />
                  <p> Phone Pay</p>
                </div>
                <div className="rateing-bill">
                  <span>87.89</span><br />
                  <span> ≈ 1 USDT </span><br />
                  <span>From P2P</span>
                </div>

              </li>
              <div className="" id="li-header" >

                <div className="li-header " onClick={() => third('li-header')} >
                  <p className="hide-aaaa" > More third-party service providers </p>
                  <p className="p2p-modal-title" >Third Party</p>
                  <ArrowDropDownIcon />
                </div>
                <div className="third-party-option">
                  <li className="">
                    <div className="card-credit">
                      <img src={wallet} alt="icon" />
                      <p> Bank Tranfer</p>
                    </div>
                    <div className="rateing-bill">
                      <span>87.89</span><br />
                      <span> ≈ 1 USDT </span><br />
                      <span>From P2P</span>
                    </div>

                  </li>

                  <li className="">
                    <div className="card-credit">
                      <img src={UPI} alt="icon" />
                      <p> UPI</p>
                    </div>
                    <div className="rateing-bill">
                      <span>87.89</span><br />
                      <span> ≈ 1 USDT </span><br />
                      <span>From P2P</span>
                    </div>

                  </li>
                </div>
              </div>

            </ul>
          </div>
        </div>

      </Modal>


    </>
  );
};

export default Add_post;
