import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import slider2 from '../Assets/Images/Footer_IMG/slider2.png'
import slider3 from '../Assets/Images/Footer_IMG/slider3.jpeg'
import "react-alice-carousel/lib/scss/alice-carousel.scss";




const VipIntroduceSlider = () => {

  return (
      <>
  </>
  )
}
export default VipIntroduceSlider
