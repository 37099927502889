import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { _fetch } from "../config/api";
import { logo, api_url } from "../config/config";

// import toast, { Toaster } from 'react-hot-toast';
import toasted from "../config/toast";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const VerificationID = (props: any) => {

    const { token } = useParams()
    let navigate = useNavigate();


    useEffect(() => {
        document.title = props.title;

        // console.log('asdasd',sponser);

    }, [])

    const [passwordConfirm, setpasswordConfirm] = useState('');
    const [password, setpassword] = useState('');
    const [showPassword, setShowPassword] = useState('password')
    const [showConPassword, setShowConPassword] = useState('password')


    const forgotSubmit = async (e: any) => {
        e.preventDefault();
        const loginData = {
            password: password,
            passwordConfirm: passwordConfirm,

        }
        var d = await _fetch(`${api_url}/auth/resetPassword/${token}`, 'POST', loginData, {});
        if (d?.status === 'success') {
            // localStorage.setItem("auth", 'true');
            // localStorage.setItem("accessToken", d?.token);
            // if (d.status == "success") {
            //     localStorage.setItem("user", JSON.stringify(d.data.user));
            // }
            // if (d?.data.kyc.kyc_status === 'Not Applied' || d?.data.kyc.kyc_status === 'Reject') {
                navigate("/login");

            // } else {
            //     navigate("/Dashboard");
            // }
            toasted.success(d?.message);
        } else {
            // localStorage.clear();
            toasted.error(d?.message);

        }

    }
    return (
        <>
            <section className="account_form account_for__new">
                <div className="row">
                    <div className="col-left">
                        <div className="card__bg__s">

                        </div>
                    </div>

                    <div className="col-right">
                        <div className="account_form_inner sec-py">
                            <div className="account_form_head txt-center">
                                <div className="web_logo line-height-0">
                                    <img src={logo} alt="" />
                                </div>
                                {/* <h1 className="form_web_name">
                                {title}
                            </h1> */}
                                {/* {props.formType === "signup" ?
                                // signup Page
                                <p className="account_form_head_txt">
                                    Already have an account?
                                    &nbsp;
                                    <Link to="/login" className="account_form_head_link">
                                        Log in
                                    </Link>
                                </p>
                                :
                                // Login Page
                                <p className="account_form_head_txt">
                                    Haven’t registered?
                                    &nbsp;
                                    <Link to="/signup" className="account_form_head_link">
                                        Sign up now
                                    </Link>
                                </p>
                            } */}
                            </div>
                            {/* Forms */}
                            <div className="account_form_content">
                                <Tabs>
                                    <TabList>
                                        <Tab >Email</Tab>
                                        {/* &nbsp; | &nbsp; */}
                                        {/* <Tab >Phone Number</Tab> */}
                                    </TabList>

                                    <TabPanel>
                                        <form id="forgotpassword">
                                            <div className="form_group_wrap">
                                                <div className="form_group  form_group-password">
                                                    <input type={showPassword} className='form_control' placeholder='Password' value={password} onChange={(e: any) => { setpassword(e.target.value) }} required />
                                                    <button type="button" className='passwod_btn' onClick={() => { setShowPassword(showPassword === 'password' ? 'text' : 'password') }}>
                                                        {showPassword ?
                                                            <img src={require(`../Assets/Icons/password-show.svg`).default} alt="" />
                                                            :
                                                            <img src={require(`../Assets/Icons/password-hide.svg`).default} alt="" />
                                                        }
                                                    </button>
                                                </div>
                                                <div className="form_group form_group-password">
                                                    <input type={showConPassword} className='form_control' placeholder='Password'   value={passwordConfirm} onChange={(e: any) => { setpasswordConfirm(e.target.value) }} required />
                                                    <button type="button" className='passwod_btn' onClick={() => { setShowConPassword(showConPassword === 'password' ? 'text' : 'password') }}>
                                                        {showConPassword ?
                                                            <img src={require(`../Assets/Icons/password-show.svg`).default} alt="" />
                                                            :
                                                            <img src={require(`../Assets/Icons/password-hide.svg`).default} alt="" />
                                                        }
                                                    </button>
                                                </div>
                                            </div>


                                            {/* <div className="form_group form_group-password" style={{marginTop: '20px'}}>
                                                <input type={showPassword ? "text" : "password"} className='form_control' placeholder='Set Login password' name="Password"  />
                                                <button type="button" className='passwod_btn' onClick={() => { setShowPassword('') }}>
                                                    {showPassword ?
                                                        <img src={require(`../Assets/Icons/password-show.svg`).default} alt="" />
                                                        :
                                                        <img src={require(`../Assets/Icons/password-hide.svg`).default} alt="" />
                                                    }
                                                </button>
                                            </div> */}
                                            <div className="form_group_btn_wrap">
                                                <button type='button'  onClick={(e:any) => {forgotSubmit(e)}}  className='form_cta_button'>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </TabPanel>


                                    <TabPanel>
                                        <form id="forgotpassword">
                                            {/* <div className="form_group_wrap">
                                            <div className="form_group form_group-flex">
                                                <input type="text" className='form_control' placeholder='Token..' required />
                                            </div>
                                            <div className="form_group">
                                                <input type="Password" className='form_control' placeholder='Password' required />
                                            </div>
                                        </div> */}


                                            {/* <div className="form_group form_group-password" style={{marginTop: '20px'}}>
                                                <input type={showPassword ? "text" : "password"} className='form_control' placeholder='Set Login password' name="Password" value={signupPassword} onChange={(e: any) => { setsignupPassword(e.target.value) }} required />
                                                <button type="button" className='passwod_btn' onClick={() => { setShowPassword(!showPassword) }}>
                                                    {showPassword ?
                                                        <img src={require(`../Assets/Icons/password-show.svg`).default} alt="" />
                                                        :
                                                        <img src={require(`../Assets/Icons/password-hide.svg`).default} alt="" />
                                                    }
                                                </button>
                                            </div>
                                        <div className="form_group_btn_wrap">
                                            <button type='button' className='form_cta_button'>
                                                Submit
                                            </button>
                                        </div> */}
                                        </form>
                                    </TabPanel>



                                </Tabs>


                                {/* forgot-password */}



                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default VerificationID;
