
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

type CardProps = {
    CoinName: string,
    CoinIcon: string,
}
const api = [
    {
        CoinIcon: "usdt-icon",
        CoinName: "USDT"
    },
    {
        CoinIcon: "usdt-icon",
        CoinName: "USDT"
    },
    {
        CoinIcon: "usdt-icon",
        CoinName: "USDT"
    },
    {
        CoinIcon: "usdt-icon",
        CoinName: "USDT"
    },
    {
        CoinIcon: "usdt-icon",
        CoinName: "USDT"
    },
    {
        CoinIcon: "usdt-icon",
        CoinName: "USDT"
    },
    {
        CoinIcon: "usdt-icon",
        CoinName: "USDT"
    },
    {
        CoinIcon: "usdt-icon",
        CoinName: "USDT"
    },
    {
        CoinIcon: "usdt-icon",
        CoinName: "USDT"
    }
]
const CommonCard = (props: CardProps) => {
    return (
        <>
            {/* Common Card */}
            <div className="slider_card">
                <span className="cirlce_element_one"></span>
                <span className="cirlce_element_second"></span>
                <img src={require(`../Assets/Icons/${props.CoinIcon}.svg`)} alt="" />
                <h4 className="card_heading">
                    {props.CoinName}
                </h4>
            </div>
        </>
    )
}
const MarketSlider = () => {
    return (
        <>
            <div className="market_slider earn_crypto_slider s-normal-bg">
                <div className="sec_content">
                    <Swiper
                        slidesPerView={7.2}
                        spaceBetween={20}
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                            1440: {
                                slidesPerView: 7.2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 5.2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 4.2,
                            },
                            280: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                        }}
                        className="mySwiper">
                        {
                            api.map((elem, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <CommonCard
                                            CoinIcon={elem.CoinIcon}
                                            CoinName={elem.CoinName}
                                        />
                                    </SwiperSlide>
                                );
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </>
    )
}
export default MarketSlider;