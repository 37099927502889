import React, { useEffect } from 'react';
import { logo, title } from '../config/config';
import LockIcon from '@mui/icons-material/Lock';


const Verification = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
  return (
    // this page css (Strategy.scss) 533 line 
    <div className='verification__maxx'>
        <div className='verification__box'>
            <div className='heading__sec'>
                <div className='heading__a'>
                    <img src={logo} alt="web_logo"/>
                    <h1>Official Channel Verification</h1>
                </div>
                <p>Please confirm that you are visiting:<span> <LockIcon/> https://gtc.exchange </span></p>
            </div>
            <p>To prevent phishing or fraud, please verify that the mail/website/social media account is from an official Bitget channel.</p>

            <div className='search__bar__ver'>
                <form>
                    <input type="search" placeholder='Enter the email/website/social media account'/>
                    <button type='submit' className='btn'>Search </button>
                </form>
            </div>

        </div>


    </div>
  );
}

export default Verification;
