import { Link, useNavigate } from "react-router-dom";
// import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { useEffect, useState } from "react";

// import { ReactCountryDropdown } from 'react-country-dropdown'
// import 'react-country-dropdown/dist/index.css'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import _fetch from "../config/api";
import { api_url, kycImage_path, title } from "../config/config";
import toasted from "../config/toast";
import dateFormat from "dateformat";

const selected = (id: any) => {
	//  var sa = document.getElementById(id).classList.add("mystyle");
	const sa: HTMLElement | any = document.getElementById(id);
	sa.classList.add("show_form");
	const column: HTMLElement | any = document.getElementById('HideColumn');
	column.style.display = 'none';
	const btn: HTMLElement | any = document.getElementById('BtnHide');
	btn.style.display = 'none';


	console.log('okay')

}

const handleSelect = (country: any) => {
	console.log(country)
	/* returns the details on selected country as an object
		{
		  name: "United States of America", 
		  code: "US", 
		  capital: "Washington, D.C.", 
		  region: "Americas", 
		  latlng: [38, -97]
		}
	*/
}


const Kyc = (props:any) => {
	const [data, setdata] = useState([]);
	const [status, setstatus] = useState('');
	const [country, setcountry] = useState('');
	const [region, setregion] = useState('');
	const [documentFront, setdocumentFront] = useState('');
	const [documentFrontImg, setdocumentFrontImg] = useState('');
	const [documentBack, SetdocumentBack] = useState('');
	const [documentBackImg, SetdocumentBackImg] = useState('');
	const [pan, Setpan] = useState('');
	const [panImage, SetpanImage] = useState('');
	const [selfie_image, Setselfie_image] = useState('');
	const [selfie_imageGet, Setselfie_imageGet] = useState('');
	const [document_type, Setdocument_type] = useState('Aadhar');
	const [first_name, Setfirst_name] = useState('');
	const [last_name, Setlast_name] = useState('');
	const [dob, Setdob] = useState('');
	const [state, Setstate] = useState('');
	const [city, Setcity] = useState('');
	const [pan_number, Setpan_number] = useState('');
	const [re_enter_pan_number, Setre_enter_pan_number] = useState('');
	const [document_number, Setdocument_number] = useState('');
	const [re_enter_document_number, Setre_enter_document_number] = useState('');
	const [pin_code, Setpin_code] = useState('');
	const [comment, Setcomment] = useState('');

	function selectCountry(val: any) {
		setcountry(val);
	}
	function selectRegion(val: any) {
		setregion(val);
	}
	let navigate = useNavigate();

	useEffect(() => {
		document.title = props.title ;

		(async () => {
			const passData = {

			}
			const data1 = await _fetch(`${api_url}/kyc/userKycInfo/`, 'GET', passData, {});
			setdata(data1.data.data.document_type);
			const newData = data1.data.data;
			if (newData.kyc_status === 'Approved') {
				navigate("/Dashboard/Kyc/Approved");
			} else if (newData.kyc_status === 'Not Applied' || newData.kyc_status === 'Reject') {
				navigate("/Dashboard/Kyc");

			}
			setcountry(newData.country)
			setregion(newData.state)
			setdocumentFront(newData.document_back_image)
			SetdocumentBack(newData.document_front_image)
			Setpan(newData.pan_image)
			Setselfie_image(newData.selfie_image)
			Setdocument_type(newData.document_type)
			Setfirst_name(newData.first_name)
			Setlast_name(newData.last_name)
			var date = dateFormat(newData.dob, "IST:yyyy-mm-dd").replace('IthP:', '').replace('IthA:', '').replace('IstP:', '').replace('IstA:', '');

			Setdob(date)
			Setcity(newData.city)
			Setpan_number(newData.pan_number)
			Setdocument_number(newData.document_number)
			Setpin_code(newData.pin_code)
			Setcomment(newData.comment)
			setstatus(newData.kyc_status)
			console.log(newData)

			if (newData.kyc_status == 'Pending' || newData.kyc_status == 'Approved') {
				selected('card__2')
			}

		})()

	}, [])

	return (
		<>
			<div className="contaienr_kyc margin_b">
				<div className="inner_contaienr">
					<div className="card">
						<div className="card-header">
							<div className="check-box">
								<div className="img-bg_a">
									<img src={require("../Assets/Icons/circle.svg").default} alt="eys icon" />
								</div>
								<span className="check-box-name" > KYC LOCKED</span>
							</div>
						</div>
						<div className="card-body">
							<h4 style={{ color: '#e7c14b' }}  >KYC Status!</h4>
							<span style={{ fontSize: '14px' }} >Your {title} Account is Pending, Please wait for approval.</span>

						</div>
					</div>

					<div className="card card-2" id="card__2" style={{ marginTop: '30px' }}  >
						<div className="card-header">
							<h3> Personal Info</h3>
						</div>
						<div className="card-body">
							<div className="form-group">
								<label htmlFor="sel1">COUNTRY</label>
								<CountryDropdown
									value={country}
									onChange={(val) => selectCountry(val)} classes="form-control" disabled />
							</div>

							<div className="form-group">
								<label htmlFor="usr">FIRST NAME *</label>
								<input type="text" className="form-control" value={first_name} onChange={(e: any) => { Setfirst_name(e.target.value) }} id="usr" disabled />
							</div>
							<div className="form-group">
								<label htmlFor="usr">LAST NAME</label>
								<input type="text" className="form-control" id="usr" value={last_name} onChange={(e: any) => { Setlast_name(e.target.value) }} disabled />
							</div>
							<div className="form-group">
								<label htmlFor="usr">DATE OF BIRTH (DD-MM-YYYY) *</label>
								<input type="date" className="form-control" placeholder="DD-MM-YYYY" id="usr" value={dob} onChange={(e: any) => { Setdob(e.target.value) }} disabled />
							</div>
							<div className="form-group">
								<label htmlFor="comment">Comment:</label>
								<textarea className="form-control" rows={5} id="comment" onChange={(e: any) => { Setcomment(e.target.value) }} value={comment} disabled>{comment}</textarea>
							</div>
							<div className="form-group">
								<label htmlFor="sel1">STATE *</label>
								<RegionDropdown
									country={country}
									value={region}
									onChange={(val: any) => selectRegion(val)} classes="form-control" disabled />
							</div>
							<div className="form-group">
								<label htmlFor="usr">CITY *</label>
								<input type="text" className="form-control" id="usr" value={city} onChange={(e: any) => { Setcity(e.target.value) }} disabled />
							</div>
							<div className="form-group">
								<label htmlFor="usr">PIN/ZIP CODE *</label>
								<input type="text" className="form-control" id="usr" value={pin_code} onChange={(e: any) => { Setpin_code(e.target.value) }} disabled />
							</div>
							{/* pan -card  */}
							<div className="pan-card">
								<div className="card-header card-header-2">
									<h3> PAN | TAX Number</h3>
								</div>
								<div className="form-group">
									<label htmlFor="usr">PAN | TAX Number</label>
									<input type="text" className="form-control" id="usr" maxLength={10} value={pan_number} onChange={(e: any) => { Setpan_number(e.target.value) }} disabled />
								</div>

								<div className="form-group">
									<div className="file__box" >
										{status != 'Pending' && status != 'Approved' && status != 'Rejected' &&
											<img src={require("../Assets/Icons/proofStyle.svg").default} width={'80%'} alt="" />
										}
										{status === 'Pending' || status === 'Approved' || status === 'Rejected' &&
											<img src={panImage == '' ? kycImage_path + pan : panImage} width={'80%'} alt="" />
										}
									</div>
									{status != 'Pending' && status != 'Approved' &&
										<>
											<div className="label__div">
												<label htmlFor="documentFront">
													<a className="upload-btn"> Upload Document   </a>
												</label>
											</div>
											<input type="file" id="documentFront" onChange={(e: any) => { Setpan(e.target.files[0]); SetpanImage(URL.createObjectURL(e.target.files[0])) }} style={{ display: 'none' }} readOnly />
										</>
									}
								</div>
							</div>
							{/* Document Type */}
							<div className="pan-card">
								<div className="card-header card-header-2">
									<h3> Document Type</h3>
								</div>
								<div className="form-group">
									<select className="form-control" id="sel1" onChange={(e: any) => { Setdocument_type(e.target.value) }} disabled>
										<option value='Aadhar' selected={document_type == 'Aadhar' ? true : false}>National ID</option>
										<option value='Passport' selected={document_type == 'Passport' ? true : false}>Passport</option>
										<option value='DrivingLicense' selected={document_type == 'DrivingLicense' ? true : false}>Driving license</option>
									</select>
								</div>
								<div className="" id="Aadhar">
									<div className="form-group">
										<label htmlFor="usr">{document_type == 'Aadhar' ?'National ID': document_type}  NUMBER *</label>
										<input type="text" className="form-control" id="usr" value={document_number} maxLength={15} onChange={(e: any) => { Setdocument_number(e.target.value) }} />
									</div>
									{status != 'Pending' && status != 'Approved' &&
										<div className="form-group">
											<label htmlFor="usr">RE-ENTER {document_type == 'Aadhar' ?'National ID': document_type}  NUMBER *</label>
											<input type="text" className="form-control" id="usr" maxLength={15} onChange={(e: any) => { Setre_enter_document_number(e.target.value) }} />
										</div>
									}
									<div className="form-group">
										<h3> {document_type == 'Aadhar' ?'National ID': document_type}  Front </h3>
										<div className="file__box" >
											{status != 'Pending' && status != 'Approved' && status != 'Rejected' &&
												<img src={require("../Assets/Icons/proofStyle.svg").default} width={'80%'} alt="" />
											}
											{(status === 'Pending' || status === 'Approved' || status === 'Rejected') &&
												<img src={documentFrontImg == '' ? kycImage_path + documentFront : documentFrontImg} width={'80%'} alt="" />
											}
										</div>
										{status != 'Pending' && status != 'Approved' &&
											<>
												<div className="label__div" >
													<label htmlFor="documentFront1">
														<a className="upload-btn"> Upload Document   </a>
													</label>
												</div>
												<input type="file" id="documentFront1" className="upload-btn" data-bv-field="documentFront" name='documentFront' onChange={(e: any) => { setdocumentFront(e.target.files[0]); setdocumentFrontImg(URL.createObjectURL(e.target.files[0])) }} style={{ display: 'none' }} />
											</>
										}
									</div>
									<div className="form-group">
										<h3> {document_type == 'Aadhar' ?'National ID': document_type}  Back </h3>
										<div className="file__box" >
											{(status != 'Pending' && status != 'Approved' && status != 'Rejected') &&
												<img src={require("../Assets/Icons/proofStyle.svg").default} width={'80%'} alt="" />
											}
											{(status == 'Pending' || status == 'Approved' || status == 'Rejected') &&
												<img src={documentBackImg == '' ? kycImage_path + documentBack : documentBackImg} width={'80%'} alt="" />
											}
										</div>
										{status != 'Pending' && status != 'Approved' &&
											<>
												<div className="label__div">
													<label htmlFor="documentFront2">
														<a className="upload-btn"> Upload Document   </a>
													</label>
												</div>
												<input type="file" id="documentFront2" onChange={(e: any) => { SetdocumentBack(e.target.files[0]); SetdocumentBackImg(URL.createObjectURL(e.target.files[0])) }} style={{ display: 'none' }} />
											</>
										}
									</div>
								</div>
							</div>

							{/* Document Type */}
							<div className="pan-card">
								<div className="card-header card-header-2">
									<h3> Selfie</h3>
								</div>
								<div className="">
									<div className="form-group">
										<div className="file__box" >
											{(status != 'Pending' && status != 'Approved' && status != 'Rejected') &&
												<img src={require("../Assets/Icons/selfie.svg").default} width={'80%'} alt="" />
											}
											{(status === 'Pending' || status === 'Approved' || status === 'Rejected') &&
												<img src={selfie_imageGet == '' ? kycImage_path + selfie_image : selfie_imageGet} width={'80%'} alt="" />
											}
										</div>
										{(status != 'Pending' && status != 'Approved') &&
											<>
												<div className="label__div">
													<label htmlFor="selfie">
														<a className="upload-btn"> Upload Selfie</a>
													</label>
												</div>
												<input type="file" id="selfie" onChange={(e: any) => { Setselfie_image(e.target.files[0]); Setselfie_imageGet(URL.createObjectURL(e.target.files[0])) }} style={{ display: 'none' }} />

											</>
										}
									</div>
								</div>
							</div>
						</div>
						{(status != 'Pending' && status != 'Approved') &&

							<div className="card card-3" >
								<h3 className="title_a">Ready to submit your application?</h3>
								<p>Please verify the details you’re submitting. Once submitted, you won’t be able to change it unless you
									<a href="#" target={"_blank"}> Contact us</a>
								</p>
								{status != 'Pending' && status != 'Approved' &&
									<div className="">
										<button type="submit" className="btn">Submit for verification </button>
									</div>
								}
							</div>
						}
					</div>
				</div>
			</div>
		</>
	)
}
export default Kyc;