import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import img from '../Assets/Images/blog-banner.jpg'

import { title } from '../config/config';

// import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Blog = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, []);
    return (
        <>
            <div className="a_container">

                <div className="page-heading">
                    <h1>{title}</h1>
                    <h4>INDIA’S MOST TRUSTED BITCOIN & CRYPTO BLOG</h4>
                </div>


                <div className="main-blog">
                    <div className="col-4">

                        <Link to="/Dashboard/Blog/Blog_view">

                       
                        <div className="card">
                            <div className="cardimg">
                                <img src={img} alt="" />
                            </div>
                            <div className="card-title">
                                <h1>Weekly Crypto Analysis: Bitcoin Fighting For $23K — Has the Bull Rally Started?</h1>
                                <p>The Commodity Futures Trading Commission’s commissioner, Christy Goldsmith Romero, second-guessed VCs and institutional...</p>

                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-4">

                        <Link to="/Dashboard/Blog/Blog_view">

                       
                        <div className="card">
                            <div className="cardimg">
                                <img src={img} alt="" />
                            </div>
                            <div className="card-title">
                                <h1>Weekly Crypto Analysis: Bitcoin Fighting For $23K — Has the Bull Rally Started?</h1>
                                <p>The Commodity Futures Trading Commission’s commissioner, Christy Goldsmith Romero, second-guessed VCs and institutional...</p>

                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-4">

                        <Link to="/Dashboard/Blog/Blog_view">

                       
                        <div className="card">
                            <div className="cardimg">
                                <img src={img} alt="" />
                            </div>
                            <div className="card-title">
                                <h1>Weekly Crypto Analysis: Bitcoin Fighting For $23K — Has the Bull Rally Started?</h1>
                                <p>The Commodity Futures Trading Commission’s commissioner, Christy Goldsmith Romero, second-guessed VCs and institutional...</p>

                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-4">

                        <Link to="/Dashboard/Blog/Blog_view">

                       
                        <div className="card">
                            <div className="cardimg">
                                <img src={img} alt="" />
                            </div>
                            <div className="card-title">
                                <h1>Weekly Crypto Analysis: Bitcoin Fighting For $23K — Has the Bull Rally Started?</h1>
                                <p>The Commodity Futures Trading Commission’s commissioner, Christy Goldsmith Romero, second-guessed VCs and institutional...</p>

                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-4">

                        <Link to="/Dashboard/Blog/Blog_view">

                       
                        <div className="card">
                            <div className="cardimg">
                                <img src={img} alt="" />
                            </div>
                            <div className="card-title">
                                <h1>Weekly Crypto Analysis: Bitcoin Fighting For $23K — Has the Bull Rally Started?</h1>
                                <p>The Commodity Futures Trading Commission’s commissioner, Christy Goldsmith Romero, second-guessed VCs and institutional...</p>

                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-4">

                        <Link to="/Dashboard/Blog/Blog_view">

                       
                        <div className="card">
                            <div className="cardimg">
                                <img src={img} alt="" />
                            </div>
                            <div className="card-title">
                                <h1>Weekly Crypto Analysis: Bitcoin Fighting For $23K — Has the Bull Rally Started?</h1>
                                <p>The Commodity Futures Trading Commission’s commissioner, Christy Goldsmith Romero, second-guessed VCs and institutional...</p>

                            </div>
                        </div>
                        </Link>
                    </div>
                   
                </div>

            </div>
        </>
    )
}

export default Blog