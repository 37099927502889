import React, { useEffect } from 'react'
import { title } from '../config/config'


const BitcoinWhat = (props:any) => {
     useEffect(() => {
          document.title =  props.title ;
      }, []);
  return (
    <>
        <div className="What___bitcoin">

            <section className='bit_A__banner'>
                <div className='bit_A____container'>
                    <div className='card__banner__left'>
                        <h1>How to buy Bitcoin from {title}</h1>
                        <button className='btn' type='submit'>Buy bitcoin now</button>
                    </div>
                </div>
            </section>

            <section className='what___sec'>
                <div className='bit_A____container'>
                   <div className='What__sec__we'>
                        <h3>What Is Bitcoin (BTC)?</h3>
                        <p>In 2008, a paper authored by Satoshi Nakamoto titled “Bitcoin: A Peer-to-Peer Electronic Cash System” was posted. The paper introduced the basic concept of Bitcoin as a decentralized peer-to-peer digital currency. In the next year, Satoshi Nakamoto mined the first block and received 50 bitcoins as a block reward. Bitcoin was launched in 2009. It provides users with the ability to send and receive digital money without any intermediaries like financial institutions.</p>
                   </div>

                   <div className='What__sec__we'>
                        <p>Unlike the fiat currencies such as USD, there is not any centralized institution like a bank or government controlling Bitcoin, which means no government issues it, and no banks or institutions manage accounts and verify transactions. Instead, the financial system in Bitcoin is run by thousands of computers distributed around the world. Transactions are verified by network nodes through cryptography and recorded in a public distributed ledger called a blockchain. Anyone can participate in the ecosystem of Bitcoin.</p>
                   </div>

                   <div className='What__sec__we'>
                        <h3>How Bitcoin Works</h3>
                        <p>Bitcoin is a system of decentralized verification based on math and cryptography. The details of a transaction are public and open to anyone. In a Bitcoin transaction, if Sender A sends a Bitcoin to Receiver B, the transaction details including the sender, receiver and the amount of coins will be recorded on a particular database called “blockchain”.</p>
                   </div>

                   <div className='What__sec__we'>
                        <p>Blockchain is a chain of blocks that contains all the transaction information. Each block contains the hash of the previous block which acts as a fingerprint of a block, unique and unrepeatable. It prevents the data from being changed by anyone and helps secure transparency and safety. There is no centralized bank or institution to process transfers. Those who record the transaction details and store them in the chain, will receive Bitcoins as a reward.</p>
                        <p>The Bitcoin blockchain uses the mechanism called the "Proof-of-Work" which allows to secure the blockchain, to trace, and to have proof that simply all the transactions made on the blockchain are valid.</p>
                        <p>Let's say Alice wants to send Bitcoin to her friend named Bob. As a reminder, when there is a transfer of Bitcoins, for this transfer to take place, several conditions are required. First of all, it is necessary to verify that Alice is solvent and therefore that she has the quantity of bitcoins she wants to send. Then, the transaction must be registered in a register and recorded in what is commonly called the "blockchain". It is then necessary to register Alice's sending of Bitcoins to Bob in the blockchain, which is a digital ledger to which all participants in the Bitcoin network have access. When Alice sends to Bob for example 1 BTC, there are then people with more or less powerful computing machines, who are called "miners", and who are located a little bit everywhere in the world, who when the transaction is going to be initiated are going to fight to solve a complex mathematical problem and the first one who succeeds in solving this complex mathematical problem will have the right to add the transaction that Alice carried out towards Bob in the blockchain. And in fact, when a miner adds a transaction to the blockchain, he is rewarded by the blockchain via an algorithm and receives BTC in exchange for his efforts..</p>
                   </div>

                   <div className='What__sec__we'>
                        <h3>How Much Bitcoin (BTC) Is in Circulation?</h3>
                        <p>Bitcoin has not been pre-mined, meaning that no coins have been distributed between the founders before it became available to the public. The long-term vision of Bitcoin’s creator has resulted in a hard supply limit of 21,000,000 coins, making it a scarce, thus valuable, asset. To date, 19 million bitcoins have already been issued - mined, with about 2 million bitcoins still to be released, signaling a near-future pivotal point in its evolution.</p>
                        <p>The successful miner finding the new block collects all transaction fees from transactions they included in the block and a reward of newly created bitcoins. All bitcoins in existence have been created through this type of transaction. When Bitcoin was first established, the reward was 50 bitcoins per block - this number gets halved with every 210,000 new blocks mined (approximately every four years). As of May 2020, this reward is currently 6.25 bitcoins. Eventually, in 2140, the reward will round down to zero, no new bitcoins will be issued, and the limit of 21 million bitcoins will be reached, the miner will then be rewarded by transaction fees only.</p>
                        <p>Moreover, the amount of BTC in circulation will only decrease with time due to various reasons such as lost wallet private keys, sending Bitcoin to the wrong addresses, etc. All these reasons are likely to cause Bitcoin to go down in supply and increase in value as a result.</p>
                   </div>

                   <div className='What__sec__we'>
                        <h3>Bitcoin Value and Price</h3>
                        <p>Maybe you have never owned or bought Bitcoin before, but you will definitely have heard the name somewhere. When we look back, it has been such an impressive journey for Bitcoin.</p>
                        <p>Bitcoin Price History </p>
                        <p>It was worth nothing when the pseudonymous Nakamoto Satoshi published the Bitcoin whitepaper and changed hands barely above zero in the next five years. Do you know that there is a Bitcoin Pizza Day that is celebrated on May 22 every year? It was the first time ever that Bitcoin was used in a commercial transaction, as a man in Florida agreed to pay for his two delivered pizzas with 10,000 BTC in 2010.</p>
                        <p>It took the coin three more years to climb to the US$1,000 mark, and even led to the installation of the first Bitcoin ATM in Vancouver, but the rally did not last long. At that time, many had predicted the fall of Bitcoin and referred to it as a scam, only to be stupefied by the 2017 upsurge. Bitcoin price exceeded US$20,000, representing a return of 20X in less than 12 months and a market capitalization of over US$1 billion dollars. It immediately captured the attention of mainstream media, encouraging more retail investors to trade and hold BTC.</p>
                        <p> To date, Bitcoin is inarguably the most well-known and most traded cryptocurrency for more than a decade. Skeptics waiting for the fall of crypto have been stunned when the famous coin hit US$67,000 in 2021.</p>
                   </div>

                   <div className='What__sec__we'>
                        <h3>How much of the world’s money is in Bitcoin now?</h3>
                        <p>The global balance sheet of 2020 reported a total wealth of US$1,540 trillion in crypto, while the market cap of Bitcoin currently stands at US$787.4 billion.</p>
                   </div>

                   <div className='What__sec__we'>
                        <h3>Is Bitcoin an alternative store of value?</h3>
                        <p>It is too early to dismiss the idea that Bitcoin may become the new store of value. What we know is Bitcoin is on the same beginning track with all other existing stores of value and, considering its current market cap, there is much more to observe until Bitcoin price finally reaches its steady state.</p>
                        <p>For now, Bitcoin meets the four criteria of being (1) rare, (2) the medium of exchange, (3) the unit of account, and (4) the store of value, so it is considered by many to be "digital gold".</p>
                   </div>


                   <div className='What__sec__we'>
                        <h3>How to Buy Bitcoin</h3>
                        <p>Consider investing in Bitcoin (BTC)? It only takes 2 minutes to create an account on {title} and start trading Bitcoin.</p>
                        <p>Check out the available BTC trading pairs on {title}!</p>
                        <p>Futures market</p>
                        <h5> <a href='#'>BTCUSDT</a></h5>
                        <h5> <a href='#'>BTCUSD</a></h5>
                        <h5> <a href='#'>BTCPERP</a></h5>
                   </div>

                </div>
            </section>



        </div>

    </>
  )
}

export default BitcoinWhat