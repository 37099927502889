import Banner from "../Sections/Banner";
import DepositSlider from "../Sections/Deposit-withdrawal-Finance";
import CoinTrade from "../Sections/Coint-trade";
import SikkaService from "../Sections/Sikka-service";
import GetFirstCoin from "../Sections/Get-first-coin";
import OnlineTreding from "../Sections/Online-trading-anytime";
import Testimonial from "../Sections/Media-saying-testimonial";
import Faq from "../Sections/Faq";
import { useEffect } from "react";
import { title } from "../config/config";



const IndexPage = (props:any) => {
        useEffect(() => {
            document.title = props.title;

        },[])
    return (
        <>
            {/*  Section Hero Banner */}
            <Banner />

            {/* Section Deposit-withdrawal-Finance */}
            {/* <DepositSlider /> */}

            {/* Section Deposit-withdrawal-Finance */}
            <CoinTrade />

            <div className="time__line__bg">
                {/* Section Get-first-coin */}
            <GetFirstCoin />

            {/* Section Sikka-service */}
            <SikkaService />



            </div>
            {/* Section Sikka-service */}
            <OnlineTreding />

            {/* Section Sikka-service */}
            <Testimonial />

            {/* Section Sikka-service */}
            <Faq />


        </>
    );
}
export default IndexPage;