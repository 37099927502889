import { useState, useEffect } from "react";
import { api_url } from "../../config/config";
import ReactPaginate from 'react-paginate';
import { _fetch } from "../../config/api";
import dateFormat, { masks } from "dateformat";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
import LaunchIcon from '@mui/icons-material/Launch';
import toasted from "../../config/toast";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoaderCom from "../loader/LoaderCom";

const WithdrawHistory = (props:any) => {

   const [records, setrecords] = useState(0);
   const [pageCount, setPageCount] = useState(0);
   const [itemOffset, setItemOffset] = useState(1);
   const [short, setshort] = useState('created_at');
   const [searchedVal, setSearchedVal] = useState("");
   const [searchedType, setSearchedType] = useState("symbol");
   const [emptyData, setemptyData] = useState([]);
   const [TableRecords, setTableRecords] = useState([]);
   const [tradeData, setdata] = useState([]);
   const [buybtntrade, setbuybtntrade] = useState(false);
   const handlePageClick = async (event: any) => {
      const newOffset = (event.selected + 1);
      setItemOffset(newOffset);
   };
   useEffect(() => {
      document.title = props.title;
      (async () => {
         setemptyData([]);
         setTableRecords([]);
         const passData = {
            limit: 10,
            page: 1,
         }
         if (searchedVal != "") {
            var searchdata = `&search=${searchedType}&value=${searchedVal}`;
         } else {
            var searchdata = "";
         }
         const data1 = await _fetch(`${api_url}/withdraw/withdrawHistory?&limit=10&sort=${short}&page=${itemOffset}${searchdata}`, 'GET', passData, {});
         setdata(data1.data.data);
         setTableRecords(data1?.data.data);
         setrecords(data1.total);
         setemptyData(data1);
      })()
      setPageCount(Math.ceil(records / 10));
      // activityLog()
   }, [itemOffset, records, short, searchedVal, searchedType]);

   const auth = localStorage.getItem("auth");
   // Dropdown js Code

   const [RecordModal, setRecordModal] = useState({
      admin_status: "",
      amount: 0,
      blockchain_transaction: "",
      chain_id: "",
      check_txn: "",
      created_at: "",
      fee: 0,
      hash: "",
      json_response: "",
      network: "",
      payable_amount: 0,
      process_status: "",
      remark: "",
      status: '',
      symbol: '',
      updated_at: '',
      user_remark: '',
      username: '',
      wallet_address: '',
      _id: ''
   })

   const [open, setOpen] = useState(false);
   const onOpenModal = (val: any) => {
      if (val.hash != '') {
         setOpen(true)
      }
      setRecordModal(val);
   };

   const onCloseModal = () => setOpen(false);

   const copyToClipboardId = (data: any) => {
      navigator.clipboard.writeText(data);
      toasted.success('Hash Id Copied Successfully!');

   }
   return (
      <>
         <section className="sec_market_trade">
            <div className="container-max">
               <div className="sec_content">

                  {/* Main Tabs */}
                  <div className="main_tabs_content">
                     <div className="token_coin_content">
                        {/* Main Market Tabs */}
                        <div className="dropdown_trabs main_market_tabs">
                           <div className="main_market_tabs_content ">
                              
                           </div>
                        </div>

                        <div className="main_market_tabs">
                           <div className="card-body-container ">
                              <div className="table____main">
                                 <div className="table-section-1">
                                    <div className="table-header">
                                       <div className="left-t-geader withdraw-H">
                                          <form>
                                             <div className="input-group mb-3 select-input-group">
                                                <select className="form-control" name="short" onChange={(e: any) => { setSearchedType(e.target.value) }}>
                                                   <option value="symbol">SYMBOL</option>
                                                   <option value="hash">HASH</option>
                                                </select>
                                             </div>
                                             <div className="input-group mb-3 select-input-group-right">
                                                <div className="input-group-prepend"> 🔍 </div>
                                                <input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearchedVal(e.target.value)} />
                                             </div>
                                             <div className="toggle" >
                                               
                                             </div>
                                          </form>
                                       </div>
                                    </div>
                                    <div className="table-body">
                                       <div className="table-responsive">
                                          <table className="table balance-table withdral-History">
                                             <thead>
                                                <tr>
                                                   <th onClick={() => { short == 'symbol' ? setshort('-symbol') : setshort('symbol') }} style={{ cursor: 'pointer', textAlign: 'center' }}>SYMBOL </th>
                                                   <th onClick={() => { short == 'network' ? setshort('-network') : setshort('network') }} style={{ cursor: 'pointer' }}>NETWORK </th>
                                                   <th onClick={() => { short == 'amount' ? setshort('-amount') : setshort('amount') }} style={{ cursor: 'pointer' }}>AMOUNT </th>
                                                   <th onClick={() => { short == 'payable_amount' ? setshort('-payable_amount') : setshort('payable_amount') }} style={{ cursor: 'pointer' }}>PAYABLE AMOUNT</th>
                                                   <th onClick={() => { short == 'status' ? setshort('-status') : setshort('status') }} style={{ cursor: 'pointer' }}>STATUS </th>
                                                   <th onClick={() => { short == 'wallet_address' ? setshort('-wallet_address') : setshort('wallet_address') }} style={{ cursor: 'pointer' }}>WALLET ADDRESS</th>
                                                   <th onClick={() => { short == 'created_at' ? setshort('-created_at') : setshort('created_at') }} style={{ cursor: 'pointer' }}>CREATED AT</th>
                                                </tr>
                                             </thead>
                                             <tbody>

                                                <LoaderCom condition={emptyData} text={'Getting latest values...'} />
                                                {TableRecords?.map((val: any, index: any) =>
                                                   <>
                                                      {
                                                         <tr key={index} onClick={() => { onOpenModal(val) }}>
                                                            <td style={{ textAlign: 'center' }}>
                                                               <p>{val.symbol.toUpperCase()}</p>
                                                            </td>
                                                            <td>
                                                               {val.network}
                                                            </td>
                                                            <td>
                                                               <p>{val.amount}</p>
                                                            </td>
                                                            <td>
                                                               {val.payable_amount}
                                                            </td>
                                                            <td>
                                                               {val.status ? val.status : 'Pending'}
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                               {val.wallet_address}
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>{dateFormat(val.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                                         </tr>
                                                      }

                                                   </>
                                                )
                                                }

                                             </tbody>
                                          </table>

                                       </div>
                                    </div>
                                    <div className="update_trending_coin_tabs">
                                       <div className="tabs_content active">
                                          <div className="content_inner_data">
                                            {records > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={1}
                                                pageCount={records / 10}
                                                previousLabel="<"
                                             />}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {buybtntrade && (
                           <div className="market buy_sell_btn s-primary-bg">
                              <button className="t-primary-color f-primary-bg">Trade</button>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </section>
        
         <Modal open={open} onClose={onCloseModal} classNames={{
            overlay: 'customOverlay',
            modal: 'customModal__Sas',
         }} center  >
            <div className="card-header" >
               <h3>Withdraw More Details</h3>
            </div>
            <div id="Modal__details">
               <div className="modal-body">
                  {RecordModal.blockchain_transaction ? <div className="column__1">
                     <p> Reference ID </p>
                     <p> {RecordModal?.admin_status}</p>
                  </div> : null}

                  <div className="column__1">
                     <p> Amount </p>
                     <p> {(RecordModal.amount.toFixed(5)) + ' ' + RecordModal.symbol.toUpperCase()}</p>
                  </div>
                  <div className="column__1">
                     <p> Payable Amount</p>
                     <p> {RecordModal.payable_amount}</p>
                  </div>
                  <div className="column__1">
                     <p> Network</p>
                     <p> {RecordModal.network.toUpperCase()}</p>
                  </div>
                  {/* {RecordModal.hash ? */}
                  <div className="column__1">
                     <p className="Transaction-fees" > Hash<br /><small></small> </p>
                     <div className="copy-btn-div" style={{ display: 'flex' }} onClick={() => copyToClipboardId(RecordModal.hash)}>
                        <p> {RecordModal.hash}</p>

                        <a className="copy-btn" title="copy">  <ContentCopyIcon />  </a>
                     </div>
                  </div>
                  {/* : null} */}
                  <div className="column__1">
                     <p> Wallet Address</p>
                     <p> {RecordModal.wallet_address}</p>
                  </div>
                  <div className="column__1">
                     <p> Fee</p>
                     <p> {RecordModal.fee}</p>
                  </div>
                  <div className="column__1">
                     <p> Status</p>
                     <p className="status_color_green"> {RecordModal.status ? RecordModal.status : 'Pending'}</p>
                  </div>
                  {RecordModal.blockchain_transaction ?
                     <div className="column__1">
                        <p> Transaction ID</p>
                        <p className="Transaction_tokan" > {RecordModal.hash}</p>
                     </div>
                     : null}
                  <div className="column__1">
                     <p> Remark </p>
                     <p className="Transaction_tokan" > {RecordModal.remark.toUpperCase()}</p>
                  </div>
                  <div className="column__1">
                     <p> Date & Time</p>
                     <p><span className="date" style={{ display: 'flex' }}>  {dateFormat(RecordModal.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")} </span><span className="time"></span></p>
                  </div>
               </div>
               {/* {RecordModal.hash ?  */}
               <div className="links">
                  <p> <a href={RecordModal.hash} target='_blank'>View Transaction Explorer <LaunchIcon /></a> </p>
               </div>
               {/* : null} */}
               
            </div>
         </Modal>
      </>
   );
};

export default WithdrawHistory;