import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img_icons from "../../Assets/Images/t.svg";
import creditCard from "../../Assets/Images/img/atm-card.png";
import p2pIS from "../../Assets/Icons/p2p.png"
// import p2p__banner from "../../Assets/Images/P2p__new.png" 
import p2p__banner from "../../Assets/Images/desktop-background.png"
import Select from "react-select";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Modal from "react-responsive-modal";
import wallet from "../../Assets/Icons/wallet.png"
import UPI from "../../Assets/Icons/upi.png"
import MarketP2P from "./MarketP2P";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import toasted from "../../config/toast";
import { title } from "../../config/config";
import { textAlign } from "@mui/system";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import { Link } from "react-router-dom";


const P2PNew = (props) => {
  const addShow = (id) => {
    const a = document.getElementById(id);
    a.classList.toggle("p2p-dropdown-show");
    // console.log("okay => show dropdown");
  };
  const [elpMarket, setElpMarket] = useState([])
  const [AvalableelpMarket, setAvalableElpMarket] = useState([])
  const [coinBalance, setcoinBalance] = useState()

  const [InrPrice, setInrPrice] = useState(87)
  const [BuyMethodView, setBuyMethodView] = useState('Payment Options')

  async function activityLog() {
    let d = await _fetch(`${api_url}/markets/getAllElpMarketbyType?type=funding_account`, 'GET', {}, {});
    if (d?.status === 'success') {
      d?.market?.map((val) => {
        var newVal = {
          value: val?.symbol,
          text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
          icon: <img src={val?.image} className="option-img-a" />,
          current_price: val?.current_price,
          coinID: val?.coin_id,
        };
        setElpMarket(elpMarket => [...elpMarket, newVal]);
        if (val.total_coin > 0) {
          var newVal = {
            value: val?.symbol,
            text: <span className="new__a"> {val?.symbol.toUpperCase()}</span>,
            icon: <img src={val?.image} className="option-img-a" />,
            total_coin: val?.total_coin,
            current_price: val?.current_price,
            coinID: val?.coin_id,

          };
          setAvalableElpMarket(AvalableelpMarket => [...AvalableelpMarket, newVal]);
        }
      })
    }
  }
  // console.log('elpMarket',elpMarket,AvalableelpMarket);



  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openspend, setOpenspend] = useState(false);
  const [openEDit, setOpenEDit] = useState(false);



  ///////////////////////////CLOSE SELL /////////////////

  /////////////////////////////////////////buy //////////////////////////////////////////////////
  const [buyPrice, setbuyPrice] = useState(0);
  const [buyQuantity, setbuyQuantity] = useState(0);
  const [selectedBuyOption, setSelectedBuyOption] = useState(null);
  const [buyChain, setbuyChain] = useState(null);
  const [getPeymentMethod, setgetPeymentMethod] = useState(null);
  const [searchType, setsearchType] = useState(false);

  const handleBuyChange = (e) => {
    var newPrice = InrPrice * e?.current_price;
    setbuyPrice(newPrice)
    setbuyChain(e.coinID)

    setSelectedBuyOption(e);
  }
  // const buySubmit = async (e) => {
  // e.preventDefault();
  var serchBuyData = {
    "searchType": searchType,
    "amount": buyQuantity,
    "price": buyPrice,
    "coinID": buyChain,
    "currency": selectedBuyOption?.value,
    'search': `&search=payment_method_type&value=${getPeymentMethod}&sell_currency=${selectedBuyOption?.value}`,
    "tab": '1',

  }

  //   var d = await _fetch(`${api_url}/trade/p2p-create-buy-trade`, 'POST', data, {});
  //   if (d?.status === 'success') {
  //     toasted.success(d.message);
  //     //  setOpenQR(false);

  //   } else {
  //     toasted.error(d.message);

  //   }
  // }



  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);


  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);



  const onOpenModalspend = () => setOpenspend(true);
  const onCloseModalspend = () => setOpenspend(false);


  const onOpenModalEDit = () => setOpenEDit(true);
  const onCloseModalEDit = () => setOpen(false);


  const third = (id) => {
    const a = document.getElementById(id);
    a.classList.toggle("show-option");
    // console.log("okay => show dropdown");
  };




  const [open3, setOpen3] = useState(false);
  const onOpenModal3 = () => setOpen3(true);
  const onCloseModal3 = () => setOpen3(false);




  const [openpost, setOpenPost] = useState(false);
  const onOpenPost = () => setOpenPost(true);
  const onClosePost = () => setOpenPost(false);

  ///////////////////////payment-options details/////////////////////////////////////////////////////
  const [allAccountdetail, setallAccountdetail] = useState([])
  async function getAccountDetails() {
    let d = await _fetch(`${api_url}/payments/paymentDetails`, 'GET', {}, {});
    if (d?.status === 'success') {
      setallAccountdetail(d.data.data)
    }
  }

  ////////////////////////////////select multiple method ////////////////
  const [payment_methods, setpayment_methods] = useState();

  const addMethod = (val) => {

    if (payment_methods == undefined) {
      setpayment_methods([val]);
    } else {
      var check = payment_methods.includes(val);
      if (check != true) {
        payment_methods.push(val);
        // console.log(payment_methods)

      } else {
        var removeIndex = payment_methods.indexOf(val);
        //remove  array
        payment_methods.splice(removeIndex, 1); // 

      }
      // setpayment_methods([...payment_methods,val])
    }
    console.log('payment_methods', payment_methods);

  }


  const data = [
    // {
    //   value: 1,
    //   text: <span className="new__a"> BTC</span>,
    //   icon: <img src={img_icons} className="option-img-a"/>
    // },

    // {
    //   value: 2,
    //   text: <span className="new__a"> USDT</span>,
    //   icon: <img src={img_icons} className="option-img-a"/>
    // },
    // {
    //   value: 3,
    //   text: <span className="new__a"> ETH</span>,
    //   icon: <img src={img_icons} className="option-img-a"/>
    // },
    // {
    //   value: 4,
    //   text: <span className="new__a"> XRP</span>,
    //   icon: <img src={img_icons} className="option-img-a"/>
    // },
  ];


  // const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);




  // handle onChange event of the dropdown

  const handleChange2 = (e) => {
    setSelectedOption2(e);
  }

  const handleChange4 = (e) => {
    setSelectedOption4(e);
  }

  // console.log(selectedOption);



  const showContent = (e) => {
    // console.log(e.currentTarget);
    var sibling = e.currentTarget.nextElementSibling;
    var siblingHeight = sibling.scrollHeight;

    let main = e.currentTarget.classList.toggle("show");

    if (main) {
      sibling.setAttribute("style", `height:${siblingHeight}px`);
    } else {
      sibling.removeAttribute("style");
    }
  }
  const [ActiveTab, setActiveTab] = useState(0);



  //////////////////////////////////////sell/////////////////////////////////////////////////////
  const [sellQuantity, setsellQuantity] = useState(0)
  const [sellPrice, setsellPrice] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [sellChainId, setsellChainId] = useState(null);
  const [sellMinLimit, setsellMinLimit] = useState(null);

  const handleChange = (e) => {
    var total_coin = e?.total_coin?.toFixed(2);
    // console.log('sad', total_coin);
    setcoinBalance(total_coin);
    var newPrice = InrPrice * e?.current_price;
    setsellPrice(newPrice)
    setsellChainId(e.coinID)
    setSelectedOption(e);
  }

  const sellSubmit = async (e) => {
    e.preventDefault();
    var data = {
      "amount": sellQuantity,
      "price": sellPrice,
      "coinID": sellChainId,
      "currency": selectedOption.value,
      "payment_method": payment_methods,
      "min_limit": sellMinLimit,
    }

    var d = await _fetch(`${api_url}/trade/p2p-create-sell-trade`, 'POST', data, {});
    if (d?.status === 'success') {
      toasted.success(d.message);
      //  setOpenQR(false);

    } else {
      toasted.error(d.message);

    }
  }


  useEffect(() => {
    document.title = props.title;

    activityLog()
    getAccountDetails();
  }, [payment_methods])

  return (
    <>


      <div className="sub-header-p2p" >
        <div className="a_container__QW" >
          <Tabs selectedIndex={ActiveTab} onSelect={(index) => setActiveTab(index)}>
            <div className="tab-sub-header">
              <TabList>
                <Tab>One-Click Buy</Tab>
                <Tab>P2P</Tab>
                <Tab>Fiat Deposit</Tab>
                {/* <Link to="/Dashboard/add_post" className="btn react-tabs__tab" >ads Post</Link> */}
              </TabList>
            </div>

            <TabPanel>
              <div className="p2p_new ">
                <div className="" >
                  <div className="ad-row">
                    <div className="col-6 first-column--6"></div>
                    <div className="col-6">
                      <div className="card border-0 card-tabs_new">
                        <Tabs>
                          <div className="card-header">
                            <TabList>
                              <Tab>Buy</Tab>
                              <Tab className="react-tabs__tab p2p-sell">Sell</Tab>
                            </TabList>
                          </div>
                          <div className="card-body">
                            <TabPanel>
                              <form onSubmit={''}>
                                <div className="form-group mb-p2p2" id="p2p_show-drop" >
                                  <div className="p-input">
                                    <label htmlFor="spend-buy">PRICE</label>
                                    <div className="in">

                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="10 -- 1000"
                                        id="receive"
                                        onChange={(e) => setbuyPrice(e.target.value)}
                                        value={buyPrice}
                                      />
                                      {/* <button type="button" className="btn" >
                                MAX
                              </button> */}
                                    </div>
                                  </div>


                                  <Select
                                    placeholder="Coins"
                                    value={selectedBuyOption}
                                    className="select-option-s"
                                    options={elpMarket}
                                    onChange={handleBuyChange}
                                    getOptionLabel={(e) => (
                                      <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                        {e.icon}
                                        <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                      </div>
                                    )}
                                  />

                                </div>
                                <div className="form-group" id="sell-drop-2">
                                  <div className="p-input">
                                    <label htmlFor="receive">QUANTITY</label>
                                    <div className="in">
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="10 -- 1000"
                                        id="spend-buy"
                                        onChange={(e) => { setbuyQuantity(e.target.value) }}
                                        value={buyQuantity}
                                      />
                                    </div>
                                  </div>


                                </div>

                                {/* <div className="form-group" id="sell-drop-2">
                          <div className="p-input">
                            <label htmlFor="receive">Receive ≈</label>
                            <div className="in">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="10 -- 1000"
                                id="receive"
                              />
                            </div>
                          </div>
                          <Select
                              placeholder="Coins"
                              value={selectedOption2}
                              className="select-option-s"
                              options={data}
                              onChange={handleChange2}
                              getOptionLabel={(e) => (
                                <div style={{ display: 'flex', alignItems: 'center' ,background: 'transparent' }} className="mian-select-2p2p" >
                                  {e.icon}
                                  <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                </div>
                              )}
                            />

                        </div> */}
                                <p className=" title-p2p mb-p2p2">Total: {buyPrice * buyQuantity} </p>

                                <div className="">
                                  <p className="title-p2p">Payment Methods</p>
                                  <div className="credit-card" onClick={onOpenModal}>
                                    <div className="card-credit">
                                      <img src={creditCard} alt="icon" />
                                      <p>  {BuyMethodView}</p>
                                    </div>
                                    <ArrowDropDownIcon />
                                  </div>
                                </div>

                                <a type="button" onClick={() => { setActiveTab(1); setsearchType(true) }} className="btn trade_btn active">
                                  BUY
                                </a>
                              </form>
                            </TabPanel>
                            <TabPanel>
                              <h4 className="text-secondary">Balance: {coinBalance}</h4>
                              <form className="sell-area" onSubmit={sellSubmit}>
                                <div className="form-group mb-p2p2 " id="sell-spend2" >
                                  <div className="p-input">
                                    <label htmlFor="spend">PRICE</label>
                                    <div className="in">
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="10 -- 1000"
                                        onChange={(e) => setsellPrice(e.target.value)}
                                        value={sellPrice}
                                      />
                                    </div>
                                  </div>
                                  <Select
                                    placeholder="Coins"
                                    value={selectedOption}
                                    className="select-option-s"
                                    options={AvalableelpMarket}
                                    onChange={handleChange}
                                    getOptionLabel={(e) => (
                                      <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                        {e.icon}
                                        <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                      </div>
                                    )}
                                  />
                                </div>

                                {/* <div className="form-group" id="receive-drop-sell">
                          <div className="p-input">
                            <label htmlFor="receive">Receive ≈</label>
                            <div className="in">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="10 -- 1000"
                              />
                               <button type="button" className="btn">
                                MAX
                              </button> 
                            </div>
                          </div>
                          <Select
                              placeholder="Coins"
                              value={selectedOption4}
                              className="select-option-s"
                              options={data}
                              onChange={handleChange4}
                              getOptionLabel={(e) => (
                                <div style={{ display: 'flex', alignItems: 'center' ,background: 'transparent' }} className="mian-select-2p2p" >
                                  {e.icon}
                                  <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                </div>
                              )}
                            />
                        </div> */}

                                <div className="form-group mb-p2p2 " id="receive-drop-sell">
                                  <div className="p-input">
                                    <label htmlFor="receive">QUANTITY</label>
                                    <div className="in">
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="10 -- 1000"
                                        id="spend"
                                        onChange={(e) => { setsellQuantity(e.target.value) }}
                                        value={sellQuantity}
                                      />
                                      <button type="button" className="btn" onClick={() => setsellQuantity(coinBalance)}>
                                        MAX
                                      </button>

                                      {/* <button type="button" className="btn">
                                MAX
                              </button> */}
                                    </div>
                                  </div>

                                </div>

                                <div className="form-group" id="receive-drop-sell">
                                  <div className="p-input">
                                    <label htmlFor="receive">MINIMUM LIMIT</label>
                                    <div className="in">
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="10 -- 1000"
                                        onChange={(e) => setsellMinLimit(e.target.value)}
                                        value={sellMinLimit}
                                      />
                                      {/* <button type="button" className="btn">
                                  MAX
                                </button> */}
                                    </div>
                                  </div>

                                </div>


                                <p className=" title-p2p mb-p2p2">Total: {sellPrice * sellQuantity} </p>

                                <div className="">
                                  <p className="title-p2p">Payment Methods</p>
                                  <div className="credit-card" onClick={() => { onOpenModal3(true) }}>
                                    <div className="card-credit">
                                      <img src={wallet} alt="icon" />
                                      <p> Payment Options</p>
                                    </div>
                                    <ArrowDropDownIcon />
                                  </div>
                                </div>
                                <p className="fonts title-p2p" >Currently the fiat assets in the funding account do not support withdrawal.</p>

                                <div className="form-group-textarea">
                                  <label for="comment">Remark</label>
                                  <textarea className="form-control" rows="6" id="comment"></textarea>
                                </div>

                                <button type="submit" className="btn trade_btn active"
                                // onClick={onOpenPost}
                                >
                                  Sell USDT  </button>
                              </form>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </TabPanel>

            <TabPanel>
              <MarketP2P props={serchBuyData} />
            </TabPanel>

            <TabPanel>
              <div className="tab3-p2p">
                <div className="card fiat-card">
                  <h3> Fiat Deposit</h3>
                  <div className="card-body">
                    <form>
                      <div className="d-flex first-2p2-a">
                        <div className="form-group">
                          <label htmlFor="Amount">Amount</label>
                          <input type="number" className="form-control" placeholder="40 -- 20000" id="Amount" />
                        </div>

                        <div className="form-group form-group-2">
                          <label htmlFor="Amount">Amount</label>
                          <Select
                            placeholder="Coins"
                            value={selectedOption}
                            className="select-option-s"
                            options={data}
                            onChange={handleChange}
                            getOptionLabel={(e) => (
                              <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                {e.icon}
                                <span style={{ marginLeft: '5px' }}>{e.text}</span>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="payment-s">
                        <p className="title-p2p">Payment Methods</p>
                        <div className="credit-card" onClick={onOpenModal}>
                          <div className="card-credit">
                            <img src={creditCard} alt="icon" />
                            <p> Payment Option </p>
                          </div>
                          <ArrowDropDownIcon />
                        </div>
                      </div>

                      <button type="submit" className="btn trade_btn">Continue</button>

                    </form>
                  </div>
                </div>
              </div>
            </TabPanel>



          </Tabs>
        </div>
      </div>

      <Modal open={openspend} onClose={onCloseModalspend}
        classNames={{
          overlay: 'P2P-coinsOverlay',
          modal: 'P2P-coins',
        }} center  >
        <div className="modal-p2p2">
          <h3>Select Coins</h3>
          <div className="all-options">
            <ul>
              {/* <p className="p2p-modal-title">Recommended</p> */}
              <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li>

              <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li>
              <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li> <li className="">
                <div className="card-credit">
                  <img src={img_icons} className="modal-p2p-img" alt="icon" />
                  <p> USDT</p>
                </div>
                <p className="coin-p2p-full-name" >Tether <span>USDT</span></p>
              </li>
            </ul>
          </div>
        </div>

      </Modal>




      <Modal open={open} onClose={onCloseModal}
        classNames={{
          overlay: 'P2P-PayOverlay',
          modal: 'P2P-Pay',
        }} center  >
        <div className="modal-p2p2">
          <h3>Select Payment Method</h3>
          <div className="all-options">
            <ul>
              <p className="p2p-modal-title">Recommended</p>
              {/* <li className="p2p-mb-5">
                <div className="card-credit">
                  <img src={creditCard} alt="icon" />
                  <p> Credit card</p>
                </div>
                <div className="rateing-bill">
                  <span>87.89</span><br />
                  <span> ≈ 1 USDT </span><br />
                  <span>From P2P</span>
                </div>

              </li> */}

              <p className="p2p-modal-title">P2PMatch with sellers in just one click</p>
              <li className="" onClick={() => { setgetPeymentMethod('gpay'); setOpen(false); setBuyMethodView('Google Pay') }} >
                <div className="card-credit" >
                  <img src={p2pIS} alt="icon" />
                  <p> Google Pay</p>
                </div>
                {/* <div className="rateing-bill">
                  <span>87.89</span><br />
                  <span> ≈ 1 USDT </span><br />
                  <span>From P2P</span>
                </div> */}

              </li>
              <li className="" onClick={() => { setgetPeymentMethod('phonepe'); setOpen(false);; setBuyMethodView('Phone Pay') }}>
                <div className="card-credit">
                  <img src={p2pIS} alt="icon" />
                  <p> Phone Pay</p>
                </div>
                {/* <div className="rateing-bill">
                  <span>87.89</span><br />
                  <span> ≈ 1 USDT </span><br />
                  <span>From P2P</span>
                </div> */}

              </li>
              <div className="" id="li-header" >

                <div className="li-header " onClick={() => third('li-header')} >
                  <p className="hide-aaaa" > More third-party service providers </p>
                  <p className="p2p-modal-title" >Third Party</p>
                  <ArrowDropDownIcon />
                </div>
                <div className="third-party-option">
                  <li className="" onClick={() => { setgetPeymentMethod('bank'); setOpen(false); setBuyMethodView('Bank') }}>
                    <div className="card-credit">
                      <img src={wallet} alt="icon" />
                      <p> Bank Tranfer</p>
                    </div>
                    {/* <div className="rateing-bill">
                      <span>87.89</span><br />
                      <span> ≈ 1 USDT </span><br />
                      <span>From P2P</span>
                    </div> */}

                  </li>

                  <li className="" onClick={() => { setgetPeymentMethod('upi'); setOpen(false); setBuyMethodView('UPI') }}>
                    <div className="card-credit">
                      <img src={UPI} alt="icon" />
                      <p> UPI</p>
                    </div>
                    {/* <div className="rateing-bill">
                      <span>87.89</span><br />
                      <span> ≈ 1 USDT </span><br />
                      <span>From P2P</span>
                    </div> */}

                  </li>
                </div>
              </div>

            </ul>
          </div>
        </div>

      </Modal>


      <Modal open={open2} onClose={onCloseModal2}
        classNames={{
          overlay: 'P2P-PayOverlay',
          modal: 'P2P-Pay',
        }} center  >
        <div className="modal-p2p2">
          <h3>Select Payment Method</h3>
          <div className="all-options">
            <ul>
              <p className="p2p-modal-title">Recommended</p>
              <li className="">
                <div className="card-credit">
                  <img src={creditCard} alt="icon" />
                  <p> Credit card</p>
                </div>
                <div className="rateing-bill">
                  <span>87.89</span><br />
                  <span> ≈ 1 USDT </span><br />
                  <span>From P2P</span>
                </div>

              </li>
              <p className="fonts">Currently the fiat assets in the funding account do not support withdrawal.</p>
            </ul>
          </div>
        </div>

      </Modal>


      {/* this left side modal code  */}


      <Modal open={open3} onClose={onCloseModal3}
        classNames={{
          overlay: 'P2P-PayOverlay',
          modal: 'P2P-ID__data',
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        animationDuration={800}
      >
        <div className="modal_left__s">
          <h3>Select UPI ID</h3>
          {allAccountdetail?.map((method_val) => {
            return (
              <div className="Payment__UPI">
                <div className="account_detail___UPI_d">
                  <div className="account_detail" onClick={(e) => { showContent(e) }} style={{ flexDirection: 'row' }}>
                    <div className="detail_inner">
                      <span> <KeyboardArrowDownIcon /> </span>
                      <p className="log_heading"> {method_val.type?.toUpperCase()}</p>
                    </div>
                    <div className="detail_inner cta_wrapper">
                      <div class="checkbox-wrapper-19">
                        <input type="checkbox" id={method_val?._id} checked={payment_methods?.includes(method_val?._id)} onClick={() => { addMethod(method_val?._id); getAccountDetails() }} />
                        <label htmlFor={method_val?._id} className="check-box"></label>
                      </div>
                    </div>
                  </div>
                  <div className="account_content">
                    <div className="account_content_inner">
                      <div className="imps_payments">
                        <h4 className="varified_heading">Your {method_val.type?.toUpperCase()} Details</h4>

                        {method_val.type === "bank" && <div className="imps_details">
                          <div className="elp_details">
                            <p className="elp_info">BANK HOLDER NAME</p>
                            <p className="elp_info_2"> {method_val.account_holder_name}</p>
                          </div>
                          <div className="elp_details">
                            <p className="elp_info">BANK ACCOUNT NUMBER</p>
                            <p className="elp_info_2"> {method_val.account_number}</p>
                          </div>
                          <div className="elp_details">
                            <p className="elp_info">BANK TYPE</p>
                            <p className="elp_info_2"> {method_val.account_type}</p>
                          </div>
                        </div>}
                        {method_val.type !== "bank" && <div className="imps_details">
                          <div className="elp_details">
                            <p className="elp_info"> NAME</p>
                            <p className="elp_info_2"> {method_val.label}</p>
                          </div>
                          <div className="elp_details">
                            <p className="elp_info">UPI/VPA ID</p>
                            <p className="elp_info_2"> {method_val.upi_id}</p>
                          </div>

                        </div>}
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            );
          })}


          <div className="modal__UPI_footer">
            <Link to="/Dashboard/account-settings/payment-options" className="btn"> <AddIcon /> Adds</Link>
            <button type="button" className="btn"> <CachedIcon /> Refresh</button>
          </div>
        </div>
      </Modal>



      <Modal open={openpost} onClose={onClosePost}
        classNames={{
          overlay: 'P2P-post',
          modal: 'P2P-ID__post',
        }}
        center
      >
        <div className="post__data">
          <h3> Confirm to post</h3>
          <div className="post___data">
            <div className="post_alert">
              After publishing the SELL Post, The trading assets will be Frozen
            </div>
            <div className="POst__data__list">
              <p> Type </p>
              <p> SELL</p>
            </div>
            <div className="POst__data__list">
              <p> Type </p>
              <p> SELL</p>
            </div>
            <div className="POst__data__list">
              <p> Type </p>
              <p> SELL</p>
            </div>
            <div className="POst__data__list">
              <p> Type </p>
              <p> SELL</p>
            </div>
          </div>
          <div className="btn__post__footer">
            <button className="btn" type="button"> Cancel</button>
            <button className="btn trade_btn active" type="button"> Confirm to post </button>
          </div>
        </div>


      </Modal>

    </>
  );
};

export default P2PNew;
