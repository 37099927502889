// import cogoToast from 'cogo-toast';
import toast from 'react-hot-toast';
const toasted = {
        success: async function (message: any) {
                toast.success(message);
        },
        error: async function (message:any) {
                toast.error(message)

        }

      
              
}
// module.exports = { success }
// export default success;
export default toasted;
