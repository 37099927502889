import React, { useEffect } from 'react'
// import banner from '../Assets/Images/bannerRight.png'
import gift from '../Assets/Images/gift.png'
import gift_box from '../Assets/Images/gift-box.svg'
import new_slogan from '../Assets/Images/new-slogan.svg'
// import Marquee from "react-fast-marquee";
import Marquee from 'react-marquee-master';
import RewardSlider from './RewardSlider'
import { title } from '../config/config'
import { Link } from 'react-router-dom';

const Rewards = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
    return (
        <>
            <div className="b_container pt__spacing rewards___INNER__A">

                <section className='reward_A__banner'>
                    <div className='reward_A____container'>
                        <div className='card__banner__left'>
                        <h1>Complete tasks to earn up to <span> 5,005 </span>USDT</h1>                            <h3>Connect your crypto, DeFi, and NFTs like never before. Unlock Web3 today with the BGB Token from {title}.</h3>
                            <Link className='btn' to="/login">Log In</Link>
                            <Link className='btn' to="/">Share</Link>
                        </div>
                        {/* <div className='card__banner__right'>
                                <img src={BAnner__img} alt="icon" />

                            </div> */}

                    </div>
                </section>

                <div className="task_section">

                    <div className="card_main">
                        <div className='reward_A____container'>
                            <h1>New User Tasks</h1>
                            <div className="row">
                                <div className="col-4">
                                    <div className="cards">
                                        <div className="img">
                                            <img src={gift} alt="" />
                                        </div>
                                        <div className="info">
                                            <h3>Sign up</h3>
                                            <p>Sign up to begin your journey with Bitget</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <button className='btn1' type='submit'>Sign up</button> */}
                                <div className="col-4">
                                    <div className="cards">
                                        <div className="img">
                                            <h1>+5,000</h1>
                                            <p>USDT</p>
                                        </div>
                                        <div className="info">
                                            <h3>Deposit</h3>
                                            <p>Earn rewards worth up to 5,000 USDT</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <button className='btn1' type='submit'>Login</button> */}

                                <div className="col-4">
                                    <div className="cards">
                                        <div className="img">
                                            <h1>+5</h1>
                                            <p>USDT</p>
                                        </div>
                                        <div className="info">
                                            <h3>Trade</h3>
                                            <p>Earn rewards worth up to 5 USDT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="luckey_draw">
                    <div className='reward_A____container'>
                        <div className="row">
                            <div className="col-8">
                                <div className="main_section">
                                    <h2>Daily Lucky Draw of 888 USDT</h2>
                                    <p>Complete new user tasks to unlock advanced tasks</p>
                                    <div className="inner">
                                        <img src={gift_box} alt="" />
                                    <Marquee marqueeItems={['The user 553****761 has a futures trading volume of 2,634,791.237054 USDT, and and has claimed 888','USDT'] } direction={'up'} delay={100} inverseMarqueeItems={false} height={25} />
                                    </div>

                                </div>

                            </div>
                            <div className="col-3">
                                <div className="img">
                                    <img src={new_slogan} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom_section ">
                    <div className='reward_A____container'>
                        <div className="slider_section">
                            <h1>User Guide</h1>

                            <div className="swiper">
                                <RewardSlider/>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </>
    )
}

export default Rewards
