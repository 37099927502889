import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _fetch from "../config/api";
import toasted from "../config/toast";
// @import "../Assets/Scss/bootstrap.min.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { api_url } from "../config/config";
// import { useInterval } from 'usehooks-ts'
const activeDiposit = (id: any) => {
  //  var sa = document.getElementById(id).classList.add("mystyle");
  const a: HTMLElement | any = document.getElementById(id);
  a.classList.toggle("show_tr");
  // console.log('okay')
}



const DepositAddress = (props: any) => {
  let navigate = useNavigate();

  // console.log(props)
  const [count, setCount] = useState(0)
  const [delay, setDelay] = useState(false);
  const [countHit, setCountHit] = useState(0)

  // const [isPlaying, setPlaying] = useState(false)

  const auth = localStorage.getItem("auth");
  var storedNames = JSON.parse(localStorage.getItem("user") || "");
  const [depositData, setDepositData] = useState({ trc20: '', bep20: '', erc20: '',gtc20:'' })
  const [chainData, setChainData] = useState({ coin: props?.coin_name })
  const [chainName, setChainName] = useState(Boolean(props.bep20) === true ? 'bep20' : Boolean(props.trc20) === true ? 'trc20' : Boolean(props.erc20) === true ? 'erc20' : '')

  // console.log('storedNames', storedNames.username)

  const copyToClipboard = (address: any, name: any) => {
    var textField = document.createElement('textarea')
    textField.innerText = address;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toasted.success(name + ' Address copied successfully!');

  }
  useEffect(() => {
    if (auth == "true") {
      if (depositData.trc20) {

      } else {
        (async () => {
          const data = await _fetch(`${api_url}/wallet/wallets/`, "GET", "", {
            // Authorization: "Bearer" + LoginToken,
          });
          setDepositData({ trc20: data.trc20, bep20: data.bep20, erc20: data.erc20 , gtc20: data.gtc20})


        })();
      }


      if (countHit < 26) {
        if (chainName == 'bep20' || chainName == 'trc20' || chainName == 'erc20' || chainName == 'gtc20') {
          const timer = setInterval(() => {
            // setCount(count + 1);
            // console.log('hi2');
            (async () => {
              var res = await _fetch(`${api_url}/deposit/checkTransaction`, 'POST', { chain: chainName, coin_symbol: chainData.coin }, {});
              if (res[0]?.status == "success") {
                setDelay(true)
                toasted.success(res[0].messsage);
                clearTimeout(timer);


                // location.reload();
                // if (chainName == 'bep20') {
                //   (async () => {
                //     var res = await _fetch(`http://157.245.55.76/elp_txn/api/v1/txn/transferBEP20?username=${storedNames.username}`, 'GET', {}, {});
                //     if (res[0]?.status == "success") {
                //       setDelay(true)
                //       toasted.success(res[0].messsage);
                //       // location.reload();

                //     }
                //   })()
                // } else {
                //   (async () => {
                //     var res = await _fetch(`http://157.245.55.76/elp_txn/api/v1/txn/transferTron?username=${storedNames.username}`, 'GET', {}, {});
                //     if (res[0]?.status == "success") {
                //       setDelay(true)
                //       toasted.success(res[0].messsage);

                //       // location.reload();

                //     }
                //   })()

                // }


              }
            })()
            var calHit = parseFloat(`${countHit + 1}`);
            setCountHit(calHit)
            // console.log('countHitcountHit', countHit);

          }, 10000);


          return () => clearInterval(timer);
          // console.log(delay)
          // // if (delay === true) {
          //   clearInterval(timer);
          // }



        }
      }

    }
  }, [countHit])
  // console.log('countHit',countHit);

  return (
    <>
      {/* <div className="container__td">
        <div className="dropdwon-active deposit-active">
          <a href="#" className="link_a"> ELP </a>
        </div>
        <div className="alert-td">
          <h6>Please ensure to select ERC Network at sender's wallet</h6>
        </div>
        <div className="text-center">
          <div className="col-lg-6">
            <div className="qr__scan">
              <img src={require("../Assets/Icons/qr-code-scan.svg").default} alt="qr-code-scan-Icon" width="70px" />
              <div className="btn-share">
                <a href="#" className="btn share-btn" target="_black"> Share</a>
              </div>
            </div>
            <div className="wallet-address">
              <h6 className="addres-a"> hjkfsdgjksldgjklsgjkhsjkdlfgjlskdhsdfjkgjsdfhkfdgfgh </h6>
              <a className="copy-btn" title="copy" >   <img src={require("../Assets/Icons/copy.svg").default} alt="Copy-Icon" width="25px" /> </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="dis_contin">
              <div className="dis-headear">
                <h6 className="h6-a">Confirmations </h6>
                <h6 className="h6-a">30 networks Confirmations</h6>
              </div>
              <div className="dis-body">
                <h4 className="title"> <img src={require("../Assets/Icons/info-red.svg").default} alt="Copy-Icon" width="20px" /> Disclaimer   </h4>
                <p>  <span> Send only using the ELP network. </span>
                  Using any others networks will result in loss of funds.  </p>
                <p>  <span> Deposit only ELP to this deposit address. </span>
                  Depositing any asset will result in a loss of funds.   </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {(Boolean(props.bep20) === false && Boolean(props.erc20) === false && Boolean(props.trc20) === false && Boolean(props.gtc20) === false) ? <div className="info_bar"><h6>{props?.coin_name?.toUpperCase()} deposits have been currently not open.</h6></div> :

        <div className="container__td">
          <Tabs>
            <TabList>
              {Boolean(props.bep20) === true ?
                <>
                  <Tab onClick={() => { setCountHit(1); setChainName('bep20') }}>
                    <div className="dropdwon-active deposit-active">
                      BEP20
                    </div>
                  </Tab>
                  {/* &nbsp; | &nbsp; */}
                </>
                : ''}
              {Boolean(props.erc20) === true ?
                <>
                  <Tab onClick={() => { setCountHit(1); setChainName('erc20') }}>
                    <div className="dropdwon-active deposit-active">
                      ERC20
                    </div>
                  </Tab>
                  {/* &nbsp; | &nbsp; */}
                </>
                : null}
              {Boolean(props.trc20) === true ?
                <>
                  <Tab onClick={() => { setCountHit(1); setChainName('trc20') }}>
                    <div className="dropdwon-active deposit-active">
                      TRC20
                    </div>
                  </Tab>
                </>
                : null}
              {Boolean(props.gtc20) === true ?
                <>
                  <Tab onClick={() => { setCountHit(1); setChainName('gtc20') }}>
                    <div className="dropdwon-active deposit-active">
                      GTC20
                    </div>
                  </Tab>
                </>
                : null}
              {/* </TabList> */}

            </TabList>

            {Boolean(props.bep20) === true ?
              <TabPanel>
                <div className="alert-td">
                  <h6>Please ensure to select BEP20 Network at sender's wallet</h6>
                </div>
                <div className="text-center">
                  <div className="col-lg-6">
                    <div className="qr__scan" style={{ textAlign: 'center', margin: '20px 0' }}>
                      <img src={'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=' + depositData.bep20} alt="qr-code-scan-Icon" width="120px" style={{ borderRadius: '10px' }} />
                      {/* <div className="btn-share">
                    <a href="#" className="btn share-btn" target="_black"> Share</a>
                  </div> */}
                    </div>
                    <div className="deposit__inner_container">
                      <div className="wallet-address">
                        <h6 className="addres-a"> {depositData.bep20} </h6>
                        <a className="copy-btn" title="copy" onClick={(e: any) => { copyToClipboard(depositData.bep20, 'BEP20') }}>  <ContentCopyIcon />  </a>
                      </div>
                      <div className="col-lg-6">

                        <div className="dis_contin">
                          <div className="dis-headear">
                            <h6 className="h6-a">Confirmations </h6>
                            <h6 className="h6-a">30 networks Confirmations</h6>
                          </div>
                          <div className="dis-body">
                            <h4 className="title"> <img src={require("../Assets/Icons/info-red.svg").default} alt="Copy-Icon" width="20px" /> Disclaimer   </h4>
                            <p> Send only using the BEP20 network.
                              Using any others networks will result in loss of funds.  </p>
                            <p> Deposit only BEP20 to this deposit address.
                              Depositing any asset will result in a loss of funds.   </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              : null}

            {Boolean(props.erc20) === true ?
              <TabPanel>
                <div className="alert-td">
                  <h6>Please ensure to select ERC20 Network at sender's wallet</h6>
                </div>
                <div className="text-center">
                  <div className="col-lg-6">
                    <div className="qr__scan" style={{ textAlign: 'center', margin: '20px 0' }}>
                      <img src={'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=' + depositData.erc20} alt="qr-code-scan-Icon" width="120px" style={{ borderRadius: '10px' }} />
                      {/* <div className="btn-share">
                    <a href="#" className="btn share-btn" target="_black"> Share</a>
                  </div> */}
                    </div>
                    <div className="deposit__inner_container">
                      <div className="wallet-address">
                        <h6 className="addres-a"> {depositData.erc20} </h6>
                        <a className="copy-btn" title="copy" onClick={(e: any) => { copyToClipboard(depositData.erc20, 'ERC20') }} > <ContentCopyIcon />  </a>
                      </div>

                      <div className="col-lg-6">

                        <div className="dis_contin">
                          <div className="dis-headear">
                            <h6 className="h6-a">Confirmations </h6>
                            <h6 className="h6-a">30 networks Confirmations</h6>
                          </div>
                          <div className="dis-body">
                            <h4 className="title"> <img src={require("../Assets/Icons/info-red.svg").default} alt="Copy-Icon" width="20px" /> Disclaimer   </h4>
                            <p> Send only using the ERC20 network.
                              Using any others networks will result in loss of funds.  </p>
                            <p> Deposit only ERC20 to this deposit address.
                              Depositing any asset will result in a loss of funds.   </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              : null}

            {Boolean(props.trc20) === true ?
              <TabPanel>

                <div className="alert-td">
                  <h6>Please ensure to select TRC20 Network at sender's wallet</h6>
                </div>
                <div className="text-center">
                  <div className="col-lg-6">
                    <div className="qr__scan" style={{ textAlign: 'center', margin: '20px 0' }}>
                      <img src={'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=' + depositData.trc20} alt="qr-code-scan-Icon" width="120px" style={{ borderRadius: '10px' }} />
                      {/* <div className="btn-share">
                    <a href="#" className="btn share-btn" target="_black"> Share</a>
                  </div> */}
                    </div>
                    <div className="deposit__inner_container">
                      <div className="wallet-address">
                        <h6 className="addres-a"> {depositData.trc20} </h6>
                        <a className="copy-btn" title="copy" onClick={(e: any) => { copyToClipboard(depositData.trc20, 'TRC20') }} >  <ContentCopyIcon />  </a>
                      </div>

                      <div className="col-lg-6">

                        <div className="dis_contin">
                          <div className="dis-headear">
                            <h6 className="h6-a">Confirmations </h6>
                            <h6 className="h6-a">30 networks Confirmations</h6>
                          </div>
                          <div className="dis-body">
                            <h4 className="title"> <img src={require("../Assets/Icons/info-red.svg").default} alt="info_icon" width="20px" /> Disclaimer   </h4>
                            <p>  Send only using the TRC20 network.
                              Using any others networks will result in loss of funds.  </p>
                            <p>  Deposit only TRC20 to this deposit address.
                              Depositing any asset will result in a loss of funds.   </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              : null}

            {Boolean(props.gtc20) === true ?
              <TabPanel>

                <div className="alert-td">
                  <h6>Please ensure to select GTC20 Network at sender's wallet</h6>
                </div>
                <div className="text-center">
                  <div className="col-lg-6">
                    <div className="qr__scan" style={{ textAlign: 'center', margin: '20px 0' }}>
                      <img src={'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=' + depositData.gtc20} alt="qr-code-scan-Icon" width="120px" style={{ borderRadius: '10px' }} />
                      {/* <div className="btn-share">
                    <a href="#" className="btn share-btn" target="_black"> Share</a>
                  </div> */}
                    </div>
                    <div className="deposit__inner_container">
                      <div className="wallet-address">
                        <h6 className="addres-a"> {depositData.gtc20} </h6>
                        <a className="copy-btn" title="copy" onClick={(e: any) => { copyToClipboard(depositData.gtc20, 'TRC20') }} >  <ContentCopyIcon />  </a>
                      </div>

                      <div className="col-lg-6">

                        <div className="dis_contin">
                          <div className="dis-headear">
                            <h6 className="h6-a">Confirmations </h6>
                            <h6 className="h6-a">30 networks Confirmations</h6>
                          </div>
                          <div className="dis-body">
                            <h4 className="title"> <img src={require("../Assets/Icons/info-red.svg").default} alt="info_icon" width="20px" /> Disclaimer   </h4>
                            <p>  Send only using the GTC20 network.
                              Using any others networks will result in loss of funds.  </p>
                            <p>  Deposit only GTC20 to this deposit address.
                              Depositing any asset will result in a loss of funds.   </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              : null}

          </Tabs>
        </div>
      }
    </>
  );
};
export default DepositAddress;