import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import TradeData from "../config/tradeData.json";
import { title, logo, markit_url, api_url } from "../config/config";
import ReactPaginate from 'react-paginate';
import { _fetch } from "../config/api";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { AiOutlineRise, AiOutlineFall } from "react-icons/ai";
import DepositAddress from "./DepositAddress";
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import LoaderCom from "./loader/LoaderCom";
import Modal from "react-responsive-modal";
import TransferModalCom from "./TransferModalCom";

const activeDiposit = (id: any) => {
   const a: HTMLElement | any = document.getElementById(id);
   a.classList.toggle("show_tr");
}

const MarketTrade = () => {


   const [records, setrecords] = useState(0);
   const [pageCount, setPageCount] = useState(0);
   const [itemOffset, setItemOffset] = useState(1);
   const [short, setshort] = useState('market_cap_rank');
   const [emptyData, setemptyData] = useState([]);

   const [tradeData, setdata] = useState([]);
   const [depositDrop, setdepositDrop] = useState('');

   const [dropDownData, setDropdownData] = useState<any[]>(TradeData.data[0].data);
   const [MarketmainTab, SetMarketmainTab] = useState(0);
   const [tokenTab, settokenTab] = useState("USD");
   const [tabiner, settabiner] = useState("Sikkaacoins");
   const [checkboxBtn, SetcheckboxBtn] = useState(true);
   const checkbobtogle = () => {
      SetcheckboxBtn(!checkboxBtn);
   };

   const [buybtntrade, setbuybtntrade] = useState(false);
   var Btntoggle = () => {
      setbuybtntrade(!buybtntrade);
   };

   const activeDiposit = (id: any) => {
      const a: HTMLElement | any = document.getElementById(id);
      a.classList.toggle("show_tr");

   }



   useEffect(() => {



      setPageCount(Math.ceil(records / 10));
      activityLog()
   }, [itemOffset, records, short]);

   const auth = localStorage.getItem("auth");

   const [elpMarket, setElpMarket] = useState([]);
   const [searchedVal, setSearchedVal] = useState("");
   async function activityLog() {
      let d = await _fetch(`${api_url}/markets/getAllElpMarketbyType?type=trade_account`, 'GET', {}, {});
      if (d?.status === 'success') {
         setElpMarket(d?.market);
         setemptyData(d);

      }
   }
   const [switchHideBal, setSwitchHideBal] = useState(0)
   const zeroBalance = (e: any) => {
      if (switchHideBal === 0) {
         setSwitchHideBal(1)
      } else {
         setSwitchHideBal(0)
      }


   }

   console.log(elpMarket);

   // Dropdown js Code
   const [symbolT, setSymbolT] = useState('');
   const [openT, setOpenT] = useState(false);
   const transferModal = (symbol: any) => {
      setOpenT(true)
      setSymbolT(symbol)
   }
   const onCloseModalT = () => setOpenT(false);
   const onOpenModalT = () => setOpenT(true);


   return (
      <>
         <section className="sec_market_trade">
            <div className="container-max">
               <div className="sec_content">

                  {/* Main Tabs */}
                  <div className="main_tabs_content">
                     <div className="token_coin_content">
                        {/* Main Market Tabs */}
                        <div className="dropdown_trabs main_market_tabs">
                           <div className="main_market_tabs_content ">
                              <div className="market_tabs_btn_inner">
                                 <button className={`trade_btn market_btn t-normal-color ${tabiner === "Sikkaacoins" && "active"}`} onClick={() => { settabiner("Sikkaacoins"); }} >
                                    {title} coins
                                 </button>
                                 <button className={`trade_btn market_btn t-normal-color ${tabiner === "MainMarket" && "active"}`} onClick={() => { settabiner("MainMarket"); }} >
                                    Main Market
                                 </button>
                              </div>
                              {/* <div className="search_input">
                                 <form action="">
                                    <label htmlFor="searchcoin">
                                       <SearchIcon />
                                    </label>
                                    <input id="searchcoin" type="text" placeholder="Search" />
                                 </form>
                                 <button className="info_btn">
                                    <InfoIcon />
                                 </button>
                              </div> */}
                           </div>
                        </div>

                        <div className="main_market_tabs">
                           <div className="card-body-container ">
                              <div className="table____main">
                                 <div className="table-section-1">
                                    <div className="table-header">
                                       <div className="left-t-geader">
                                          <form>
                                             <div className="input-group mb-3">
                                                <div className="input-group-prepend"> 🔍 </div>
                                                <input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearchedVal(e.target.value)} />
                                             </div>
                                             <div className="toggle" >
                                                <label className="switch">
                                                   <input type="checkbox" value={switchHideBal} onClick={zeroBalance} />
                                                   <span className="slider round"></span>
                                                   <span className="user-none">Hide Zero Balances</span>
                                                </label>
                                             </div>
                                          </form>
                                       </div>
                                    </div>
                                    <div className="table-body">
                                       <div className="table-responsive">
                                          <table className="table balance-table">
                                             <thead>
                                                <tr>
                                                   <th>COIN</th>
                                                   <th>LAST PRICE</th>
                                                   <th>BALANCE</th>
                                                   <th>24H CHANGE</th>
                                                   <th rowSpan={444}>MARKETS</th>
                                                   <th>TRADE</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {/* <tr>
                                                   <td>
                                                      <div className="img__td">
                                                         <img src={logo} alt="ELP-Icon" width="30px" />
                                                      </div>
                                                      <div className="Text__td">
                                                         <p>ELP</p>
                                                         <small>ELP</small>
                                                      </div>
                                                   </td>
                                                   <td>
                                                      <p> 0 ELP</p>
                                                      <small> <img src={require("../Assets/Icons/lock.svg").default} alt="lock-Icon" width="15px" />  0 ELP</small>
                                                   </td>
                                                   <td>
                                                      <p>0 ELP</p>
                                                   </td>
                                                   <td>
                                                      <p>0 ELP</p>
                                                   </td>
                                                   <td>
                                                      <p> - </p>
                                                   </td>
                                                   <td style={{ textAlign: 'center' }}>
                                                      <div className="cta_wrapper"><button className="trade_btn" onClick={() => { depositDrop != "ELP" ? setdepositDrop("ELP") : setdepositDrop("") }} > Deposit</button>
                                                      </div>
                                                   </td>
                                                </tr> */}
                                                {/* deposite dropdwon  */}
                                                {depositDrop == "ELP" &&
                                                   <tr className="active-td show_tr" id={"ELP"}>
                                                      <td colSpan={8} >

                                                         <DepositAddress />
                                                      </td>
                                                   </tr>}
                                                {/* Withdraw dropdwon  */}
                                                {/* <tr className="active-td" id="activeWithdraw__tr">
                                                   <td colSpan={5} className="withdraw_a" >
                                                      <div className="container__td">
                                                         <div className="text-center">
                                                            <form action="">
                                                               <div className="form-group">
                                                                  <label htmlFor="adress">DESTINATION ADDRESS</label>
                                                                  <div className="Input__with">
                                                                     <input type="email" className="form-control" placeholder="Paste hare or Scan QR Code" id="adress" />
                                                                     <div className="qr__as">
                                                                        <img src={require("../Assets/Icons/qr-code-scan.svg").default} alt="qr-code-scan-Icon" width="30px" />
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div className="form-group">
                                                                  <label htmlFor="network">NETWORK</label>
                                                                  <div className="Input__with">
                                                                     <input type="text" className="form-control" style={{ width: '100%' }} placeholder="" id="network" />
                                                                  </div>
                                                               </div>

                                                               <div className="form-group">
                                                                  <label htmlFor="network">{title} AMOUNT <span>0 {title} </span> </label>
                                                                  <div className="Input__with">
                                                                     <input type="number" className="form-control" placeholder={`Minimum: 56.2 ` + {title} } id="network" />
                                                                     <div className="btn___S">
                                                                        <button type="submit" className="btn"> WITHDRAW ALL</button>
                                                                     </div>
                                                                  </div>
                                                               </div>

                                                               <div className="form-group">
                                                                  <label htmlFor="network">REMOARK</label>
                                                                  <div className="Input__with">
                                                                     <input type="text" className="form-control" style={{ width: '100%' }} placeholder="" id="network" />
                                                                  </div>
                                                               </div>

                                                               <div className="form-group">
                                                                  <label htmlFor="network">YOU RECEIVE <span>FREE: 28.1 {title} </span> </label>
                                                                  <div className="Input__with">
                                                                     <input type="number" className="form-control" style={{ width: '100%' }} placeholder="0.0" id="network" />
                                                                  </div>
                                                               </div>
                                                               <div className="form-group form-check">
                                                                  <label className="form-check-label" style={{ justifyContent: 'start' }} >
                                                                     <input className="form-check-input" type="checkbox" />
                                                                     <span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio ducimus eligendi quidem maxime odio at numquam aperiam. Unde, voluptates. Corrupti ipsa dolorum laudantium possimus optio qui eligendi, debitis totam ab.</span>
                                                                  </label>
                                                               </div>

                                                               <button type="submit" className="btn submit-btn">PROCEED</button>
                                                            </form>
                                                         </div>
                                                      </div>
                                                   </td>
                                                </tr> */}
                                                <LoaderCom condition={emptyData} text={'Getting latest values...'} />

                                                {elpMarket?.filter((val: any, index: any) =>
                                                   !searchedVal.length || val?.symbol
                                                      .toString()
                                                      .toLowerCase()
                                                      .includes(searchedVal.toString().toLowerCase())
                                                ).map((val: any, index: any) =>
                                                   <>
                                                      {(val?.total_coin >= 0 && switchHideBal === 0) ?
                                                         <tr>
                                                            <td>
                                                               <div className="img__td">
                                                                  <img src={val.image} alt="Icon" width="30px" />
                                                               </div>
                                                               <div className="Text__td">
                                                                  <p>{val.symbol.toUpperCase()}</p>
                                                                  <small>{val.name}</small>
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <p>{'$' + val.current_price}</p>
                                                               {/* <small> <img src={require("../Assets/Icons/lock.svg").default} alt="lock-Icon" width="15px" />  0 ELP</small> */}
                                                            </td>
                                                            <td><p>{val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p> <small>${val.usdt_income.toFixed(3)}</small></td>

                                                            <td>
                                                               <Link to="/" className="f-normal-color" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }} >
                                                                  {val.price_change_percentage_24h?.toFixed(1)}% {val.price_change_percentage_24h < 0 ? <AiOutlineFall /> : <AiOutlineRise />}
                                                               </Link>
                                                               {/* <p>{val.symbol.toUpperCase()}</p> */}
                                                            </td>
                                                            <td style={{ width: '200px' }}>
                                                               {(() => {
                                                                  if (val.price_change_percentage_24h > 0) {
                                                                     return (
                                                                        <div><Sparklines data={val.sparkline_in_7d[0].price}>
                                                                           <SparklinesLine color="green" />
                                                                        </Sparklines></div>
                                                                     )
                                                                  } else {
                                                                     return (
                                                                        <div><Sparklines data={val.sparkline_in_7d[0].price}>
                                                                           <SparklinesLine color="red" />
                                                                        </Sparklines></div>
                                                                     )
                                                                  }
                                                               })()}
                                                            </td>
                                                            <td style={{ textAlign: 'center', width: '350px' }}>
                                                               <div className="cta_wrapper">
                                                                  <button className="trade_btn" onClick={() => { depositDrop != val.symbol.toUpperCase() ? setdepositDrop(val.symbol.toUpperCase()) : setdepositDrop("") }}  > Deposit</button>
                                                                  <Link to={val.token_type == "auto" ? `/Dashboard/trade/${val.symbol.toUpperCase()}` : `/Dashboard/trading/${val.symbol.toUpperCase()}`} className="trade_btn active">TRADE</Link>
                                                                  <button className="trade_btn red__theme_btn" onClick={() => transferModal(val.symbol)} > Transfer </button>

                                                                  <Link to={`/Dashboard/WithdrawalAddress/${val.symbol.toUpperCase()}`} className="trade_btn active" > Withdrawal</Link>

                                                               </div>
                                                            </td>
                                                         </tr>
                                                         :
                                                         (val?.total_coin > 0 && switchHideBal === 1) ?
                                                            <tr>
                                                               <td>
                                                                  <div className="img__td">
                                                                     <img src={val.image} alt="Icon" width="30px" />
                                                                  </div>
                                                                  <div className="Text__td">
                                                                     <p>{val.symbol.toUpperCase()}</p>
                                                                     <small>{val.name}</small>
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <p>{'$' + val.current_price}</p>
                                                               </td>
                                                               <td><p>{val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p> <small>${val.usdt_income.toFixed(3)}</small></td>

                                                               <td>
                                                                  <Link to="/" className="f-normal-color" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }} >
                                                                     {val.price_change_percentage_24h?.toFixed(1)}% {val.price_change_percentage_24h < 0 ? <AiOutlineFall /> : <AiOutlineRise />}
                                                                  </Link>
                                                               </td>
                                                               <td style={{ width: '200px' }}>
                                                                  {(() => {
                                                                     if (val.price_change_percentage_24h > 0) {
                                                                        return (
                                                                           <div><Sparklines data={val.sparkline_in_7d[0].price}>
                                                                              <SparklinesLine color="green" />
                                                                           </Sparklines></div>
                                                                        )
                                                                     } else {
                                                                        return (
                                                                           <div><Sparklines data={val.sparkline_in_7d[0].price}>
                                                                              <SparklinesLine color="red" />
                                                                           </Sparklines></div>
                                                                        )
                                                                     }
                                                                  })()}
                                                               </td>
                                                               <td style={{ textAlign: 'center', width: '350px' }}>
                                                                  <div className="cta_wrapper">
                                                                     <button className="trade_btn" onClick={() => { depositDrop != val.symbol.toUpperCase() ? setdepositDrop(val.symbol.toUpperCase()) : setdepositDrop('') }}  > Deposit</button>
                                                                     <Link to={auth ? `/Dashboard/trade/${val.symbol.toUpperCase()}` : "/signup"} className="trade_btn active">TRADE</Link>
                                                                     <button className="trade_btn red__theme_btn" onClick={() => transferModal(val.symbol)} > Transfer </button>

                                                                     <Link to={`/Dashboard/WithdrawalAddress/${val.symbol.toUpperCase()}`} className="trade_btn active" > Withdrawal</Link>
                                                                  </div>
                                                               </td>
                                                            </tr>
                                                            : <></>

                                                      }
                                                      {depositDrop == val.symbol.toUpperCase() &&
                                                         <tr className="active-td show_tr" id={val.symbol.toUpperCase()}>
                                                            <td colSpan={8} >

                                                               <DepositAddress coin_name={val.symbol} bep20={Boolean(val.bep20.status)} trc20={Boolean(val.trc20.status)} erc20={Boolean(val.erc20.status)} gtc20={Boolean(val.gtc20.status)} />
                                                            </td>
                                                         </tr>}
                                                   </>
                                                )
                                                }

                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {buybtntrade && (
                           <div className="market buy_sell_btn s-primary-bg">
                              <button className="t-primary-color f-primary-bg">Trade</button>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Modal open={openT} onClose={onCloseModalT}
            classNames={{
               overlay: 'customOverlay',
               modal: 'customModal_S',
            }} center  >
            <TransferModalCom symbolT={symbolT} onCloseModalT={onCloseModalT} accountType={'main_account'} />

         </Modal>
      </>
   );
};

export default MarketTrade;