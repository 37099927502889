
import React, { useEffect } from 'react'
import { title } from '../config/config';
import BG_imgs from '../Assets/Images/about.png'
// all img Icon career inner 
import qutos from '../Assets/Icons/career/quto.svg'
import Business_d from '../Assets/Icons/career/Business.svg'
import selery_career from '../Assets/Icons/career/salery.svg'
import rolles_career from '../Assets/Icons/career/rolles.svg'
import deveploment_career from '../Assets/Icons/career/deveploment.svg'

const Careers = (props:any) => {
    useEffect(() => {
        document.title = props.title ;
    }, []);
    return (
        <>
            <div className='career__E'>
                {/* Banenr  */}
                <div className='career__banenr' style={{ backgroundImage: `url(${BG_imgs})` }}>
                    <div className='career__banenr__container'>
                        <h1 className='heading_career'>Careers at  {title}</h1>
                        <h2 className='pragraph__career'>Join our quest to increase the Freedom of Money</h2>
                        <a href='#' className='btn'>View Openings</a>

                    </div>
                </div>

                <div className='values_container'>
                    <div className='career___mistion'>
                        <h2 className='career___mistion_heading' >Our Values</h2>
                        <p className='career___mistion_pragraph'> {title} Core Values guide our behavior, decisions, and action, enabling unified collaboration across our diverse, international teams.</p>

                        <button type='button' className='btn'> Leran more</button>
                    </div>

                    <div className='columns__career'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='card'>
                                    <img src={qutos} alt="icons" />
                                    <div className='left__card_career'>
                                        <h2>User-Focused</h2>
                                        <p>We communicate openly.<br />
                                            We work as a team towards shared goals to build the ecosystem together.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6'>
                                <div className='card'>
                                    <img src={qutos} alt="icons" />
                                    <div className='left__card_career'>
                                        <h2>Collaboration</h2>
                                        <p>We protect our users by putting our users' needs first and delivering quality service.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6'>
                                <div className='card'>
                                    <img src={qutos} alt="icons" />
                                    <div className='left__card_career'>
                                        <h2>Hardcore</h2>
                                        <p>We are results driven. We get things done.</p>
                                        <p> We are passionate and work hard. When we fail, we learn fast, and pick ourselves up.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='card'>
                                    <img src={qutos} alt="icons" />
                                    <div className='left__card_career'>
                                        <h2>User-Focused</h2>
                                        <p>We protect our users by putting our users' needs first and delivering quality service.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6'>
                                <div className='card'>
                                    <img src={qutos} alt="icons" />
                                    <div className='left__card_career'>
                                        <h2>Freedom</h2>
                                        <p>We execute responsibly and autonomously.</p>
                                        <p> We empower those around us. Our team is diverse and we challenge the status quo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='card'>
                                    <img src={qutos} alt="icons" />
                                    <div className='left__card_career'>
                                        <h2>User-Focused</h2>
                                        <p>We protect our users by putting our users' needs first and delivering quality service.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6'>
                                <div className='card'>
                                    <img src={qutos} alt="icons" />
                                    <div className='left__card_career'>
                                        <h2>Humility</h2>
                                        <p>We accept critical feedback.</p>
                                        <p>We treat everyone as equals.</p>
                                        <p>We are modest about our success.</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='details___total'>
                    <div className='values_container'>
                        <div className='row'>
                            <div className='col-6'>
                                <h1> 100+</h1>
                                <p> Nationalities</p>
                            </div>

                            <div className='col-6'>
                                <h1> 1200+</h1>
                                <p> Employees</p>
                            </div>


                            <div className='col-6'>
                                <h1> 100+</h1>
                                <p> Locations</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='values_container'>

                    <div className='career___mistion'>
                        <h2 className='career___mistion_heading' >Choose Your Team</h2>
                        <p className='career___mistion_pragraph'> Select a team most relevant to your interests and experience to view job openings.</p>
                    </div>

                    <div className='career_card__career'>
                        <div className='row'>
                            <div className='col-4'>
                                <div className='card'>
                                    <a href='#'>
                                        <img src={Business_d} alt="icons" />
                                        <div className='left__card_career'>
                                            <p> Business Development</p>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className='col-4'>
                                <div className='card'>
                                    <a href='#'>
                                        <img src={Business_d} alt="icons" />
                                        <div className='left__card_career'>
                                            <p> Communications</p>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className='col-4'>
                                <div className='card'>
                                    <a href='#'>
                                        <img src={Business_d} alt="icons" />
                                        <div className='left__card_career'>
                                            <p> Customer Support </p>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className='col-4'>
                                <div className='card'>
                                    <a href='#'>
                                        <img src={Business_d} alt="icons" />
                                        <div className='left__card_career'>
                                            <p> Data & Research </p>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className='col-4'>
                                <div className='card'>
                                    <a href='#'>
                                        <img src={Business_d} alt="icons" />
                                        <div className='left__card_career'>
                                            <p>Editorial & Video</p>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className='col-4'>
                                <div className='card'>
                                    <a href='#'>
                                        <img src={Business_d} alt="icons" />
                                        <div className='left__card_career'>
                                            <p>  Engineering</p>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className='col-4'>
                                <div className='card'>
                                    <a href='#'>
                                        <img src={Business_d} alt="icons" />
                                        <div className='left__card_career'>
                                            <p> Finance & Administration </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className='work_career'>
                        <div className='row '>
                            <div className='col-12'>
                                <div className='career___mistion'>
                                    <h2 className='career___mistion_heading' >Why Work Here</h2>
                                    <p className='career___mistion_pragraph'> We are proud to offer competitive benefits that enable healthy and fulfilling careers at {title}</p>
                                </div>

                            </div>
                            <div className='col-4'>
                                <div className='card'>
                                    <img src={selery_career} alt="salary-svg" />
                                    <ul className='list'>
                                        <li className='list-data'> Competitive salary</li>
                                        <li className='list-data'>Option to be paid in crypto </li>
                                        <li className='list-data'>Health insurance </li>
                                        <li className='list-data'> Flexible working hours</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='col-4'>
                                <div className='card'>
                                    <img src={rolles_career} alt="salary-svg" />
                                    <ul className='list'>
                                        <li className='list-data'> Remote work for many roles</li>
                                        <li className='list-data'>Company sponsored holidays </li>
                                        <li className='list-data'>Team building activities</li>
                                        <li className='list-data'>And other various perks and benefits</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='col-4'>
                                <div className='card'>
                                    <img src={deveploment_career} alt="salary-svg" />
                                    <ul className='list'>
                                        <li className='list-data'> Learning and development programs</li>
                                        <li className='list-data'>Free language classes</li>
                                        <li className='list-data'>Relocation support </li>
                                        <li className='list-data'> International transfers mid-career</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className='work_career__2'>
                        <div className='career___mistion'>
                            <h2 className='career___mistion_heading' >How We Hire</h2>
                            <p className='career___mistion_pragraph'> On average 2~4 week interview process with 4 interviews.</p>
                        </div>
                        <div className='row '>
                            <div className='col-3'>
                                <div className='card'>
                                    <div className='Back___bg'>01</div>
                                    <div className=''>
                                        <p>Application Review</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-3'>
                                <div className='card'>
                                    <div className='Back___bg'>02</div>
                                    <div className=''>
                                        <p>Interviews </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-3'>
                                <div className='card'>
                                    <div className='Back___bg'>03</div>
                                    <div className=''>
                                        <p>Offer </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-3'>
                                <div className='card'>
                                    <div className='Back___bg'>04</div>
                                    <div className=''>
                                        <p>Onboarding </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>





            </div>
        </>
    )
}

export default Careers