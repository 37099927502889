import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import LoaderCom from "../loader/LoaderCom";

const SellBuySocketNew = (props: any) => {
    
    const { id } = useParams();
    const dropShow = (id: any) => {
        const a: HTMLElement | any = document.getElementById(id);
        a.classList.toggle("drop_show");
    };
    const [emptyData,setemptyData] = useState([]);
    const [sellemptyData,setsellemptyData] = useState([]);

    const [sellData, setSellData] = useState([]);
    const [buyData, setBuyData] = useState([]);
    async function buyDataFetch() {
		var d = await _fetch(`${api_url}/trade/getBuySellHistory?trade_on=${id?.toLocaleLowerCase()}&market_trade_type=buy&order_status=pending`, 'GET', {}, {});
		if (d?.status === 'success') {
			setBuyData(d.data.data);
            // console.log('buy data',d.data.data)
			setemptyData(d)
		}
	}
    async function sellDataFetch() {
		var d = await _fetch(`${api_url}/trade/getBuySellHistory?trade_on=${id?.toLocaleLowerCase()}&market_trade_type=sell&order_status=pending`, 'GET', {}, {});
		if (d?.status === 'success') {
			setSellData(d.data.data);
            setsellemptyData(d);
            // console.log('buy data',d.data.data)
			
		}
	}
    useEffect(() => {
        buyDataFetch()
        sellDataFetch();
        // const nowStream = `${props.symbol.toLowerCase()}usdt@depth20`;
        // const streams = ['@ticker', '@depth20', '@trade'];
        // const ws = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${nowStream}`);

        // ws.onmessage = function (event) {
        //     const json = JSON.parse(event.data);
        //     try {
        //         if ((json)) {
        //             // console.log(json.stream)
        //             if (json.stream === nowStream) {
        //                 if (json) {
        //                     var d = json.data;
        //                     // console.log(d.bids)
        //                     if (d.asks) {
        //                         // console.log
        //                         setSellData(d.asks.reverse());
        //                     }

        //                     if (d.bids) {
        //                         setBuyData(d.bids);

        //                     }
                           
        //                 }
        //             }
        //         }
        //     } catch (err) {
        //         // console.log(err);
        //     }
        // };



    }, [props.LoadApi])
    
  return (
    <div className='table___column__1a '>
    <div className='table__left_buy table__column scroll_data'>
        <table className="order__table ">
            <thead className='s-value'>
                <tr>
                    {/* <th></th> */}
                    <th>
                        <h6 className="right text-uppercase">Price(USDT)</h6>
                    </th>
                    <th>
                        <h6 className="right text-uppercase">Amount({props.symbol.toUpperCase()})</h6>
                    </th>
                    <th>
                        <h6 className="right text-uppercase">Total</h6>
                    </th>
                </tr>
            </thead>
            <tbody className="buy ">
            <LoaderCom condition={emptyData} text={'Getting Buy Orders...'} />

                {
                    buyData?.map((item: any, index: any, sellData: any) =>

                        <>
                            {(item) ?
                                <tr key={index} onClick={() => {props.copyOrder(item);props.setActiveTab(1)}}>
                                    <td className="status-dot-cell-green"> {item.coin_price?.toFixed(3)} </td>
                                    <td>{item.amount_total?.toFixed(3)}</td>
                                    <td>{(item.coin_price * item.amount_total)?.toFixed(2)}</td>
                                    <div className="Bar__green" style={{ width: (Math.round(item.amount_total * 100 / item.coin_price) > 100) ? '100%' : `${Math.round(item.amount_total * 100 / item.coin_price)}%` }}></div>
                                </tr>
                                : ''}
                        </>
                    )}

               
            </tbody>
        </table>
    </div>

    <div className='table__right_buy table__column scroll_data'>
        <table className="order__table">
            <thead className="s-value">
                <tr>
                    <th>
                        <h6 className="">Price(USDT)</h6>
                    </th>
                    <th>
                        <h6 className="">Amount({props.symbol.toUpperCase()})</h6>
                    </th>
                    <th>
                        <h6 className="">Total</h6>
                    </th>
                </tr>
            </thead>
            <tbody className="buy">
            <LoaderCom condition={sellemptyData} text={'Getting Sell  Orders...'} />

                {sellData?.reverse().map((item: any, index: any, sellData: any) =>

                    <>
                        {(item) ?
                            <tr key={index} onClick={() => {props.copyOrder(item);props.setActiveTab(0) }} >
                                 <td className="status-dot-cell-green"> {item.coin_price.toFixed(3)} </td>
                                    <td>{item.amount_total.toFixed(3)}</td>
                                    <td>{(item.coin_price * item.amount_total)?.toFixed(2)}</td>
                                    <div className="Bar__red" style={{ width: (Math.round(item.amount_total * 100 / item.coin_price) > 100) ? '100%' : `${Math.round(item.amount_total * 100 / item.coin_price)}%` }}></div>

                                {/* <div className="Bar__red" style={{ width: (Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%` }} ></div> */}
                            </tr>

                            : ''}
                    </>


                )}

              
            </tbody>
        </table>
    </div>
</div>
  )
}

export default SellBuySocketNew