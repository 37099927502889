import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-responsive-tabs/styles.css';
import { logo, title } from '../../config/config';
import SearchIcon from '@mui/icons-material/Search';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Modal } from 'react-responsive-modal';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SouthIcon from '@mui/icons-material/South';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
// responsive footer icon 
import DvrIcon from '@mui/icons-material/Dvr';
import ListIcon from '@mui/icons-material/List';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import HistoryIcon from '@mui/icons-material/History';
import PersonPinIcon from '@mui/icons-material/PersonPin';




const P2P = (props:any) => {




    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);


    const [open1, setOpen1] = useState(false);
    const onOpenModald = () => setOpen1(true);
    const onCloseModal1 = () => setOpen1(false);

    const [open2, setOpen2] = useState(false);
    const onOpenModalbuy = () => setOpen2(true);
    const onCloseModal2 = () => setOpen2(false);




    // responsive state work 

    let [marketTab, P2PMarkets] = useState('');
    let [chartTab, OrderBooks] = useState('');
    let [orderTab, HistoryTab] = useState('');
    let [myorderTab, openCOMTab] = useState('');
    let [BuySellTab, P2PBuySell] = useState('');



    let tabDisplay = (tab: string) => {
        if (window.screen.width! <= 768) {
            // window.location.reload();
            tab == 'P2Pmarket' ? P2PMarkets('block') : P2PMarkets('none');
            tab == 'OrderBooks' ? OrderBooks('block') : OrderBooks('none');
            tab == 'HistoryT' ? HistoryTab('block') : HistoryTab('none');
            tab == 'openTabs' ? openCOMTab('block') : openCOMTab('none');
            tab == 'P2pbuySell' ? P2PBuySell('block') : P2PBuySell('none');
            // console.log(window.screen.width)
        }

    }



    useEffect(() => {
        document.title = props.title ;

    }, [])





    return (
        <>


            <div className='trading_responsive_tab'>
                <div className="nav Footer___tab">
                    <div className='react-tab__1' onClick={() => tabDisplay('P2Pmarket')} > <a className="btn active">
                        <LightbulbIcon />
                        <span> Markets</span>
                    </a>
                    </div>
                    <div className='react-tab__1' onClick={() => tabDisplay('OrderBooks')} > <a className="btn">
                        <DvrIcon />
                        <span> Orders</span>
                    </a>
                    </div>
                    <div className='react-tab__1' onClick={() => tabDisplay('P2pbuySell')} > <a className="btn logo__Main">
                        <div className='react-tab__1__logo'>
                            <img src={logo} alt="" />
                        </div>
                        <span> Buy/Sell</span>
                    </a>
                    </div>
                    <div className='react-tab__1' onClick={() => tabDisplay('HistoryT')} > <a className="btn">
                        <HistoryIcon />
                        <span> Trades</span>
                    </a>
                    </div>
                    <div className='react-tab__1' onClick={() => tabDisplay('openTabs')} > <a className="btn">
                        <PersonPinIcon />
                        <span> My Orders</span>
                    </a>
                    </div>
                </div>
            </div>




            <div className='p2p_main'>
                <div className='p2p_left trading_center_top__2' style={{ display: chartTab }} >
                    <div className='p2p__left-1'  >
                        <Tabs>
                            <div className='card' id='card__left__table_1'>
                                <div className='card-header'>
                                    <div className='card__tab__title'>
                                        <p> ORDER BOOK</p>
                                    </div>
                                    <div className='card__tab__title_a'>
                                        <TabList className="nav ul___left">
                                            <Tab>
                                                <a className="btn active"> Market Depth</a>
                                            </Tab>
                                            <Tab>
                                                <a className="btn "> Order Volume</a>
                                            </Tab>
                                        </TabList>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <TabPanel>
                                        <div className='table___column__1a'>
                                            <div className='table__left_buy table__column'>
                                                <table className="order__table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <h6 className="right text-uppercase">Volume</h6>
                                                            </th>
                                                            <th>
                                                                <h6 className="right text-uppercase">Buy Price</h6>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="buy">
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_10'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_40'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_50'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_80'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='table__right_buy table__column'>
                                                <table className="order__table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <h6 className="">Sell Price</h6>
                                                            </th>
                                                            <th>
                                                                <h6 className="">Volume</h6>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="buy">
                                                        <tr>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_10'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_40'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_50'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_80'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_100'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_100'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_100'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_100'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_100'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className='table___column__1a'>
                                            <div className='table__left_buy table__column'>
                                                <table className="order__table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <h6 className="right text-uppercase">Volume</h6>
                                                            </th>
                                                            <th>
                                                                <h6 className="right text-uppercase">Buy Price</h6>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="buy">
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_10'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_40'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className=''>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_80'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='profit_100'>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='table__right_buy table__column'>
                                                <table className="order__table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <h6 className="">Sell Price</h6>
                                                            </th>
                                                            <th>
                                                                <h6 className="">Volume</h6>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="buy">
                                                        <tr className='less_10'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_100'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_10'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_40'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_100'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_40'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className=''>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_40'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_100'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className='less_40'>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                        <tr className=''>
                                                            <td className="status-dot-cell-danger"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                    <div className='p2p__left-1 p2p__left-2' >
                        <Tabs>
                            <div className='card' id='card__left__table_1'>
                                <div className='card-header'>
                                    <div className='card__tab__title'>
                                        <p> XID ORDER BOOK (<a href='#'>Read more</a>)</p>
                                    </div>
                                    <div className='card__tab__title_a'>
                                        <TabList className="nav ul___left">
                                            <Tab>
                                                <a className="btn active"> buyers</a>
                                            </Tab>
                                            <Tab>
                                                <a className="btn "> sellers</a>
                                            </Tab>
                                        </TabList>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <TabPanel>
                                        <div className='table___column__1a'>
                                            <div className='table__left_buy table__column'>
                                                <table className="order__table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <h6 className="right text-uppercase">Buy Price</h6>
                                                            </th>
                                                            <th>
                                                                <h6 className="right text-uppercase">Volume</h6>
                                                            </th>
                                                            <th>
                                                                <h6 className="right text-uppercase">XID</h6>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="buy">
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-green"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className='table___column__1a'>
                                            <div className='table__left_buy table__column'>
                                                <table className="order__table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <h6 className="right text-uppercase">Buy Price</h6>
                                                            </th>
                                                            <th>
                                                                <h6 className="right text-uppercase">Volume</h6>
                                                            </th>
                                                            <th>
                                                                <h6 className="right text-uppercase">XID</h6>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="buy">
                                                        <tr className='less_100'>
                                                            <td className="status-dot-cell-red"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514sdsdsd</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-red"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514sdsdsd</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-red"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514sdsdsd</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-red"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514sdsdsd</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="status-dot-cell-red"> 0.36533 </td>
                                                            <td>17,41,602</td>
                                                            <td>rdar7514sdsdsd</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
                <div className='p2p_center'>
                    <div className='p2p_center_1  trading_center_top__2' style={{ display: orderTab }} >
                        <Tabs>
                            <div className='card' id='card__left__table_1'>
                                <div className='card-header'>
                                    <div className='card__tab__title'>
                                        <p> MATCH HISTORY</p>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='table___column__1a'>
                                        <div className='table__left_buy table__column'>
                                            <table className="match__history">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <h6 className="right text-uppercase">Buy Price</h6>
                                                        </th>
                                                        <th>
                                                            <h6 className="right text-uppercase">Volume</h6>
                                                        </th>
                                                        <th>
                                                            <h6 className="right text-uppercase">Time</h6>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody className="buy">
                                                    <tr className='profit_100'>
                                                        <td className="status-dot-cell-green"> <ArrowUpwardIcon />  0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='less_100'>
                                                        <td className="status-dot-cell-red"> <SouthIcon /> 0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='less_100'>
                                                        <td className="status-dot-cell-red"> <SouthIcon /> 0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='profit_100'>
                                                        <td className="status-dot-cell-green"> <ArrowUpwardIcon />  0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='profit_100'>
                                                        <td className="status-dot-cell-green"> <ArrowUpwardIcon />  0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='profit_100'>
                                                        <td className="status-dot-cell-green"> <ArrowUpwardIcon />  0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='less_100'>
                                                        <td className="status-dot-cell-red"> <SouthIcon /> 0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='profit_100'>
                                                        <td className="status-dot-cell-green"> <ArrowUpwardIcon />  0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='less_100'>
                                                        <td className="status-dot-cell-red"> <SouthIcon /> 0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='profit_100'>
                                                        <td className="status-dot-cell-green"> <ArrowUpwardIcon />  0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>
                                                    <tr className='profit_100'>
                                                        <td className="status-dot-cell-green"> <ArrowUpwardIcon />  0.36533 </td>
                                                        <td>17,41,602</td>
                                                        <td>17:30:20</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                    <div className='p2p_center_2 trading_center_top__2' style={{ display: myorderTab }}>
                        <Tabs>
                            <div className='card' id='card__left__table_1'>
                                <div className='card-header'>
                                    <TabList className="nav ul___left">
                                        <Tab>
                                            <a className="btn active"> Open Orders</a>
                                        </Tab>
                                        <Tab>
                                            <a className="btn "> Completed Orders</a>
                                        </Tab>
                                    </TabList>
                                </div>
                                <div className='card-body'>
                                    <TabPanel>
                                        <div className='order__right__table'>
                                            <table className="open_order_table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <h6 className="right text-uppercase">Pair</h6>
                                                        </th>
                                                        <th>
                                                            <h6 className="right text-uppercase">Quantity</h6>
                                                        </th>
                                                        <th>
                                                            <h6 className="right text-uppercase">Price</h6>
                                                        </th>
                                                        <th>
                                                            <h6 className="right text-uppercase">Status</h6>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="buy">
                                                    <tr className='less_coin'>
                                                        <td>
                                                            <span className='coin__name'> trx</span>
                                                            <br />
                                                            <small>inr</small>
                                                        </td>
                                                        <td>
                                                            <span className='coin__amount'> 1,484</span>
                                                            <br />
                                                            <small>1,484</small>
                                                        </td>
                                                        <td className="status-dot-cell-green"> 6,6400 </td>
                                                        <td className='status__td'>
                                                            <span>Cencelled</span>
                                                            <i><ChevronRightIcon /></i>

                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className='order__right__table'>
                                            <table className="open_order_table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <h6 className="right text-uppercase">Pair</h6>
                                                        </th>
                                                        <th>
                                                            <h6 className="right text-uppercase">Quantity</h6>
                                                        </th>
                                                        <th>
                                                            <h6 className="right text-uppercase">Price</h6>
                                                        </th>
                                                        <th>
                                                            <h6 className="right text-uppercase">Status</h6>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="buy">
                                                    <tr className='profit_coin'>
                                                        <td>
                                                            <span className='coin__name'> trx</span>
                                                            <br />
                                                            <small>inr</small>
                                                        </td>
                                                        <td>
                                                            <span className='coin__amount'> 1,484</span>
                                                            <br />
                                                            <small>1,484</small>
                                                        </td>
                                                        <td className="status-dot-cell-green"> 6,6400 </td>
                                                        <td className='status__td' rowSpan={2}>
                                                            <span>Cencelled</span>
                                                            <i><ChevronRightIcon /></i>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
                <div className='p2p_right'>
                    <div className='p2p_right_1' style={{ display: marketTab }} >
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-header-1 active'>
                                    <span>USDT</span>/ <span>EUSD</span>
                                </div>
                                <div className='card-header-2'>
                                    <span><CurrencyRupeeIcon /> 89.99 </span>
                                </div>
                            </div>
                            <div className='card-body'>
                                <h4>P2P Markets</h4>
                                <p> Use {title} P2P when you want to buy USDT to trade cryptos, or when you want to sell USDT and cash out to INR. It's safe and hassle free!</p>
                                <div className='how__bar'>
                                    <p>How it works</p>
                                </div>
                                <div className='img__area__p2p'>
                                    <img src={require("../../Assets/Icons/trading/p2p_right-1.svg").default} alt="Right-arrow-icon" />
                                </div>
                                {/* <div className='more__then__links'>
                        <a href='#' className='link'> Know more  </a>
                        <a href='#' className='link'>  <SlowMotionVideoIcon /> Video Tutorials</a>
                    </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='p2p_right_2 trading_center_top__2' style={{ display: BuySellTab }}>
                        <Tabs>
                            <div className='card-buy-sell'>
                                <div className='card-header'>
                                    <TabList className="nav ul___left">
                                        <Tab>
                                            <div className="btn active" onClick={() => { console.log("BUY") }}> Buy</div>
                                        </Tab>
                                        <Tab className="react-tabs__tab sell_btn">
                                            <div className="btn " onClick={() => { console.log("SELL") }}> Sell</div>
                                        </Tab>
                                    </TabList>
                                </div>
                                <div className='card-body'>
                                    <TabPanel>
                                        <div className='buy_data_card'>
                                            <Tabs>
                                                <div className='tab__card'>
                                                    <TabList className="nav ul___left">
                                                        <Tab>
                                                            <a className="btn active"> Limit</a>
                                                        </Tab>
                                                        <Tab>
                                                            <a className="btn "> Stop Limit</a>
                                                        </Tab>
                                                    </TabList>
                                                </div>
                                                <div className='tab__card_inner'>
                                                    <TabPanel>
                                                        <form>
                                                            <div className='form-group'>
                                                                <div className='lable'>
                                                                    <div> AT PRICE </div>
                                                                    <div> INR </div>
                                                                </div>
                                                                <input type="number" placeholder='0.0' className='at__price_input' />
                                                                <div className='btn-input'>
                                                                    <span> Lowest Price</span>
                                                                </div>
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='lable'>
                                                                    <div> Amount </div>
                                                                    <div> UFI</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='form-group form-group_last'>
                                                                <div className='lable lable-4'>
                                                                    <div> Total </div>
                                                                    <div> inr</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='btn__form'>
                                                                <div className='btn' onClick={onOpenModald}> BUY UFI</div> {/* <button type='submit' className='btn' onClick={onOpenModal}> BUY UFI </button> */}
                                                            </div>
                                                        </form>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <form>
                                                            <div className='form-group'>
                                                                <div className='lable'>
                                                                    <div> STOP PRICE </div>
                                                                    <div> inr</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='lable'>
                                                                    <div> AT PRICE </div>
                                                                    <div> INR </div>
                                                                </div>
                                                                <input type="number" placeholder='0.0' className='at__price_input' />
                                                                <div className='btn-input'>
                                                                    <span> Lowest Price</span>
                                                                </div>
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='lable'>
                                                                    <div> Amount </div>
                                                                    <div> UFI</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='form-group form-group_last'>
                                                                <div className='lable lable-4'>
                                                                    <div> Total </div>
                                                                    <div> inr</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='btn__form'>
                                                                <div className='btn' onClick={onOpenModald}> BUY UFI</div> {/* <button type='submit' className='btn'> BUY UFI </button> */}
                                                            </div>
                                                        </form>
                                                    </TabPanel>
                                                </div>
                                            </Tabs>
                                        </div>
                                        <div className='Next__link'>
                                            <span> Fee: 0.00%
                                            </span>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className='buy_data_card sell_data_card'>
                                            <Tabs>
                                                <div className='tab__card'>
                                                    <TabList className="nav ul___left">
                                                        <Tab>
                                                            <a className="btn active"> Limit</a>
                                                        </Tab>
                                                        <Tab>
                                                            <a className="btn "> Stop Limit</a>
                                                        </Tab>
                                                    </TabList>
                                                </div>
                                                <div className='tab__card_inner'>
                                                    <TabPanel>
                                                        <form>
                                                            <div className='form-group'>
                                                                <div className='lable'>
                                                                    <div> AT PRICE </div>
                                                                    <div> INR </div>
                                                                </div>
                                                                <input type="number" placeholder='0.0' className='at__price_input' />
                                                                <div className='btn-input'>
                                                                    <span> Lowest Price</span>
                                                                </div>
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='lable lable-4'>
                                                                    <div> AMOUNT </div>
                                                                    <div> ufi</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='lable'>
                                                                    <div> Total </div>
                                                                    <div> inr</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='btn__form'>
                                                                <div className='btn btn-sell' onClick={onOpenModalbuy}>sell UFI </div> {/* <button type='submit' className='btn btn-sell'> sell UFI </button> */}
                                                            </div>
                                                        </form>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <form>
                                                            <div className='form-group'>
                                                                <div className='lable lable-4'>
                                                                    <div> STOP PRICE </div>
                                                                    <div> inr</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='lable'>
                                                                    <div> AT PRICE </div>
                                                                    <div> INR </div>
                                                                </div>
                                                                <input type="number" placeholder='0.0' className='at__price_input' />
                                                                <div className='btn-input'>
                                                                    <span> Highest Price</span>
                                                                </div>
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='lable lable-4'>
                                                                    <div> AMOUNT </div>
                                                                    <div> ufi</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='form-group'>
                                                                <div className='lable'>
                                                                    <div> Total </div>
                                                                    <div> inr</div>
                                                                </div>
                                                                <input type="number" placeholder='0' />
                                                            </div>
                                                            <div className='btn__form'>
                                                                <div className='btn btn-sell' onClick={onOpenModalbuy}>sell UFI </div> {/* <button type='submit' className='btn btn-sell'> sell UFI </button> */}
                                                            </div>
                                                        </form>
                                                    </TabPanel>
                                                </div>
                                            </Tabs>
                                        </div>
                                        <div className='Next__link'>
                                            <span> Fee: 0.00% <i>|</i> TDS: 1%
                                            </span>
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>




            <Modal open={open} onClose={onCloseModal}
                classNames={{
                    overlay: 'Modal__trading',
                    modal: 'Modal__trading_main',
                }} center>
                <div className="card-header">
                    <h2> Order Details</h2>
                </div>
                <div id="Modal__details">
                    <div className="modal-body">
                        <div className="column__1 modal__colun-1">
                            <p className='coin_modal-name'> trx</p>
                            <p className='activity'> <span>BUY</span>  </p>
                        </div>
                        <div className="column__1">
                            <p> Type </p>
                            <p> Limit</p>
                        </div>
                        <div className="column__1">
                            <p> Placed on</p>
                            <p> 13 May, 05:06 </p>
                        </div>
                        <div className="column__1">
                            <p className="Transaction-fees" > Filled / Amount</p>
                            <p> <span>  0.00 TRX</span>/ <span> 719.54 Trx</span> </p>
                        </div>
                        <div className="column__1 column__1__icon">
                            <p> Average / price</p>
                            <p>  <CurrencyRupeeIcon /><span>  0.00  </span>/ <CurrencyRupeeIcon /> <span> 1.254 </span> </p>
                        </div>
                        <div className="column__1 column__1__icon last_sc">
                            <p> Total </p>
                            <p className="" >  <CurrencyRupeeIcon /> 0.000 </p>
                        </div>
                        <div className="column__1">
                            <p> Fee</p>
                            <p> N/A</p>
                        </div>
                        <div className="column__1">
                            <p> TDS</p>
                            <p> N/A</p>
                        </div>
                        <div className='trading_details_table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th> Date/ Time</th>
                                        <th> Filled</th>
                                        <th> Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </Modal>

            {/* ========== >>>> */}

            <Modal open={open1} onClose={onCloseModal1}
                classNames={{
                    overlay: 'Modal__trading',
                    modal: 'BUY___BUTTON',
                }} center>
                <div className="card-header">
                    <h2> Buy BTC For INR</h2>
                </div>
                <div id="Modal__details">
                    <div className="modal-body">
                        <div className="column__1">
                            <p> Type </p>
                            <p> Limit</p>
                        </div>
                        <div className="column__1">
                            <p> At Price</p>
                            <p> ₹10.2801 </p>
                        </div>
                        <div className="column__1">
                            <p> Amount </p>
                            <p className="" > 1000 BTC </p>
                        </div>
                        <div className="column__1">
                            <p> Total</p>
                            <p> ₹17.5602</p>
                        </div>
                        <div className='Next__link__2'>
                            <span>  Fee <a href='#' className='fdf'> Maker: 0.2% | Taker: 0.2% | TDS: 1.0% | Incl. Taxes</a> </span>
                        </div>
                        <div className='btn___modal_1'>
                            <button type='submit' className='modal__btn' > Confiram buy</button>
                        </div>

                    </div>
                </div>
            </Modal>


            <Modal open={open1} onClose={onCloseModal1}
                classNames={{
                    overlay: 'Modal__trading',
                    modal: 'BUY___BUTTON',
                }} center>
                <div className="card-header">
                    <h2> Buy BTC For INR</h2>
                </div>
                <div id="Modal__details">
                    <div className="modal-body">
                        <div className="column__1">
                            <p> Type </p>
                            <p> Limit</p>
                        </div>
                        <div className="column__1">
                            <p> At Price</p>
                            <p> ₹10.2801 </p>
                        </div>
                        <div className="column__1">
                            <p> Amount </p>
                            <p className="" > 1000 BTC </p>
                        </div>
                        <div className="column__1">
                            <p> Total</p>
                            <p> ₹17.5602</p>
                        </div>
                        <div className='Next__link__2'>
                            <span>  Fee <a href='#' className='fdf'> Maker: 0.2% | Taker: 0.2% | TDS: 1.0% | Incl. Taxes</a> </span>
                        </div>
                        <div className='btn___modal_1'>
                            <button type='submit' className='modal__btn' > Confiram buy</button>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal open={open2} onClose={onCloseModal2}
                classNames={{
                    overlay: 'Modal__trading',
                    modal: 'BUY___BUTTON',
                }} center>
                <div className="card-header">
                    <h2> Buy BTC For INR</h2>
                </div>
                <div id="Modal__details">
                    <div className="modal-body">
                        <div className="column__1">
                            <p> Type </p>
                            <p> Limit</p>
                        </div>
                        <div className="column__1">
                            <p> At Price</p>
                            <p> ₹10.2801 </p>
                        </div>
                        <div className="column__1">
                            <p> Amount </p>
                            <p className="" > 1000 BTC </p>
                        </div>
                        <div className="column__1">
                            <p> Total</p>
                            <p> ₹17.5602</p>
                        </div>
                        <div className='Next__link__2'>
                            <span>  Fee <a href='#' className='fdf'> Maker: 0.2% | Taker: 0.2% | TDS: 1.0% | Incl. Taxes</a> </span>
                        </div>
                        <div className='btn___modal_1'>
                            <button type='submit' className='modal__btn Sell___btn' > Confiram buy</button>
                        </div>

                    </div>
                </div>
            </Modal>


        </>
    )
}

export default P2P