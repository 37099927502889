import React, { useEffect } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { title } from '../config/config'
import cardIcon1 from '../Assets/Images/cardIcon1.png'
import cardIcon2 from '../Assets/Images/cardIcon2.png'
import btc from '../Assets/Images/btc.svg'
import usdt from '../Assets/Images/usdt..svg'
import reserveLeft from '../Assets/Images/reserveLeft.png'
import vs from '../Assets/Images/vs.png'
import merkel from '../Assets/Images/merkel.png'
import { Link } from 'react-router-dom';


const ReserveCertificate = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
    return (
        <>
            <div className="section_ReserveCertificate pt__spacing">

                <section className='Certificate__banner'>
                    <div className='Certificate____container'>
                        <div className='card__banner__left'>
                            <h1>Proof of Reserves</h1>
                            <h3>{title} guarantees it will hold 100% of users' assets in reserves and will publish its Merkle Tree proof, platform reserves, and platform reserve ratio on a monthly basis.</h3>
                            <div className="inner-button">
                                <Link className='btn' to="/login">Proof of users' assets</Link>
                                <Link className='btn' target="_blank" to="https://github.com/">GitHub</Link>
                            </div>
                        </div>
                        {/* <div className='card__banner__right'>
                                <img src={BAnner__img} alt="icon" />

                            </div> */}
                    </div>
                </section>

                <section className='ratio__sec__C'>
                    <div className='Certificate____container'>
                        <div className="row">
                                <div className="col-8">
                                    <h1>The latest {title} reserve ratio</h1>
                                    <p>Reserve ratio = funds in the platform/funds of platform users. A reserve ratio greater than or equal to 100% means that the platform has sufficient funds.</p>
                                </div>
                                <div className="col-4">
                                    <div className="dropdown">
                                        <select className="form-select">
                                            <option>Snapshot Time 2023-01-31 07:30:00  (GMT+8)</option>
                                            <option>Snapshot Time 2023-01-31 07:30:00  (GMT+8)</option>
                                            <option>Snapshot Time 2023-01-31 07:30:00  (GMT+8)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>

                <section className='C_cards___IN'>
                    <div className="Certificate____container">
                        <div className="row">
                            <div className="col-6">
                                <div className="top-section">
                                    <div className="img-sec">
                                        <img src={cardIcon1} alt="" />

                                    </div>
                                    <div className="info">
                                        <div className="inner-info">
                                            <h3>Merkle root hash</h3>
                                            <p>The Merkle tree generated from all balances has 23 layer(s) and 3054871 record(s) for secure and efficient verification</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="foot-section">
                                    <h1>bab4dcc0b4020956</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="top-section">
                                    <div className="img-sec">
                                        <img src={cardIcon2} alt="" />

                                    </div>
                                    <div className="info">
                                        <div className="inner-info">
                                            <h3>Total reserve ratio</h3>
                                            <p>{title} holds more than 100% of the users' total assets (BTC, ETH, USDT)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="foot-section">
                                    <h1>227%</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='C__card__OUT'>
                    <div className="Certificate____container">
                        <div className="row">
                            <div className="col-4">
                                <div className="card">
                                    <div className="header-section">
                                        <img src={btc} alt="" />
                                        <div className="info">
                                            <p>BTC reserve ratio</p>
                                            <div className="another">
                                                <h2>620%</h2>
                                                <p><CheckCircleIcon /> Reserves are sufficient</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="footer-section">
                                        <div className="foot-info">
                                            <p>{title} wallet reserves</p>
                                            <h4>8730.69 BTC</h4>
                                        </div>
                                        <div className="foot-info">
                                            <p>{title} wallet reserves</p>
                                            <h4>8730.69 BTC</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card">
                                    <div className="header-section">
                                        <img src={usdt} alt="" />
                                        <div className="info">
                                            <p>USDT reserve ratio</p>
                                            <div className="another">
                                                <h2>202%</h2>
                                                <p><CheckCircleIcon /> Reserves are sufficient</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="footer-section">
                                        <div className="foot-info">
                                            <p>{title} wallet reserves</p>
                                            <h4>446274115.16 USDT</h4>
                                        </div>
                                        <div className="foot-info">
                                            <p>{title} wallet reserves</p>
                                            <h4>221278562.90 USDT</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="card">
                                    <div className="header-section">
                                        <img src={btc} alt="" />
                                        <div className="info">
                                            <p>ETH reserve ratio</p>
                                            <div className="another">
                                                <h2>186%</h2>
                                                <p><CheckCircleIcon /> Reserves are sufficient</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="footer-section">
                                        <div className="foot-info">
                                            <p>{title} wallet reserves</p>
                                            <h4>12167.08 ETH</h4>
                                        </div>
                                        <div className="foot-info">
                                            <p>{title} wallet reserves</p>
                                            <h4>6533.70 ETH</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className='proof__sec_C'>
                    <div className="Certificate____container">
                            <div className="row">
                                <div className="col-6">
                                    <h3>What is proof of reserves?</h3>
                                    <p>'Proof of reserves' refers to an auditing procedure that is verifiable through cryptographic proofs, checks of public wallet ownerships, and recurring audits to certify the holdings of an exchange. The custodian provides transparency and proof of the existence of on-chain reserves and that the total amounts of those coins held and effectively at the disposal of the platform exceeds or is equal to the sum of all users' holdings of those coins.
                                        To achieve this, {title} stores the hash of each user's account assets in a leaf node on the Merkle tree. Each user can verify that their funds are included in the Merkle tree by checking the total amount of users' assets stored in the Merkle tree leaf nodes.</p>
                                    <p>If the total amount verified is greater than or equal to 100%, then the platform has proven that users' funds are indeed intact.</p>
                                    <h4>The open source code of the platform's proof of its 100% solvency program has been published to GitHub. <a href="#"> See for yourself</a></h4>
                                </div>
                                <div className="col-6">
                                    <div className="img__contianer__C">
                                        <img src={reserveLeft} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row MIN____ROW__C">

                                <div className="col-6">
                                    <div className="img__contianer__C">
                                        <img src={reserveLeft} alt="" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <h3>We are committed to maximum transparency</h3>
                                    <p>{title}'s principles are meant to prioritize our users. To ensure the verifiable safety of our users' assets, we will be fully transparent with all our platform's assets.</p>
                                    <h5>1. Take snapshots monthly and publish all wallet assets of the platform</h5>
                                    <h5>2. Take snapshots of each user's assets monthly and desensitize for publication</h5>
                                    <h5>3. Users can verify their assets in a few simple steps</h5>
                                </div>
                            </div>
                        </div>
                </section>

                <section className='proof__sec_C__2'>
                    <div className="Certificate____container">
                            <div className="main-risk">
                                <h1>Why 100% on-hand reserves are so important</h1>

                                <div className="risk">
                                    <div className="risk-left">
                                        <h1>Unable to prove 100% on-hand reserves</h1>
                                        <div className="info">
                                            <h4>It can be a telltale sign of the misappropriation of assets <CloseIcon /></h4>
                                            <h4>And a source of low risk tolerance for black swan events <CloseIcon /></h4>
                                            <h4>Concentrated withdrawals can lead to a run, which may result in a loss of users' assets <CloseIcon /></h4>
                                        </div>
                                    </div>
                                    <div className="risk-right">
                                        <div className="another">
                                            <h1>The platform provides 100% reserves</h1>
                                            <div className="info">
                                                <h4>It means that the platform has sufficient reserves</h4>
                                                <h4>It effectively guarantees that users' assets are safe</h4>
                                                <h4>The platform will have no issues paying 100% of any concentrated withdrawals of users' assets</h4>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="risk-vs">
                                        <img src={vs} alt="" />
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>

                <section className='table__A_C'>
                <div className="Certificate____container">
                        <h1>{title} reserve details</h1>
                        <div className="scroll">
                        <table className="customers">
                            <tr>
                                <th>Coin name</th>
                                <th>Users' assets</th>
                                <th>Platform's assets</th>
                                <th>Public chain</th>
                                <th>Reserve ratio</th>
                            </tr>
                            <tr>
                                <td>BTC</td>
                                <td>1316.48632672BTC</td>
                                <td>8163.65741914 BTC</td>
                                <td>BITCOIN</td>
                                <td>620%</td>
                            </tr>
                            <tr>
                                <td>USDT</td>
                                <td>254289764.3307006USDT</td>
                                <td>
                                    <p>46350469.18399736 USDT</p>
                                    <p>198049869.635799 USDT</p>
                                    <p>953823.386945 USDT</p>
                                    <p>216206136.210211 USDT</p>
                                </td>
                                <td>
                                    <p>Binance Smart Chain</p>
                                    <p>Ethereum</p>
                                    <p>Solana</p>
                                    <p>Tron</p>
                                </td>
                                <td>182%</td>
                            </tr>
                            <tr>
                                <td>ETH</td>
                                <td>5400.6952265ETH</td>
                                <td>
                                    <p>404.52968079 ETH</p>
                                    <p>494.38388166 ETH</p>
                                    <p>10243.58522904 ETH</p>
                                </td>
                                <td>
                                    <p>Arbitrum One</p>
                                    <p>Binance Smart Chain</p>
                                    <p>Ethereum</p>
                                </td>
                                <td>206%</td>
                            </tr>


                        </table>

                        </div>

                        <p className='last__table_A'>107 users have verified the authenticity of the data. <a href="#"> See for yourself</a></p>
                    </div>
                </section>

                <section className='table__A_C'>
                <div className="Certificate____container">
                        <h1>{title} reserve details</h1>
                        <div className="scroll">
                        <table className="customers">
                            <tr>
                                <th>Coin name</th>
                                <th>Users' assets</th>
                                <th>Platform's assets</th>
                                <th>Public chain</th>
                                <th>Reserve ratio</th>
                            </tr>
                            <tr>
                                <td>BTC</td>
                                <td>1316.48632672BTC</td>
                                <td>8163.65741914 BTC</td>
                                <td>BITCOIN</td>
                                <td>620%</td>
                            </tr>
                            <tr>
                                <td>USDT</td>
                                <td>254289764.3307006USDT</td>
                                <td>
                                    <p>46350469.18399736 USDT</p>
                                    <p>198049869.635799 USDT</p>
                                    <p>953823.386945 USDT</p>
                                    <p>216206136.210211 USDT</p>
                                </td>
                                <td>
                                    <p>Binance Smart Chain</p>
                                    <p>Ethereum</p>
                                    <p>Solana</p>
                                    <p>Tron</p>
                                </td>
                                <td>182%</td>
                            </tr>
                            <tr>
                                <td>ETH</td>
                                <td>5400.6952265ETH</td>
                                <td>
                                    <p>404.52968079 ETH</p>
                                    <p>494.38388166 ETH</p>
                                    <p>10243.58522904 ETH</p>
                                </td>
                                <td>
                                    <p>Arbitrum One</p>
                                    <p>Binance Smart Chain</p>
                                    <p>Ethereum</p>
                                </td>
                                <td>206%</td>
                            </tr>


                        </table>

                        </div>

                        <p className='last__table_A'>107 users have verified the authenticity of the data. <a href="#"> See for yourself</a></p>
                    </div>
                </section>

                <section className='table__A_C'>
                    <div className="Certificate____container">
                        <h1>{title} wallet details</h1>
                        <div className="scroll">
                        <table className="customers">
                            <tr>
                                <th>Coin name</th>
                                <th>Public chain</th>
                                <th>Wallet address</th>
                                <th>Amout</th>
                                <th>Block height</th>
                            </tr>
                            <tr>
                                <td>BTC</td>
                                <td>BITCOIN</td>
                                <td><a href="#">1FWQiwK27EnGXb6BiBMRLJvunJQZZPMcGd</a></td>
                                <td>294.26</td>
                                <td>774403</td>
                            </tr>
                            <tr>
                                <td>BTC</td>
                                <td>BITCOIN</td>
                                <td><a href="#">1GDn5X4R5vjdSvFPrq1MJubXFkMHVcFJZv</a></td>
                                <td>154.47</td>
                                <td>774403</td>
                            </tr>

                        </table>

                        </div>
                    </div>
                </section>

                <section className='final-bottom-section'>
                    <div className="Certificate____container">
                            <div className="heading">
                                <h3>Merkle Tree Basics</h3>
                                <p>A <a href="#">Merkle Tree</a>  is primarily used to verify data integrity more efficiently. Each account is represented by an Acct node at the bottom, and the balances and account names of each account will be computed once with SHA256 encryption. The obtained hash value can be computed again with the adjacent one, and then layer by layer upwards until the hash calculation reaches the root of the Merkle tree. To verify whether the current reserves have been changed, the user only needs to follow the steps to make a hash calculation of their own account, find the position in the tree and the adjacent nodes, and then calculate the hash upward layer by layer until finally reaching the root of the tree calculated by the user. If it is consistent with the official announcement, then everything is accounted for.</p>
                                <h4>As shown in the figure below:</h4>
                            </div>
                            <div className="img">
                                <img src={merkel} alt="" />
                            </div>
                        </div>
                </section>

            </div>

            <div className="b_container">
                <div className="ration-section">
                   
                   
                  

                  
                    
                   
                    
                   
                </div>
            </div>
        </>
    )
}

export default ReserveCertificate
