// type spotype = {
//     image: string,
//     Cardheading: string,
//     logigicon: string,
//     Cardbodyheading: string,
//     Crdprice: string,
//     cardfooterheading: string,
//     Crdpricenumber: string,
//     progress: string
// }
const Spotlightcard = (props: any) => {
    return (
        <>
            {/* Common Card */}
            <li className="spotlight_card">
                <div className="card_content">
                    <span className="cirle_element">
                    </span>
                    <span className="second_cirle_element  ">
                    </span>
                    <div className="card_header">
                        <div className="card_logo">
                            <img src={require(`../Assets/Icons/${props.image}.svg`)} alt="" />
                            <span className="card_heading s-normal-color">
                                {props.Cardheading}
                            </span>
                        </div>
                        <img className="logo" src={require(`../Assets/Icons/${props.logigicon}.svg`)} alt="" />
                    </div>
                    <div className="card_body">
                        <div>
                            <span className="price-number f-cta-color">
                                {props.Crdpricenumber}
                            </span>
                            <span className="price f-cta-color">
                                {props.Crdprice}
                            </span>

                            <h4 className="card_body_heading s-normal-color">
                                {props.Cardbodyheading}
                            </h4>
                        </div>
                        <div className="card_body_btn">
                            <button >
                                <span className="progress_btn s-normal-color">{props.progress}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card_footer">
                    <p className="card_footer_heading">
                        {props.cardfooterheading}
                    </p>
                </div>
            </li>

        </>
    )
}
export default Spotlightcard; 