import { useState, useEffect } from "react";
import { api_url, title } from "../../config/config";
import ReactPaginate from 'react-paginate';
import { _fetch } from "../../config/api";
import dateFormat, { masks } from "dateformat";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
import LaunchIcon from '@mui/icons-material/Launch';
import toasted from "../../config/toast";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useParams } from "react-router";
import LoaderCom from "../loader/LoaderCom";
import { CSVLink } from "react-csv";
// import { CSVLink, CSVDownload } from "react-csv";

const IncomeReports = (props:any) => {
   const { accountType } = useParams();
   const [records, setrecords] = useState(0);
   const [pageCount, setPageCount] = useState(0);
   const [itemOffset, setItemOffset] = useState(1);
   const [short, setshort] = useState('created_at');
   const [searchedVal, setSearchedVal] = useState("");
   const [searchedType, setSearchedType] = useState("symbol");
   const [emptyData, setemptyData] = useState([]);
   const [TableRecords, setTableRecords] = useState([]);
   const [tradeData, setdata] = useState([]);
   const [buybtntrade, setbuybtntrade] = useState(false);
   const handlePageClick = async (event: any) => {
      const newOffset = (event.selected + 1);
      setItemOffset(newOffset);
   };
   const [csv, setcsv] = useState([]);
   useEffect(() => {
      document.title =  accountType?.toString().replace("_", ' ')?.toLocaleUpperCase() + ` ` +props.title ;

      (async () => {
         setemptyData([]);;
         setTableRecords([]);
         const passData = {};
         if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
         } else {
            var searchData = "";
         }
         const data1 = await _fetch(`${api_url}/incomes/incomeHistory?sort=${short}&page=${itemOffset}&type=${accountType}&limit=10${searchData}`, 'GET', passData, {});
         const csv = await _fetch(`${api_url}/incomes/incomeHistory?type=${accountType}`, 'GET', passData, {});
         var val: any = [['#', 'symbol', 'Amount', 'Remark', 'Created At']];
         csv?.data?.data?.map((valData: any, key: any) => {

            val[key + 1] = [`${key + 1}`, `${valData.symbol?.toUpperCase()}`, `${valData.amount.toFixed(3)}`, `${valData.remark}`, `${dateFormat(valData.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}`];

            setcsv(val)

         })

         setdata(data1.data.data);
         setTableRecords(data1?.data.data);
         setrecords(data1.total);
         setemptyData(data1);

      })()
      setPageCount(Math.ceil(records / 10));
      // activityLog()
   }, [itemOffset, records, short, searchedVal, searchedType]);

   const auth = localStorage.getItem("auth");
   const [RecordModal, setRecordModal] = useState({
      amount: 0,
      blockHash: '',
      blockNumber: '',
      blockchain_scan: '',
      blockchain_transaction: '',
      chain: '',
      coin_id: '',
      confirmations: '',
      contractAddress: '',
      created_at: '',
      decimal: '',
      decimal_number: '',
      from: '',
      gas: '',
      gasPrice: '',
      gasUsed: '',
      hash: '',
      isError: '',
      nonce: '',
      remark: '',
      symbol: '',
      timeStamp: '',
      to: '',
      transactionIndex: '',
      txreceipt_status: '',
      type: '',
      type_name: '',
      updated_at: '',
      username: '',
      value: '',
      _id: '',
   })
   const [open, setOpen] = useState(false);
   const onOpenModal = (val: any) => {
      if (val.hash != '') {
         setOpen(true)
      }
      setRecordModal(val);
   };
   const onCloseModal = () => setOpen(false);
   const copyToClipboardId = (data: any) => {
      navigator.clipboard.writeText(data);
      toasted.success('Transaction ID Copied Successfully!');
   }

   ///////transfer modal data
   const [openT, setOpenT] = useState(false);
   const onOpenModalT = () => setOpenT(true);
   const onCloseModalT = () => setOpenT(false);
   const transferModal = (symbol: any) => {
      setOpenT(true)

   }

   const csvData = csv;



   return (
      <>
         <section className="sec_market_trade">
            <div className="container-max">
               <div className="sec_content">
                  {/* Main Tabs */}
                  <div className="main_tabs_content">
                     <div className="token_coin_content">
                        {/* Main Market Tabs */}
                        <div className="dropdown_trabs main_market_tabs">
                           <div className="main_market_tabs_content ">

                           </div>
                        </div>

                        <div className="main_market_tabs">
                           <div className="card-body-container ">
                              <div className="table____main">
                                 <div className="table-section-1">
                                    <div className="table-header">
                                       <div className="left-t-geader withdraw-H with__abs">
                                          <h3>{accountType?.toString().replace("_", ' ')} History</h3>
                                          <form>
                                             <div className="input-group mb-3 select-input-group">
                                                <select className="form-control" name="short" onChange={(e: any) => { setSearchedType(e.target.value) }}>
                                                   <option value="symbol">SYMBOL</option>
                                                   <option value="hash">HASH</option>
                                                </select>
                                             </div>
                                             <div className="input-group mb-3 select-input-group-right">
                                                <div className="input-group-prepend"> 🔍 </div>
                                                <input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearchedVal(e.target.value)} />
                                             </div>

                                             <div className="toggle" >             
                                                                             <CSVLink data={csvData}   filename={"gtc-exchange.csv"}   className="btn___A_AD">Download CSV</CSVLink>

                                             </div>
                                          </form>
                                       </div>
                                    </div>

                                    <div className="table-body">
                                       <div className="table-responsive">
                                          <table className="table balance-table withdral-History">
                                             <thead>
                                                <tr>
                                                   <th onClick={() => { short == 'symbol' ? setshort('-symbol') : setshort('symbol') }} style={{ cursor: 'pointer' }}>SYMBOL </th>
                                                   <th onClick={() => { short == 'amount' ? setshort('-amount') : setshort('amount') }} style={{ cursor: 'pointer' }}>AMOUNT </th>
                                                   <th onClick={() => { short == 'remark' ? setshort('-remark') : setshort('remark') }} style={{ cursor: 'pointer' }}>REMARK</th>
                                                   <th onClick={() => { short == 'created_at' ? setshort('-created_at') : setshort('created_at') }} style={{ cursor: 'pointer' }}>CREATED AT</th>
                                                   {/* <th >Action</th> */}
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <LoaderCom condition={emptyData} text={'Getting latest values...'} />
                                                {TableRecords?.map((val: any, index: any) =>
                                                   <>
                                                      {
                                                         <tr key={index} onClick={() => { onOpenModal(val) }}>
                                                            <td style={{ textAlign: 'center' }}>
                                                               <p>{val.symbol.toUpperCase()}</p>
                                                            </td>
                                                            <td>
                                                               <p style={{color:val.amount > 0 ?'green':'red'}}>{val.amount.toFixed(3)}</p>
                                                            </td>
                                                            <td>
                                                               {val.remark}
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>{dateFormat(val.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                                         </tr>
                                                      }
                                                   </>
                                                )
                                                }
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <div className="update_trending_coin_tabs">
                                       <div className="tabs_content active">
                                          <div className="content_inner_data">
                                             {records > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={1}
                                                pageCount={records / 10}
                                                previousLabel="<"
                                             />}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {buybtntrade && (
                           <div className="market buy_sell_btn s-primary-bg">
                              <button className="t-primary-color f-primary-bg">Trade</button>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Modal open={open} onClose={onCloseModal} classNames={{
            overlay: 'customOverlay',
            modal: 'customModal__Sas',
         }} center  >
            <div className="card-header" >
               <h3>Income Details</h3>
            </div>
            <div id="Modal__details">
               <div className="modal-body">
                  {RecordModal.blockNumber ? <div className="column__1">
                     <p> Reference ID </p>
                     <p> {RecordModal?.blockNumber}</p>
                  </div> : null}

                  <div className="column__1">
                     <p> Amount </p>
                     <p> {(RecordModal.amount.toFixed(3)) + ' ' + RecordModal.symbol.toUpperCase()}</p>
                  </div>
                  <div className="column__1">
                     <p> Type</p>
                     <p> {RecordModal.type.replace("_", ' ').charAt(0).toUpperCase() + RecordModal.type.replace("_", ' ').slice(1)}</p>
                  </div>
                  <div className="column__1">
                     <p> Network</p>
                     <p> {RecordModal.chain.toUpperCase()}</p>
                  </div>
                  {RecordModal.blockchain_transaction ?
                     <div className="column__1">
                        <p className="Transaction-fees" > Transaction Fee<br /><small>Incl, of all application taxes</small> </p>
                        <p>{RecordModal.gas}</p>
                     </div>
                     : null}

                  {RecordModal.blockchain_transaction ?

                     <div className="column__1">
                        <p> Transaction ID</p>
                        <p className="Transaction_tokan" > {RecordModal.hash}</p>
                        <a className="copy-btn" title="copy" onClick={() => copyToClipboardId(RecordModal.hash)}>  <ContentCopyIcon />  </a>

                     </div>
                     : null}
                  <div className="column__1">
                     <p> Remark </p>
                     <p className="Transaction_tokan" > {RecordModal.remark.toUpperCase()}</p>
                  </div>
                  <div className="column__1">
                     <p> Date & Time</p>
                     <p><span className="date" style={{ display: 'flex' }}>  {dateFormat(RecordModal.created_at, "UTC:dd-mm-yyyy TT hh:mm:ss")} </span><span className="time"></span></p>
                  </div>
               </div>
               {RecordModal.blockchain_transaction ? <div className="links">
                  <p> <a href={RecordModal.blockchain_transaction} target='_blank'>View More Blockchain Explorer <LaunchIcon /></a> </p>
               </div>
                  : null}
            </div>
         </Modal>
      </>
   );
};

export default IncomeReports;