import { useEffect, useState } from "react";
import _fetch from "../config/api";
import 'react-tabs/style/react-tabs.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useParams } from "react-router";
import { api_url, title } from "../config/config";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toasted from "../config/toast";
import { ColorRing } from "react-loader-spinner";





const WithdrawalAddress = (props: any) => {

  // onOpenModalFrom
  const { coin_id } = useParams();
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [chain, SetChain] = useState([{}]);
  const [coinDetail, SetCoinDetail] = useState({ 'total_coin': 0, 'min_withdraw_limit': '0', 'coin_id': '', 'symbol': '', 'max_withdraw_limit': 0, 'withdraw_status': true });
  const [amount, SetAmount] = useState(0);
  const [walletAddress, SetWalletAddress] = useState();
  const [networkName, SetNetworkName] = useState("");
  const [remark, SetRemark] = useState();
  const [acceptWithraw, SetAcceptWithraw] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);
  const [multiWalletAddress, SetMultiWalletAddress] = useState([]);
  const [openFrom, setOpenFrom] = useState(false);
  const onOpenModalFrom = () => setOpenFrom(true);
  const onCloseModalFrom = () => setOpenFrom(false);
  async function CoinInfo() {
    var d = await _fetch(`${api_url}/markets/getElpSingleMarketByType?symbol=${coin_id?.toLowerCase()}&type=main_account`, 'GET', {}, {});
    // console.log('d',d);

    if (d?.status === 'success') {
      // console.log('d',d);

      var bep20 = d?.market[0].bep20.status
      var erc20 = d?.market[0].erc20.status
      var trc20 = d?.market[0].trc20.status
      var gtc20 = d?.market[0]?.gtc20?.status
      // console.log('d',d);
      
      if (bep20 === true) {
        SetNetworkName('BEP20');
        WalletInfo('BEP20')
      } else if (erc20 === true) {
        SetNetworkName('ERC20');
        WalletInfo('ERC20')

      } else if (trc20 === true) {
        SetNetworkName('TRC20');
        WalletInfo('TRC20')
      }else if (gtc20 === true) {
        SetNetworkName('GTC20');
        WalletInfo('GTC20')
      }
      SetChain([{ 'status': bep20, 'name': 'bep20' }, { 'status': erc20, 'name': 'erc20' }, { 'status': trc20, 'name': 'trc20' },{ 'status': gtc20, 'name': 'gtc20' }]);
      SetCoinDetail(d?.market[0])
    }
  }
  const [getSecrityOtp, setgetSecrityOtp] = useState();

  async function WalletInfo(data: any) {
    var wInfo = await _fetch(`${api_url}/withdraw/getWallets?network=${data}`, 'GET', {}, {});
    if (wInfo?.status === 'success') {
      SetMultiWalletAddress(wInfo.data.data);
    }
  }
  useEffect(() => {
		document.title = coin_id?.toLocaleUpperCase() + ` `+props.title ;

    CoinInfo()
    getUserInfo();

  }, [])
  const [userInfo, setUserInfo] = useState({ 'phone': '', 'security_type': '', 'email': '' });
  async function getUserInfo() {
    var d = await _fetch(`${api_url}/user/userInfo`, 'GET', {}, {});
    if (d?.status === 'success') {
      // console.log(d)
      setUserInfo(d?.data?.data)

    }
  }
  const [timer, settimer] = useState('00:00')
  const [otpBtn, setotpBtn] = useState('')

  async function getphone2faOtp(val: any) {


    var d = await _fetch(`${api_url}/auth/generateOTP?check=2fa authentication&email_type=2fa authentication`, 'GET', {}, {});
    if (d?.status === 'success') {

      toasted.success(d.message);
      // console.log(d)
      setotpBtn('resend')
      if (timer === "00:00") {
        var seconds: any = '180';
        var intervalId = window.setInterval(function () {
          seconds--;

          // if (seconds > 1) {
          let minutes: any = Math.floor(seconds / 60);
          let extraSeconds: any = seconds % 60;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
          var leftData = minutes + ":" + extraSeconds;

          settimer(leftData);
          if (seconds == 0) {
            stop();

          }

        }, 1000);
      }
      let stop = () => clearInterval(intervalId);

    }
  }
  const fixAmount = (e: any) => {
    if (e.target.value > 0) {
      const float = Number(parseFloat(e.target.value).toFixed(3));
      SetAmount(float);
    }else{
      SetAmount(e.target.value)
    }
  }

  const [createAddress, SetCreateAddress] = useState()
  const [createLabel, SetCreateLabel] = useState()
  const [createAddressChain, SetCreateAddressChain] = useState()
  const [createUniversalStatus, SetCreateUniversalStatus] = useState(false)
  const onSubmitAddAddress = async (e: any) => {
    setbtnDisable(true)
    e.preventDefault();
    var data = {
      'wallet_address': createAddress,
      'network': createAddressChain,
      'label': createLabel,
      'universal_status': createUniversalStatus,
    };
    let d = await _fetch(`${api_url}/withdraw/addWallet`, 'POST', data, {});
    if (d?.status === 'success') {
      toasted.success(d?.message);
      onCloseModalFrom()
      WalletInfo(data.network);
      setbtnDisable(false);
    } else {
      setbtnDisable(false);
      toasted.error(d?.message);
    }
  }

  const copyToClipboardId = (data: any) => {
    navigator.clipboard.writeText(data);
    toasted.success('Wallet Address Copied Successfully!');

  }
  const SubmitWithdrawrequest = async (e: any) => {
    setbtnDisable(true);
    e.preventDefault();
    var data = {
      "amount": amount,
      "wallet_address": walletAddress,
      "network": networkName,
      "chain_id": coinDetail?.coin_id,
      "symbol": coinDetail?.symbol,
      "user_remark": remark,
      "otp": getSecrityOtp,
    };
    let d = await _fetch(`${api_url}/withdraw/withdrawRequest`, 'POST', data, {});
    if (d?.status === 'success') {
      toasted.success(d?.message);
      setbtnDisable(false);
    } else {
      toasted.error(d?.message);
      setbtnDisable(false);
    }
  }
  // console.log('coinDetail.withdraw_status',coinDetail.withdraw_status);
  
  return (
    <>
      <div className="Main__box margin_b box__design">
        <div className="container-withdrawal">
          <div className="withdrawal_address_1">
            <div className="card-header">
              <p>Withdraw {coin_id?.toUpperCase()} </p>
            </div>
            {coinDetail.withdraw_status === false ? <div className="info_bar"><h6>Withdraw have been currently not open.</h6></div> : ""}
            {coinDetail.withdraw_status === true &&
              <div className="card-body">
                <form onSubmit={SubmitWithdrawrequest}>
                  <div className="form-group">
                    <label htmlFor="inputa"> Destination address</label>
                    <div className="input_area__group">
                      <input type="text" className="with-form-btn" placeholder="Enter Destination address" value={walletAddress} onChange={(e: any) => SetWalletAddress(e.target.value)} />
                      <span className="btn_input-a" onClick={onOpenModal} >   saved address</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputa"> Network</label>
                    <select className="form-control1 user_none" name="address" id="address" onChange={(e: any) => { SetNetworkName(e.target.value); WalletInfo(e.target.value) }}>
                      {
                        chain?.map((val: any) => {
                          if (val?.status === true) {
                            return (
                              <option value={val?.name.toUpperCase()} selected={networkName === val?.name.toUpperCase() ?true :false }>{val?.name.toUpperCase()}</option>
                            )
                          }
                        })
                      }
                    </select>
                  </div>
                  <div className="form-group">
                    <div> <label >Available {coin_id}: {coinDetail?.total_coin?.toFixed(3)}</label></div>
                    <label htmlFor="inputa"> {coin_id} Amount</label>
                    <div className="input_area__group">
                      <input type="number" className="with-form" value={amount} onChange={fixAmount} placeholder={`minimum : ${coinDetail?.min_withdraw_limit + ' ' + coin_id}`} />
                      <span className="btn_input-a" onClick={() => { SetAmount(coinDetail?.total_coin > 0.05 ?(Number(coinDetail?.total_coin?.toFixed(3)) - 0.05) :0) }} >   Withdraw all</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputa"> Remark</label>
                    <input type="text" className="with-form" placeholder="Enter Remark" value={remark} onChange={(e: any) => { SetRemark(e.target.value) }} />
                  </div>

                  {userInfo.security_type === '2fa' &&
                    <div className="form-group">
                      <label> OTP </label>
                      <input type="text" onChange={(e: any) => setgetSecrityOtp(e.target.value)} value={getSecrityOtp} className="form-control" placeholder="Enter your google authenticator OTP" />
                    </div>}

                  {userInfo.security_type === 'other' && <div className="form-group">
                    <label> Verify OTP</label>
                    <label >{timer !== '00:00' ? 'OTP Expire in: ' + timer : ''}</label>
                    <div className="otp_section">
                      <input type="number" onChange={(e: any) => setgetSecrityOtp(e.target.value)} value={getSecrityOtp} className="form-control" placeholder="OTP" />
                      {/* <div className="" */}
                      <button type="button" className="otp-btn" onClick={() => { getphone2faOtp(false) }} disabled={timer !== '00:00' ? true : false} >{otpBtn === 'resend' ? 'Resend OTP':'Send OTP'}</button>
                    </div>
                  </div>}
                  <div className="form-group">
                    <div className="form-group_s">
                      <input type="checkbox" id="chekc-1" onChange={(e: any) => { SetAcceptWithraw(e.target.checked) }} />
                      <label className="label__d" htmlFor="chekc-1">  i hereby confirm that i am withdrawing these crypto assets either ti my own crypto wallet or to a third party indian resident crypto wallet without any consideration in return</label>
                    </div>
                  </div>
                  <button type="submit" className="trade_btn trade_btn_with" disabled={acceptWithraw === true ? false : true}>
                    {btnDisable === true &&
                      <div className="color-ring">
                        <ColorRing
                          visible={true}
                          height="20"
                          width="20"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                        Please Wait...
                      </div>
                    }
                    {btnDisable === false ? 'Proceed with withdrawal' : ''}                  </button>

                  
                </form>
              </div>
            }
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center
        classNames={{
          overlay: 'withdralOverlay',
          modal: 'withdralModal'
        }}>
        <h4>Select Address</h4>
        <div className="card-address">
          <div className="new_address">
            <p className="address-title">Saved Address</p>
            <form>
              <div className="secroll-data">
                {multiWalletAddress?.map((val: any) => {
                  return (
                    <div className="form-check">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" id="sd" onChange={(e: any) => { SetWalletAddress(e.target.value); onCloseModal() }} name="chekcbox-1" value={val?.wallet_address} /><span>{val.label == '' ? val?.wallet_address : val.label}</span>
                      </label>
                      <div className="copy-btn-div" onClick={() => copyToClipboardId(val?.wallet_address)}>
                        <a className="copy-btn" title="copy">  <ContentCopyIcon />  </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            </form>
          </div>
          <div className="card-search-icon">
            <SearchOffIcon />
          </div>
          <p> No saved {coin_id?.toLocaleUpperCase()} Address found</p>
          <span className="trade_btn active" onClick={() => { onOpenModalFrom(); onCloseModal() }} > ADD {coin_id?.toLocaleUpperCase()} ADDRESS</span>
        </div>
      </Modal>
      
      <Modal open={openFrom} onClose={onCloseModalFrom} center
        classNames={{
          overlay: 'withdralOverlay',
          modal: 'withdralModal'
        }}>
        <h4>Add {coin_id?.toLocaleUpperCase()} Address</h4>
        <div className="card-form-add">
          <h5>Please ensure to select the network that matches the destination address to  avoid loss of funds.</h5>
          <form onSubmit={onSubmitAddAddress}>
            <div className="form-group">
              <label htmlFor="inputa"> Destination address</label>
              <input type="text" className="with-form-btn" value={createAddress} onChange={(e: any) => { SetCreateAddress(e.target.value) }} placeholder="Paste Address here" />
            </div>

            <div className="form-group">
              <label htmlFor="inputa"> Network</label>
              <select className="form-control1 user_none" name="address" id="address" onChange={(e: any) => { SetCreateAddressChain(e.target.value) }} >
                <option>Select Network</option>
                {
                  chain?.map((val: any) => {
                    if (val?.status === true) {
                      return (
                        <option value={val?.name.toUpperCase()}>{val?.name.toUpperCase()}</option>
                      )
                    }
                  })
                }

              </select>
            </div>
            <div className="form-group">
              <label htmlFor="inputa"> label</label>
              <input type="text" value={createLabel} onChange={(e: any) => { SetCreateLabel(e.target.value) }} className="with-form" placeholder="e.g. john (4-20 characters)" />
            </div>
            <div className="form-group">
              <div className="form-group_s">
                <input type="checkbox" id="set-value1" onClick={(e: any) => { SetCreateUniversalStatus(e.target.checked) }} />
                <label className="label__d" htmlFor="set-value1">set as universal, not as coin-specific.</label>
              </div>
            </div>
            <button type="submit" className="trade_btn trade_btn_with" disabled={btnDisable}>
              {btnDisable === true &&
                <div className="color-ring">
                  <ColorRing
                    visible={true}
                    height="20"
                    width="20"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                  />
                  Please Wait...
                </div>
              }
              {btnDisable === false ? 'Proceed with withdrawal' : ''}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default WithdrawalAddress;