import React, { useState } from "react";
import { Link } from "react-router-dom";
import CommonCoinData from "../Snippets/Update-trade-coin"

const tradeData = [
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        CoinShortName: "Bitcoin",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        CoinShortName: "Bitcoin",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        CoinShortName: "Bitcoin",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        CoinShortName: "Bitcoin",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        CoinShortName: "Bitcoin",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        CoinShortName: "Bitcoin",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        CoinShortName: "Bitcoin",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        CoinShortName: "Bitcoin",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        CoinName: "BTC",
        CoinShortName: "Bitcoin",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        CoinShortName: "Bitcoin",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",

        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
    {
        CoinIcon: "coin-icon",
        CoinName: "BTC",
        CoinShortName: "Bitcoin",
        perioddata: "Redemption Period",
        daydata: "3 Day",
        Lastupdateprice: "0.0066231",
        Priceh_changes: "Flexible",
        TradeIcon: "",
        BuyCoin: "Buy",
        TradeCoin: "Subscribe",
        quotaprice: "57.87%",
        quotainnerprice: "104,171,577.53210815",
        quotacoinprice: "/ 180,000,000 FCON",
        status: "In Progress",
        endin: "58d 3h 57m"
    },
]

const CoinTrade = () => {
    const [tab, setTab] = useState("Trending");
    const [taasfab, setTabtaasfab] = useState("Trending Coins");
    const [Dropdown, setDropdown] = useState(false);
    const OpenDropdown = () => {
        setDropdown(!Dropdown)
    }
    const [buybtntrade, setbuybtntrade] = useState(false);
    var Btntoggle = () => {
        setbuybtntrade(!buybtntrade)
    }
    return (
        <>
            <section className="sec_coin_trade_data sec_popular_coin sec-py s-secondary-bg">
                <div className="container">
                    <div className="sec_content">
                        <h2 className="sec_heading">
                            Limited Offers, Popular Coins.
                        </h2>
                        <div className="d_view_more_btn view_more_btn">
                            <Link to="/" className="t-normal-color">View More<span><svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5L6 6.5L1 11.5" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span></Link>
                        </div>
                    </div>
                    <div className="tabs_button">
                        <div className="d_tabs_btn_dropdown">
                            <button className={`tab_btn ${tab === "Trending" ? 'active' : ""}`} onClick={() => { setTab("Trending") }} >
                                Trending Coins
                            </button>
                            <button className={`tab_btn ${tab === "Coins" ? 'active' : ""}`} onClick={() => { setTab("Coins") }} >
                                New Coins
                            </button>
                            <button className={`tab_btn ${tab === "Gainers" ? 'active' : ""}`} onClick={() => { setTab("Gainers") }} >
                                Top Gainers
                            </button>
                        </div>
                        <div className="m_tabs_btn_dropdown">
                            <button className="tab_btn " onClick={OpenDropdown} >
                                {taasfab}
                                <span><img src={require("../Assets/Icons/arrow-icon-dropwdown.svg").default} alt="" /> </span>
                            </button>

                            {Dropdown && (
                                <>
                                    <div>
                                        <button className={`tab_btn ${tab === "Trending" ? 'active' : ""}`} onClick={() => { setTab("Trending"); setTabtaasfab("Trending Coins"); OpenDropdown(); }}  >
                                            Trending Coins
                                        </button>
                                        <button className={`tab_btn ${tab === "Coins" ? 'active' : ""}`} onClick={() => { setTab("Coins"); setTabtaasfab("New Coins"); OpenDropdown(); }} >
                                            New Coins
                                        </button>
                                        <button className={`tab_btn ${tab === "Gainers" ? 'active' : ""}`} onClick={() => { setTab("Gainers"); setTabtaasfab("Top Gainers"); OpenDropdown(); }} >
                                            Top Gainers
                                        </button>
                                    </div>
                                    <span onClick={OpenDropdown} className="dropdown_bg_layer">
                                    </span>
                                </>
                            )}
                        </div>
                        <div className="m_view_more_btn view_more_btn">
                            <Link to="/" className="t-normal-color">View More<span><svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5L6 6.5L1 11.5" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span></Link>
                        </div>

                    </div>
                    <span className="tabs__bottom_line" >
                    </span>
                    <div className="popular_coin_tabs">
                        <div className="content_heading">
                            <ul>
                                <li className="t-normal-color">
                                    Coin/Token
                                </li>
                                <li className="t-normal-color">
                                    Est. APR
                                </li>
                                <li className="t-normal-color">
                                    Type
                                </li>
                                <li className="t-normal-color">
                                    Time
                                </li>
                                <li className="t-normal-color quota_data">
                                    Quota
                                </li>
                                <li className="t-normal-color">
                                    Status
                                </li>
                                <li className="t-normal-color">
                                    End In
                                </li>
                                <li className="t-normal-color">
                                    Operation
                                </li>
                            </ul>
                        </div>
                        {/* Tabs */}
                        {
                            (tab === "Trending") &&
                            <div className="update_trending_coin_tabs">
                                <div className="tabs_content active">
                                    <div className="content_inner_data">
                                        <ul className="coins_price">
                                            {tradeData.map(function (val: any, index: any) {
                                                return (
                                                    <CommonCoinData
                                                        key={index}
                                                        marketdata={false}
                                                        ClickEvent={Btntoggle}
                                                        CoinIcon={val.CoinIcon}
                                                        perioddata={val.perioddata}
                                                        daydata={val.daydata}
                                                        CoinName={val.CoinName}
                                                        CoinShortName={val.CoinShortName}
                                                        Lastupdateprice={val.Lastupdateprice}
                                                        Priceh_changes={val.Priceh_changes}
                                                        TradeIcon={val.TradeIcon}
                                                        BuyCoin={val.BuyCoin}
                                                        TradeCoin={val.TradeCoin}
                                                        quotaprice={val.quotaprice}
                                                        quotainnerprice={val.quotainnerprice}
                                                        quotacoinprice={val.quotacoinprice}
                                                        status={val.status}
                                                        endin={val.endin}

                                                    />
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (tab === "Coins") &&
                            <div className="update_trending_coin_tabs">
                                <div className="tabs_content active">
                                    <div className="content_inner_data">
                                        <ul className="coins_price">
                                            {tradeData.map(function (val, index) {
                                                return (
                                                    <>
                                                        <CommonCoinData
                                                            key={index}
                                                            marketdata={false}
                                                            ClickEvent={Btntoggle}
                                                            perioddata={val.perioddata}
                                                            daydata={val.daydata}
                                                            CoinIcon={val.CoinIcon}
                                                            CoinName={val.CoinName}
                                                            CoinShortName={val.CoinShortName}
                                                            Lastupdateprice={val.Lastupdateprice}
                                                            Priceh_changes={val.Priceh_changes}
                                                            TradeIcon={val.TradeIcon}
                                                            BuyCoin={val.BuyCoin}
                                                            TradeCoin={val.TradeCoin}
                                                            quotaprice={val.quotaprice}
                                                            quotainnerprice={val.quotainnerprice}
                                                            quotacoinprice={val.quotacoinprice}
                                                            status={val.status}
                                                            endin={val.endin}
                                                        />
                                                    </>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (tab === "Gainers") &&
                            <div className="update_trending_coin_tabs">
                                <div className="tabs_content active">
                                    <div className="content_inner_data">
                                        <ul className="coins_price">
                                            {tradeData.map(function (val, index) {
                                                return (
                                                    <>
                                                        <CommonCoinData
                                                            key={index}
                                                            marketdata={false}
                                                            ClickEvent={Btntoggle}
                                                            perioddata={val.perioddata}
                                                            daydata={val.daydata}
                                                            CoinIcon={val.CoinIcon}
                                                            CoinName={val.CoinName}
                                                            CoinShortName={val.CoinShortName}
                                                            Lastupdateprice={val.Lastupdateprice}
                                                            Priceh_changes={val.Priceh_changes}
                                                            BuyCoin={val.BuyCoin}
                                                            TradeIcon={val.TradeIcon}
                                                            TradeCoin={val.TradeCoin}
                                                            quotaprice={val.quotaprice}
                                                            quotainnerprice={val.quotainnerprice}
                                                            quotacoinprice={val.quotacoinprice}
                                                            status={val.status}
                                                            endin={val.endin}
                                                        />
                                                    </>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {
                        buybtntrade && (<div className="buy_sell_btn s-primary-bg">
                            <button className="t-primary-color f-primary-bg">
                                Buy
                            </button>
                            <button className="t-primary-color f-primary-bg">
                                Trade
                            </button>
                        </div>
                        )
                    }
                    <div className="btn_bg_layer" onClick={Btntoggle}>
                    </div>
                </div>
            </section>
        </>
    );
}


export default CoinTrade;