import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { title } from '../config/config'
import banner__sec from '../Assets/Images/Footer_IMG/banner-logo.svg'
import banner_business from '../Assets/Images/Footer_IMG/business.svg'
import Discounted_IMG from '../Assets/Images/Footer_IMG/equity1.svg'

const Vipservice = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
  return (
    <>
        <div className='vip__service pt__spacing'>
            <div className='vip_banner'>
                <div className='vip__container'>
                    <div className='row'>
                        <div className='col-6'>
                            <h1>Institutional Services</h1>
                            <p>{title} offers bespoke services to institutions and professional traders</p>
                            <Link to="/" className='btn' > Contact Us</Link>
                        </div>
                        <div className='col-6'>
                            <img src={banner__sec} alt='icon' width="100%" />
                        </div>
                    </div>
                </div>
            </div>


            <div className='Services__vip'>
                <div className='vip__container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1 className='card__sec_heading' >Scope of Services</h1>
                        </div>

                        <div className='col-4'>
                            <div className='card'>
                                <img src={banner_business} alt="icon"/>

                                <h4>Institution Account KYC</h4>
                                <p> {title} offers institution verification for companies</p>

                                <Link to="/" className='btn'> Apply </Link>
                            </div>
                        </div>

                        <div className='col-4'>
                            <div className='card'>
                                <img src={banner_business} alt="icon"/>

                                <h4>API trading</h4>
                                <p> {title} offers institution verification for companies</p>

                                <Link to="/" className='btn'> Apply </Link>
                            </div>
                        </div>



                        <div className='col-4'>
                            <div className='card'>
                                <img src={banner_business} alt="icon"/>

                                <h4>Institution Account KYC</h4>
                                <p> {title} offers institution verification for companies</p>

                                <Link to="/" className='btn'> Apply </Link>
                            </div>
                        </div>


                        <div className='col-4'>
                            <div className='card'>
                                <img src={banner_business} alt="icon"/>

                                <h4>Institution Account KYC</h4>
                                <p> {title} offers institution verification for companies</p>

                                <Link to="/" className='btn'> Apply </Link>
                            </div>
                        </div>


                        <div className='col-4'>
                            <div className='card'>
                                <img src={banner_business} alt="icon"/>

                                <h4>Institution Account KYC</h4>
                                <p> {title} offers institution verification for companies</p>

                                <Link to="/" className='btn'> Apply </Link>
                            </div>
                        </div>


                        <div className='col-4'>
                            <div className='card'>
                                <img src={banner_business} alt="icon"/>

                                <h4>Institution Account KYC</h4>
                                <p> {title} offers institution verification for companies</p>

                                <Link to="/" className='btn'> Apply </Link>
                            </div>
                        </div>


                        <div className='col-4'>
                            <div className='card'>
                                <img src={banner_business} alt="icon"/>

                                <h4>Institution Account KYC</h4>
                                <p> {title} offers institution verification for companies</p>

                                <Link to="/" className='btn'> Apply </Link>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </div>


            <div className='full__vip'>
                <div className='vip__container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1>Advantages and Privileges</h1>
                        </div>
                        <div className='col-4'>
                            <div className='card'>
                                <img src={Discounted_IMG} alt="icon"/>
                                <div className='card__full_data'>
                                    <h4>Discounted Fees for VIP</h4>
                                    <p> Discounted fees in the first two months</p>
                                </div>

                            </div>
                        </div>

                        <div className='col-4'>
                            <div className='card'>
                                <img src={Discounted_IMG} alt="icon"/>
                                <div className='card__full_data'>
                                    <h4>Exclusive Withdrawal Limit </h4>
                                    <p> Customized daily withdrawal limit</p>
                                </div>

                            </div>
                        </div>


                        <div className='col-4'>
                            <div className='card'>
                                <img src={Discounted_IMG} alt="icon"/>
                                <div className='card__full_data'>
                                    <h4>Tailored Sub-Account Service</h4>
                                    <p> Add additional sub-accounts and create demo sub-accounts</p>
                                </div>

                            </div>
                        </div>


                        <div className='col-4'>
                            <div className='card'>
                                <img src={Discounted_IMG} alt="icon"/>
                                <div className='card__full_data'>
                                    <h4>Professional Trading Experience</h4>
                                    <p>Increased order frequency and technical support through API rate limits</p>
                                    <p>Reduced transaction latency and improved transaction efficiency</p>
                                </div>

                            </div>
                        </div>


                        <div className='col-4'>
                            <div className='card'>
                                <img src={Discounted_IMG} alt="icon"/>
                                <div className='card__full_data'>
                                    <h4>Professional Customer Support</h4>
                                    <p>  One-on-one account manager</p>
                                    <p> Global customer support team providing services 24/7</p>
                                </div>

                            </div>
                        </div>

                        <div className='col-4'>
                            <div className='card'>
                                <img src={Discounted_IMG} alt="icon"/>
                                <div className='card__full_data'>
                                    <h4>More to come</h4>
                                    <p> Stay tuned......</p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='last__vip'>
                <div className='card'>
                    <h1>Become an institutional client of {title}</h1>
                    <button type='button' className='btn'>Contact Us</button>
                </div>
            </div>
            
        </div>
    </>
  )
}

export default Vipservice