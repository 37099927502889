import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';




const RewardSlider = () => {
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };



    const items = [

        <div className='first_card'>
            <div className="top-img">
            </div>
            <div className="info">
                <h2>  How to start my crypto journey on Bitget？  </h2>
            </div>
        </div>
        ,

        <div className='first_card'>
            <div className="top-img">
            </div>
            <div className="info">
                <h2>How to buy my first crypto？</h2>
            </div>
        </div>
        ,

        <div className='first_card'>
            <div className="top-img">
            </div>
            <div className="info">
                <h2>  How to trade in the Spot Market？  </h2>
            </div>
        </div>
        ,

        <div className='first_card'>
            <div className="top-img">
            </div>
            <div className="info">
                <h2>  How to trade in the Futures Market？</h2>
            </div>
        </div>
        ,

        <div className='first_card'>
            <div className="top-img">
            </div>
            <div className="info">
                <h2> How to get started with Copy Trade？ </h2>
            </div>
        </div>
        ,

    ];
    return (
        <>
            <AliceCarousel
                mouseTracking
                infinite={true}
                autoPlayInterval={1000}
                disableDotsControls
                animationDuration={1500}
                alice-carousel__next-btn={true}
                alice-carousel__play-btn={true}
                responsive={responsive}
                autoPlay={true}
                items={items}
            />
        </>
    )
}

export default RewardSlider
