import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CommonCoinData from "../Snippets/Update-trade-coin"
import { _fetch } from "../config/api";
import { markit_url } from "../config/config";
// import { useState, useEffect, useCallback } from "react";
// var d = await _fetch('https://reqres.in/api/users?page=2', 'GET', 'a');
import ReactPaginate from 'react-paginate';



// const tradeData = [
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
//     {
//         CoinIcon: "coin-icon",
//         CoinName: "BTC",
//         CoinShortName: "Bitcoin",
//         Lastupdateprice: "$43795.4",
//         Priceh_changes: "+0.66%",
//         TradeIcon: "trade-icon",
//         BuyCoin: "Buy",
//         TradeCoin: "Trade",
//     },
// ]

const CoinTrade = () => {
    const [tab, setTab] = useState("Trending");
    const [taasfab, setTabtaasfab] = useState("Trending Coins");
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(1);
    const [records, setrecords] = useState(0);
    const [short, setshort] = useState('market_cap_rank');




    const [Dropdown, setDropdown] = useState(false);
    const OpenDropdown = () => {
        setDropdown(!Dropdown)
    }
    const [buybtntrade, setbuybtntrade] = useState(false);
    var Btntoggle = () => {
        setbuybtntrade(!buybtntrade)
    }

    const [tradeData, setdata] = useState([]);
    useEffect(() => {
        (async () => {
            const passData = {
                currency: 'USD',
                limit: 10,
                page: 1,
                // fields:'_id'
            }
            if(tab == 'Coins'){
                var newSrch = '&token_type="manual"';
             }else{
                var  newSrch = '';
            }
            const data1 = await _fetch(`${markit_url}/market/getAllMarket?currency=USD&limit=10&sort=${short}&page=${itemOffset}${newSrch}`, 'GET', passData, {});
            // const dataaa = await JSON.stringify(data1);

            setdata(data1.data.data);
            setrecords(data1.total)

        })()
        setPageCount(Math.ceil(records / 10));
    }, [itemOffset,records,short,tab]);
    // if (typeof tradeData === "undefined") {
    //     tradeData = [];
    // }
    const handlePageClick = async (event: any) => {

        const newOffset = (event.selected + 1);

        setItemOffset(newOffset);
    };
    const onSearch = async (e: any) => {
        const passData = {
            currency: 'USD',
            limit: 10,
            page: 1,
            // fields:'_id'
        }
        const data1 = await _fetch(`${markit_url}/market/getAllMarket?currency=USD&limit=10&search=symbol&value=${e}`, 'GET', passData, {});
        // const dataaa = await JSON.stringify(data1);

        setdata(data1.data.data);
    }

    return (
        <>
            <section className="sec_coin_trade_data sec-py s-secondary-bg">
                <div className="container">
                    <div className="tabs_button">
                        <div className="d_tabs_btn_dropdown">
                            <button className={`tab_btn ${tab === "Trending" ? 'active' : ""}`} onClick={() => { setTab("Trending") }} >
                                Trending Coins
                            </button>
                            <button className={`tab_btn ${tab === "Coins" ? 'active' : ""}`} onClick={() => { setTab("Coins") }} >
                                New Coins
                            </button>
                            <button className={`tab_btn ${tab === "Gainers" ? 'active' : ""}`} onClick={() => { setshort('-price_change_percentage_24h');setTab("Gainers"); }} >
                                Top Gainers
                            </button>
                        </div>
                        {/* <div className="d_tabs_btn_dropdown">
                            <form action="">
                                <label htmlFor="searchcoin">
                                    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.7">
                                            <circle cx="8.71795" cy="8.71795" r="7.96795" stroke="#636F7D" strokeWidth="1.5" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.0201 13.9795C14.2844 13.7152 14.2348 13.2367 14.4991 13.501L20.8014 19.8457C21.0657 20.1099 21.0657 20.5384 20.8014 20.8027C20.5371 21.067 20.1086 21.067 19.8443 20.8027L13.542 14.458C13.2777 14.1938 13.7558 14.2438 14.0201 13.9795Z" fill="#636F7D" />
                                            <rect opacity="0.04" x="6.35" y="4.35" width="6.3" height="6.3" rx="3.15" stroke="#636F7D" strokeWidth="0.7" />
                                        </g>
                                    </svg>
                                </label>
                                <input id="searchcoin" type="text" placeholder="Search" onChange={(e:any) => {onSearch(e.target.value)}} />
                            </form>
                                                  
                        </div> */}

                        <div className="main_market_tabs main_market_tabs_content search_input">
                            <form action="">
                                <label htmlFor="searchcoin">
                                    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.7">
                                            <circle cx="8.71795" cy="8.71795" r="7.96795" stroke="#636F7D" strokeWidth="1.5" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.0201 13.9795C14.2844 13.7152 14.2348 13.2367 14.4991 13.501L20.8014 19.8457C21.0657 20.1099 21.0657 20.5384 20.8014 20.8027C20.5371 21.067 20.1086 21.067 19.8443 20.8027L13.542 14.458C13.2777 14.1938 13.7558 14.2438 14.0201 13.9795Z" fill="#636F7D" />
                                            <rect opacity="0.04" x="6.35" y="4.35" width="6.3" height="6.3" rx="3.15" stroke="#636F7D" strokeWidth="0.7" />
                                        </g>
                                    </svg>
                                </label>
                                <input id="searchcoin" type="text" placeholder="Search" onChange={(e: any) => { onSearch(e.target.value) }} />
                            </form>
                            <button className="info_btn">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6024 16.9094C13.3571 16.9323 13.1107 16.8769 12.8988 16.7512C12.7439 16.5922 12.6696 16.3715 12.697 16.1512C12.7027 15.9679 12.7246 15.7853 12.7624 15.6058C12.799 15.3998 12.8463 15.1959 12.9042 14.9949L13.5478 12.7803C13.6142 12.5617 13.6581 12.3369 13.6788 12.1094C13.6788 11.8639 13.7115 11.6948 13.7115 11.5967C13.7252 11.1592 13.5384 10.7394 13.2042 10.4567C12.7932 10.1411 12.2813 9.986 11.7642 10.0203C11.3937 10.0259 11.026 10.0865 10.6733 10.2003C10.2878 10.3203 9.88236 10.4639 9.45692 10.6312L9.27148 11.3512C9.39692 11.3076 9.54969 11.2585 9.7242 11.2039C9.8907 11.1546 10.0633 11.1289 10.2369 11.1276C10.4805 11.1012 10.7256 11.161 10.9296 11.2967C11.0682 11.462 11.1332 11.6768 11.1096 11.8912C11.109 12.0747 11.0889 12.2575 11.0496 12.4367C11.0114 12.6276 10.9624 12.8294 10.9024 13.0421L10.2533 15.2675C10.201 15.4744 10.1591 15.6837 10.1278 15.8948C10.1023 16.0755 10.0896 16.2578 10.0896 16.4403C10.087 16.8807 10.2884 17.2977 10.6351 17.5693C11.0525 17.8898 11.5716 18.0487 12.0969 18.0166C12.4667 18.0242 12.8353 17.9708 13.1878 17.8584C13.4969 17.7529 13.9096 17.6021 14.426 17.4057L14.6006 16.7184C14.4607 16.7764 14.3166 16.8238 14.1696 16.8602C13.9837 16.9027 13.7929 16.9192 13.6024 16.9094Z" fill="#636F7D" />
                                    <path d="M14.2846 6.40958C13.9877 6.13695 13.5966 5.99028 13.1936 6.0005C12.7909 5.99141 12.4002 6.13794 12.1027 6.40958C11.5575 6.87973 11.4966 7.70291 11.9668 8.2482C12.0087 8.29681 12.0541 8.34223 12.1027 8.38414C12.7239 8.93975 13.6634 8.93975 14.2845 8.38414C14.8298 7.90934 14.8869 7.08247 14.4121 6.53722C14.3726 6.4918 14.33 6.44914 14.2846 6.40958Z" fill="#636F7D" />
                                    <path d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 22.9091C5.97506 22.9091 1.09092 18.0249 1.09092 12C1.09092 5.97506 5.97506 1.09092 12 1.09092C18.0249 1.09092 22.9091 5.97506 22.9091 12C22.9091 18.0249 18.0249 22.9091 12 22.9091Z" fill="#636F7D" />
                                </svg>
                            </button>
                        </div>
                        <div className="m_tabs_btn_dropdown add__search__bar">
                            <button className="tab_btn " onClick={OpenDropdown} >
                                {taasfab}
                                <span><img src={require("../Assets/Icons/arrow-icon-dropwdown.svg").default} alt="" /> </span>
                            </button>
                            {Dropdown && (
                                <>
                                    <div>
                                        <button className={`tab_btn ${tab === "Trending" ? 'active' : ""}`} onClick={() => { setTab("Trending"); setTabtaasfab("Trending Coins"); OpenDropdown(); }}  >
                                            Trending Coins
                                        </button>
                                        <button className={`tab_btn ${tab === "Coins" ? 'active' : ""}`} onClick={() => { setTab("Coins"); setTabtaasfab("New Coins"); OpenDropdown(); }} >
                                            New Coins
                                        </button>
                                        <button className={`tab_btn ${tab === "Gainers" ? 'active' : ""}`} onClick={() => { setTab("Gainers"); setTabtaasfab("Top Gainers"); OpenDropdown(); }} >
                                            Top Gainers
                                        </button>
                                    </div>
                                    <span onClick={OpenDropdown} className="dropdown_bg_layer">
                                    </span>
                                </>
                            )}

                        </div>
                        {/* <div className="view_more_btn ">
                            <Link to="/market" className="t-normal-color">View More<span><svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5L6 6.5L1 11.5" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span></Link>
                        </div> */}
                    </div>
                    <span className="tabs__bottom_line" >
                    </span>
                    <div className="scroll__trade__main_table">
                       <div className="scroll__trade__main_table__width">
                            <div className="content_heading ">
                                    <ul>
                                        <li className="t-normal-color" style={{ cursor: 'pointer' }} onClick={() => { short == 'market_cap_rank' ? setshort('-market_cap_rank') : setshort('market_cap_rank') }}>
                                            Coin
                                        </li>
                                        <li className="t-normal-color" style={{ cursor: 'pointer' }} onClick={() => { short == 'current_price' ? setshort('-current_price') : setshort('current_price') }}>
                                            Last Price
                                        </li>
                                        <li className="t-normal-color" style={{ cursor: 'pointer' }} onClick={() => { short == 'price_change_percentage_24h' ? setshort('-price_change_percentage_24h') : setshort('price_change_percentage_24h') }}>
                                            24H Change
                                        </li>
                                        <li className="t-normal-color">
                                            Markets
                                        </li>
                                        <li className="t-normal-color">
                                            Trade
                                        </li>
                                    </ul>
                                </div>
                                {/* Tabs */}
                                {
                                    // (tab === "Trending") &&
                                    <div className="update_trending_coin_tabs">
                                        <div className="tabs_content active">
                                            <div className="content_inner_data">
                                               <div className="scroll__trade__main_table__width">
                                                        <ul className="coins_price">
                                                                {
                                                                    tradeData != undefined && tradeData != null ? tradeData?.map(function (val: any, index: any) {
                                                                        return (
                                                                            <CommonCoinData
                                                                                marketdata={true}
                                                                                key={index}
                                                                                ClickEvent={Btntoggle}
                                                                                CoinIcon={val.image}
                                                                                CoinName={val.name}
                                                                                CoinShortName={val.symbol}
                                                                                Lastupdateprice={'$' + val.current_price}
                                                                                Priceh_changes={val?.price_change_percentage_24h}
                                                                                TradeIcon={val.sparkline_in_7d[0].price}
                                                                                daydata={''}
                                                                                perioddata={""}
                                                                                BuyCoin={"BUY"}
                                                                                TradeCoin={"TRADE"}
                                                                                quotaprice={""}
                                                                                quotainnerprice={""}
                                                                                quotacoinprice={""}
                                                                                status={""}
                                                                                endin={""}
                                                                            />
                                                                        )
                                                                    }) : ''
                                                                }
                                                        </ul>
                                                </div>
                                                <ReactPaginate className="pagination__2 " activeClassName="active"

                                                    breakLabel="..."
                                                    nextLabel=">"
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={1}
                                                    pageCount={records / 10}
                                                    previousLabel="<"

                                                // renderOnZeroPageCount={null}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                                {
                                    buybtntrade && (<div className="buy_sell_btn s-primary-bg f">
                                        <button className="t-primary-color f-primary-bg">
                                            Buy
                                        </button>
                                        <button className="t-primary-color f-primary-bg">
                                            Trade
                                        </button>
                                    </div>
                                    )
                                }
                       </div>
                    </div>
                    <div className="btn_bg_layer" onClick={Btntoggle}>
                    </div>
                </div>
            </section>
        </>
    );
}


export default CoinTrade;