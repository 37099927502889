import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import { logo, title } from '../config/config';
// import StarsIcon from '@mui/icons-material/Stars';
// import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const HelpCenter_inner = (props:any) => {


    useEffect(() => {
        document.title = props.title;
    }, []);


   const ADD_HELP = (id: any) => {
    const a: HTMLElement | any = document.getElementById(id);
    a.classList.toggle("page__a");
 }
 const remove_HELP = (id: any) => {
    const a: HTMLElement | any = document.getElementById(id);
    a.classList.remove("page__a");
 }



  return (
    <>
        <div className='Helpcenter_section'>
            <div className='a_container'>
                <div className='section__anyone' id='section_body_A_HELP' >
                    <div className='section_header_security'>
                        <p> <Link to="/Dashboard" > Home</Link> /<Link to="/Dashboard" > Security</Link>  </p>
                        <div className='secion___bacn_icon' onClick={() => remove_HELP('section_body_A_HELP')} >  <ArrowBackIcon/> </div>


                        <div className='search__bar_a'>
                            <form>
                                <div className='form_search'>
                                    <SearchIcon/>
                                    <input type="search" className='input__section' placeholder='Search'/>
                                </div>
                            </form>
                        </div>
                    </div>


                    <div className='section_body'>
                        <Tabs>
                            <div className='row' onClick={() => ADD_HELP('section_body_A_HELP')}>
                                <div className='col-4'>
                                    <div className="security-header"  >
                                        <TabList>
                                            <Tab>How to find transaction ID for payments done via IMPS/NEFT/RTGS?</Tab>
                                            <Tab>Withdraw INR from your {title} account - Process & Time taken</Tab>

                                            <Tab>My withdrawal is not processed/stuck/Almost done/Processing</Tab>
                 

                                        </TabList>
                                    </div>
                                </div>


                                <div className='col-8'>
                                    <div className='column__8_security'>
                                        <TabPanel>
                                            <div className='security-card'>
                                                <div className='security-card_header__demo'>
                                                    <h2 className='card__title_security'> 
                                                    Withdraw INR from your {title} account - Process & Time taken</h2>

                                                </div>

                                                    

                                                <div className='logo__security'>
                                                    <img src={logo} alt="logo_icon"/>
                                                    <div className=''>
                                                        <h2>{title}</h2>
                                                        <h6> 5 months ago</h6>
                                                    </div>
                                                </div>

                                                <div className='security__data'>
                                                    <p>NEFT withdrawals are normally settled within a couple of hours during banking hours. Depending on which bank you have an account with, the funds should be credited to your account within 72 hours.
Please try and avoid contacting the support team unless your withdrawal has not been reflected for more than 72 hours.</p>

                                                    <p>No. The withdrawal will only happen to the Bank account that you have linked to your {title} account. The name on the Bank account that you link MUST match the name on your {title} account.</p>
                                                    <h4>Is there a Withdrawal fee and minimum/maximum withdrawal limit?</h4>
                                                    <p>Yes, in order to process the withdrawals near-instantly we use Payment processors to whom the fee is paid</p>

                                                    <div className='inner_tabs__security'>
                                                        <Tabs>
                                                            <div className='card__tabs__header_security'>
                                                                <TabList>
                                                                    <Tab> Desktop</Tab>
                                                                    <Tab> IOS</Tab>
                                                                    <Tab> Anddroid</Tab>
                                                                </TabList>
                                                            </div>
                                                            <div className='card__tabs__body_security'>
                                                                <TabPanel>
                                                                    <ul>
                                                                        <li>Click on Funds in the top bar </li>
                                                                        <li>Under Indian Rupee, Click on the Withdraw button</li>
                                                                        <li>If you have already linked your Bank account, this step will be skipped. If not, Link your bank account by entering your Bank details. (Read more - Adding your Bank Account details to {title}) </li>
                                                                       <li>Enter the Amount & Optional Remarks and tap Withdraw.</li>
                                                                     </ul>
                                                                </TabPanel>

                                                                <TabPanel>
                                                                    <div className='second__data'>
                                                                        <p>coming soon  </p>
                                                                    </div>
                                                                </TabPanel>

                                                                <TabPanel>
                                                                    <div className='second__data'>
                                                                        <p>coming soon  </p>
                                                                    </div>
                                                                </TabPanel>

                                                            </div>
                                                        </Tabs>
                                                    </div>

                                                </div>

                                                <div className='help_full'>
                                                    <p>Was this article helpful?</p>
                                                    <button type='button' className='btn'> <CheckIcon/>  Yes </button>
                                                    <button type='button' className='btn'> <CloseIcon/> No</button>
                                                </div>

                                            </div>
                                        </TabPanel>
                                    </div>
                                </div>




                            </div>
                           
                        </Tabs>
                    </div>


                </div>
            </div>
        </div>
    
    </>
  )
}

export default HelpCenter_inner