import React, { useEffect } from 'react'
import { logo, title } from '../config/config'
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import img___toekn_1 from '../Assets/Images/Footer_IMG/Futures-Margin.26e7d96.svg'
import img___toekn_2 from '../Assets/Images/Footer_IMG/Trading-transaction.5039d8a.svg'
import img___toekn_3 from '../Assets/Images/Footer_IMG/token_download.svg'
import img___toekn_4 from '../Assets/Images/Footer_IMG/Multiple-Rewards.07c0b3f.svg'
import img___toekn_5 from '../Assets/Images/Footer_IMG/download__21.svg'
import img___toekn_6 from '../Assets/Images/Footer_IMG/Launchpad.28d3a45.svg'



const TokenName = (props: any) => {
  useEffect(() => {
    document.title = props.title;
  }, [])
  return (
    <>
      <div className='main___token__name pt__spacing__main'>

        <section className='token__banner'>
          <div className='token____container'>
            <div className='card__banner__left'>
              <h1>{title} Token: Your {title} to the Crypto Revolution</h1>
              <h3>Connect your crypto, DeFi, and NFTs like never before. Unlock Web3 today with the BGB Token from {title}.</h3>
              <Link to="/login" className='btn'>Our vision {">>"}</Link>
            </div>
            {/* <div className='card__banner__right'>
                        <img src={BAnner__img} alt="icon" />

                    </div> */}

          </div>
        </section>

        <section className='card__token__sec'>
          <div className='token____container'>
            <div className='row'>
              <div className='col-4__ad'>
                <div className='card-to'>
                  <div className='card-header'>
                    <h4>Est. APY</h4>
                    <h1>6.00%</h1>
                  </div>

                  <h3>My {title} Center</h3>
                  <div className='log__token__img'>
                    <img src={logo} alt="logo" />
                  </div>
                  <div className='data_token'>
                    <h4>- - / - -</h4>
                    <h5>Total {title} Subscribed / Total {title}</h5>
                  </div>
                  <div className='card-footer'>
                    <div className='footer__card-1'>
                      <button type='button' className='btn'> subscribe </button>
                      <button type='button' className='btn'> redeem </button>
                    </div>
                    <Link to="/login" className='full_btn'> Login</Link>
                  </div>

                </div>

              </div>

              <div className='col-8__ad'>
                <div className='card-to_right'>
                  <div className='card-header'>
                    <h3>Current Profits</h3>
                  </div>

                  <div className='card-body'>
                    <div className='card-sec-body-1'>
                      <div className='card-sec-body-1__data'>
                        <h3>{title} Earn Rewards</h3>
                        <span> <CopyAllIcon /></span>
                      </div>
                      <p>{title} Earn is an asset management platform on Bitget for investing and growing your {title} holdings. You can earn {title} every day by subscribing to a variety of products that support early redemption.</p>

                    </div>

                    <div className='logo___name_abc'>
                      <img src={logo} alt="logo" />
                      <h4> {title}</h4>
                    </div>

                    <div className='token___card_sec__ad12'>
                      <div className='ad__12_data'>
                        <p>- -</p>
                        <h5>Total {title} Subscribed</h5>
                      </div>

                      <div className='ad__12_data'>
                        <p>- -</p>
                        <h5>Cumulative Profit</h5>
                      </div>

                    </div>
                    <h4 className='token___card_sec__ad12__title'> Other Events</h4>

                    <div className='token___card_sec__ad12'>
                      <div className='ad__12_data'>
                        <p>- -</p>
                        <h5>Current Profits</h5>
                      </div>

                      <div className='ad__12_data'>
                        <p>- -</p>
                        <h5>24h Profits</h5>
                      </div>

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </section>

        <section className='token___sec__A'>
          <div className='token____container'>
            <div className='header___token__A'>
              <h2>Feel the power of {title}</h2>
              <Link to="/"> <p>Cutting-edge, cross-chain trading, made simple</p></Link>
            </div>

            <div className='row'>
              <div className='col-6'>
                <div className='card'>
                  <img src={img___toekn_1} alt="img" />

                  <h3>Futures Margin</h3>
                  <p>Use {title} as a margin to unlock a 15% discount on trading fees.</p>
                  <Link to="/" className='link'>Stay Tuned </Link>
                </div>
              </div>

              <div className='col-6'>
                <div className='card'>
                  <img src={img___toekn_2} alt="img" />

                  <h3>Trading Fees</h3>
                  <p>Pay with {title} to get a 20% discount on all trading fees.</p>
                  <Link to="/" className='link'>check details</Link>
                </div>
              </div>

              <div className='col-6'>
                <div className='card'>
                  <img src={img___toekn_3} alt="img" />

                  <h3>KOL Cooperation</h3>
                  <p>Use BGB as collateral for more collaboration opportunities.</p>
                  {/* <Link to="/" className='link'>check details</Link> */}
                </div>
              </div>


              <div className='col-6'>
                <div className='card'>
                  <img src={img___toekn_4} alt="img" />

                  <h3>Impressive Rewards</h3>
                  <p>Holding {title} gives you access to Bitget rewards, private circle tickets, and more.</p>
                  <Link to="/" className='link'>Stay Tuned </Link>
                </div>
              </div>


              <div className='col-6'>
                <div className='card'>
                  <img src={img___toekn_5} alt="img" />

                  <h3>Earn Passive Income</h3>
                  <p>Stake {title} to earn and enjoy value-added income.</p>
                  {/* <Link to="/" className='link'>Stay Tuned </Link> */}
                </div>
              </div>


              <div className='col-6'>
                <div className='card'>
                  <img src={img___toekn_6} alt="img" />

                  <h3>Early Investment Access</h3>
                  <p>Use {title} as a margin to unlock a 15% discount on trading fees.</p>
                  <Link to="/" className='link'>Stay Tuned </Link>
                </div>
              </div>




            </div>
          </div>


        </section>


      </div>
    </>
  )
}

export default TokenName