import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import img_icons from "../../Assets/Images/t.svg";
import _fetch from '../../config/api';
import { api_url, title } from '../../config/config';

import ReactPaginate from 'react-paginate';


const Adds_postData = (props:any) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionA, setSelectedOptionA] = useState(null);
    const [searchedVal, setSearchedVal] = useState("");
    const [searchedType, setSearchedType] = useState();
    const [sellCoinType, setsellCoinType] = useState("");

    const data = [
        {
            value: `bnb`,
            text: <span className="new__a"> BNB</span>,
            icon: <img src={img_icons} className="option-img-a" />
        },
        {
            value: `usdt`,
            text: <span className="new__a"> USDT</span>,
            icon: <img src={img_icons} className="option-img-a" />
        },
        {
            value: `eth`,
            text: <span className="new__a"> ETH</span>,
            icon: <img src={img_icons} className="option-img-a" />
        },
        {
            value: `xrp`,
            text: <span className="new__a"> XRP</span>,
            icon: <img src={img_icons} className="option-img-a" />
        },
    ];

    // handle onChange event of the dropdown/////////////////////////////////////////////////
    //  const handleChange = (e) => {
    //     setSelectedOption(e);
    //     // setSearchedType('sell_currency');
    //     setsellCoinType(e.value)
    // }
    const [buyRecords, setbuyRecords] = useState(0);
    const [buyPageCount, setbuyPageCount] = useState(0);
    const [BuyItemOffset, setBuyItemOffset] = useState(1);
    const BuyhandlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setBuyItemOffset(newOffset);
    };
    const [allData, setallData] = useState([])
    async function getAllData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }



        let d = await _fetch(`${api_url}/trade/p2p-selfTransactons?page=${BuyItemOffset}&limit=10`, 'GET', {}, {});
        if (d?.status === 'success') {
            setallData(d?.data.data);
            setbuyRecords(d.total);
        }
    }


    useEffect(() => {
    document.title =  props.title;

        getAllData()
    }, [])


    return (
        <>
            <div className='adds___data'>
                <div className='a_container'>
                    <div className="new_market__p2p">
                        <div className="card p2p_tab_card table__p2p__history_demo">
                            <Tabs>

                                <div className="card-body">
                                    <TabPanel>
                                        <div className="table__p2p__history">
                                            <table className="table">
                                                <thead>
                                                    <tr className="">
                                                        <th>Advertiser</th>
                                                        <th>Price</th>
                                                        <th>Available
                                                            <span className="th__divider__span"></span>
                                                            Limits
                                                        </th>
                                                        <th>Payment Method	</th>

                                                        <th>Status</th>
                                                        <th>Type</th>

                                                        <th>Action <span className="zero__fees"> 0 fees</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allData?.map((val: any) => {
                                                        return (
                                                            <>
                                                               {val.order_type === 'buy'&& <tr>
                                                                    <td>
                                                                        <div className="p2p-TD_1">
                                                                            <div className="Img__user__p2p">A</div>
                                                                            <div className="user__info__p2p">
                                                                                <p>{val.buy_currency?.toUpperCase()}</p>
                                                                                {/* <h6>65 Order(s)| 93 % </h6>
                                                                                <h6>Active in the last 1 hour</h6> */}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{val.price} <small>{val.sell_currency?.toUpperCase()}</small></td>
                                                                    <td>
                                                                        <div className="History___p2p__ad">
                                                                            <div className="History___p2p__ad-data">
                                                                                <p>Receivable</p>
                                                                                <p>{val.received_amount} {val.buy_currency?.toUpperCase()}</p>
                                                                            </div>
                                                                            <div className="History___p2p__ad-data">
                                                                                <p>Pending</p>
                                                                                <p>{val.pending_amount} {val.buy_currency?.toUpperCase()}</p>
                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                    {/* <td></td> */}
                                                                    <td>
                                                                        <div className="mad-btn">  </div>
                                                                    </td>
                                                                    <td><div className='mad-btn'>{val.order_status}</div></td>
                                                                   
                                                                    <td>
                                                                        <div className="mad-btn">{val.order_type}  </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="btn_hit_p2p">
                                                                            <Link className="trade_btn active" to={`/Dashbaord/view-adds-data/${val.order_type}/${val.order_id}/`}> View</Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>}

                                                                {val.order_type !== 'buy'&& <tr>
                                                                    <td>
                                                                        <div className="p2p-TD_1">
                                                                            <div className="Img__user__p2p">A</div>
                                                                            <div className="user__info__p2p">
                                                                                <p>{val.sell_currency?.toUpperCase()}</p>
                                                                                {/* <h6>65 Order(s)| 93 % </h6>
                                                                                <h6>Active in the last 1 hour</h6> */}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{val.price} <small>{val.buy_currency?.toUpperCase()}</small></td>
                                                                    <td>
                                                                        <div className="History___p2p__ad">
                                                                            <div className="History___p2p__ad-data">
                                                                                <p>Available</p>
                                                                                <p>{val.pending_amount} {val.sell_currency?.toUpperCase()}</p>
                                                                            </div>
                                                                            <div className="History___p2p__ad-data">
                                                                                <p>Limits</p>
                                                                                <p>{val.minimum_limit} ~ {val.pending_amount} {val.sell_currency?.toUpperCase()}</p>
                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {val.payment_method_type?.map((method:any) => {
                                                                            return(
                                                                                <div className="mad-btn"> {method} </div>

                                                                            );
                                                                        })}
                                                                    </td>
                                                                    <td><div className='mad-btn'>{val.order_status}</div></td>
                                                                    <td>
                                                                        <div className="mad-btn">{val.order_type}  </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="btn_hit_p2p">
                                                                            <Link className="trade_btn active" to={`/Dashbaord/view-adds-data/${val.order_type}/${val.order_id}/`}> View</Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>}
                                                            </>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                            <div className="update_trending_coin_tabs">
                                                <div className="tabs_content active">
                                                    <div className="content_inner_data"></div>
                                                    {buyRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                                        breakLabel="..."
                                                        nextLabel=">"
                                                        onPageChange={BuyhandlePageClick}
                                                        pageRangeDisplayed={1}
                                                        pageCount={buyRecords / 10}
                                                        previousLabel="<"

                                                    // renderOnZeroPageCount={null}
                                                    />}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                </div>
                            </Tabs>
                        </div>
                    </div>
                </div >
            </div>

        </>
    )
}

export default Adds_postData