import React, { useEffect } from 'react'
import { title } from '../config/config'
import ShareIcon from '@mui/icons-material/Share';
import Launchpad__img from '../Assets/Images/Footer_IMG/launchpad__optins.png'
import Slider__img from '../Assets/Images/Footer_IMG/choose44.svg'
import { Link } from 'react-router-dom';

const Launchpad = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
    //  this page css Affiliates page(240 line ) +++
  return (
    <>
    <div className='Launchpad__sec pt__spacing'>
            <div className='card__banner'>
                <div className='Launchpad__contain'>
                    <div className='card__banner__left'>
                        <h1>{title} <br/> Launchpad</h1>
                        <h3>Invest in the best before they skyrocket</h3>
                        <p>The {title} launchpad is where we handpick the brightest projects for you. Purchase tokens in their early stage with BGB.</p>
                        <button type='button' className='btn'><ShareIcon/></button>
                    </div>

                    {/* <div className='card__banner__right'>
                        <img src={BAnner__img} alt="icon" />

                    </div> */}
                    
            </div>

        </div>


        <div className='more__option_lan'>
            <div className='Launchpad__contain'>
                <div className='Launchpad__option_header'>
                    <h1> Past Projects {title}</h1>
                </div>

                <div className='option___data'>
                   <Link to="/launchpad_data">
                    <div className='row'>
                            <div className='col-3__demo'>
                                <img src={Launchpad__img} alt="img/icon"/>
                            </div>

                            <div className='col-8__demo'>
                                <div className=''>
                                    <h3 className='pro__name' > revo  </h3>
                                    <p className='pro__diss' >Revoland is an e-sport game based on Blockchain technology.</p>

                                    <div className='pro___data'>
                                        <div className=''>
                                            <h4>150,000 REVO</h4>
                                            <h5>Total Allocation</h5>
                                        </div>

                                        <div className=''>
                                            <h4>2,000</h4>
                                            <h5>Total Tickets</h5>
                                        </div>

                                        <div className=''>
                                            <h4>75 REVO</h4>
                                            <h5>Each Ticket</h5>
                                        </div>
                                        <div className=''>
                                            <h4>1 REVO = 5.16732308 BGB</h4>
                                            <h5>Sale Price </h5>
                                        </div>

                                    </div>

                                    <div className='time__in_post'>
                                        <p>2022-06-13 16:30 --  2022-06-20 16:30 (UTC+5.5)</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                   </Link>

                </div>


                <div className='option__more_button'>
                    <Link to="/launchpad_data" className='btn__more' > More Projects</Link>
                </div>

            </div>
        </div>

        <div className='slider-__option_lan'>
            <div className='Launchpad__contain'>
                <h2>Launching a project? Choose {title}.</h2>
                <div className='row'>
                    <div className='col-6'>
                        <ul className='list'>
                            <li className='list__item'>
                                <h4>Global Exposure</h4>
                                <p> Your project will be visible to millions of {title} users worldwide</p>
                            </li>

                            <li className='list__item'>
                                <h4>2000+ Influencer Network</h4>
                                <p>Sit back and let our influencers spread the word for you</p>
                            </li>

                            <li className='list__item'>
                                <h4>Diverse Ecosystem</h4>
                                <p>Use our full suite of resources in the {title} ecosystem to your advantage</p>
                            </li>

                            <li className='list__item'>
                                <h4>Bespoke Campaigns</h4>
                                <p>Tailor-made solutions for your next promotion whenever you need them</p>
                            </li>
                        </ul>

                    </div>

                    <div className=''>

                        <img src={Slider__img} alt="icon"/>
                    </div>
                </div>
            </div>
        </div>




    </div>




    </>
  )
}

export default Launchpad