import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function PublicSellBuySocket(props: any) {

    const { id } = useParams();
    const dropShow = (id: any) => {
        const a: HTMLElement | any = document.getElementById(id);
        a.classList.toggle("drop_show");
    };


    const [sellData, setSellData] = useState([]);
    const [buyData, setBuyData] = useState([]);

    useEffect(() => {
        const nowStream = `${props.symbol?.toLowerCase()}usdt@depth20`;
        const streams = ['@ticker', '@depth20', '@trade'];
        const ws = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${nowStream}`);

        ws.onmessage = function (event) {
            const json = JSON.parse(event.data);
            try {
                if ((json)) {
                    if (json.stream === nowStream) {
                        if (json) {
                            var d = json.data;
                            if (d.asks) {
                                // console.log
                                setSellData(d.asks.reverse());
                            }

                            if (d.bids) {
                                setBuyData(d.bids);

                            }
                        }
                    }
                }
            } catch (err) {
                // console.log(err);
            }
        };



    }, [])
    
    return (

        <div className='table___column__1a '>
            <div className='table__left_buy table__column scroll_data'>
                <table className="order__table ">
                    <thead className='s-value'>
                        <tr>
                            {/* <th></th> */}
                            <th>
                                <h6 className="right text-uppercase">Price(USDT)</h6>
                            </th>
                            <th>
                                <h6 className="right text-uppercase">Amount({props.symbol?.toUpperCase()})</h6>
                            </th>
                            <th>
                                <h6 className="right text-uppercase">Total</h6>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="buy ">

                        {
                            buyData?.map((item: any, index: any, sellData: any) =>

                                <>
                                    {(item) ?
                                        <tr key={index}>
                                            <td className="status-dot-cell-green"> {parseFloat(item[0]).toFixed(3)} </td>
                                            <td>{parseFloat(item[1]).toFixed(3)}</td>
                                            <td>{(parseFloat(item[0]) * parseFloat(item[1])).toFixed(2)}</td>
                                            <div className="Bar__green" style={{ width: (Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%` }}></div>
                                        </tr>
                                        : ''}
                                </>
                            )}

                       
                    </tbody>
                </table>
            </div>

            <div className='table__right_buy table__column scroll_data'>
                <table className="order__table">
                    <thead className="s-value">
                        <tr>
                            <th>
                                <h6 className="">Price(USDT)</h6>
                            </th>
                            <th>
                                <h6 className="">Amount({props.symbol?.toUpperCase()})</h6>
                            </th>
                            <th>
                                <h6 className="">Total</h6>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="buy">
                        {sellData?.reverse().map((item: any, index: any, sellData: any) =>

                            <>
                                {(item) ?
                                    <tr>
                                        <td className="status-dot-cell-danger"> {parseFloat(item[0]).toFixed(3)} </td>
                                        <td>{parseFloat(item[1]).toFixed(3)}</td>

                                        <td>{(parseFloat(item[0]) * parseFloat(item[1])).toFixed(2)}</td>
                                        <div className="Bar__red" style={{ width: (Math.round(item[1] * 100 / item[0]) > 100) ? '100%' : `${Math.round(item[1] * 100 / item[0])}%` }} ></div>
                                    </tr>

                                    : ''}
                            </>


                        )}

                        
                    </tbody>
                </table>
            </div>
        </div>


    )
}

export default PublicSellBuySocket;