import React, { useEffect } from 'react'
import { title } from '../config/config'
import ShareIcon from '@mui/icons-material/Share';
import img__affilates from '../Assets/Images/Footer_IMG/afficar__A.svg';
import img__affilates_1 from '../Assets/Images/Footer_IMG/affi__gtc.svg';
import img__affilates_2 from '../Assets/Images/Footer_IMG/affi__do.svg';
import Features from '../Assets/Images/Footer_IMG/advantage.svg';
import Transparently from '../Assets/Images/Footer_IMG/advantage_2svg.svg';
import Support from '../Assets/Images/Footer_IMG/advantage_3.svg';

const Affiliates = (props:any) => {

    useEffect(() => {
        document.title = props.title;
      }, [])


  return (
    <>

    <div className='affiliates_a pt__spacing'>
        <div className='affiliates_a__banner'>
            <div className='affiliates_a__container'>
                <div className='row'>
                    <div className='col-6'>
                        <h1>Become {title}'s Business Affiliate</h1>
                        <p>Affluence For Influence, Count Your Daily Commission. Get Monthly Bonus & Exclusive BGB Bonus!</p>
                        <div className='btn_affiliates'>
                            <button type='button' className='btn'> Be an Affiliate </button>
                            <button type='button' className='shere-btn'><ShareIcon/> </button>
                        </div>
                    </div>
                    {/* <div className='col-6'>
                        <img src={img__affilates} alt="icon"/>
                    </div> */}
                </div>
            </div>
        </div>

        <div className='affiliates__cards'>
            <div className='affiliates_a__container'>
                <div className='row '>
                    <div className='col-4'>
                       <div className='card'>
                        <img src={img__affilates} alt="icon"/>
                            <h4>50%</h4>
                            <p>Up to 50% daily commission</p>
                       </div>
                    </div>

                    <div className='col-4'>
                      <div className='card'>
                        <img src={img__affilates_1} alt="icon"/>
                            <h4>5,000 BGB</h4>
                            <p>Up to 5,000 BGB bonus for 3 months</p>
                      </div>
                    </div>

                    <div className='col-4'>
                        <div className='card'>
                            <img src={img__affilates_2} alt="icon"/>
                            <h4>$3,000</h4>
                            <p>Up to $3,000 monthly bonus from referrals' sign up</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>



            <section className='affiliates__Advantage'>
                <div className='affiliates_a__container'>
                  <div className='aff__heading_ad'>
                    <h1>The {title} Advantage</h1>
                    <h4> We help you monetize your influence and develop a sustainable passive income</h4>
                  </div>

                  <div className='aff__cards'>
                    <div className='row'>
                        <div className='col-6 img___txt__add'>
                            <h2>Diverse Features</h2>
                            <p> With our industry-leading one-click copy trading, grid trading, mixed contracts, and much more, you can start trading now and acquire monetized users.</p>
                        </div>

                        <div className='col-6'>
                            <div className='add__end_img'>
                            <img src={Features} alt="img"/>
                          </div>
                        </div>
                    </div>

                    <div className='row'>
                       

                       <div className='col-6'>
                           <div className='add__left_img'>
                           <img src={Transparently} alt="img"/>
                         </div>
                       </div>

                       <div className='col-6 img___txt__add'>
                           <h2>Manage Transparently</h2>
                           <p> With our industry-leading one-click copy trading, grid trading, mixed contracts, and much more, you can start trading now and acquire monetized users.</p>
                       </div>
                   </div>

                   <div className='row'>
                        <div className='col-6 img___txt__add'>
                            <h2>Professional Support</h2>
                            <p> By leveraging our extensive resources, we promote content and activities to convert your audiences. Eligible affiliate partners will receive exposure and opportunities to grow your audience and maximize your affiliate earnings with Bitget.</p>
                        </div>

                        <div className='col-6'>
                            <div className='add__end_img'>
                            <img src={Support} alt="img"/>
                          </div>
                        </div>
                    </div>

                  </div>

                </div>

            </section>

            <section className='affi_'>

            </section>

    </div>

    </>
  )
}

export default Affiliates