import { Link } from "react-router-dom";
import { title, logo, base_url } from "../config/config";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CopyrightIcon from '@mui/icons-material/Copyright';

const PrivateFooter = () => {






    return (
        <footer className="pvt__footer sec-py s-primary-bg">
            <div className="footer-container">
                <div className="row">
                    <div className="col-footer-4 footer__logo_gf">
                        <div className="temprary">
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                        <p>{title} makes trading easy, fast & reliable. With 24/7 support, staking and bank-grade security & insurance.</p>

                        </div>
                    </div>
                    <div className="col-footer-4">
                        <h3 className="nav-title"> About</h3>
                        <ul className="nav">
                            <li className="nav-item">
                                <Link to="Dashboard/Blog" className="nav-link"> Blog</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="Dashboard/careers" className="nav-link"> Careers</Link>
                            </li>
                            {/* <li className="nav-item">
                            <a href="#" className="nav-link"> {title} Warriors</a>
                        </li> */}
                            {/* <li className="nav-item">
                            <a href="#" className="nav-link"> Announcements</a>
                        </li> */}
                            {/* <li className="nav-item">
                            <a href="#" className="nav-link"> Media Assets</a>
                        </li> */}
                            <li className="nav-item">
                                <Link to="/Dashboard/privacy" className="nav-link"> Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-footer-4">
                        <h3 className="nav-title"> Support</h3>
                        <ul className="nav">
                            <li className="nav-item">
                                <Link to="Dashboard/helpCenter" className="nav-link"> Help Center</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="Dashboard/fees" className="nav-link"> Fees</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="Dashboard/security" className="nav-link"> Security</Link>
                            </li>
                            {/* <li className="nav-item">
                            <a href="#" className="nav-link"> Law Enforcement Request</a>
                        </li> */}
                            <li className="nav-item">
                                <Link to="Dashboard/bitcoin" className="nav-link"> What Is Bitcoin</Link>
                            </li>

                        </ul>
                    </div>
                    <div className="col-footer-4">
                        <h3 className="nav-title"> Buy Crypto</h3>
                        <ul className="nav">
                            <li className="nav-item">
                                <Link to="/Dashboard/coin" className="nav-link"> Buy Bitcoin</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Dashboard/eth" className="nav-link"> Buy Ethereum</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Dashboard/USDT" className="nav-link"> Buy USDT</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Dashboard/tron" className="nav-link">Buy Tron</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-footer-5">
                      
                        <ul className="nav1">
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i> <TwitterIcon /></i>
                                    </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i> <FacebookIcon /></i>
                                    </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i> <InstagramIcon /></i>
                                    </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i> <LinkedInIcon /></i>
                                    </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i> <TelegramIcon /></i>
                                    </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i> <YouTubeIcon /></i>
                                    </a>
                            </li>
                        </ul>
                    </div>
                </div>
                    <div className="col-footer-12 last__pt_ss">
                        <div className="last__copy" style={{ border: 'none', textAlign: 'center' }}>
                            <h4>
                                <i> <CopyrightIcon /></i>
                                <span><Link to="/" className="web-Link" > {title}.exchange.</Link> All rights reserved</span>
                            </h4>
                        </div>
                    </div>
            </div>
        </footer>
    );
}
export default PrivateFooter;