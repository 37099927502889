import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logo, title } from '../config/config';
import SearchIcon from '@mui/icons-material/Search';
import LaunchIcon from '@mui/icons-material/Launch';


const PublicCareers = (props: any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
    return (
        <>
            <div className='max__carees'>
                <div className='careers__header_P'>
                    <Link to="/"> <img src={logo} alt="icon" /></Link>
                    <p>Buy & Trade WithTrust Brands </p>
                </div>

                <div className='max__carees_body'>
                    <div className='max__carees_hero'>
                        <p>Established in 2023, {title} has become one of the world’s largest crypto copy trading and derivatives exchanges and is ranked in the top five globally by CoinGecko for derivatives trading by volume. Currently serving over 8 million users in more than 100 countries around the world, {title} accelerated its mission to promote decentralized finance with an 800-strong workforce. </p>
                        <p> Adhering closely to its philosophy of ‘Better Trading, Better Life’, {title} is committed to providing comprehensive and secure trading solutions to users globally. {title} announced its partnership with Argentinian legendary footballer, Lionel Messi in Oct 2022. And earlier in 2021, {title} announced its sponsorship of world-renowned football team Juventus as its first-ever sleeve partner and PGL Major’s official esports crypto partner soon after. Partnerships with the leading esports organization, Team Spirit, was also announced in early 2022.</p>
                    </div>
                </div>

                <div className='job__sec'>
                    <div className='job__sec_header'>
                        <form className='main__job_form'>
                            <div className='form-group_input'>
                                <span><SearchIcon /> </span>
                                <input type="search" placeholder='search Job' />
                            </div>

                            <div className='form-select_a_12'>
                                <div className='form-select_a'>
                                    <select className="form-select" placeholder='Location '>
                                        <option>Location</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                                <div className='form-select_a'>
                                    <select className="form-select" placeholder='Location '>
                                        <option>Location</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                                <div className='form-select_a'>
                                    <select className="form-select" placeholder='Location '>
                                        <option>Location</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className='job__sec_body'>
                        <div className='job__details'>
                            <Link to="/jobs">
                                <div className=''>
                                    <p> Backend Developer</p>
                                    <p> Posted Today </p>
                                </div>

                                <div className=''>
                                    <p> Kowloon, Tsim Sha Tsui, Hong Kong</p>
                                    <p> Hong Kong  </p>
                                </div>

                                <div className=''>
                                    <p> Full time</p>
                                </div>
                            </Link>

                            <Link to="">
                                <div className=''>
                                    <p> Backend Developer</p>
                                    <p> Posted Today </p>
                                </div>

                                <div className=''>
                                    <p> Kowloon, Tsim Sha Tsui, Hong Kong</p>
                                    <p> Hong Kong  </p>
                                </div>

                                <div className=''>
                                    <p> Full time</p>
                                </div>
                            </Link>

                            <Link to="">
                                <div className=''>
                                    <p> Backend Developer</p>
                                    <p> Posted Today </p>
                                </div>

                                <div className=''>
                                    <p> Kowloon, Tsim Sha Tsui, Hong Kong</p>
                                    <p> Hong Kong  </p>
                                </div>

                                <div className=''>
                                    <p> Full time</p>
                                </div>
                            </Link>

                            <Link to="">
                                <div className=''>
                                    <p> Backend Developer</p>
                                    <p> Posted Today </p>
                                </div>

                                <div className=''>
                                    <p> Kowloon, Tsim Sha Tsui, Hong Kong</p>
                                    <p> Hong Kong  </p>
                                </div>

                                <div className=''>
                                    <p> Full time</p>
                                </div>
                            </Link>

                        </div>
                    </div>

                </div>


                <div className='carees___footer'>
                    <p><Link to="/">View website Help <LaunchIcon /></Link></p>
                </div>

            </div>
        </>
    );
}

export default PublicCareers;
