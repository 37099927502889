import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import { logo, title } from '../config/config';
// import StarsIcon from '@mui/icons-material/Stars';
// import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Security = (props:any) => {

    useEffect(() => {
        document.title = props.title ;
    }, []);



   const ADD_security = (id: any) => {
    const a: HTMLElement | any = document.getElementById(id);
    a.classList.toggle("page__a");
 }
 const remove_security = (id: any) => {
    const a: HTMLElement | any = document.getElementById(id);
    a.classList.remove("page__a");
 }



  return (
    <>
        <div className='security_section'>
            <div className='a_container'>
                <div className='section__anyone' id='section_body_A' >
                    <div className='section_header_security'>
                        <p> <Link to="/Dashboard" > Home</Link> /<Link to="/Dashboard" > Security</Link>  </p>
                        <div className='secion___bacn_icon' onClick={() => remove_security('section_body_A')} >  <ArrowBackIcon/> </div>


                        <div className='search__bar_a'>
                            <form>
                                <div className='form_search'>
                                    <SearchIcon/>
                                    <input type="search" className='input__section' placeholder='Search'/>
                                </div>
                            </form>
                        </div>
                    </div>


                    <div className='section_body' >
                        <Tabs>
                            <div className='row' onClick={() => ADD_security('section_body_A')}>
                                <div className='col-4'>
                                    <div className="security-header"  >
                                        <TabList>
                                            <Tab>Two-Factor Authentication (2FA) - Setup, Change & Recovery</Tab>
                                            <Tab>Securing your {title} account - Tips & Best Practices</Tab>

                                            <Tab>{title} Bug bounty - Wall Of Fame</Tab>
                                            <Tab>Do you have a Bug Bounty program?</Tab>

                                        </TabList>
                                    </div>
                                </div>


                                <div className='col-8'>
                                    <div className='column__8_security'>
                                        <TabPanel>
                                            <div className='security-card'>
                                                <div className='security-card_header__demo'>
                                                    <h2 className='card__title_security'> 
                                                    Two-Factor Authentication (2FA) - Setup, Change & Recovery</h2>

                                                </div>

                                                    

                                                <div className='logo__security'>
                                                    <img src={logo} alt="logo_icon"/>
                                                    <div className=''>
                                                        <h2>{title}</h2>
                                                        <h6> 10 day ago</h6>
                                                    </div>
                                                </div>

                                                <div className='security__data'>
                                                    <p>Two-Factor Authentication (2FA) provides an extra layer of security for your account by requiring a second step of verification when you log in or perform some specific actions. In addition to your password, you'll also need an OTP received via SMS/Email or an OTP generated by apps like Google Authenticator, Authy, etc.</p>

                                                    <p>The default mode of 2FA on {title} is via SMS/Email. But if you would like to perform app-based 2FA, you will need to install authenticator apps like Google Authenticator, Authy which display a 6-digit passcode on the opening screen & you typically get 30 seconds to use it before it expires & then displays a new passcode.</p>
                                                    <h4>Enabling app based 2-Factor Authentication</h4>
                                                    <p>If you would like to switch from SMS/Email OTP based 2FA to an App based 2FA, follow the below steps -</p>

                                                    <div className='inner_tabs__security'>
                                                        <Tabs>
                                                            <div className='card__tabs__header_security'>
                                                                <TabList>
                                                                    <Tab> Desktop</Tab>
                                                                    <Tab> IOS</Tab>
                                                                    <Tab> Anddroid</Tab>
                                                                </TabList>
                                                            </div>
                                                            <div className='card__tabs__body_security'>
                                                                <TabPanel>
                                                                    <ul>
                                                                        <li> On the Homepage, click on Settings.</li>
                                                                        <li>  Click on Profile button at the top right of your screen & click on Account Settings.</li>
                                                                        <li>Enter the OTP you receive on SMS/Email & tap on Next. </li>
                                                                        <li> Open your Authenticator app & follow the steps to add a new account. You can scan your {title} Barcode or manually enter the Recovery code into your authenticator Web.</li>
                                                                        <li> Enter the OTP you see in your Authenticator app.</li>
                                                                        <li>Save the Recovery Code in a secured place (you will need this in the future in case you lose or change your mobile device). </li>
                                                                        <li> Open the email & click on Approve 2FA request in the confirmation email you receive.</li>
                                                                    </ul>
                                                                </TabPanel>

                                                                <TabPanel>
                                                                    <div className='second__data'>
                                                                        <p>coming soon  </p>
                                                                    </div>
                                                                </TabPanel>

                                                                <TabPanel>
                                                                    <div className='second__data'>
                                                                        <p>coming soon  </p>
                                                                    </div>
                                                                </TabPanel>

                                                            </div>
                                                        </Tabs>
                                                    </div>

                                                </div>

                                                <div className='help_full'>
                                                    <p>Was this article helpful?</p>
                                                    <button type='button' className='btn'> <CheckIcon/>  Yes </button>
                                                    <button type='button' className='btn'> <CloseIcon/> No</button>
                                                </div>

                                            </div>
                                        </TabPanel>
                                    </div>
                                </div>




                            </div>
                           
                        </Tabs>
                    </div>


                </div>
            </div>
        </div>
    
    </>
  )
}

export default Security