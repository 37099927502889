import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import { title } from '../config/config';
import buy_bitcoin from '../Assets/Images/Buy-bitcoin.svg'
import exchange_bitcoin from '../Assets/Images/exchange-bitcoin.svg'
import lock from '../Assets/Images/lock.svg'




const Quick = (props:any) => {
    const dropdown = async () => {
        const a: HTMLElement | any = document.getElementById("myDropdown")
        a.classList.toggle("show");
    }
    const dropdown1 = async () => {
        const a: HTMLElement | any = document.getElementById("myDropdown1")
        a.classList.toggle("show");
    }

    window.onclick = function (event: any) {
        if (!event.target.matches('.dropbtn')) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        }
    }
    useEffect(() => {
        document.title = props.title;

    }, [])
    return (
        <>
            <div className="b_container pt__spacing">

                <Tabs>
                    <div className='quick____container'>
                        <div className="main-tabs">
                            <TabList>
                                <Tab>Quick Buy / Express</Tab>
                                {/* <Tab>P2P Trading</Tab> */}
                                {/* <Tab>Credit/Debit card</Tab> */}
                            </TabList>
                        </div>
                        <TabPanel>
                            <Tabs>
                                <div className="inner-tabs">
                                    <TabList>
                                        <Tab>Buy</Tab>
                                        <Tab>Sell</Tab>
                                    </TabList>
                                </div>

                                <TabPanel>
                                    <div className="inner-contant">
                                        <div className="row">
                                            <div className="col-6-2">
                                                <h1>Buy Crypto In One Click</h1>


                                                <div className="quick-text">
                                                    <span className='main-quick' >

                                                        <h2>
                                                            1. Select a fiat and cryptocurrency to create an order
                                                        </h2>
                                                    </span>
                                                    <span className="defination">
                                                        We support 40+ fiat currencies and 100+ cryptocurrencies
                                                    </span>
                                                </div>

                                                <div className="quick-text">
                                                    <span className='main-quick' >

                                                        <h2>
                                                            2. Choose your preferred payment method

                                                        </h2>
                                                    </span>
                                                    <span className="defination">
                                                        We support 70+ payment methods including Mastercard, Visa, SEPA, SWIFT, Fast Payment Poli and many other local payment methods

                                                    </span>
                                                </div>

                                                <div className="quick-text">
                                                    <span className='main-quick' >

                                                        <h2>
                                                            3. Make your payment and start enjoying the {title} ecosystem
                                                        </h2>
                                                    </span>
                                                    <span className="defination">
                                                        The {title} ecosystem supports a wide range of cryptocurrency products and services, including spot trading, futures trading, a token launchpad, and much more!

                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form">
                                                    <div className="first-input">
                                                        <div className="first">
                                                            <p>I want to pay</p>
                                                            <input type="text" placeholder='10.00-5000.00' />

                                                        </div>
                                                        <div className="second">

                                                            <div className="dropdown">
                                                                {/* <select className="dropbtn" onChange={handleDropDwon}>
                                                                    <option defaultValue={'Home'}>Home</option>
                                                                    <option value={"About"}>About</option>
                                                                    <option value={50}>50</option>
                                                                    <option value={100}>100</option>
                                                                </select> */}
                                                                <button onClick={dropdown} className="dropbtn">BUSD<ArrowDropDownIcon /></button>
                                                                <div id="myDropdown" className="dropdown-content">
                                                                    <a href='#'>Home</a>
                                                                    <a href='#'>About</a>
                                                                    <a href='#'>Contact</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="first-input">
                                                        <div className="first">
                                                            <p>I will get ≈</p>
                                                            <input type="text" placeholder='Enter Amount' />

                                                        </div>
                                                        <div className="second">

                                                            <div className="dropdown">
                                                                {/* <select className="dropbtn" onChange={handleDropDwon}>
                                                                    <option defaultValue={'Home'}>Home</option>
                                                                    <option value={"About"}>About</option>
                                                                    <option value={50}>50</option>
                                                                    <option value={100}>100</option>
                                                                </select> */}
                                                                <button onClick={dropdown1} className="dropbtn">USDT<ArrowDropDownIcon /></button>
                                                                <div id="myDropdown1" className="dropdown-content">
                                                                    <a href='#'>Home</a>
                                                                    <a href='#'>About</a>
                                                                    <a href='#'>Contact</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="info">
                                                        <p>Reference price <span>19.24 TRY </span></p>

                                                        <InfoIcon />

                                                        <span className="tooltiptext">This price is for reference only. In the event of extreme market conditions, the actual price matched will deviate from the reference price. If you do not agree with the actual price, you can cancel the order or contact customer support.</span>

                                                    </div>
                                                    <div className="foot-contaat">
                                                        <button type='submit'>NEXT</button>
                                                        <p>No withdrawals or selling is allowed for 48 hours after crypto purchases. During this period, transfer and trading will not be affected.</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </TabPanel>
                                <TabPanel>
                                    <div className="inner-contant">
                                        <div className="row">
                                            <div className="col-6-2">
                                                <h1>Buy Crypto In One Click</h1>


                                                <div className="quick-text">
                                                    <span className='main-quick' >

                                                        <h2>
                                                            1. Create an order
                                                        </h2>
                                                    </span>
                                                    <span className="defination">
                                                        Your cryptocurrency assets will be hosted by Bitget P2P after the order is created
                                                    </span>
                                                </div>

                                                <div className="quick-text">
                                                    <span className='main-quick' >

                                                        <h2>
                                                            2. Awaiting payment from buyers

                                                        </h2>
                                                    </span>
                                                    <span className="defination">
                                                        The buyer will transfer money to you via the payment method in the transaction information

                                                    </span>
                                                </div>

                                                <div className="quick-text">
                                                    <span className='main-quick' >

                                                        <h2>
                                                            3. Release cryptocurrencies
                                                        </h2>
                                                    </span>
                                                    <span className="defination">
                                                        After the buyer has marked the transfer as complete, please login to your receiving account to confirm receipt of payment before releasing the cryptocurrency
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form">
                                                    <div className="first-input">
                                                        <div className="first">
                                                            <p>I want to sell</p>
                                                            <input type="text" placeholder='Enter Amount' />

                                                        </div>
                                                        <div className="second">

                                                            <div className="dropdown">
                                                                {/* <select className="dropbtn" onChange={handleDropDwon}>
                                                                    <option defaultValue={'Home'}>Home</option>
                                                                    <option value={"About"}>About</option>
                                                                    <option value={50}>50</option>
                                                                    <option value={100}>100</option>
                                                                </select> */}
                                                                <button onClick={dropdown1} className="dropbtn">USDT<ArrowDropDownIcon /></button>
                                                                <div id="myDropdown1" className="dropdown-content">
                                                                    <a href='#'>Home</a>
                                                                    <a href='#'>About</a>
                                                                    <a href='#'>Contact</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="first-input">
                                                        <div className="first">
                                                            <p>I will get ≈</p>

                                                            <input type="text" placeholder='10.00-5000.00' />

                                                        </div>
                                                        <div className="second">

                                                            <div className="dropdown">
                                                                {/* <select className="dropbtn" onChange={handleDropDwon}>
                                                                    <option defaultValue={'Home'}>Home</option>
                                                                    <option value={"About"}>About</option>
                                                                    <option value={50}>50</option>
                                                                    <option value={100}>100</option>
                                                                </select> */}
                                                                <button onClick={dropdown} className="dropbtn">BUSD<ArrowDropDownIcon /></button>
                                                                <div id="myDropdown" className="dropdown-content">
                                                                    <a href='#'>Home</a>
                                                                    <a href='#'>About</a>
                                                                    <a href='#'>Contact</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="info">
                                                        <p>Reference price <span>19.24 TRY </span></p>

                                                        <InfoIcon />

                                                        <span className="tooltiptext">This price is for reference only. In the event of extreme market conditions, the actual price matched will deviate from the reference price. If you do not agree with the actual price, you can cancel the order or contact customer support.</span>

                                                    </div>
                                                    <div className="foot-contaat">
                                                        <button type='submit'>NEXT</button>
                                                        <p>No withdrawals or selling is allowed for 48 hours after crypto purchases. During this period, transfer and trading will not be affected.</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                            <div className="section">
                                <h1>The {title} Advantages</h1>

                                <div className="row">
                                    <div className="col-4">
                                        <img src={buy_bitcoin} alt="logo" />

                                        <div className="info-card">
                                            <h4>Buy crypto</h4>
                                            <p>Buy crypto with one-click and you don't need to be a professional to get started</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <img src={exchange_bitcoin} alt="logo" />

                                        <div className="info-card">
                                            <h4>Asset security</h4>
                                            <p>Once the buyer place an order, the seller's asset will be locked by the platform. Customer support will intervene in case any dispute arises</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <img src={lock} alt="logo" />

                                        <div className="info-card">
                                            <h4>Best quote</h4>
                                            <p>When a user places an order, the system will match it with the best price for the corresponding payment method</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>


                        <TabPanel>
                        <div className="p2p-table">
                            <div className="row">
                                <div className="col-12">
                                    <Tabs>
                                        <div className="row">
                                            <div className="col-2">
                                                <TabList>
                                                    <Tab>Buy</Tab>
                                                    <Tab>Sell</Tab>
                                                </TabList>

                                            </div>
                                            <div className="col-3">
                                                <p>Prime Merchants </p>
                                                <div className="checkbox-wrapper-2">
                                                    <input type="checkbox" className="sc-gJwTLC ikxBAC"/>
                                                </div>
                                            </div>

                                        </div>

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </>
    )
}

export default Quick


