import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import _fetch from "../config/api";
import { logo, api_url, title } from "../config/config";
import DepositAddress from "./DepositAddress";
// @import "../Assets/Scss/bootstrap.min.css";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { AiOutlineRise, AiOutlineFall } from "react-icons/ai";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TransferModalCom from "./TransferModalCom";
import LoaderCom from "./loader/LoaderCom";


const activeDiposit = (id: any) => {

	//  var sa = document.getElementById(id).classList.add("mystyle");
	const a: HTMLElement | any = document.getElementById(id);
	a.classList.toggle("show_tr");

	if (id == 'activeDeposit__tr') {
		const b: HTMLElement | any = document.getElementById("activeWithdraw__tr");
		b.className = "active-td"
	} else {
		const c: HTMLElement | any = document.getElementById("activeDeposit__tr");
		c.className = "active-td";
	}
	// console.log('okay')
}


const Dashboard = (props: any) => {
	const { id } = useParams();
	const [depositDrop, setdepositDrop] = useState("");
	const [getBalence, setgetBalence] = useState({ main_account: 0, trading: 0, funding: 0, metastake: 0 });
	const [elpMarket, setElpMarket] = useState([]);
	const [searchedVal, setSearchedVal] = useState("");
	const [emptyData, setemptyData] = useState([]);

	const auth = localStorage.getItem("auth");
	var storedNames = JSON.parse(localStorage.getItem("user") || "");
	// Tranfer button Modal 
	const [symbolT, setSymbolT] = useState('');
	const [openT, setOpenT] = useState(false);
	const onOpenModalT = () => setOpenT(true);
	const onCloseModalT = () => setOpenT(false);

	async function activityLog() {
		let d = await _fetch(`${api_url}/markets/getAllElpMarketbyType?type=main_account`, 'GET', {}, {});
		if (d?.status === 'success') {
			setElpMarket(d?.market);
			setemptyData(d)
		}
	}
	const transferModal = (symbol: any) => {
		setOpenT(true)
		setSymbolT(symbol)
	}

	useEffect(() => {
		document.title = props.title;

		(async () => {
			const balanceData = await _fetch(`${api_url}/incomes/portfolio`, 'GET', {}, {});
			setgetBalence({ main_account: balanceData.data[1].total_amount, trading: balanceData.data[0].total_amount, funding: balanceData.data[2].total_amount, metastake: 0 });
		})()

		activityLog()

	}, [])

	const dropdownRemove = (id: any) => {
		const a: HTMLElement | any = document.getElementById(id);
		a.classList.remove("dropdown-show");
	};
	const [switchHideBal, setSwitchHideBal] = useState(0)
	const zeroBalance = (e: any) => {
		if (switchHideBal === 0) {
			setSwitchHideBal(1)
		} else {
			setSwitchHideBal(0)
		}
	}

	return (
		<>
			<div className="Main__box margin_b" onClick={() => dropdownRemove("dropdown_section")} >
				<div className="container-max">
					<div className="card-header-bar">
						<div className="left-header-bar" style={{ width: '100%' }}>
							{/* <ul className="nav"> */}
							<Tabs>
								<TabList className="nav sidebar__d">
									<Tab> <Link className="btn active" style={{ marginLeft: '0' }} to='#' > Balances</Link></Tab>
									<Tab> <Link className="btn" to='#'> Transfer History</Link></Tab>
									<div className="sidebar_d__right_nav">
										<Tab> <Link className="btn " to='DepositHistory'>  Deposit History</Link></Tab>
										<Tab> <Link className="btn " to='withdrawHistory'>  Withdraw History</Link></Tab>

									</div>
								</TabList>
								<div className="card-body-container">
									<TabPanel>
										<div className="card-sction-1">
											<div className="card-1">
												<Link to="/Dashboard/WalletReport/main_account" className="card__main_link" >
													<h5> <Link to="/Dashboard/WalletReport/main_account"> Main Account <ChevronRightIcon /> </Link> </h5>
													<div className="blanace">
														<h4> <span>{getBalence.main_account.toFixed(3)}</span> USDT</h4>
													</div>
													<div className="btn__a">
														<Link to="/Dashboard/incomesHistory/main_account" className="btn" > Report</Link>
													</div>
												</Link>
												<img src={logo} alt="" className="img__logo" />
											</div>
											<div className="card-1">
												<Link to="/Dashboard/WalletReport/trade_account" className="card__main_link" >
													<h5> <Link to="/Dashboard/WalletReport/trade_account"> Trading  Account   <ChevronRightIcon /></Link></h5>
													<div className="blanace">
														<h4> <span>{getBalence.trading.toFixed(3)}</span> USDT</h4>
													</div>
													<div className="btn__a">
														<Link to="/Dashboard/incomesHistory/trade_account" className="btn" > Report</Link>
													</div>
												</Link>
												<img src={logo} alt="" className="img__logo" />
											</div>

											<div className="card-1">
												<Link to="/Dashboard/WalletReport/funding_account" className="card__main_link" >
													<h5> <Link to="/Dashboard/WalletReport/funding_account"> Funding Account  <ChevronRightIcon /> </Link></h5>
													<div className="blanace">
														<h4> <span>{getBalence.funding.toFixed(3)}</span> USDT</h4>
													</div>
													<div className="btn__a">
														<Link to="/Dashboard/incomesHistory/funding_account" className="btn" > Report</Link>
													</div>
												</Link>
												<img src={logo} alt="" className="img__logo" />
											</div>

											<div className="card-1 card__2">
												<Link className="card__main_link" to='#' >
													<h5> <Link to="#"> MetaStake Account <ChevronRightIcon /></Link> </h5>
													<div className="blanace">
														<h4> <span>0</span> USDT</h4>
													</div>
													<div className="btn__a">
														<Link to="#" className="btn" target="_black"> Deposit</Link>
													</div>
													<img src={logo} alt="" className="img__logo" />
												</Link>
											</div>
										</div>
										<div className="card-sction-1">
										</div>
										<div className="table____main">
											<div className="table-section-1" style={{}}>
												<div className="table-header">
													<div className="left-t-geader">
														<form>


															<div className="toggle" >
																<label className="switch">
																	<input type="checkbox" value={switchHideBal} onClick={zeroBalance} />
																	<span className="slider round"></span>
																	<span className="user-none">Hide Zero Balances</span>
																</label>
															</div>
														</form>
													</div>

													<div className="right-t-geader">
														<form>
															<div className="input-group mb-3">
																<div className="input-group-prepend">
																	🔍
																</div>
																<input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearchedVal(e.target.value)} />
															</div>
														</form>

													</div>
												</div>
												<div className="table-body">
													<div className="table-responsive">
														<table className="table balance-table">
															<thead>
																<tr>
																	<th style={{ width: '180px' }}>COIN</th>
																	<th style={{ width: '180px' }} >LAST PRICE</th>
																	<th style={{ width: '180px' }} >BALANCE</th>
																	<th style={{ width: '180px' }} >24H CHANGE</th>
																	<th>MARKETS</th>
																	<th style={{ width: '180px' }}>TRADE</th>
																</tr>
															</thead>
															<tbody>
																{/* deposite dropdwon  */}
																{depositDrop === "ELP" &&
																	<tr className="active-td show_tr" id={"ELP"}>
																		<td colSpan={8} >

																			<DepositAddress />
																		</td>
																	</tr>}
																{/* Withdraw dropdwon  */}
																<LoaderCom condition={emptyData} text={'Getting latest values...'} />


																{elpMarket?.filter((val: any, index: any) =>
																	// note that I've incorporated the searchedVal length check here
																	!searchedVal.length || val?.symbol
																		.toString()
																		.toLowerCase()
																		.includes(searchedVal.toString().toLowerCase())
																).map((val: any, index: any) =>
																	<>
																		{(val?.total_coin >= 0 && switchHideBal === 0) ?
																			<tr key={index}>
																				<td>
																					<div className="img__td">
																						<img src={val.image} alt="ELP-Icon" width="30px" />
																					</div>
																					<div className="Text__td">
																						<p>{val.symbol.toUpperCase()}</p>
																						<small>{val.name}</small>
																					</div>
																				</td>
																				<td>
																					<p>{'$' + val.current_price}</p>
																				</td>
																				<td>
																					<p>{val.total_coin.toFixed(3)} {val.symbol.toUpperCase()} <small>${val.usdt_income.toFixed(3)}</small></p>
																				</td>
																				{/* <td><p>{val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p> <small>${val.usdt_income.toFixed(3)}</small></td> */}

																				<td>
																					<Link to="/" className="f-normal-color" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }} >
																						{val.price_change_percentage_24h?.toFixed(1)}% {val.price_change_percentage_24h < 0 ? <AiOutlineFall /> : <AiOutlineRise />}
																					</Link>
																				</td>
																				<td style={{ width: '200px' }}>
																					{(() => {
																						if (val.price_change_percentage_24h > 0) {
																							return (
																								<div><Sparklines data={val.sparkline_in_7d[0].price}>
																									<SparklinesLine color="green" />
																								</Sparklines></div>
																							)
																						} else {
																							return (
																								<div><Sparklines data={val.sparkline_in_7d[0].price}>
																									<SparklinesLine color="red" />
																								</Sparklines></div>
																							)
																						}
																					})()}
																				</td>
																				<td style={{ textAlign: 'center', width: '350px' }}>
																					<div className="cta_wrapper">
																						<button className="trade_btn" onClick={() => { depositDrop !== val.symbol.toUpperCase() ? setdepositDrop(val.symbol.toUpperCase()) : setdepositDrop("") }}  > Deposit</button>
																						<Link to={val.token_type == 'auto' ? `/Dashboard/trade/${val.symbol.toUpperCase()}` : `/Dashboard/trading/${val.symbol.toUpperCase()}`} className="trade_btn active">TRADE</Link>
																						<button className="trade_btn red__theme_btn" onClick={() => transferModal(val.symbol)} > Transfer </button>
																						<Link to={`WithdrawalAddress/${val.symbol.toUpperCase()}`} className="trade_btn active" > Withdrawal</Link>
																					</div>
																				</td>
																			</tr>
																			:

																			(val?.total_coin > 0 && switchHideBal === 1) ?
																				<tr>
																					<td>
																						<div className="img__td">
																							<img src={val.image} alt="ELP-Icon" width="30px" />
																						</div>
																						<div className="Text__td">
																							<p>{val.symbol.toUpperCase()}</p>
																							<small>{val.name}</small>
																						</div>
																					</td>
																					<td>
																						<p>{'$' + val.current_price}</p>
																					</td>
																					<td><p>{val.total_coin.toFixed(3)} {val.symbol.toUpperCase()}</p> <small>${val.usdt_income.toFixed(3)}</small></td>

																					<td>
																						<Link to="/" className="f-normal-color" style={{ color: val.price_change_percentage_24h < 0 ? 'red' : '#24AE8F' }} >
																							{val.price_change_percentage_24h?.toFixed(1)}% {val.price_change_percentage_24h < 0 ? <AiOutlineFall /> : <AiOutlineRise />}
																						</Link>
																					</td>
																					<td style={{ width: '200px' }}>
																						{(() => {
																							if (val.price_change_percentage_24h > 0) {
																								return (
																									<div><Sparklines data={val.sparkline_in_7d[0].price}>
																										<SparklinesLine color="green" />
																									</Sparklines></div>
																								)
																							} else {
																								return (
																									<div><Sparklines data={val.sparkline_in_7d[0].price}>
																										<SparklinesLine color="red" />
																									</Sparklines></div>
																								)
																							}
																						})()}
																					</td>
																					<td style={{ textAlign: 'center', width: '350px' }}>
																						<div className="cta_wrapper">
																							<button className="trade_btn" onClick={() => { depositDrop !== val.symbol.toUpperCase() ? setdepositDrop(val.symbol.toUpperCase()) : setdepositDrop("") }}  > Deposit</button>
																							<Link to={auth ? `/Dashboard/trade/${val.symbol.toUpperCase()}` : "/signup"} className="trade_btn active">TRADE</Link>
																							<button className="trade_btn red__theme_btn" onClick={() => transferModal(val?.symbol)} > Transfer </button>
																							<Link to={'WithdrawalAddress'} className="trade_btn active" > Withdrawal</Link>

																						</div>
																					</td>
																				</tr>
																				: <></>

																		}
																		{depositDrop === val.symbol.toUpperCase() &&
																			<tr className="active-td show_tr" id={val.symbol.toUpperCase()}>
																				<td colSpan={8} >

																					<DepositAddress coin_name={val?.symbol ? val?.symbol : null} bep20={Boolean(val.bep20.status)} trc20={Boolean(val.trc20.status)} erc20={Boolean(val.erc20.status)} gtc20={Boolean(val.gtc20.status)} />
																				</td>
																			</tr>}
																	</>
																)
																}

															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>


									</TabPanel>
									{/* Transfer History tab section  */}
									<TabPanel>
										<div className="table____main">
											<div className="table-section-1" style={{ padding: '0 0 20px' }} >
												<div className="table-body">
													<div className="table-responsive">
														<table className="table tranfer-table">
															<thead>
																<tr>
																	<th>	ASSETS</th>
																	<th>	TYPE</th>
																	<th>VOLUME</th>
																	<th>	STATUS</th>
																	<th> 	TIME</th>
																</tr>
															</thead>
															<tbody>
																<td colSpan={8} className="none_data" >
																	<span className="no-data" >No Transfer history found...</span>
																</td>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</TabPanel>
								</div>
							</Tabs>
						</div>
					</div>
				</div>
			</div>

			<Modal open={openT} onClose={onCloseModalT}
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal_S',
				}} center  >
				<TransferModalCom symbolT={symbolT} onCloseModalT={onCloseModalT} accountType={'main_account'} />

			</Modal>

		</>
	);
};
export default Dashboard;