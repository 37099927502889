import React from 'react';
import { useEffect } from "react";
// import banner__LI from "../Assets/Images/Footer_IMG/compliance-banner.png"
import license__sponsar_1 from "../Assets/Images/Footer_IMG/compliance-licence-1.png"
import license__sponsar_2 from "../Assets/Images/Footer_IMG/compliance-licence-2.png"
import license__full_1 from "../Assets/Images/Footer_IMG/compliance-identify_1.png"
import license__full_2 from "../Assets/Images/Footer_IMG/compliance-identify_2.png"
import license__full_3 from "../Assets/Images/Footer_IMG/compliance-identify_3.png"
import license__full_4 from "../Assets/Images/Footer_IMG/compliance-identify_4.png"
import { Link } from 'react-router-dom';


const FaqData = [
    {
        FaqQues: "1. Compliance Management",
        FaqAns: "Our dedicated team of experts ensures that we follow strict compliance standards and protocols. Our team follows a comprehensive compliance management framework, which is overseen by senior management, to identify, assess, execute and evaluate effectively. ",
        FaqAns2: "It is our responsibility to examine and identify any risks to or regarding our platform, and follow through with any necessary remediation. External risks may refer to those that may arise from outside circumstances, such as incorrect information published by third-party advertisements from Bitget affiliates. Internal risks refer to those that may arise from within our organization, such as unauthorized, inappropriate, illegal, or unethical actions taken by our employees.        ",
        FaqAns3: "In conjunction with our assessments and annual compliance reviews, we work with local regulators to ensure our compliance standards adhere to regional requirements.        ",
    },

    {
        FaqQues: "2. eKYC",
        FaqAns: "We use extensive eKYC procedures to safeguard our platform and mitigate any potential risk from fraud and illicit behavior. We have established proper sanctions and anti-money laundering policies to ensure we comply with all relevant jurisdictions and prevent counterfeiting or other criminal activities on the platform.        ",
        FaqAns2: "Efficiency is crucial to our Customer Due Diligence (CDD). To optimise the KYC process without compromising compliance, we utilize eKYC technology. This includes enabling facial recognition and auto cross-matching between provided identification and live self-portraits, thus preventing the risk of impersonation. As a result, the identity verification process on Bitget is drastically shortened.        ",
    },
    {
        FaqQues: "3. Blockchain Analytics ",
        FaqAns: "External blockchain analytical tools are deployed to closely monitor transactions on our platform and reduce this risk of fraudulent activities and the appearance of high-risk clients*. Due to the immutability and transparency of public blockchains, we are able to thoroughly examine transactions conducted on our platform.",
    },
    {
        FaqQues: "4. Cybersecurity        ",
        FaqAns: "We consult with independent cybersecurity experts to improve the security and stability of our platform. Penetration testing and vulnerability assessments are conducted on our network biannually and, if potential threats are detected, correction is swift.        ",
        FaqAns2: "In addition to the aforementioned tests, we encrypt all sensitive data to prevent theft and unauthorized access or modification. All data that is transmitted or held is encrypted and stored securely in fault-tolerant storage.        ",
    }
]


const ComplianceLicense = (props:any) => {

    useEffect(() => {
        document.title = props.title;

        window.addEventListener("load", () => {
            let faqItem: any = document.querySelector(".sec_faq_content .faq_ques");
            faqItem.classList.add("active");
            faqItem.nextElementSibling.setAttribute("style", `height:${faqItem.nextElementSibling.scrollHeight}px`);
        })
    }, []);
    function Faqclicked(e: any) {
        let favActive: any = document.querySelector(".sec_faq_content .faq_ques.active");
        if (favActive) {
            favActive.classList.remove("active");
            favActive.nextElementSibling.removeAttribute("style");
        }
        e.currentTarget.classList.add("active");
        e.currentTarget.nextElementSibling.setAttribute("style", `height:${e.currentTarget.nextElementSibling.scrollHeight}px`);
    }

    return (
        <>
            <div className='License___sec pt__spacing'>
                <div className='max__contain_license'>

                    <section className='IIner__banner'>
                        <div className='IIner____container'>
                            <div className='card__banner__left'>
                                <h1>Compliance and Accountability</h1>
                                <p> We are committed to protecting your assets, and we take this responsibility seriously. Our values guide us to make decisions that meet our community's needs and have a lasting impact on society.</p>
                                <p> Our operations are conducted competently and transparently, and are ever-evolving to meet global compliance standards in cryptocurrency. We work with affiliates to ensure our information is accurate, and our platform is secure. We offer services and products to our users that reflect our industry-leading security and credibility.</p>
                                <button type='button' className='btn'>Our vision {">>"}</button>
                            </div>
                            {/* <div className='card__banner__right'>
                                <img src={BAnner__img} alt="icon" />

                            </div> */}

                        </div>
                    </section>

                    <div className='license__sponsar'>
                        <div className='IIner____container'>
                            <div className='row_max'>
                                <div className='col-4'>
                                    <div className='card'>
                                        <img src={license__sponsar_1} alt="sponsar_img" />
                                        <h2> U.S. MSB License</h2>
                                        <p>Regulatory Authority: The Financial Crimes Enforcement Network (FinCEN), U.S. Department of the Treasury</p>
                                    </div>
                                </div>

                                <div className='col-4'>
                                    <div className='card'>
                                        <img src={license__sponsar_2} alt="sponsar_img" />
                                        <h2> Canada MSB Licence</h2>
                                        <p>Regulatory Authority: Financial Transactions and Reports Analysis Centre of Canada</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className='license__full'>
                    <div className='row_max'>
                        <div className='col-3'>
                            <img src={license__full_1} alt="icon" />
                            <h5> Identify</h5>
                        </div>
                        <div className='col-3'>
                            <img src={license__full_2} alt="icon" />
                            <h5> Assess</h5>
                        </div>
                        <div className='col-3'>
                            <img src={license__full_3} alt="icon" />
                            <h5> Execute</h5>
                        </div>
                        <div className='col-3'>
                            <img src={license__full_4} alt="icon" />
                            <h5> Evaluate</h5>
                        </div>
                    </div>
                </div>

                <div className='max__contain_license'>
                    <section className="license__FAQ sec_faq sec-py">
                        <div className="container_FAQ">
                            <div className="">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                            <div className="sec_content">
                                <div className="sec_faq_content">
                                    <ul>
                                        {FaqData.map((val: any, index: any) => {
                                            return (
                                                <li key={index} >
                                                    <div className={`faq_ques t-normal-color`} onClick={(e: any) => { Faqclicked(e) }} >
                                                        <h3>
                                                            {val.FaqQues}
                                                        </h3>
                                                        <span>
                                                            <svg width={12} height={8} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1 1.5L6 6.5L11 1.5" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="faq_ans" /* style={{paddingBottom: '20px'}} */>
                                                        <p  >
                                                            {val.FaqAns}
                                                        </p>
                                                        <p  >
                                                            {val.FaqAns2}
                                                        </p>
                                                        <p  >
                                                            {val.FaqAns3}
                                                        </p>
                                                    </div>
                                                </li>
                                            )
                                        })
                                        }
                                    </ul>
                                    <div className="sec_button m-sec-btn">
                                        <Link to="/" className=" t-normal-color">View More<span><svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5L6 6.5L1 11.5" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span></Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </>
    );
}

export default ComplianceLicense;
