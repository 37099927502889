import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _fetch from "../../config/api";
import { api_url } from "../../config/config";

const OrderValumeNew = (props: any) => {

    
    const { id } = useParams();
    const dropShow = (id: any) => {
        const a: HTMLElement | any = document.getElementById(id);
        a.classList.toggle("drop_show");
    };

    const [buyOrders,setBuyOrders] = useState([])
    async function OrdersBuy() {
        let data = await _fetch(`${api_url}/trade/getBuySellHistory?market_trade_type=buy&buy_currency=${id}&sell-currency=usdt`, 'GET', {}, {});
        if (data?.status === 'success') {
            setBuyOrders(data?.data?.data);

        }
    }

    const [sellOrders,setSellOrders] = useState([])
    async function OrdersSell() {
        let data = await _fetch(`${api_url}/trade/getBuySellHistory?market_trade_type=sell&sell_currency=${id?.toLowerCase()}&buy-currency=usdt`, 'GET', {}, {});
        if (data?.status === 'success') {
            setSellOrders(data?.data?.data);

        }
    }

    useEffect(() => {
        OrdersBuy()
        OrdersSell()
    }, [props.LoadApi])

    // console.log('sell', sellOrders);
  return (
    <div className='table___column__1a '>
    <div className='table__left_buy table__column scroll_data'>
        <table className="order__table ">
            <thead className='s-value'>
                <tr>

                    <th>
                        <h6 className="right text-uppercase">Price(USDT)</h6>
                    </th>
                    <th>
                        <h6 className="right text-uppercase">Amount({props.symbol.toUpperCase()})</h6>
                    </th>
                    <th>
                        <h6 className="right text-uppercase">Total</h6>
                    </th>
                </tr>
            </thead>
            <tbody className="buy ">
                {
                    buyOrders?.map((item: any, index: any, sellData: any) =>

                        <>
                            {(item) ?
                                    <tr >
                                            <td className="status-dot-cell-green"> {parseFloat(item.coin_price).toFixed(3)} </td>
                                            <td>{parseFloat(item.coin).toFixed(3)}</td>
                                            <td>{(parseFloat(item.coin_price) * parseFloat(item.coin)).toFixed(2)}</td>
                                            <div className="Bar__green" style={{width: (Math.round(item.coin * 100 / item.coin_price) > 100) ? '100%' : `${Math.round(item.coin * 100 / item.coin_price)}%`} }></div>
                                    </tr>
                                : ''}
                        </>
                    )}
            </tbody>
        </table>
    </div>



    <div className='table__right_buy table__column scroll_data'>
        <table className="order__table">
            <thead className="s-value">
                <tr>
                    <th>
                        <h6 className="">Price(USDT)</h6>
                    </th>
                    <th>
                        <h6 className="">Amount({props.symbol.toUpperCase()})</h6>
                    </th>
                    <th>
                        <h6 className="">Total</h6>
                    </th>
                </tr>
            </thead>
            <tbody className="buy">
            
                {sellOrders?.reverse().map((item: any, index: any, sellData: any) =>
                
                    <>
                    
                        {(item) ?
                            <tr>
                                <td className="status-dot-cell-danger"> {parseFloat(item.coin_price).toFixed(3)} </td>
                                <td>{parseFloat(item.coin).toFixed(3)}</td>
                                
                                <td>{(parseFloat(item.coin_price) * parseFloat(item.coin)).toFixed(2)}</td>
                                <div className="Bar__red" style={{width: (Math.round(item.coin * 100 / item.coin_price) > 100) ? '100%' : `${Math.round(item.coin * 100 / item.coin_price)}%`} } ></div>
                            </tr>

                            : ''}
                    </>


                )}


            </tbody>
        </table>
    </div>
</div>
 
  );
}

export default OrderValumeNew;
