import { useEffect } from "react";
import { Link } from "react-router-dom";
import { title } from "../config/config";

const FaqData = [
    {
        FaqQues: "How much can I earn in this referral program?",
        FaqAns: "You can earn unlimited rewards! Every time your friend trades, you get 50% of their trading fees as reward in GTC. The more your friend trades, the more you earn.",
    },
    {
        FaqQues: "When will the referral rewards be credited to my account?",
        FaqAns: "All rewards earned for a day are credited to your account at once in the next 24 hours. Usually early in the morning.",
    },
    {
        FaqQues: "How will referral rewards be distributed?",
        FaqAns: "From 15th August 2021, all referral rewards are distributed in GTC irrespective of the trading fee being paid in INR, USDT, GTC or BTC. Rewards earned before 15th August 2021 remain unaffected and will not be touched.",
    },
    {
        FaqQues: "Do you have any tips that will help me earn more rewards?",
        FaqAns: "Share your invite link with as many friends as you can. The more friends you share it with, the more likely they are to sign up. Use social media, chat and emails to share your invite link",
    },
    {
        FaqQues: "How do I know if my friend has successfully signed up via my referral link?",
        FaqAns: "When a friend completes sign up via your referral link, their email will get added in 'Referred friends'  tab below your referral link. You can start receiving rewards as soon as they complete KYC verification and start trading.",
    },
    {
        FaqQues: "Important rules to keep in mind",
        FaqAns: ` Please keep in mind the below rules when you participate in the ` +  {title} +  ` Referral Program.`,
        
    },
]
const FaqQ = () => {
    useEffect(() => {
        window.addEventListener("load", () => {
            let faqItem: any = document.querySelector(".sec_faq_content .faq_ques");
            faqItem.classList.add("active");
            faqItem.nextElementSibling.setAttribute("style", `height:${58}px`);
        })
    }, []);
    function Faqclicked(e: any) {
        let favActive: any = document.querySelector(".sec_faq_content .faq_ques.active");
        if (favActive) {
            favActive.classList.remove("active");
            favActive.nextElementSibling.removeAttribute("style");
        }
        e.currentTarget.classList.add("active");
        e.currentTarget.nextElementSibling.setAttribute("style", `height:${58}px`);
    }
    return (    
        <>
            <section className="fAQ-QUE sec_faq s-secondary-bg sec-py">
                <div className="container_FAQ">
                    <div className="">
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    <div className="sec_content">
                        <div className="sec_faq_content">
                            <ul>
                                {FaqData.map((val: any, index: any) => {
                                    return (
                                        <li key={index} >
                                            <div className={`faq_ques t-normal-color`} onClick={(e: any) => { Faqclicked(e) }} >
                                                <h3>
                                                    {val.FaqQues}
                                                </h3>
                                                <span>
                                                    <svg width={12} height={8} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 1.5L6 6.5L11 1.5" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="faq_ans" /* style={{paddingBottom: '20px'}} */>
                                                <p  >
                                                    {val.FaqAns}
                                                </p>
                                            </div>
                                        </li>
                                    )
                                })
                                }
                            </ul>
                            <div className="sec_button m-sec-btn">
                                <Link to="/" className=" t-normal-color">View More<span><svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5L6 6.5L1 11.5" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span></Link>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default FaqQ;