import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { logo, title } from '../config/config';
import LaunchIcon from '@mui/icons-material/Launch';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';




const Job = (props: any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
    // const [searchType, setsearchType] = useState(false);
    // const [ActiveTab, setActiveTab] = useState(0);
    // onClick={() => {setActiveTab(1);setsearchType(true)}}

    return (
        <>
            <div className='max__carees'>
                <div className='careers__header_P'>
                    <Link to="/"> <img src={logo} alt="icon" /></Link>
                    <h1 style={{ marginTop: '10px' }} >Backend Developer</h1>
                </div>


                <div className='Job__tabs'>
                    <Tabs  >
                        <TabList>
                            <Tab>OVERVIEW</Tab>
                            <Tab>APPLICATION</Tab>
                        </TabList>
                        <div className='tabs__data_job'>
                            <TabPanel>
                                <div className='job__data_h'>
                                    <h3>Description</h3>
                                    <h3> About the company:</h3>
                                </div>

                                <div className=''>
                                    <p>We are a top-tier digital assets exchange platform with over 50 countries' global footprints, and aim to be the portal that transcends Web2 and Web3, connects CeFi and DeFi, and {title} the vast web of crypto.</p>
                                </div>

                                <div className='trading__ul'>
                                    <ul className='nav__ul'>
                                        <li> Top 1 platform for copy trading globally </li>
                                        <li> Top 5 derivatives trading globally </li>
                                        <li>  8+ million registered users</li>
                                        <li> 1K+ employees in over 38 countries </li>
                                        <li> Provide stable security of A+ ranking for 12 SSL indicators </li>
                                        <li> Proof of Reserves - guarantees 100% of users' assets in reserves </li>
                                    </ul>

                                    <h4>Responsibility:</h4>
                                    <ul className='nav__ul'>
                                        <li>Design and build the next generation of our digital assets trading system; </li>
                                        <li>Responsible for system development, implementation, testing, optimization, bug fixing and maintenance; </li>
                                        <li> Responsible for the technical upgrade and maintenance of the quantitative system;</li>
                                        <li>Responsible for maintaining the online server, making optimization plans from the code, JVM and other levels according to the server status provided by the operation and maintenance, and executing them; </li>
                                        <li> Responsible for reasonable architecture design, refactoring, research and development of Java/Python projects;</li>
                                        <li>Work closely with team members to develop and test applications, complete the business needs proposed by the business party on time and with high quality; </li>
                                        <li>Participate in application releases, systems upgrades and UAT; </li>
                                        <li>Maintain and support the application for production and usage; </li>
                                        <li>Prepare system documentation and user guide. </li>
                                    </ul>

                                    <h4>Requirements:</h4>
                                    <ul className='nav__ul'>
                                        <li> High Diploma/ Associate Degree/ Degree holder in Computer Science, Information Technology or equivalent; </li>
                                        <li> 5+ years of experience in Java development, familiar with system design CAP principles, and experience in design and development of high-concurrency and high-availability systems; </li>
                                        <li> Familiar with commonly used data structures and algorithms; </li>
                                        <li> Proficiency in Java language is a MUST, strong learning ability, and familiarity with Python/NodeJS and other languages would be an advantage; </li>
                                        <li> Solid understanding of Java foundation, and basic frameworks such as multi-threading, collection, thread pool, etc;</li>
                                        <li> Familiar with Spring, MyBatis and other open source frameworks, and have a deep understanding of JVM; </li>

                                    </ul>

                                    <h4>Benefits:</h4>
                                    <ul className='nav__ul'>
                                        <li>Great exposure to blockchain and the crypto industry </li>
                                        <li> Attractive package with guaranteed 2 months bonus and performance-driven bonus;</li>
                                        <li>Medical insurance coverage </li>
                                        <li> 5 days of work and bank holidays</li>
                                        <li> Monthly birthday party</li>
                                        <li> Staff activities, team building and company events</li>
                                    </ul>

                                    <a href='#' className='btn' > Apply for this job </a>


                                </div>

                            </TabPanel>

                            <TabPanel>
                                <form className='jab__details__add'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h3>Personal information</h3>
                                        </div>
                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='f-name'>First name</label>
                                                <input type="text" placeholder='*First name' id='f-name' />
                                            </div>
                                        </div>

                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='l-name'>Last name</label>
                                                <input type="text" placeholder='*Last name' id='l-name' />
                                            </div>
                                        </div>

                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='email'>Email</label>
                                                <input type="email" placeholder='*First name' id='email' />
                                            </div>
                                        </div>

                                        <div className='col-6'>
                                            <div className='form-group'>
                                                <label htmlFor='sa'>Headline (Optional)</label>
                                                <input type="text" placeholder='*First name' id='sa' />
                                            </div>
                                        </div>

                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label htmlFor='Ph-name'>Phone</label>
                                                <input type="number" placeholder='*Phone Number' id='PH-name' />
                                            </div>
                                        </div>

                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label htmlFor='Ph-name'> Summary (Optional)</label>
                                                <textarea placeholder='Summary' ></textarea>
                                            </div>
                                        </div>

                                        <div className='col-12'>
                                            <h4>*High Diploma/ Associate Degree/ Degree holder in Computer Science, Information Technology or equivalent;</h4>

                                            <div className="form-check">
                                                <label className="form-check-label" htmlFor="radio1">
                                                    <input type="radio" className="form-check-input" id="radio1" name="optradio" value="option1" checked />
                                                    Yes</label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label" htmlFor="radio2">
                                                    <input type="radio" className="form-check-input" id="radio2" name="optradio" value="option2" />
                                                    NO</label>
                                            </div>

                                        </div>

                                        <div className='col-12'>
                                            <button type='submit' className='btn'>Submit application</button>
                                        </div>


                                    </div>

                                </form>
                            </TabPanel>
                        </div>

                    </Tabs>
                </div>





                {/* <div className='carees___footer'>
                <p><Link to="/">View website Help <LaunchIcon/></Link></p>
            </div> */}

            </div>
        </>
    )
}

export default Job