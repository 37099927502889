import React, { useEffect } from 'react'
import { title } from '../config/config'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import banner from '../Assets/Images/bannerRight.png'
import privilegeImg from '../Assets/Images/Footer_IMG/privilegeImg.png'
import privilegeImg2 from '../Assets/Images/Footer_IMG/privilegeImg2.png'
import privilegeImg3 from '../Assets/Images/Footer_IMG/privilegeImg3.png'
import privilegeImg4 from '../Assets/Images/Footer_IMG/privilegeImg4.png'
import privilegeImg5 from '../Assets/Images/Footer_IMG/privilegeImg5.png'
import privilegeImg6 from '../Assets/Images/Footer_IMG/privilegeImg6.png'
import avatar1 from '../Assets/Images/Footer_IMG/avatar1.png'
import avatar2 from '../Assets/Images/Footer_IMG/avatar2.png'
import avatar3 from '../Assets/Images/Footer_IMG/avatar3.png'
import VipIntroduceSlider from './VipIntroduceSlider';



const VipIntroduce = (props:any) => {
    useEffect(() => {
        document.title = props.title;
      }, [])

    const Vipintro_security = (id: any) => {
        const a: HTMLElement | any = document.body
    ;
        a.classList.toggle("show-more");
     }
    
    return (
        <>
            <div className="b_container__1 pt__spacing">

                <section className='VIP__banner'>
                <div className='VIP____container'>
                    <div className='card__banner__left'>
                        <h1>{title} VIP Services</h1>
                        <h3>{title} provides a full range of exclusive privileges to VIP users.</h3>
                        <button type='button' className='btn'>Become a VIP</button>
                    </div>
                    {/* <div className='card__banner__right'>
                            <img src={BAnner__img} alt="icon" />

                        </div> */}

                </div>
            </section>
            
                <div className="VipIntroduce">
                   <div className='VIP____container'>
                    <div className="sec-sec">
                            <div className="slider">
                                <div className='VIP____container'>
                                    <VipIntroduceSlider/> {/*  slider card ============== */}
                                </div>
                            </div>
                        </div>
                        <div className="thired_sec">
                            <h1>VIP Trading Privileges</h1>
                            <div className="cards">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="main-card">
                                            <div className="top-img">
                                                <img src={privilegeImg} alt="" />
                                            </div>
                                            <div className="info">
                                                <p>Exclusive VIP Funding Rate</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="main-card">
                                            <div className="top-img">
                                                <img src={privilegeImg2} alt="" />
                                            </div>
                                            <div className="info">
                                                <p>Exclusive Account Manager</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="main-card">
                                            <div className="top-img">
                                                <img src={privilegeImg3} alt="" />
                                            </div>
                                            <div className="info">
                                                <p>Exclusive Green Channel to Deposit and Withdraw</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="main-card">
                                            <div className="top-img">
                                                <img src={privilegeImg4} alt="" />
                                            </div>
                                            <div className="info">
                                                <p>Fund Custody</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="main-card">
                                            <div className="top-img">
                                                <img src={privilegeImg5} alt="" />
                                            </div>
                                            <div className="info">
                                                <p>High-yield Products</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="main-card">
                                            <div className="top-img">
                                                <img src={privilegeImg6} alt="" />
                                            </div>
                                            <div className="info">
                                                <p>Festive Rewards</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tabs-sec">
                            <div className="heading">
                                <h1>Lower Transaction Fees and Higher Withdrawal Limits</h1>
                            </div>
                            <div className="tabs">
                                <Tabs>
                                    <div className="tabbuttons">
                                        <TabList>
                                            <Tab>Spot Trading</Tab>
                                            <Tab>Futures Trading</Tab>
                                            <Tab>Leverage Trading</Tab>
                                        </TabList>

                                    </div>

                                    <TabPanel>

                                        <div className="main-head">
                                            <div className="table-head">
                                                <h3>VIP Tier</h3>
                                                <h3>Requirements</h3>
                                                <h3>24-Hour Withdrawal Limit</h3>
                                            </div>
                                            <div className="table-data">
                                                <h3>VIP 5</h3>
                                                <h3>Assets (USDT) : 3,000,000</h3>
                                                <h3>8,500,000USD</h3>
                                            </div>
                                            <div className="table-data">
                                                <h3>VIP 4</h3>
                                                <h3>Assets (USDT) : 2,000,000</h3>
                                                <h3>8,500,000USD</h3>
                                            </div>
                                            <div className="table-data">
                                                <h3>VIP 3</h3>
                                                <h3>Assets (USDT) : 1,000,000</h3>
                                                <h3>5,000,000USD</h3>
                                            </div>
                                            <div className="table-data">
                                                <h3>VIP 2</h3>
                                                <h3>Assets (USDT) : 500,000</h3>
                                                <h3>5,000,000USD</h3>
                                            </div>
                                            <div className="table-data">
                                                <h3>VIP 1</h3>
                                                <h3>Assets (USDT) : 50,000</h3>
                                                <h3>5,000,000USD</h3>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <h2>Any content 2</h2>
                                    </TabPanel>
                                    <TabPanel>
                                        <h2>Any content 3</h2>
                                    </TabPanel>
                                </Tabs>
                                <div className="foot-info">
                                    <div className="contant">
                                        <p>VIP Requirements:</p>
                                        <p>1.30-Day Trading Volume: the volume accumulated from all trades in sub-accounts and master accounts from 0:00 (UTC+0) until the next day. Sub-accounts will follow the master accounts' funding rate after the account is created.</p>
                                        <p>2.Assets: all account assets including spot, USDC futures, USDT-M futures, Coin-M futures, P2P and Earn assets in sub-accounts and master accounts will be recorded at 0:00 the next day (UTC+0), and will be converted based on the coin-USDT conversion rate at the time of recording. Spot accounts contain available assets, locked-up assets, and frozen assets; futures accounts contain margined and frozen assets, excluding unrealised profit and losses; P2P contain available assets, excluding frozen assets.</p>
                                        <p>3.24-Hour Withdrawal Limit: {title} will set a user's daily withdrawal limit based on their VIP tier. All coins will be calculated in USD, and the final amount after conversion should be less than the withdrawal limit of the corresponding VIP tier. The withdrawal limit for users who fail to complete KYC verification is 50000 USD.</p>
                                    <p>4.If a user's spot trading volume, futures trading volume, and assets meet different VIP tiers, the user may enjoy VIP privileges for the highest one. If a user meets any trading type's VIP tier requirements, all other trading types will be upgraded to that respective VIP tier. For example: if the user meets VIP requirements for spot 1, they will be automatically upgraded to futures 1 VIP.</p>
                                <p>5.The VIP level will be automatically updated the next day at 1:00 (UTC+0). If the customer's trading behavior triggers the risk control system, the platform reserves the right to adjust their VIP level.</p>
                                <p>6.The VIP system and requirements are only applicable to non-institutional users.</p>
                                <p>{title} reserves the right of final interpretation for this event. If you have any further questions, please contact vip@{title}.com.</p>
                                    </div>
                                <span></span>
                                    <div className="btn">

                                    <button onClick={Vipintro_security} type='submit' >Show more</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                   </div>
                </div>
            </div>
            <div className="bottom-sec">
                <div className="VIP____container">
                    <div className="row">
                        <div className="col-4">
                            <div className="cards">
                                <div className="top">
                                    <img src={avatar1} alt="" />
                                </div>
                                <div className="info">
                                    <h2>Christina</h2>
                                    <h4>VIP Ambassador</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="cards">
                                <div className="top">
                                    <img src={avatar2} alt="" />
                                </div>
                                <div className="info">
                                    <h2>Ludovic Migneault</h2>
                                    <h4>VIP Ambassador</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="cards">
                                <div className="top">
                                    <img src={avatar3} alt="" />
                                </div>
                                <div className="info">
                                    <h2>Gracelyn</h2>
                                    <h4>VIP Ambassador</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VipIntroduce
