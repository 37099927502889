import { useEffect, useState } from 'react';
import cryptojsondata from '../config/crypto-earn.json'
import MarketSlider from '../Sections/crypto-earn-slider'
import { Swiper, SwiperSlide } from "swiper/react";
import { title } from '../config/config';

const Cryptoearn = (props:any) => {

    const [activeTab, setActiveTab] = useState(0);
    const [dropdown, Setdropdown] = useState("Lend");
    const filterlowertab = (id: any, item: any) => {
        setActiveTab(id);
        Setdropdown(item)
    }
    const [dropdownToggle, SetdropdownToggle] = useState(false)
    const toggleBtn = () => {
        SetdropdownToggle(!dropdownToggle)
    }

    const [openLendactive, SetopenLendactive] = useState(false)
    const openLend = () => {
        SetopenLendactive(!openLendactive)
    }
    const [marketOption, SetmarketOption] = useState(false);
    const [marketOptiontext, SetmarketOptiontext] = useState("All");
    const marketBtn = () => {
        SetmarketOption(!marketOption);
    }

    const [Checkbox, SetCheckbox] = useState(false)
    const checkboxBtn = () => {
        SetCheckbox(!Checkbox)
    }

    const [openOrderactive, SetopenOrderactive] = useState("OpenOrders")
    const openOrder = () => {
    }

    useEffect(() => {
        document.title = props.title;

    }, [])

    return (
        <>
            <section className="sec_crypto-earn sec-py sec-py__new">
                <div className="container">
                    <div className="tabs_btn">
                        <div className='button'>
                            <button onClick={toggleBtn} className='m_dropdown' > {dropdown}
                                <img src={require("../Assets/Icons/arrow-icon-dropwdown.svg").default} alt="" />
                            </button>
                            <div className='d_btn_perent btn_perent'>
                                {cryptojsondata.cryptoTab.map((item, index) => {
                                    return (
                                        <button key={index} className={`tab_btn ${activeTab === item.id ? 'active' : ''}`} onClick={(event) => filterlowertab(item.id, item.name)}>{item.name}</button>
                                    )
                                })}
                            </div>
                            {dropdownToggle && (
                                <>
                                    <div className='btn_perent'>
                                        {cryptojsondata.cryptoTab.map((item, index) => {
                                            return (
                                                <button key={index} className={`tab_btn ${activeTab === item.id ? 'active' : ''}`} onClick={(event) => { filterlowertab(item.id, item.name); toggleBtn() }}>{item.name}</button>
                                            )
                                        })}
                                    </div>
                                    <div className="bg_layer" onClick={toggleBtn}>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="beginner_guide">
                            <button className='guide_btn'>
                                <img src={require("../Assets/Icons/guide-icon.svg").default} alt="" />
                                <span>Beginner’s Guide</span>
                            </button>
                            <form className="form d_form"><label className="label" htmlFor="seach_coin_referral" />
                                <input id="seach_coin_referral" type="text" placeholder="Search Coins" />
                            </form>
                        </div>
                    </div>
                    {dropdown === "Lend" && (
                        <div className="main_tabs_content lendings_market">
                            <div className="sec_content">
                                <Swiper
                                    slidesPerView={3}
                                    spaceBetween={30}
                                    breakpoints={{
                                        1440: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                        768: {
                                            slidesPerView: 2.5,
                                        },
                                        280: {
                                            slidesPerView: 1.5,
                                            spaceBetween: 10,
                                        },
                                    }}
                                    className="mySwiper">
                                    <SwiperSlide>
                                        <div className="lending_slider_card">
                                            <div className="card_header">
                                                <div>
                                                    <img className='profit-icon' src={require("../Assets/Icons/profit-icon.svg").default} alt="" />
                                                    <h3 className='card_heading t-primary-color'>Current Asset On Lending</h3>
                                                </div>
                                            </div>
                                            <div className="card_footer">
                                                <h3 className='footer_heading t-primary-color'>0 BTC</h3>
                                                <span className='t-normal-color'>≈ 0 USD</span>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="lending_slider_card">
                                            <div className="card_header">
                                                <div>
                                                    <img className='profit-icon' src={require("../Assets/Icons/profit-icon.svg").default} alt="" />
                                                    <h3 className='card_heading t-primary-color'>Realised Profit</h3>
                                                </div>
                                                <img src={require("../Assets/Icons/info-icon.svg").default} alt="" />
                                            </div>
                                            <div className="card_footer">
                                                <h3 className='footer_heading t-primary-color'>0 BTC</h3>
                                                <span className='t-normal-color'>≈ 0 USD</span>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="lending_slider_card">
                                            <div className="card_header">
                                                <div>
                                                    <img className='profit-icon' src={require("../Assets/Icons/profit-icon.svg").default} alt="" />
                                                    <h3 className='card_heading t-primary-color'>Accrued Interest</h3>
                                                </div>
                                                <img src={require("../Assets/Icons/info-icon.svg").default} alt="" />
                                            </div>
                                            <div className="card_footer">
                                                <h3 className='footer_heading t-primary-color'>0 BTC</h3>
                                                <span className='t-normal-color'>≈ 0 USD</span>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="lending_market_data">
                                <ul className='data_heading'>
                                    <li className='heading'>Coin</li>
                                    <li className='heading lending_orders'>Lending Orders</li>
                                    <li className='heading lending_market_data_filled'>Filled</li>
                                    <li className='heading accrued_interest'>Accrued Interest</li>
                                    <li className='heading'>Realised Profit</li>
                                    <li className='heading  auto-lend-i-close'>Auto - Lend I Close</li>
                                    <li className='heading operation'>Operation</li>
                                </ul>
                                <ul className='lending_market_inner_data'>
                                    {/* <LendingMarketData /> */}
                                </ul>
                            </div>

                        </div>
                    )}
                    {dropdown === "My Lendings" && (
                        <div className="main_tabs_content">

                            <form className="form m_form"><label className="label" htmlFor="seach_coin_referral" />
                                <input id="seach_coin_referral" type="text" placeholder="Search Coins" />
                            </form>
                            <div className="tabs_content">
                                <MarketSlider />
                            </div>
                            <div className="lend_btc">
                                <h3 className="heading">
                                    Lend BTC
                                </h3>
                                <div className='lend_btn'>
                                    <span> Auto - Lend</span>
                                    <button className={`button ${openLendactive && ("active")}`} onClick={openLend}>
                                    </button>
                                </div>
                            </div>
                            {
                                (!openLendactive) && (
                                    <form className="total_assets">
                                        <div className="assets">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Main Account Available:
                                                </h5>
                                                <span>0</span>
                                                <img src={require("../Assets/Icons/convert-icon.svg").default} alt="" />
                                            </div>
                                            <div className='input'>
                                                <input id='convertcoin' type="number" placeholder='Amount' />
                                                <label htmlFor="convertcoin">BTC</label>
                                            </div>
                                            <div className="terms">
                                                <ul>
                                                    <li className='item'>25%</li>
                                                    <li className='item'>50%</li>
                                                    <li className='item'>75%</li>
                                                    <li className='item'>100%</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="assets min_day_rate">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Optimal Rate:
                                                </h5>
                                                <span>0.009%</span>
                                            </div>
                                            <div className='input'>
                                                <input id='convertcoin2' type="number" placeholder='Reserved Amount' />
                                                <label htmlFor="convertcoin2">%</label>
                                            </div>
                                            <div className="terms">
                                                <ul>
                                                    <li className='main_term'>Terms:</li>
                                                    <li className='item'>7 Days</li>
                                                    <li className='item'>14 Days</li>
                                                    <li className='item'>28 Days</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="assets lend_btc_assets">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Estimated Profit
                                                </h5>
                                                <span className='btc'>0 BTC </span>
                                                <span className='usd'>- 0 USD</span>
                                            </div>
                                            <button className='enable_auto_lend' onClick={openLend}>
                                                Lend BTC
                                            </button>
                                        </div>
                                    </form>
                                )
                            }
                            {
                                (openLendactive) && (
                                    <form className="total_assets">
                                        <div className="assets">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Total Assets:
                                                </h5>
                                                <span>0</span>
                                                <img src={require("../Assets/Icons/convert-icon.svg").default} alt="" />
                                            </div>
                                            <div className='input'>
                                                <input id='convertcoin3' type="text" placeholder='Reserved Amount' />
                                                <label htmlFor="convertcoin3">BTC</label>
                                            </div>
                                            <p className="info">
                                                Lending All Excludes the Reserved
                                            </p>
                                        </div>
                                        <div className="assets min_day_rate">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Min. Day Rate
                                                </h5>
                                            </div>
                                            <div className='input'>
                                                <input id='convertcoinsecond' type="text" placeholder='Reserved Amount' />
                                                <label htmlFor="convertcoinsecond">%</label>
                                            </div>
                                            <div className="terms">
                                                <ul>
                                                    <li className='main_term'>Terms:</li>
                                                    <li className='item'>7 Days</li>
                                                    <li className='item'>14 Days</li>
                                                    <li className='item'>28 Days</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="assets">
                                            <button className='enable_auto_lend' onClick={openLend}>
                                                Enable Auto - Lend
                                            </button>
                                            <p className="info">
                                                The system will automatically lend out the available funds at the <span>priority interest rate</span>  for you. If the current priority interest rate is lower than your preset minimum day rate, the system will place the lending order at your preset one.
                                            </p>
                                        </div>
                                    </form>
                                )
                            }
                            {/* USDT Funding Market */}
                            <div className="usdt_funding_market">
                                <div className="market_header">
                                    <h2 className="heading t-primary-color">
                                        USDT Funding Market
                                    </h2>
                                    <div className="market_dropdown">
                                        <button className='dropdown_btn' onClick={marketBtn}>
                                            <span>{marketOptiontext}</span>
                                            <svg width={13} height={8} viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 1.5L6.5 6.5L1.5 1.5" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                        {marketOption && (
                                            <>
                                                <ul className='dropdown_options'>
                                                    <li>
                                                        <button onClick={() => { marketBtn(); SetmarketOptiontext("one") }} >One</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => { marketBtn(); SetmarketOptiontext("Two") }} >Two</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => { marketBtn(); SetmarketOptiontext("Three") }} >Three</button>
                                                    </li>
                                                </ul>
                                                <div className="bg_layer" onClick={marketBtn}>
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                </div>
                                <div className="market_data">
                                    <div className="content_heading">
                                        <ul>
                                            <li className="t-normal-color">Daily Interest Rate</li>
                                            <li className="t-normal-color">Annualized Rate</li>
                                            <li className="t-normal-color">Terms</li>
                                            <li className="t-normal-color total">Total Amount</li>
                                        </ul>
                                    </div>

                                    <div className="update_trending_coin_tabs">
                                        {marketOptiontext === "All" &&
                                            <div className="tabs_content active">
                                                <div className="content_inner_data">
                                                    <ul className="coins_price">
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%
                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>

                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>

                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%


                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>
                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        {marketOptiontext === "one" &&
                                            <div className="tabs_content active">
                                                <div className="content_inner_data">
                                                    <ul className="coins_price">
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%
                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>

                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>

                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%


                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>
                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        {marketOptiontext === "Two" &&
                                            <div className="tabs_content active">
                                                <div className="content_inner_data">
                                                    <ul className="coins_price">
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%
                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>

                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>

                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%


                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>
                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        {marketOptiontext === "Three" &&
                                            <div className="tabs_content active">
                                                <div className="content_inner_data">
                                                    <ul className="coins_price">
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%
                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>

                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>

                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%


                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>
                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="btn_bg_layer" />
                                </div>
                            </div>
                            {/* Inner Tabs  */}
                            <div className="order_detail_tabs">
                                <div className="order__tabs">
                                    <div className="order__details">
                                        <div className="order__details__inner">
                                            <button className={`order__button ${openOrderactive === "OpenOrders" ? "active" : ""}`} onClick={() => { openOrder(); SetopenOrderactive("OpenOrders") }}>
                                                Open Orders
                                            </button>
                                            <button className={`order__button ${openOrderactive === "Unsettled" ? "active" : ""}`} onClick={() => { openOrder(); SetopenOrderactive("Unsettled") }}>
                                                Unsettled
                                            </button>
                                            <button className={`order__button ${openOrderactive === "Settled" ? "active" : ""}`} onClick={() => { openOrder(); SetopenOrderactive("Settled") }}>
                                                Settled
                                            </button>
                                            <button className={`order__button ${openOrderactive === "OrderHistory" ? "active" : ""}`} onClick={() => { openOrder(); SetopenOrderactive("OrderHistory") }}>
                                                Order History
                                            </button>
                                        </div>
                                        <button className="hide_icon">
                                            <span className='checkbox' onClick={checkboxBtn}>
                                                {Checkbox && (
                                                    <svg width={11} height={8} viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.49976 7.50067C4.30786 7.50067 4.11578 7.4278 3.96985 7.28076L0.719727 4.03082C0.426758 3.73767 0.426758 3.2627 0.719727 2.96973C1.01288 2.67676 1.48785 2.67676 1.78082 2.96973L4.50085 5.68976L9.47089 0.719727C9.76385 0.426758 10.2388 0.426758 10.5318 0.719727C10.8248 1.0127 10.8248 1.48767 10.5318 1.78082L5.03186 7.28076C4.88373 7.4278 4.69183 7.50067 4.49976 7.50067Z" fill="#636F7D" /></svg>
                                                )
                                                }
                                            </span>
                                            <span onClick={checkboxBtn}>Hide Other Coins</span>
                                        </button>
                                    </div>
                                    <div className="order__wrapper">

                                        <div className="order__data ">
                                            <ul className="order__tabs__Data">
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Order ID
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Coin
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Amount
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data ">
                                                    <h4>
                                                        Filled
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data daily_interest_rate">
                                                    <h4>
                                                        Daily Interest Rate
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Terms
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Time
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data ">
                                                    <h4>
                                                        Filled
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data cancer__order">
                                                    <button className="cancel__btn">
                                                        <span>
                                                            Cancel All
                                                        </span>
                                                        <span>
                                                            Operation
                                                        </span>
                                                    </button>
                                                </li>
                                            </ul>
                                            {openOrderactive === "OpenOrders" && (
                                                <ul className="inner__data__order">




                                                </ul>
                                            )}
                                            {openOrderactive === "Unsettled" && (
                                                <ul className="inner__data__order">




                                                </ul>
                                            )}
                                            {openOrderactive === "Settled" && (
                                                <ul className="inner__data__order">




                                                </ul>
                                            )}
                                            {openOrderactive === "OrderHistory" && (
                                                <ul className="inner__data__order">




                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {dropdown === "Borrow" && (
                        <div className="main_tabs_content">
                            <form className="form m_form"><label className="label" htmlFor="seach_coin_referral" />
                                <input id="seach_coin_referral" type="text" placeholder="Search Coins" />
                            </form>
                            <div className="tabs_content">
                                <MarketSlider />
                            </div>
                            <div className="lend_btc">
                                <h3 className="heading">
                                    Lend BTC
                                </h3>
                                <div className='lend_btn'>
                                    <span> Auto - Lend</span>
                                    <button className={`button ${openLendactive && ("active")}`} onClick={openLend}>
                                    </button>
                                </div>
                            </div>
                            {
                                (!openLendactive) && (
                                    <form className="total_assets">
                                        <div className="assets">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Main Account Available:
                                                </h5>
                                                <span>0</span>
                                                <img src={require("../Assets/Icons/convert-icon.svg").default} alt="" />
                                            </div>
                                            <div className='input'>
                                                <input id='convertcoin' type="number" placeholder='Amount' />
                                                <label htmlFor="convertcoin">BTC</label>
                                            </div>
                                            <div className="terms">
                                                <ul>
                                                    <li className='item'>25%</li>
                                                    <li className='item'>50%</li>
                                                    <li className='item'>75%</li>
                                                    <li className='item'>100%</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="assets min_day_rate">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Optimal Rate:
                                                </h5>
                                                <span>0.009%</span>
                                            </div>
                                            <div className='input'>
                                                <input id='convertcoin2' type="number" placeholder='Reserved Amount' />
                                                <label htmlFor="convertcoin2">%</label>
                                            </div>
                                            <div className="terms">
                                                <ul>
                                                    <li className='main_term'>Terms:</li>
                                                    <li className='item'>7 Days</li>
                                                    <li className='item'>14 Days</li>
                                                    <li className='item'>28 Days</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="assets lend_btc_assets">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Estimated Profit
                                                </h5>
                                                <span className='btc'>0 BTC </span>
                                                <span className='usd'>- 0 USD</span>
                                            </div>
                                            <button className='enable_auto_lend' onClick={openLend}>
                                                Lend BTC
                                            </button>
                                        </div>
                                    </form>
                                )
                            }
                            {
                                (openLendactive) && (
                                    <form className="total_assets">
                                        <div className="assets">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Total Assets:
                                                </h5>
                                                <span>0</span>
                                                <img src={require("../Assets/Icons/convert-icon.svg").default} alt="" />
                                            </div>
                                            <div className='input'>
                                                <input id='convertcoin3' type="text" placeholder='Reserved Amount' />
                                                <label htmlFor="convertcoin3">BTC</label>
                                            </div>
                                            <p className="info">
                                                Lending All Excludes the Reserved
                                            </p>
                                        </div>
                                        <div className="assets min_day_rate">
                                            <div className="heading">
                                                <h5 className="inner_heading">
                                                    Min. Day Rate
                                                </h5>
                                            </div>
                                            <div className='input'>
                                                <input id='convertcoinsecond' type="text" placeholder='Reserved Amount' />
                                                <label htmlFor="convertcoinsecond">%</label>
                                            </div>
                                            <div className="terms">
                                                <ul>
                                                    <li className='main_term'>Terms:</li>
                                                    <li className='item'>7 Days</li>
                                                    <li className='item'>14 Days</li>
                                                    <li className='item'>28 Days</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="assets">
                                            <button className='enable_auto_lend' onClick={openLend}>
                                                Enable Auto - Lend
                                            </button>
                                            <p className="info">
                                                The system will automatically lend out the available funds at the <span>priority interest rate</span>  for you. If the current priority interest rate is lower than your preset minimum day rate, the system will place the lending order at your preset one.
                                            </p>
                                        </div>
                                    </form>
                                )
                            }
                            {/* USDT Funding Market */}
                            <div className="usdt_funding_market">
                                <div className="market_header">
                                    <h2 className="heading t-primary-color">
                                        USDT Funding Market
                                    </h2>
                                    <div className="market_dropdown">
                                        <button className='dropdown_btn' onClick={marketBtn}>
                                            <span>{marketOptiontext}</span>
                                            <svg width={13} height={8} viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 1.5L6.5 6.5L1.5 1.5" stroke="#636F7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                        {marketOption && (
                                            <>
                                                <ul className='dropdown_options'>
                                                    <li>
                                                        <button onClick={() => { marketBtn(); SetmarketOptiontext("one") }} >One</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => { marketBtn(); SetmarketOptiontext("Two") }} >Two</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => { marketBtn(); SetmarketOptiontext("Three") }} >Three</button>
                                                    </li>
                                                </ul>
                                                <div className="bg_layer" onClick={marketBtn}>
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                </div>
                                <div className="market_data">
                                    <div className="content_heading">
                                        <ul>
                                            <li className="t-normal-color">Daily Interest Rate</li>
                                            <li className="t-normal-color">Annualized Rate</li>
                                            <li className="t-normal-color">Terms</li>
                                            <li className="t-normal-color total">Total Amount</li>
                                        </ul>
                                    </div>

                                    <div className="update_trending_coin_tabs">
                                        {marketOptiontext === "All" &&
                                            <div className="tabs_content active">
                                                <div className="content_inner_data">
                                                    <ul className="coins_price">
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%
                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>

                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>

                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%


                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>
                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        {marketOptiontext === "one" &&
                                            <div className="tabs_content active">
                                                <div className="content_inner_data">
                                                    <ul className="coins_price">
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%
                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>

                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>

                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%


                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>
                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        {marketOptiontext === "Two" &&
                                            <div className="tabs_content active">
                                                <div className="content_inner_data">
                                                    <ul className="coins_price">
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%
                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>

                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>

                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%


                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>
                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        {marketOptiontext === "Three" &&
                                            <div className="tabs_content active">
                                                <div className="content_inner_data">
                                                    <ul className="coins_price">
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%
                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>

                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>

                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <ul>
                                                                <li>
                                                                    <a className="coin f-normal-color" href="#/">0.01%


                                                                    </a>
                                                                </li>
                                                                <li className="last_price">
                                                                    <a className="t-primary-color" href="#/">
                                                                        3.65%
                                                                    </a>
                                                                </li>
                                                                <li className="h_changes">
                                                                    <a className="t-primary-color" href="#/">
                                                                        7 Days
                                                                    </a>
                                                                </li>
                                                                <li className='amount'>
                                                                    <a className='total_amount' href="#/">
                                                                        <span className="t-primary-color">
                                                                            8,642,369 USDT
                                                                        </span>
                                                                        <span className='t-normal-color'>
                                                                            ≈ 8,646,690.18USD
                                                                        </span>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="btn_bg_layer" />
                                </div>
                            </div>
                            {/* Inner Tabs  */}
                            <div className="order_detail_tabs">
                                <div className="order__tabs">
                                    <div className="order__details">
                                        <div className="order__details__inner">
                                            <button className={`order__button ${openOrderactive === "OpenOrders" ? "active" : ""}`} onClick={() => { openOrder(); SetopenOrderactive("OpenOrders") }}>
                                                Open Orders
                                            </button>
                                            <button className={`order__button ${openOrderactive === "Unsettled" ? "active" : ""}`} onClick={() => { openOrder(); SetopenOrderactive("Unsettled") }}>
                                                Unsettled
                                            </button>
                                            <button className={`order__button ${openOrderactive === "Settled" ? "active" : ""}`} onClick={() => { openOrder(); SetopenOrderactive("Settled") }}>
                                                Settled
                                            </button>
                                            <button className={`order__button ${openOrderactive === "OrderHistory" ? "active" : ""}`} onClick={() => { openOrder(); SetopenOrderactive("OrderHistory") }}>
                                                Order History
                                            </button>
                                        </div>
                                        <button className="hide_icon">
                                            <span className='checkbox' onClick={checkboxBtn}>
                                                {Checkbox && (
                                                    <svg width={11} height={8} viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.49976 7.50067C4.30786 7.50067 4.11578 7.4278 3.96985 7.28076L0.719727 4.03082C0.426758 3.73767 0.426758 3.2627 0.719727 2.96973C1.01288 2.67676 1.48785 2.67676 1.78082 2.96973L4.50085 5.68976L9.47089 0.719727C9.76385 0.426758 10.2388 0.426758 10.5318 0.719727C10.8248 1.0127 10.8248 1.48767 10.5318 1.78082L5.03186 7.28076C4.88373 7.4278 4.69183 7.50067 4.49976 7.50067Z" fill="#636F7D" /></svg>
                                                )
                                                }
                                            </span>
                                            <span onClick={checkboxBtn}>Hide Other Coins</span>
                                        </button>
                                    </div>
                                    <div className="order__wrapper">

                                        <div className="order__data ">
                                            <ul className="order__tabs__Data">
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Order ID
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Coin
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Amount
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data ">
                                                    <h4>
                                                        Filled
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data daily_interest_rate">
                                                    <h4>
                                                        Daily Interest Rate
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Terms
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data">
                                                    <h4>
                                                        Time
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data ">
                                                    <h4>
                                                        Filled
                                                    </h4>
                                                </li>
                                                <li className="order__tabs__inner__Data cancer__order">
                                                    <button className="cancel__btn">
                                                        <span>
                                                            Cancel All
                                                        </span>
                                                        <span>
                                                            Operation
                                                        </span>
                                                    </button>
                                                </li>
                                            </ul>
                                            {openOrderactive === "OpenOrders" && (
                                                <ul className="inner__data__order">




                                                </ul>
                                            )}
                                            {openOrderactive === "Unsettled" && (
                                                <ul className="inner__data__order">




                                                </ul>
                                            )}
                                            {openOrderactive === "Settled" && (
                                                <ul className="inner__data__order">




                                                </ul>
                                            )}
                                            {openOrderactive === "OrderHistory" && (
                                                <ul className="inner__data__order">




                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}
export default Cryptoearn;
