import React, { useEffect } from 'react'
import Launchpad__img from '../Assets/Images/Footer_IMG/launchpad__optins.png'
import PunchClockIcon from '@mui/icons-material/PunchClock';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';


const LaunchpadData = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
  return (
    <>
        <div className='Launchpad__sec pt__spacing'>
            <div className='Launchpad__sec__data_container'>
                <div className='Launchpad__contain'>
                    <div className='main__row'>
                        <div className='col-8'>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-3__demo'>
                                        <img src={Launchpad__img} alt="img/icon"/>
                                    </div>

                                    <div className='col-8__demo'>
                                        <div className=''>
                                            <h3 className='pro__name' > revo  </h3>
                                            <p className='pro__diss' >Revoland is an e-sport game based on Blockchain technology.</p>

                                          

                                            {/* <div className='time__in_post'>
                                                <p>2022-06-13 16:30 --  2022-06-20 16:30 (UTC+5.5)</p>
                                            </div> */}

                                            <div className='links__a'>
                                                <div className='linka_a'>
                                                   <Link to="">
                                                        <LanguageIcon/>
                                                        <p> Website</p>
                                                   </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='pro___data'>
                                    <div className=''>
                                        <h4>150,000 REVO</h4>
                                        <h5>Total Allocation</h5>
                                    </div>

                                    <div className=''>
                                        <h4>2,000</h4>
                                        <h5>Total Tickets</h5>
                                    </div>

                                    <div className=''>
                                        <h4>75 REVO</h4>
                                        <h5>Each Ticket</h5>
                                    </div>
                                    <div className=''>
                                        <h4>1 REVO = 5.16732308 BGB</h4>
                                        <h5>Sale Price </h5>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='col-4'>
                            <div className='card___closed'>
                                <div className='left_top'>
                                    <PunchClockIcon/>

                                    <div className='left_top__data'>
                                        <h5>-</h5>
                                        <p> Obtained Tickets</p>
                                    </div>
                                </div>

                                <div className='left_top'>
                                    <PunchClockIcon/>

                                    <div className='left_top__data'>
                                        <h5>-</h5>
                                        <p> Winning Tickets</p>
                                    </div>
                                </div>

                                <button type='button' className='btn'>Closed</button>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    
    </>
  )
}

export default LaunchpadData