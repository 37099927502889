import { useState, useEffect } from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { _fetch } from "../config/api";
import { title, logo, api_url } from "../config/config";

// import toast, { Toaster } from 'react-hot-toast';
import toasted from "../config/toast";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import PhoneInput from "react-phone-number-input/react-hook-form-input";
// import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
// import 'react-phone-number-input/style.css';
// import ReactCountryFlag from "react-country-flag";

// import Input, {
//     getCountries,
//     getCountryCallingCode,
// } from "react-phone-number-input/input";
// import en from "react-phone-number-input/locale/en.json";
// import "react-phone-number-input/style.css";
// import countryList from 'react-select-country-list'
// import { CountryData1 } from "../../public/countrycode";

import { useForm } from "react-hook-form"

type formTypes = {
    setUserLogin: any
    formType: "signup" | "login" | "forgot-password" | "forgot-password-number" | "login-qr",

}

const LoginSignup = (props: any) => {

    const { sponser } = useParams()
    // let history : any  = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [value, setValue] = useState()
    const [phone, setphone] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState()
    const [signUpEmail, setsignUpEmail] = useState()
    const [signupPhone, setsignupPhone] = useState(0)
    const [refrel, setrefrel] = useState('')
    const [signupPassword, setsignupPassword] = useState()
    const [signupConformPassword, setsignupConformPassword] = useState()
    const location = useLocation();
    let navigate = useNavigate();
    const [AuthOTP, setAuthOTP] = useState()
    const [AuthOTPeMAIL, setAuthOTPeMAIL] = useState()

    const [signupForm, setSignupForm] = useState({
        Email: "",
        Phone: "",
        Password: ""
    } as any);
    const [loginForm, setLoginForm] = useState({
        Email: "",
        Phone: "",
        Password: ""
    } as any);

    //    Signup Form
    const updateSignupForm = (e: any) => {
        setSignupForm((preState: any) => {
            return {
                ...preState,
                [e.target.name]: e.target.value
            }
        });
    }
    //    LogIn Form
    const updateloginForm = (e: any) => {
        setLoginForm((preState: any) => {
            return {
                ...preState,
                [e.target.name]: e.target.value
            }
        });
    }
    const [signUpemailOtp, setsignUpemailOtp] = useState('')
    //    Signup Form

    const [otpName, setotpName] = useState('')

    const signupSubmit = async (e: any) => {

        e.preventDefault();
        const signup = {
            login_type: "email",
            email: signUpEmail,
            password: signupPassword,
            passwordConfirm: signupConformPassword,
            referal: refrel,
            otp: signUpemailOtp,
        }
        var d = await _fetch(`${api_url}/auth/signup`, 'POST', signup, {});
        // console.log(d)
        // var d = await _fetch('https://reqres.in/api/users?page=2', 'GET', 'a');

        if (d?.status == 'success') {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", d?.token);
            if (d.status == "success") {
                localStorage.setItem("user", JSON.stringify(d?.data.user));
            }
            navigate("/Dashboard");
            toasted.success(d?.message);
        } else {
            toasted.error(d?.message);
        }

    }

    const [signUpphoneOtp, setsignUpphoneOtp] = useState('')

    const signupSubmitPhone = async (e: any) => {
        const newNum = signupPhone.toString();
        const num = newNum.slice(-10);
        const country = newNum.substring(0, newNum.length - 10);


        e.preventDefault();
        const signup = {
            login_type: "phone",
            country_code: country,
            phone: signupPhone,
            password: signupPassword,
            passwordConfirm: signupConformPassword,
            referal: refrel,
            otp: signUpphoneOtp,
        }
        var d = await _fetch(`${api_url}/auth/signup`, 'POST', signup, {});
        // console.log(d)
        // var d = await _fetch('https://reqres.in/api/users?page=2', 'GET', 'a');

        if (d?.status == 'success') {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", d?.token);
            if (d.status == "success") {
                localStorage.setItem("user", JSON.stringify(d.data.user));
            }
            navigate("/Dashboard");
            toasted.success(d?.message);
        } else {
            toasted.error(d?.message);
        }

    }

    //    LogIn Form
    const loginSubmit = async (e: any) => {
        e.preventDefault();
        const loginData = {
            email: email,
            password: password,
            login_type: "email",
            otp: AuthOTP,
        }
        var d = await _fetch(`${api_url}/auth/login`, 'POST', loginData, {});
        // console.log(d)
        // var d = await _fetch('https://reqres.in/api/users?page=2', 'GET', 'a');

        if (d?.status == 'success') {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", d?.token);
            // console.log('d?.data.user.kyc_status', d?.data.user.kyc_status);
            if (d.status == "success") {
                localStorage.setItem("user", JSON.stringify(d.data.user));
            }
            if (d?.data.kyc.kyc_status === 'Not Applied' || d?.data.kyc.kyc_status === 'Reject') {
                navigate("/Dashboard/Kyc");
            } else {
                navigate("/Dashboard");
            }
            toasted.success(d?.message);
        } else {
            localStorage.clear();
            toasted.error(d?.message);
        }

    }
    const loginSubmitPhone = async (e: any) => {
        e.preventDefault();
        const loginData = {
            phone: phone,
            password: password,
            login_type: "phone",
            otp: AuthOTPeMAIL,

        }
        var d = await _fetch(`${api_url}/auth/login`, 'POST', loginData, {});
        // console.log(d)
        // var d = await _fetch('https://reqres.in/api/users?page=2', 'GET', 'a');

        if (d?.status == 'success') {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", d?.token);
            if (d.status == "success") {
                localStorage.setItem("user", JSON.stringify(d.data.user));
            }
            if (d?.data.kyc.kyc_status === 'Not Applied' || d?.data.kyc.kyc_status === 'Reject') {
                navigate("Dashboard/Kyc");

            } else {
                navigate("/Dashboard");
            }
            toasted.success('Login SuccessFully!');
        } else {
            localStorage.clear();
            toasted.error(d?.message);

        }

    }
    const { control, handleSubmit } = useForm()

    useEffect(() => {
        document.title = props.title;

        // console.log('asdasd',sponser);
        if (sponser != null) {
            setrefrel(sponser);
        }

    }, [])

    const [timerlogin, settimerlogin] = useState('00:00');
    const [timerLoginPhn, settimerLoginPhn] = useState('00:00');

    async function generateAuthOTP() {
        if (email === '') {
            var passData: any = `&sendTo=${phone}&check=phone`;
        } else {
            var passData: any = `&sendTo=${email}&check=email`;

        }

        var d = await _fetch(`${api_url}/auth/generateAuthOTP?email_type=Login${passData}`, 'GET', {}, {});
        if (d?.status == 'success') {
            toasted.success(d?.message);
            if (email === '') {
                var passData: any = `&sendTo=${phone}&check=phone`;
            } else {
                var passData: any = `&sendTo=${email}&check=email`;

            }


            if (timer === "00:00") {
                var seconds: any = '180';
                var intervalId = window.setInterval(function () {
                    seconds--;

                    // if (seconds > 1) {
                    let minutes: any = Math.floor(seconds / 60);
                    let extraSeconds: any = seconds % 60;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
                    var leftData = minutes + ":" + extraSeconds;

                    // console.log(leftData);
                    if (email !== '') {
                        settimerlogin(leftData);
                    } else {
                        settimerLoginPhn(leftData);

                    }
                    if (seconds == 0) {
                        stop();

                    }

                }, 1000);
            }
            let stop = () => clearInterval(intervalId);
        } else {
            localStorage.clear();
            toasted.error(d?.message);

        }
    }
    const [timer, settimer] = useState('00:00');
    const [timerSignUpPhn, settimerSignUpPhn] = useState('00:00');

    async function generateSignUpOTP(val: any) {
        if (val === 'phone') {
            var passData: any = `&sendTo=${signupPhone}&check=phone`;
        } else if (val === 'email') {
            var passData: any = `&sendTo=${signUpEmail}&check=email`;

        }

        var d = await _fetch(`${api_url}/auth/generateAuthOTP?email_type=Register${passData}`, 'GET', {}, {});
        if (d?.status == 'success') {
            toasted.success(d?.message);

            if (timer === "00:00") {
                var seconds: any = '180';
                var intervalId = window.setInterval(function () {
                    seconds--;

                    // if (seconds > 1) {
                    let minutes: any = Math.floor(seconds / 60);
                    let extraSeconds: any = seconds % 60;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
                    var leftData = minutes + ":" + extraSeconds;

                    // console.log(leftData);
                    if (val === 'phone') {
                        settimerSignUpPhn(leftData)
                    } else {
                        settimer(leftData)
                    }
                    if (seconds == 0) {
                        stop();

                    }

                }, 1000);
            }
            let stop = () => clearInterval(intervalId);
        } else {
            localStorage.clear();
            toasted.error(d?.message);

        }
    }


    const [forgotmail, setforgotmail] = useState('')

   
    return (
        <>
            <section className="account_form account_for__new">
                <div className="row">
                    <div className="col-left">
                        <div className="card__bg__s">

                        </div>
                    </div>

                    <div className="col-right">
                        <div className="account_form_inner sec-py">
                            <div className="account_form_head txt-center">
                                <div className="web_logo line-height-0">
                                    <img src={logo} alt="" />
                                </div>
                                {/* <h1 className="form_web_name">
                                {title}
                            </h1> */}
                                {/* {props.formType === "signup" ?
                                // signup Page
                                <p className="account_form_head_txt">
                                    Already have an account?
                                    &nbsp;
                                    <Link to="/login" className="account_form_head_link">
                                        Log in
                                    </Link>
                                </p>
                                :
                                // Login Page
                                <p className="account_form_head_txt">
                                    Haven’t registered?
                                    &nbsp;
                                    <Link to="/signup" className="account_form_head_link">
                                        Sign up now
                                    </Link>
                                </p>
                            } */}
                            </div>
                            {/* Forms */}
                            <div className="account_form_content">
                                <Tabs>
                                    <TabList>
                                        <Tab onClick={() => setphone('')}>Email</Tab>
                                        &nbsp; | &nbsp;
                                        <Tab onClick={() => setemail('')}>Phone Number</Tab>
                                    </TabList>
                                    {/* signup Page  */}
                                    {props.formType === "signup" &&
                                        <>
                                            {/* // <form id="signupForm" onSubmit={(e: any) => { signupSubmit(e) }}>
                                    //     <div className="form_group_wrap"> */}
                                            <TabPanel>
                                                <form id="signupForm" onSubmit={(e: any) => { signupSubmit(e) }}>
                                                    <div className="form_group_wrap">
                                                        <div className="form_group form_group-flex">
                                                            <input type="text" name='email' className='form_control' placeholder='Email' value={signUpEmail} onChange={(e: any) => { setsignUpEmail(e.target.value) }} required />
                                                            <button className='optSendbtn' type='button' disabled={timer !== '00:00' ? true : false} onClick={() => { generateSignUpOTP('email'); setotpName('upEmail') }} ><span>{otpName === 'upEmail' ? 'Resend Code' : 'Send Code'}</span> {timer !== '00:00' && <>  {timer}</>}</button>

                                                        </div>
                                                        <div className="form_group txt-right forgot_password-mobile">
                                                            <button type='button' className='forgot_password  f-cta-color'>Cannot receive SMS?</button>

                                                        </div>


                                                        <div className="form_group opt_feild">
                                                            {/* <label> </label> */}
                                                            <input type="number" className='form_control' onChange={(e: any) => setsignUpemailOtp(e.target.value)} value={signUpemailOtp} placeholder='Enter OTP' required />

                                                        </div>




                                                        <div className="form_group form_group-password">
                                                            <input type={showPassword ? "text" : "password"} className='form_control' placeholder='Set Login password' name="Password" value={signupPassword} onChange={(e: any) => { setsignupPassword(e.target.value) }} required />
                                                            <button type="button" className='passwod_btn' onClick={() => { setShowPassword(!showPassword) }}>
                                                                {showPassword ?
                                                                    <img src={require(`../Assets/Icons/password-show.svg`).default} alt="" />
                                                                    :
                                                                    <img src={require(`../Assets/Icons/password-hide.svg`).default} alt="" />
                                                                }
                                                            </button>
                                                        </div>
                                                        <div className="form_group">
                                                            <input type="password" className='form_control' placeholder='Confirm Password' value={signupConformPassword} onChange={(e: any) => { setsignupConformPassword(e.target.value) }} required />
                                                        </div>

                                                    </div>
                                                    <div className="form_group form_group__sec">
                                                        <input type="text" className='form_control' placeholder='Referral Code (Optional)' value={refrel} onChange={(e: any) => { setrefrel(e.target.value) }} required />
                                                    </div>
                                                    <div className="form_group-checkbox__A">
                                                        <div className="form_group-checkbox">
                                                            <div className='form_control-checkbox-wrap'>
                                                                <input type="checkbox" id="termCondition" className='form_control-checkbox' required />
                                                                <label className='check_active' htmlFor="termCondition"><img src={require('../Assets/Icons/checkbox.svg').default} alt="" /></label>
                                                            </div>
                                                            <label htmlFor="termCondition">I have read and agree to the Terms of Use</label>
                                                        </div>

                                                        <div className="form_group txt-right forgot_password-dektop">
                                                            <button type='button' className='forgot_password  f-cta-color'>Cannot receive SMS?</button>
                                                        </div>

                                                    </div>

                                                    <div className="form_group_btn_wrap">
                                                        <button type='submit' className='form_cta_button'>
                                                            Sign Up
                                                        </button>
                                                    </div>
                                                </form>
                                            </TabPanel>

                                            <TabPanel>
                                                <form id="signupForm" onSubmit={(e: any) => { signupSubmitPhone(e) }}>
                                                    <div className="form_group_wrap">
                                                        <div className="form_group form_group-flex">
                                                            <PhoneInput
                                                                inputStyle={{ height: '50px', padding: '0px 20px 0px 50px', border: '1px solid rgba(99, 111, 125, 0.3)', borderRadius: '0', outline: 'none', color: '$txt-primary-color', width: '100%', fontSize: '15px' }}
                                                                containerStyle={{}}
                                                                searchClass="search-class"
                                                                disableSearchIcon={false}
                                                                enableTerritories
                                                                countryCodeEditable={true}
                                                                placeholder='Phone Number'
                                                                buttonStyle={{ width: '47px' }}
                                                                dropdownStyle={{ height: '150px' }}
                                                                country={"in"}
                                                                value={value}
                                                                enableSearch={true}
                                                                onChange={(newValue: any) => setsignupPhone(newValue)} />
                                                            <button className='optSendbtn' type='button' onClick={() => { generateSignUpOTP('phone'); setotpName('upphone') }} disabled={timerSignUpPhn !== '00:00' ? true : false}><span>{otpName === 'upphone' ? 'Resend Code' : 'Send OTP'} </span> {timerSignUpPhn !== '00:00' && <>{timerSignUpPhn}</>}</button>
                                                        </div>
                                                        {/* <div className="form_group">
                                                            <input type="text" className='form_control' placeholder='Phone Or Email Verification Code' />
                                                        </div> */}

                                                        <div className="form_group opt_feild">
                                                            {/* <label></label> */}

                                                            <input type="number" className='form_control' onChange={(e: any) => setsignUpphoneOtp(e.target.value)} value={signUpphoneOtp} placeholder='Enter OTP' required />
                                                        </div>

                                                        <div className="form_group form_group-password">
                                                            <input type={showPassword ? "text" : "password"} className='form_control' placeholder='Set Login password' name="Password" value={signupPassword} onChange={(e: any) => { setsignupPassword(e.target.value) }} required />
                                                            <button type="button" className='passwod_btn' onClick={() => { setShowPassword(!showPassword) }}>
                                                                {showPassword ?
                                                                    <img src={require(`../Assets/Icons/password-show.svg`).default} alt="" />
                                                                    :
                                                                    <img src={require(`../Assets/Icons/password-hide.svg`).default} alt="" />
                                                                }
                                                            </button>
                                                        </div>
                                                        <div className="form_group">
                                                            <input type="password" className='form_control' placeholder='Confirm Password' value={signupConformPassword} onChange={(e: any) => { setsignupConformPassword(e.target.value) }} required />
                                                        </div>

                                                    </div>

                                                    <div className="form_group form_group__sec">
                                                        <input type="text" className='form_control' placeholder='Referral Code (Optional)' value={refrel} onChange={(e: any) => { setrefrel(e.target.value) }} required />
                                                    </div>
                                                    <div className="form_group-checkbox__A">
                                                        <div className="form_group-checkbox">
                                                            <div className='form_control-checkbox-wrap'>
                                                                <input type="checkbox" id="termCondition" className='form_control-checkbox' required />
                                                                <label className='check_active' htmlFor="termCondition"><img src={require('../Assets/Icons/checkbox.svg').default} alt="" /></label>
                                                            </div>
                                                            <label htmlFor="termCondition">I have read and agree to the Terms of Use</label>
                                                        </div>
                                                        <div className="form_group txt-right forgot_password-dektop">
                                                            <button type='button' className='forgot_password  f-cta-color'>Cannot receive SMS?</button>
                                                        </div>

                                                    </div>

                                                    <div className="form_group_btn_wrap">
                                                        <button type='submit' className='form_cta_button'>
                                                            Sign Up
                                                        </button>
                                                    </div>
                                                </form>
                                            </TabPanel>
                                        </>
                                    }

                                    {/* Login Page */}
                                    {props.formType === "login" &&
                                        <>
                                            <TabPanel>
                                                <form id="loginForm" onSubmit={(e: any) => { loginSubmit(e) }}>
                                                    <div className="form_group_wrap">
                                                        <div className="form_group form_group-flex">
                                                            <input type="email" name='Email' className='form_control' placeholder='Enter your Email..' value={email} onChange={(e: any) => { setemail(e.target.value) }} required />
                                                            <button className='optSendbtn' type='button' disabled={timerlogin !== '00:00' ? true : false} onClick={() => { generateAuthOTP(); setotpName('loginMail') }}> <span>{otpName === 'loginMail' ? 'Resend Code' : 'Send Code'}</span> {timerlogin !== '00:00' && <>  {timerlogin}</>}  </button>
                                                        </div>

                                                        <div className="form_group  opt_feild counter___nunber">
                                                            {/* <label></label> */}
                                                            <input type="number" className='form_control' onChange={(e: any) => setAuthOTP(e.target.value)} value={AuthOTP} placeholder='Enter OTP' required />
                                                        </div>


                                                        {/*                                             
                                                    <div className="form_group">
                                                        <input type="text" className='form_control' placeholder='Phone Or Email Verification Code' name="Email" value={loginForm.Email} onInput={(e: any) => { updateloginForm(e) }} />
                                                    </div> */}

                                                    </div>

                                                    <div className="form_group form_group-password form_group__sec">
                                                        <input type={showPassword ? "text" : "password"} className='form_control' placeholder='Login password' name="Password" value={password} onChange={(e: any) => { setpassword(e.target.value) }} required />
                                                        <button type="button" className='passwod_btn' onClick={() => { setShowPassword(!showPassword) }}>
                                                            {showPassword ?
                                                                <img src={require(`../Assets/Icons/password-show.svg`).default} alt="" />
                                                                :
                                                                <img src={require(`../Assets/Icons/password-hide.svg`).default} alt="" />
                                                            }
                                                        </button>
                                                    </div>

                                                    <div className="form_group ">
                                                        <div className="form_group-checkbox__A">
                                                            <div className="">
                                                                <Link to='/login-qr' className='forgot_password  f-cta-color t-normal-color'>
                                                                    Log In With <span className='f-cta-color font-w-600'>QR Code</span>
                                                                </Link>
                                                            </div>

                                                            <div className="form_group txt-right forgot_password-dektop">
                                                                <Link to='/forgot-password' className='forgot_password t-normal-color'>Forgot Password?</Link>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="form_group_btn_wrap">
                                                        <button type='submit' className='form_cta_button'>
                                                            Log In
                                                        </button>
                                                    </div>
                                                </form>
                                            </TabPanel>

                                            <TabPanel>
                                                <form id="loginForm" onSubmit={(e: any) => { loginSubmitPhone(e) }}>
                                                    <div className="form_group_wrap">
                                                        {/* <div className="form_group form_group-flex">
                                                            <input type="number" name='phone' className='form_control' placeholder='Phone ..' value={phone} onChange={(e: any) => { setphone(e.target.value) }} />
                                                            <button className='optSendbtn' type='button'>Send Code</button>
                                                        </div> */}
                                                        <div className="form_group form_group-flex">
                                                            <PhoneInput
                                                                inputStyle={{ height: '50px', padding: '0px 20px 0px 50px', border: '1px solid rgba(99, 111, 125, 0.3)', borderRadius: '5px 0 0 5px', outline: 'none', color: '$txt-primary-color', width: '100%', fontSize: '15px' }}
                                                                containerStyle={{}}
                                                                searchClass="search-class"
                                                                disableSearchIcon={false}
                                                                enableTerritories
                                                                countryCodeEditable={true}
                                                                placeholder='Phone Number'
                                                                buttonStyle={{ width: '47px' }}
                                                                dropdownStyle={{ height: '150px' }}
                                                                country={"in"}
                                                                value={value}
                                                                enableSearch={true}
                                                                onChange={(newValue: any) => setphone(newValue)} />

                                                            <button className='optSendbtn' type='button' disabled={timerLoginPhn !== '00:00' ? true : false} onClick={() => { generateAuthOTP(); setotpName('loginPhone') }}> <span>{otpName === 'loginPhone' ? 'Resend Code' : 'Send Code'}</span>  {timerLoginPhn !== '00:00' && <>{timerLoginPhn} </>}</button>
                                                        </div>



                                                        <div className="form_group  opt_feild counter___nunber">
                                                            {/* <label></label> */}
                                                            <input type="number" onChange={(e: any) => setAuthOTPeMAIL(e.target.value)} value={AuthOTPeMAIL} className='form_control' placeholder='Enter OTP' required />
                                                        </div>


                                                        {/*                                             
                                                        <div className="form_group">
                                                            <input type="text" className='form_control' placeholder='Phone Or Email Verification Code' name="Email" value={loginForm.Email} onInput={(e: any) => { updateloginForm(e) }} />
                                                        </div> */}

                                                    </div>

                                                    <div className="form_group form_group-password form_group__sec">
                                                        <input type={showPassword ? "text" : "password"} className='form_control' placeholder='Login password' name="Password" value={password} onChange={(e: any) => { setpassword(e.target.value) }} required />
                                                        <button type="button" className='passwod_btn' onClick={() => { setShowPassword(!showPassword) }}>
                                                            {showPassword ?
                                                                <img src={require(`../Assets/Icons/password-show.svg`).default} alt="" />
                                                                :
                                                                <img src={require(`../Assets/Icons/password-hide.svg`).default} alt="" />
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="form_group ">
                                                        <div className="form_group-checkbox__A">
                                                            <div className="">
                                                                <Link to='/login-qr' className='forgot_password  f-cta-color t-normal-color'>
                                                                    Log In With <span className='f-cta-color font-w-600'>QR Code</span>
                                                                </Link>
                                                            </div>

                                                            <div className="form_group txt-right forgot_password-dektop">
                                                                <Link to='/forgot-password' className='forgot_password t-normal-color'>Forgot Password?</Link>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="form_group_btn_wrap">
                                                        <button type='submit' className='form_cta_button'>
                                                            Log In
                                                        </button>
                                                    </div>
                                                </form>
                                            </TabPanel>
                                        </>
                                    }

                                     {/* forgot-password */}
                                    {props.formType === "forgot-password" &&
                                        <form id="forgotpassword">
                                            <div className="form_group_wrap">
                                                <div className="form_group form_group-flex">
                                                    <input type="email" className='form_control' placeholder='Phone Or Email..' required onChange={(e: any) => setforgotmail(e.target.value)} />
                                                    {/* <button className='optSendbtn' type='button'>Send Code</button> */}
                                                </div>
                                                {/* <div className="form_group">
                                                <input type="text" className='form_control' placeholder='Phone Or Email Verification Code' required />
                                            </div> */}
                                                <div className="form_group ">
                                                </div>
                                                <div className="form_group txt-right forgot_password-dektop">
                                                    <button type='button' className='forgot_password f-cta-color'>Cannot receive SMS? numsd</button>
                                                </div>
                                            </div>
                                            <div className="form_group_btn_wrap">
                                                <button type='button'  className='form_cta_button'>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    }


                                    {props.formType === "forgot-password-number" &&
                                        <form id="forgotpassword_A">
                                            <div className="form_group_wrap">
                                                <div className="form_group form_group-flex">
                                                    <input type="email" className='form_control' placeholder='Phone Or Email.. ioiooi' required onChange={(e: any) => setforgotmail(e.target.value)} />
                                                    {/* <button className='optSendbtn' type='button'>Send Code</button> */}
                                                </div>
                                                {/* <div className="form_group">
                                                <input type="text" className='form_control' placeholder='Phone Or Email Verification Code' required />
                                            </div> */}
                                                <div className="form_group ">
                                                </div>
                                                <div className="form_group txt-right forgot_password-dektop">
                                                    <button type='button' className='forgot_password f-cta-color'>Cannot receive SMS?d</button>
                                                </div>
                                            </div>
                                            <div className="form_group_btn_wrap">
                                                <button type='button' className='form_cta_button'>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    }


                                </Tabs>


                               
                                {/* login-qr */}
                                {props.formType === "login-qr" &&
                                    <form id="loginQr" className='txt-center'>
                                        <div className='qr_wrap'>
                                            <div className='qr_img'>
                                                <img src={logo} alt="" />
                                            </div>
                                            <div className='qr-border'>
                                                <span className='qr-bdr qr-bdr-top-left'></span>
                                                <span className='qr-bdr qr-bdr-top-right'></span>
                                                <span className='qr-bdr qr-bdr-left-center'></span>
                                                <span className='qr-bdr qr-bdr-right-center'></span>
                                                <span className='qr-bdr qr-bdr-bottom-right'></span>
                                                <span className='qr-bdr qr-bdr-bottom-left'></span>
                                            </div>
                                        </div>
                                        <p className='qr_info'>Open {title} App and scan the QR code to log in</p>
                                        <div className="form_group_btn_wrap qr">
                                            <button type='submit' className='f-cta-color font-w-600 code_refresh'>
                                                Refresh Code
                                            </button>
                                        </div>
                                    </form>
                                }

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default LoginSignup;
