import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _fetch from "../../config/api";
import { api_url } from "../../config/config";
import LoaderCom from "../loader/LoaderCom";

const TradeSocketNew = (props: any) => {
    
  const { id } = useParams();
  const [tradeData, setTradeData] = useState([]);

  const dropShow = (id: any) => {
    const a: HTMLElement | any = document.getElementById(id);
    a.classList.toggle("drop_show");
  };
  const [emptyData,setemptyData] = useState([]);
  async function fetchData() {
		var d = await _fetch(`${api_url}/trade/getBuySellHistory?trade_on=${id?.toLocaleLowerCase()}&order_status=completed`, 'GET', {}, {});
		if (d?.status === 'success') {
			setTradeData(d.data.data);
            // console.log('buy data',d.data.data)
            setemptyData(d)
		}
	}
  useEffect(() => {
    fetchData()
    if (props.symbol.toLowerCase() == 'usdt' && props.symbol != '') {
      var nowStream = `busdusdt@trade`;
    } else {
      var nowStream = `${props.symbol.toLowerCase()}usdt@trade`;
    }
    // const streams = ['@ticker', '@depth20', '@trade'];
    // const ws = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${nowStream}`);

    // ws.onmessage = function (event) {
    //   const json = JSON.parse(event.data);
    //   // console.log(json.stream, nowStream)
    //   try {
    //     if ((json)) {

    //       if (json.stream === 'bnbusdt@ticker') {

    //       } else if (json.stream === 'bnbusdt@depth20') {

    //       } else if (json.stream === nowStream) {
    //         if (json) {
    //           var d = json.data;
    //           // let tradeData2 = sort(d);
    //           // let newArray = tradeData.slice().reverse(); //[...tradeData]
    //           // newArray.reverse();
    //           // console.log(d.E)
    //           if (d.E > 0) {

    //             setTimeout(() => {
    //               tradeData.slice(0, 21);

    //               setTradeData(tradeData => [...tradeData.reverse(), d]);
    //             }, 5000);
    //           }
    //         }
    //       }
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };



  }, [])


  // function sort(tradeData: any) {
  //   return tradeData.reverse();
  // }

  // console.log(tradeData)
  return (
    <div className='trading__right-center ' id='trading__right-center'>
    <div className='card '>
      <div className='card-header' onClick={() => dropShow("trading__right-center")}> <div className='card__tab__title'>
        <h6>
          <span className='icon-up'>
            <ArrowDropUpIcon />
          </span>
          <span className='icon-down'>
            < ArrowDropDownIcon />
          </span>
        </h6>
        <p> Trade History</p>
      </div>
      </div>
      <div className='card-body '>
        <div className='table___column__2a scroll_data'>
          <div className='table__Trade  '>
            <table className="order__table">
              <thead className="s-value">
                <tr>
                  <th>
                    <h6 className="right text-uppercase">Price(USDT)</h6>
                  </th>
                  <th>
                    <h6 className="right text-uppercase">Amount({id?.toUpperCase()})</h6>
                  </th>
                  <th><h6>TIME</h6>
                  </th>
                </tr>
              </thead>
              {/* <p></p> */}
              <tbody className="">
              <LoaderCom condition={emptyData} text={'Getting Trading History Orders...'} />

                {/* {tradeData?.filter((item:any, index:any, tradeData:any) => (index < 21)).map((item:any,index:any, tradeData:any) => */}
                {tradeData?.map((item: any, index: any, tradeData: any) =>


                  <>
                    {(item.coin_price > 0) ?
                      <tr className={ item.market_trade_type != "buy" ? 'less_100' : 'profit_100'}>
                        <td className="status-dot-cell-green"> {parseFloat(item.coin_price).toFixed(3)} </td>
                        <td>{parseFloat(item.coin).toFixed(3)} </td>
                        <td>{new Date(item.createdAt).toLocaleTimeString("en-US")}  </td>
                      </tr>
                      : ''}
                  </>


                )}

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TradeSocketNew