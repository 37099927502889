import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-responsive-tabs/styles.css';
// import 'react-tabs/style/react-tabs.css';
import SearchIcon from '@mui/icons-material/Search';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Modal } from 'react-responsive-modal';

// responsive footer icon 
import ListIcon from '@mui/icons-material/List';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import HistoryIcon from '@mui/icons-material/History';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { title, api_url, logo, base_url } from '../../config/config';
import _fetch from '../../config/api';
import { Link } from 'react-router-dom';
import toasted from '../../config/toast';
// import TradeSocket from '../chart/TradeSocket';
import LivePriceBarNew from './LivePriceBarNew';
import TradeSocketNew from './TradeSocketNew';
import OpenCompleteOrderNew from './OpenCompleteOrderNew';
import OrderValumeNew from './OrderValumeNew';
import SellBuySocketNew from './SellBuySocketNew';
import MenualChart from './MenualChart';
import LoaderCom from '../loader/LoaderCom';
import { ColorRing } from 'react-loader-spinner';
import TradingManualChart from './TradingManualChart';


const TradingPageNew = (props: any) => {

	const [value, setvalue] = useState();
	const [coinData, setCoinData] = useState({});
	const [btnDisable, setbtnDisable] = useState(false);
	let [LoadApi, setsetLoadApi] = useState(0);
	const [ActiveTab, setActiveTab] = useState(0);


	const { id } = useParams();
	const dropShow = (id: any) => {
		const a: HTMLElement | any = document.getElementById(id);
		a.classList.toggle("drop_show");
	};

	const [open, setOpen] = useState(false);
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);

	// === SELL === //
	const [openSellLimit, setOpenSellLimit] = useState(false);
	const onOpenSellModal = () => setOpenSellLimit(true);
	const onCloseSellModal = () => setOpenSellLimit(false);

	const [openSellStopLimit, setOpenSellStopLimit] = useState(false);
	const onOpenSellStopModal = () => setOpenSellStopLimit(true);
	const onCloseSellStopModal = () => setOpenSellStopLimit(false);

	// === BUY === //
	const [openLimit, setOpenLimit] = useState(false);
	const onOpenLimitModald = () => setOpenLimit(true);
	const onCloseLimitModald = () => setOpenLimit(false);

	const [openStopLimit, setOpenStopLimit] = useState(false);
	const onOpenStopLimitModald = () => setOpenStopLimit(true);
	const onCloseStopLimitModald = () => setOpenStopLimit(false);


	// responsive state work 
	let [marketTab, setMarketTab] = useState('');
	let [chartTab, setChartTab] = useState('');
	let [orderTab, setOrderTab] = useState('');
	let [myorderTab, setmyorderTab] = useState('');
	let [BuySellTab, setsetBuySellTab] = useState('');



	let tradingTab = (tab: string) => {
		if (window.screen.width! <= 768) {
			// window.location.reload();
			tab == 'market' ? setMarketTab('block') : setMarketTab('none');
			tab == 'chart' ? setChartTab('block') : setChartTab('none');
			tab == 'order-table' ? setOrderTab('block') : setOrderTab('none');
			tab == 'my-orders' ? setmyorderTab('block') : setmyorderTab('none');
			tab == 'buy-sell' ? setsetBuySellTab('block') : setsetBuySellTab('none');
		}
	}

	const [elpMarket, setElpMarket] = useState([]);
	const [emptyData, setemptyData] = useState([]);
	const [changePrice, setChangePrice] = useState(0);
	const [totalBuyConvertPrice, settotalBuyConvertPrice] = useState(0);
	const [chartThemeMode, setchartThemeMode] = useState('dark');
	const [coinCurrentPrice, setcoinCurrentPrice] = useState(0);
	const [coinBallance, setCoinBallance] = useState(0);
	const [eusdPrice, seteusdPrice] = useState(0);
	const [buyStopPriceAt, setbuyStopPriceAt] = useState(0);
	const [searchedVal, setSearchedVal] = useState('');

	const [buyValue, setbuyValue] = React.useState('');

	const onCurrentPrice = (e: any) => {
		// if (e.target.value > 0) {
			const float = Number(parseFloat(e.target.value).toFixed(3));
			setcoinCurrentPrice(float);
			if (changePrice > 0 && changePrice > 0) {
				const main2 = Number(changePrice * float).toFixed(3);
				seteusdPrice(parseFloat(main2));
			}
		// } else {
		// 	if (e.target.value === '') {
		// 		setcoinCurrentPrice(0);
		// 		seteusdPrice(0)
		// 	}

		// }
	}

	const onBlur = (e: any) => {
		const float = Number(parseFloat(e.target.value).toFixed(3));
		setChangePrice(float)
		const main2 = Number(float * coinCurrentPrice).toFixed(3);
		seteusdPrice(parseFloat(main2));
	}
	const onBlur2 = (e: any) => {
		const float = Number(parseFloat(e.target.value).toFixed(3));
		const main2 = Number(e.target.value / coinCurrentPrice).toFixed(3);
		setChangePrice(parseFloat(main2));
		seteusdPrice(float);
	}
	const onBlur3 = (e: any) => {
		const float = Number(parseFloat(e.target.value).toFixed(3));
		setbuyStopPriceAt(float);
	}

	const buyPercentage = (buyPercentage: any) => {
		const float = Number(parseFloat(buyPercentage).toFixed(3));
		const totalUSDPercentage = (coinBallance * float / 100).toFixed(3);
		const main2 = Number(parseFloat(totalUSDPercentage) / coinCurrentPrice).toFixed(3);
		setChangePrice(parseFloat(main2));
		seteusdPrice(parseFloat(totalUSDPercentage));
	}
	const [coinBallanceShow, setCoinBallanceShow] = useState(0)

	const sellPercentage = (buyPercentage: any) => {

		const float = Number(parseFloat(buyPercentage).toFixed(3));
		const totalUSDPercentage = (coinBallanceShow * float / 100).toFixed(3);

		const main2 = Number(parseFloat(totalUSDPercentage) * coinBallanceShow).toFixed(3);
		const coinCurrent = (Number(totalUSDPercentage) * Number(coinCurrentPrice)).toFixed(3)


		setChangePrice(parseFloat(totalUSDPercentage));
		seteusdPrice(parseFloat(coinCurrent));
	}

	async function activityLog() {
		let d = await _fetch(`${api_url}/markets/getAllElpMarketbyType?type=trade_account`, 'GET', {}, {});
		if (d?.status === 'success') {
			setElpMarket(d?.market);
			setemptyData(d)
		}
	}


	async function BuyCoin(e: any) {
		setbtnDisable(true);
		e.preventDefault();
		const limit = {
			buy_coin_id: currentId,
			amount: changePrice,
			buyCurrency: id,
			limit_price: buyStopPriceAt,
			trade_type: (buyStopPriceAt > 0) ? 'limit' : 'market',
			symbol: id?.toLowerCase()
		}

		let d = await _fetch(`${api_url}/trade/buy-trade`, 'POST', limit, {});
		if (d?.status === 'success') {
			toasted.success(d?.message);
			setbtnDisable(false)
			setsetLoadApi(LoadApi == 0 ? 1 : 0);
			onCloseLimitModald()
			onCloseStopLimitModald()

		} else {
			toasted.error(d?.message);
			setbtnDisable(false)

		}
	}
	// ======= SELL Functions ======== //
	const [] = useState()

	async function SellCoin(e: any) {
		setbtnDisable(true)

		e.preventDefault();
		const limit = {
			amount: changePrice,
			sellCurrency: id?.toLowerCase(),
			sell_coin_id: currentId,
			limit_price: buyStopPriceAt,
			trade_type: (buyStopPriceAt > 0) ? 'limit' : 'market',
			symbol: id?.toLowerCase()
		}

		let sell = await _fetch(`${api_url}/trade/sell-trade`, 'POST', limit, {});
		if (sell?.status === 'success') {
			toasted.success(sell?.message);
			setbtnDisable(false)
			onCloseSellStopModal()
			onCloseSellModal()
			setsetLoadApi(LoadApi == 0 ? 1 : 0);

		} else {
			toasted.error(sell?.message);
			setbtnDisable(false)

		}
	}

	const [oderCurrentPrice, setOrderCurrentPrice] = useState()
	const [currentId, setCurrentId] = useState('')

	async function CoinInfo() {
		var d = await _fetch(`${api_url}/markets/getSingleTradeMarket?symbol=${id?.toLowerCase()}`, 'GET', {}, {});
		if (d?.status === 'success') {
			setcoinCurrentPrice(d?.market[0].current_price);
			setCoinBallanceShow(d?.market[0].total_coin);
			props.settradeCoinPrice(d?.market[0].current_price)

			setCurrentId(d?.market[0].coin_id);
			setOrderCurrentPrice(d?.market[0].current_price);
			setCoinData(d.market[0]);
			// console.log('dsdad',d)
		}
	}
	const [saleId, setSaleId] = useState();
	const [saleSymbol, setSaleSymbol] = useState();

	async function SaleCoinInfo() {
		var d = await _fetch(`${api_url}/markets/getSingleTradeMarket?symbol=usdt`, 'GET', {}, {});
		if (d?.status === 'success') {
			setSaleId(d?.market[0].coin_id);
			let f = Number(d?.market[0].usdt_income).toFixed(3)
			setCoinBallance(parseFloat(f));
			// console.log('f',d?.market[0].usdt_income);

			setSaleSymbol(d?.market[0].symbol);
		}
	}
	// console.log('coinBallanceShow',coinBallanceShow)


	useEffect(() => {
		document.title = title + ` EXCHANGE  TRADING ON ` + id?.toLocaleUpperCase();

		activityLog()
		CoinInfo()
		SaleCoinInfo()
	}, [LoadApi])
	// console.log('coinData',)
	const copyOrders = (data: any) => {
		setbuyStopPriceAt(data.coin_price?.toFixed(3))

		setChangePrice(data.coin?.toFixed(3))
		setcoinCurrentPrice(data.coin_price?.toFixed(3))
		seteusdPrice(data.amount)

		if (data.market_trade_type === "buy") {
			setActiveTab(0)

		} else {
			setActiveTab(1)

		}
		// setActiveTab(Number(tab))
		// console.log(data);

	}
	// console.log('ActiveTab', title);

	return (
		<>

			<div className='trading_responsive_tab'>
				<div className="nav Footer___tab">
					<div className='react-tab__1' onClick={() => tradingTab('market')} >
						<a className="btn active">
							<ListIcon />
							<span> Markets</span>
						</a>
					</div>
					<div className='react-tab__1' onClick={() => tradingTab('chart')} >
						<a className="btn">
							<AutoGraphIcon />
							<span> Charts</span>
						</a>
					</div>
					<div className='react-tab__1' onClick={() => tradingTab('buy-sell')} >
						<a className="btn logo__Main">
							<div className='react-tab__1__logo'>
								<img src={logo} alt="" />
							</div>
							<span> Buy/Sell</span>
						</a>
					</div>
					<div className='react-tab__1' onClick={() => tradingTab('order-table')} >
						<a className="btn">
							<HistoryIcon />
							<span> Trades</span>
						</a>
					</div>
					<div className='react-tab__1' onClick={() => tradingTab('my-orders')} >
						<a className="btn">
							<PersonPinIcon />
							<span> My Orders</span>
						</a>
					</div>
				</div>
			</div>



			<div className='trading_main'>
				<div className='trading_main_column trading_left' style={{ display: marketTab }}>
					<div className='d__trading_none'>
						<Tabs>
							<TabList className="nav ul___left">
								<Tab>
									<a className="btn active"> USDT</a>
								</Tab>
								{/* <Tab>
       						<a className="btn "> Usdt</a>
       					</Tab>
       					<Tab>
       						<a className="btn"> ELP</a>
       					</Tab> */}
							</TabList>
							<div className='ul__search_left'>
								<form>
									<label htmlFor='sd'>
										<SearchIcon />
									</label>
									{/* <input type="search" className='form-control' placeholder='hello' /> */}
									<input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearchedVal(e.target.value)} />

								</form>
							</div>
							<div className='searchItem__header'>
								<div className='searchItem__data'>
									<p>Pair</p>
								</div>
								<div className='searchItem__data'>
									<p>Vol</p>
								</div>
								<div className='searchItem__data'>
									<p>Changed</p>
								</div>
							</div>
							<div className='tab_as mt-2'>
								<TabPanel>
									<div className='tab___data__flex'>
										<LoaderCom condition={emptyData} text={`Getting latest ` + title + ` Market...`} />

										{elpMarket?.filter((value: any, index: any) =>
											!searchedVal.length || value?.symbol
												.toString()
												.toLowerCase()
												.includes(searchedVal?.toString().toLowerCase())
										).map((value: any, index: any) =>

											<>
												<div className='col_left__trading__1'>
													<a href={value.token_type == "auto" ? `${base_url}/Dashboard/trade/${value?.symbol}` : `${base_url}/Dashboard/trading/${value?.symbol}`} className='tab__data__inner'>
														<div className='tab__data__inner__left'>
															<div className='coin__img'>
																{/* <MonetizationOnIcon /> */}
																<img src={value?.image} />
															</div>

															<div className='coin__name'>
																<p>{value.symbol ? value?.symbol.toUpperCase() : null} <small>/USDT</small></p>
																{value?.price_change_percentage_24h ? value?.price_change_percentage_24h > 0 ? <div className='trading__status'>
																	<h6 className='green__status'>
																		<ArrowDropUpIcon />
																		<span>{value?.price_change_percentage_24h.toFixed(2)}%</span>
																	</h6>
																</div> : <div className='trading__status'>
																	<h6 className='red__status'>
																		<ArrowDropDownIcon />
																		<span>{value?.price_change_percentage_24h.toFixed(2)}%</span>
																	</h6>
																</div> : null}

															</div>

														</div>



														<div className='coin__values'>
															<p> {value.current_price ? value.current_price.toFixed(5) : null} <span className='coin__name_values'>USDT</span>
															</p>
															<h6>
																<AttachMoneyIcon />
																<small>{value.usdt_income ? value.usdt_income.toFixed(2) : '0.00'} <br />  </small><br />
															</h6>
															<h6>
																<small> {`${value?.symbol.toUpperCase()} ${value.total_coin ? value.total_coin.toFixed(2) : '0.00'}`} </small><br />
															</h6>
														</div>
													</a>
												</div>
											</>
										)}
									</div>
								</TabPanel>
								<TabPanel>
									<div className='tab___data__flex'>
										<div className='col_left__trading__1'>
											<a href='#' className='tab__data__inner'>
												<div className='tab__data__inner__left'>
													<div className='coin__img'> {/* <img src={require("../Assets/Icons/trading/Iscons.svg").default} alt="Right-arrow-icon" /> */}
														<MonetizationOnIcon />
													</div>
													<div className='coin__name'>
														<p>Zrx <small>/btc</small>
														</p>
														<div className='trading__status'>
															<h6 className='green__status'>
																<ArrowDropUpIcon />
																<span>0%</span>
															</h6>
														</div>
													</div>
												</div>
												<div className='coin__values'>
													<p> 00001223 <span className='coin__name_values'>btc</span>
													</p>
													<h6>
														<CurrencyRupeeIcon />

														<small>21.72 </small>
													</h6>
												</div>
											</a>
										</div>
									</div>
								</TabPanel>
								<TabPanel>
									<div className='tab___data__flex'>
										<div className='col_left__trading__1'>
											<a href='#' className='tab__data__inner'>
												<div className='tab__data__inner__left'>
													<div className='coin__img'> {/* <img src={require("../Assets/Icons/trading/Iscons.svg").default} alt="Right-arrow-icon" /> */}
														<MonetizationOnIcon />
													</div>
													<div className='coin__name'>
														<p>{title} <small>/btc</small>
														</p>
														<div className='trading__status'>
															<h6 className='green__status'>
																<ArrowDropUpIcon />
																<span>1%</span>
															</h6>
														</div>
													</div>
												</div>
												<div className='coin__values'>
													<p> 002223 <span className='coin__name_values'>btc</span>
													</p>
													<h6>
														<CurrencyRupeeIcon />
														<small>1.72 </small>
													</h6>
												</div>
											</a>
										</div>
									</div>
								</TabPanel>
							</div>
						</Tabs>
					</div>
				</div>
				<div className='trading_main_column trading_center '>
					<div className='trading_center_top__2' style={{ display: chartTab }}>
						<div className='d__trading_none'>
							<LivePriceBarNew symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} coinData={coinData} />

							<div className='chart__mobail '>
								{/* <MenualChart /> */}
								{/* <MenualChart LoadApi={LoadApi} /> */}
								<TradingManualChart LoadApi={LoadApi} chartTab={chartTab} />
								{/* <TradingViewWidget symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} /> */}
							</div>
						</div>
					</div>
					<div className='trading_center_top__2' style={{ display: orderTab }}>
						<div className='d__trading_none'>
							<div className='trading__center__column '>
								<div className='trading__left-center'>
									<Tabs>
										<div className='card' id='card__left__table_1'>
											<div className='card-header'>
												<div className='card__tab__title' onClick={() => dropShow("card__left__table_1")} > <h6>
													<span className='icon-up'>
														<ArrowDropUpIcon />
													</span>
													<span className='icon-down'>
														< ArrowDropDownIcon />
													</span>
												</h6>
													<p> ORDER BOOK</p>
												</div>
												<div className='card__tab__title_a'>
													<TabList className="nav ul___left">
														<Tab>
															<a className="btn active"> Market Depth</a>
														</Tab>
														<Tab>
															<a className="btn "> Order Volume</a>
														</Tab>
													</TabList>
												</div>
											</div>
											<div className='card-body'>
												<TabPanel>
													{/* <div className='table___column__1a scroll_data'> */}
													<SellBuySocketNew symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} setActiveTab={setActiveTab} copyOrder={copyOrders} LoadApi={LoadApi} setsetLoadApi={setsetLoadApi} />


												</TabPanel>
												<TabPanel>
													<OrderValumeNew symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} current_price={oderCurrentPrice} LoadApi={LoadApi} setsetLoadApi={setsetLoadApi} />

												</TabPanel>
											</div>
										</div>
									</Tabs>
								</div>
								<TradeSocketNew symbol={id?.toLocaleLowerCase() === 'usdt' ? 'busd' : id} />

							</div>
						</div>
					</div>
				</div>
				<div className='trading_main_column trading_right'>
					<OpenCompleteOrderNew copyOrder={copyOrders} myorderTab={myorderTab} LoadApi={LoadApi} price={coinData} setsetLoadApi={setsetLoadApi}  />

					{/* buy and sell */}
					<div className='buy-sell-section trading_center_top__2' style={{ display: BuySellTab }}>
						<Tabs selectedIndex={ActiveTab} onSelect={(index) => setActiveTab(index)}>
							<div className='card-buy-sell'>
								<div className='card-header'>
									<TabList className="nav ul___left" >
										<Tab>
											<div className="btn active" onClick={() => { console.log("BUY") }}> Buy</div>
										</Tab>
										<Tab className="react-tabs__tab sell_btn">
											<div className="btn " onClick={() => { console.log("SELL") }}> Sell</div>
										</Tab>
									</TabList>
								</div>
								<div className='card-body'>
									<TabPanel>
										<div className='buy_data_card'>
											<Tabs>
												<div className='tab__card'>
													<TabList className="nav ul___left">
														<Tab>
															<a className="btn active"> Market</a>
														</Tab>
														<Tab>
															<a className="btn "> Stop Limit</a>
														</Tab>
													</TabList>
												</div>
												<div className='tab__card_inner'>
													<TabPanel>
														<form>
															<div className='form-group'>
																<div className='lable'>
																	<div> AT PRICE </div>
																	<div> USDT </div>
																</div>
																<input type="number" placeholder='0.0'
																	// defaultValue={''}
																	// value={coinCurrentPrice}
																	value={(coinCurrentPrice == 0) ? '' : coinCurrentPrice}
																	onChange={onCurrentPrice}
																	readOnly
																	// onBlur={onCurrentPrice}
																	// onKeyPress={onCurrentPrice}
																	className='at__price_input' />
																<div className='btn-input'>
																	<span> Lowest Price </span>
																</div>
															</div>
															<div className='form-group'>
																<div className='lable'>
																	<div> Amount </div>
																	<div> {id}</div>
																</div>
																<input type="number" placeholder='0'
																	value={changePrice}
																	onChange={onBlur}
																/>
															</div>
															<div className='form-group form-group_last'>
																<div className='lable lable-4'>
																	<div> Total</div>
																	<div> USDT</div>
																</div>
																<input type="number" placeholder='0'
																	// defaultValue={0}
																	onChange={onBlur2}
																	value={eusdPrice}
																/>
															</div>
															<div className='form-extra'>
																<div className='left-form-group'>
																	<AccountBalanceWalletIcon />
																	<AttachMoneyIcon />
																	<span> {coinBallance}</span>
																</div>
																<div className='right-form-group'>
																	<button className='btn' type='button' onClick={() => buyPercentage('25')}> 25% </button>
																	<button className='btn' type='button' onClick={() => buyPercentage('50')}> 50% </button>
																	<button className='btn' type='button' onClick={() => buyPercentage('75')}> 75% </button>
																	<button className='btn' type='button' onClick={() => buyPercentage('100')}> 100% </button>
																</div>
															</div>
															<div className='btn__form'>
																<div className='btn' onClick={() => (changePrice > 0) ? onOpenLimitModald() : toasted.error('Please Enter a valid Amount!')}> BUY {id}</div> {/* <button type='submit' className='btn' onClick={onOpenModal}> BUY UFI </button> */}
															</div>
														</form>
													</TabPanel>
													<TabPanel>
														<form>
															<div className='form-group'>
																<div className='lable'>
																	<div> STOP PRICE </div>
																	<div> USDT</div>
																</div>
																<input type="number" placeholder='0'
																	onChange={onBlur3}
																	value={buyStopPriceAt}
																/>
															</div>
															<div className='form-group'>
																<div className='lable'>
																	<div> AT PRICE </div>
																	<div> USDT </div>
																</div>
																<input type="number" placeholder='0.0'
																	// defaultValue={(coinCurrentPrice > 0) ? coinCurrentPrice : 0} 
																	// value={coinCurrentPrice}
																	value={coinCurrentPrice}
																	onChange={onCurrentPrice}
																	className='at__price_input' />
																<div className='btn-input'>
																	<span> Lowest Price</span>
																</div>
															</div>
															<div className='form-group'>
																<div className='lable'>
																	<div> Amount </div>
																	<div> {id}</div>
																</div>
																<input type="number" placeholder='0'
																	value={changePrice} onChange={onBlur}
																/>
															</div>
															<div className='form-group form-group_last'>
																<div className='lable lable-4'>
																	<div> Total </div>
																	<div> USDT</div>
																</div>
																<input type="number" placeholder='0'
																	// value={(coinCurrentPrice > 0 && changePrice > 0) ? (changePrice * coinCurrentPrice).toFixed(4) : 0}
																	onChange={onBlur2}
																	value={eusdPrice}
																/>
															</div>
															<div className='form-extra'>
																<div className='left-form-group'>
																	<AccountBalanceWalletIcon />
																	<AttachMoneyIcon />
																	<span> {coinBallance}</span>
																</div>
																<div className='right-form-group'>
																	<button className='btn' type='button' onClick={() => buyPercentage('25')}> 25% </button>
																	<button className='btn' type='button' onClick={() => buyPercentage('50')}> 50% </button>
																	<button className='btn' type='button' onClick={() => buyPercentage('75')}> 75% </button>
																	<button className='btn' type='button' onClick={() => buyPercentage('100')}> 100% </button>
																</div>
															</div>
															<div className='btn__form'>
																<div className='btn'
																	onClick={() => (changePrice > 0) ? (buyStopPriceAt > 0) ? onOpenStopLimitModald() : toasted.error('Please enter a valid stop price!') : toasted.error('Please Enter a valid Amount!')}> BUY {id}
																</div>
															</div>
														</form>
													</TabPanel>
												</div>
											</Tabs>
										</div>
										<div className='Next__link'>
											<span> Fee <a href='#' className='fdf'> Maker: 0.2% | Taker: 0.2% | Incl. Taxes</a>
											</span>
										</div>
									</TabPanel>

									{/* SELL */}

									<TabPanel>
										<div className='buy_data_card sell_data_card'>
											<Tabs>

												<div className='tab__card'>
													<TabList className="nav ul___left">
														<Tab>
															<a className="btn active"> Market</a>
														</Tab>
														<Tab>
															<a className="btn "> Stop Limit</a>
														</Tab>
													</TabList>
												</div>
												<div className='tab__card_inner'>
													<TabPanel>
														<form>
															<div className='form-group'>
																<div className='lable'>
																	<div> AT PRICE </div>
																	<div> USDT </div>
																</div>
																<input type="number" placeholder='0.0'
																	value={(coinCurrentPrice == 0) ? '' : coinCurrentPrice}
																	onChange={onCurrentPrice}
																	readOnly
																	className='at__price_input' />
																<div className='btn-input'>
																	<span> Lowest Price</span>
																</div>
															</div>
															<div className='form-group form-group_last'>
																<div className='lable lable-4'>
																	<div> AMOUNT </div>
																	<div> {id}</div>
																</div>
																<input type="number" placeholder='0'
																	value={changePrice}
																	onChange={onBlur}
																/>
															</div>
															<div className='form-extra form-group-extra'>
																<div className='left-form-group'>
																	<AccountBalanceWalletIcon />
																	{/* {id} */}
																	<span> {`${id?.toLocaleUpperCase()} ` + coinBallanceShow?.toFixed(3)} </span>

																</div>
																<div className='right-form-group'>
																	<button className='btn' type='button' onClick={() => sellPercentage('25')}> 25% </button>
																	<button className='btn' type='button' onClick={() => sellPercentage('50')}> 50% </button>
																	<button className='btn' type='button' onClick={() => sellPercentage('75')}> 75% </button>
																	<button className='btn' type='button' onClick={() => sellPercentage('100')}> 100% </button>
																</div>
															</div>
															<div className='form-group'>
																<div className='lable'>
																	<div> Total </div>
																	<div> USDT</div>
																</div>
																<input type="number" placeholder='0'
																	// value={(coinCurrentPrice > 0 && changePrice > 0) ? (changePrice * coinCurrentPrice).toFixed(4) : 0}
																	onChange={onBlur2}
																	value={eusdPrice}
																/>
															</div>
															<div className='btn__form'>
																<div className='btn btn-sell' onClick={() => (changePrice > 0) ? onOpenSellModal() : toasted.error('Please Enter a valid Amount!')}> SELL {id}</div> {/* <button type='submit' className='btn btn-sell'> sell UFI </button> */}
															</div>
														</form>
													</TabPanel>
													<TabPanel>
														<form>
															<div className='form-group'>
																<div className='lable lable-4'>
																	<div> STOP PRICE </div>
																	<div> USDT</div>
																</div>
																<input type="number" placeholder='0'
																	onChange={onBlur3}
																	value={buyStopPriceAt}
																/>
															</div>
															<div className='form-group'>
																<div className='lable'>
																	<div> AT PRICE </div>
																	<div> USDT </div>
																</div>
																<input type="number" placeholder='0.0'
																	value={coinCurrentPrice}
																	onChange={onCurrentPrice}
																	className='at__price_input'
																/>
																<div className='btn-input'>
																	<span> Highest Price</span>
																</div>
															</div>
															<div className='form-group form-group_last'>
																<div className='lable lable-4'>
																	<div> AMOUNT </div>
																	<div> {id}</div>
																</div>
																<input type="number" placeholder='0'
																	value={changePrice} onChange={onBlur} />
															</div>
															<div className='form-extra form-group-extra'>
																<div className='left-form-group'>
																	<AccountBalanceWalletIcon />
																	{/* <AttachMoneyIcon /> */}
																	<span> {`${id?.toLocaleUpperCase()} ` + coinBallanceShow.toFixed(3)} </span>
																</div>
																<div className='right-form-group'>
																	<button className='btn' type='button' onClick={() => sellPercentage('25')}> 25% </button>
																	<button className='btn' type='button' onClick={() => sellPercentage('50')}> 50% </button>
																	<button className='btn' type='button' onClick={() => sellPercentage('75')}> 75% </button>
																	<button className='btn' type='button' onClick={() => sellPercentage('100')}> 100% </button>
																</div>
															</div>
															<div className='form-group'>
																<div className='lable'>
																	<div> Total </div>
																	<div> USDT</div>
																</div>
																<input type="number" placeholder='0'
																	onChange={onBlur2}
																	value={eusdPrice}
																/>
															</div>
															<div className='btn__form'>
																{/* <div className='btn btn-sell' onClick={onOpenModalbuy}>SELL {id} </div> */}
																<div className='btn btn-sell' onClick={() =>
																	(changePrice > 0) ? (buyStopPriceAt > 0) ? onOpenSellStopModal() : toasted.error('Please enter a valid stop price!') : toasted.error('Please Enter a valid Amount!')
																}> SELL {id}</div>
																{/* <button type='submit' className='btn btn-sell'> sell UFI </button> */}
															</div>
														</form>
													</TabPanel>
												</div>
											</Tabs>
										</div>
										<div className='Next__link'>
											<span> Fee <a href='#' className='fdf'> Maker: 0.2% | Taker: 0.2% | TDS: 1.0% | Incl. Taxes</a>
											</span>
										</div>
									</TabPanel>
								</div>
							</div>
						</Tabs>
					</div>
				</div>
			</div>







			{/* ========== BUY >>>> */}
			<form id='limit' onSubmit={(e: any) => { BuyCoin(e) }}>
				<Modal open={openLimit} onClose={onCloseLimitModald}
					classNames={{
						overlay: 'Modal__trading',
						modal: 'BUY___BUTTON',
					}} center>
					<div className="card-header">
						<h2> Buy {id} For USDT</h2>
					</div>
					<div id="Modal__details">
						<div className="modal-body">
							<div className="column__1">
								<p> Type </p>
								<p>Limit</p>
							</div>
							<div className="column__1">
								<p> At Price</p>
								<p> ${coinCurrentPrice} </p>
							</div>
							<div className="column__1">
								<p> Amount </p>
								<p className=""> {changePrice} {id} </p>
							</div>
							<div className="column__1">
								<p> Total</p>
								<p> {eusdPrice} USDT</p>
							</div>
							<div className='Next__link__2'>
								<span>  Fee <a href='#' className='fdf'> Maker: 0.2% | Taker: 0.2% | TDS: 1.0% | Incl. Taxes</a> </span>
							</div>
							<div className='btn___modal_1'>
								<button type='submit' className='modal__btn' onClick={BuyCoin} disabled={btnDisable}>

									{btnDisable === true &&
										<div className="d-flex">
											<ColorRing
												visible={true}
												height="20"
												width="20"
												ariaLabel="blocks-loading"
												wrapperStyle={{}}
												wrapperClass="blocks-wrapper"
												colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
											/>
											Please Wait...
										</div>
									}
									{btnDisable === false ? 'CONFIRM BUY' : ''}
								</button>
							</div>

						</div>
					</div>
				</Modal>
			</form>

			<form id='stopLimit' onSubmit={(e: any) => { BuyCoin(e) }}>
				<Modal open={openStopLimit} onClose={onCloseStopLimitModald}
					classNames={{
						overlay: 'Modal__trading',
						modal: 'BUY___BUTTON',
					}} center>
					<div className="card-header">
						<h2> Buy {id} For USDT</h2>
					</div>
					<div id="Modal__details">
						<div className="modal-body">
							<div className="column__1">
								<p> Type </p>
								<p>Stop Limit</p>
							</div>
							<div className="column__1">
								<p> At Price</p>
								<p > ${coinCurrentPrice} </p>
							</div>
							<div className="column__1">
								<p> Stop Price</p>
								<p > {buyStopPriceAt} USDT </p>
							</div>
							<div className="column__1">
								<p> Amount </p>
								<p className="" > {changePrice} {id} </p>
							</div>
							<div className="column__1">
								<p> Total</p>
								<p> {eusdPrice} USDT</p>
							</div>
							<div className='Next__link__2'>
								<span>  Fee <a href='#' className='fdf'> Maker: 0.2% | Taker: 0.2% | TDS: 1.0% | Incl. Taxes</a> </span>
							</div>
							<div className='btn___modal_1'>
								<button type='submit' className='modal__btn' onClick={BuyCoin} disabled={btnDisable}>


									{btnDisable === true &&
										<div className="d-flex">
											<ColorRing
												visible={true}
												height="20"
												width="20"
												ariaLabel="blocks-loading"
												wrapperStyle={{}}
												wrapperClass="blocks-wrapper"
												colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
											/>
											Please Wait...
										</div>
									}
									{btnDisable === false ? 'CONFIRM BUY' : ''}
								</button>
							</div>

						</div>
					</div>
				</Modal>
			</form>

			{/* ========== SELL >>>> */}
			<form id='Limit' onSubmit={(e: any) => { SellCoin(e) }}>
				<Modal open={openSellLimit} onClose={onCloseSellModal}
					classNames={{
						overlay: 'Modal__trading',
						modal: 'BUY___BUTTON',
					}} center>
					<div className="card-header">
						<h2>SELL {id} For USDT</h2>
					</div>
					<div id="Modal__details">
						<div className="modal-body">
							<div className="column__1">
								<p> Type </p>
								<p> Limit</p>
							</div>
							<div className="column__1">
								<p> At Price</p>
								<p> ${coinCurrentPrice} </p>
							</div>
							<div className="column__1">
								<p> Amount </p>
								<p className="" > {changePrice} {id} </p>
							</div>
							<div className="column__1">
								<p> Total</p>
								<p> {eusdPrice}</p>
							</div>
							<div className='Next__link__2'>
								<span>  Fee <a href='#' className='fdf'> Maker: 0.2% | Taker: 0.2% | TDS: 1.0% | Incl. Taxes</a> </span>
							</div>
							<div className='btn___modal_1'>
								<button type='submit' className='modal__btn Sell___btn' onClick={SellCoin} disabled={btnDisable}>

									{btnDisable === true &&
										<div className="d-flex">
											<ColorRing
												visible={true}
												height="20"
												width="20"
												ariaLabel="blocks-loading"
												wrapperStyle={{}}
												wrapperClass="blocks-wrapper"
												colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
											/>
											Please Wait...
										</div>
									}
									{btnDisable === false ? 'CONFIRM SELL' : ''}
								</button>
							</div>

						</div>
					</div>
				</Modal>
			</form>

			<form id='StopLimit' onSubmit={(e: any) => { SellCoin(e) }}>
				<Modal open={openSellStopLimit} onClose={onCloseSellStopModal}
					classNames={{
						overlay: 'Modal__trading',
						modal: 'BUY___BUTTON',
					}} center>
					<div className="card-header">
						<h2>SELL {id} For USDT</h2>
					</div>
					<div id="Modal__details">
						<div className="modal-body">
							<div className="column__1">
								<p> Type </p>
								<p>Stop Limit</p>
							</div>
							<div className="column__1">
								<p> At Price</p>
								<p> ${coinCurrentPrice} </p>
							</div>
							<div className="column__1">
								<p> Stop Price</p>
								<p > {buyStopPriceAt} USDT </p>
							</div>
							<div className="column__1">
								<p> Amount </p>
								<p className="" > {changePrice} {id} </p>
							</div>
							<div className="column__1">
								<p> Total</p>
								<p> {eusdPrice}</p>
							</div>
							<div className='Next__link__2'>
								<span>  Fee <a href='#' className='fdf'> Maker: 0.2% | Taker: 0.2% | TDS: 1.0% | Incl. Taxes</a> </span>
							</div>
							<div className='btn___modal_1'>
								<button type='submit' className='modal__btn Sell___btn' onClick={SellCoin} disabled={btnDisable}>
									{btnDisable === true &&
										<div className="d-flex">
											<ColorRing
												visible={true}
												height="20"
												width="20"
												ariaLabel="blocks-loading"
												wrapperStyle={{}}
												wrapperClass="blocks-wrapper"
												colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
											/>
											Please Wait...
										</div>
									}
									{btnDisable === false ? 'CONFIRM SELL' : ''}
								</button>
							</div>

						</div>
					</div>
				</Modal>
			</form>

		</>
	)
}

export default TradingPageNew
