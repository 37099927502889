import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import trading_banner_main from "../Assets/Images/Footer_IMG/Top-trader-1.svg"
import { title } from '../config/config';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GridViewIcon from '@mui/icons-material/GridView';
import avtar from "../Assets/Images/Footer_IMG/avatar.jpg"
import tether from "../Assets/Images/Footer_IMG/tether.png"




const CopyTrading = (props:any) => {
    useEffect(() => {
        document.title = props.title;

    }, [])
  return (
    <div className='max__contain max__contain_copyTrading pt__spacing'>
       <div className='tradingCopy__banner'>
            <div className='row_max'>
                <div className='col-6 strategy__banner_left'>
                    <h1>Top Traders {title}</h1>
                    <ul>
                        <li> Meet Some of Our Most Popular Trader</li>
                    </ul>
                    <div className=''>
                        <Link to="/" className='link' > Trader Leaderboard</Link>
                        <Link to="/" className='link' > Hero Trader Awards 2022</Link>
                    </div>
                </div>
                <div className='col-6 strategy__banner_right'>
                    <img src={trading_banner_main} alt="strategy-1/icon" width="100%" height="200px" />
                </div>
            </div>
       </div>


       <div className='tradingCopy__tabs'>
            <Tabs>
                <div className='tradingCopy__tablist'>
                    <TabList>
                        <Tab><ListAltIcon/></Tab>
                        <Tab><GridViewIcon/></Tab>
                        <Tab>Ranking</Tab>
                    </TabList>
                </div>
                <TabPanel>
                       <div className='strategy__market_1'>
                            <table>
                                <thead>
                                    <tr>
                                        <th> Participant</th>
                                        <th> ROI</th>
                                        <th> Total P&L</th>
                                        <th> AUM</th>
                                        <th> Followers </th>
                                        <th>Operation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='icon__img'> 
                                            <div className='icon__img__WWE'>
                                                <div className='icon__img__img'>
                                                    <img src={avtar} alt="icon"/>
                                                </div>
                                                <div className='icon__title'>
                                                    <h5>Principle</h5>
                                                    <p>Frequency Stable Short Term </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p>$14,174.32</p>
                                        </td>
                                        <td>
                                            <p>$14,174.32</p>
                                        </td>
                                        <td className='avtar__strategy'>
                                           <p>1776559.40</p>
                                        </td>
                                        <td>
                                            <p> 1000/1000</p>
                                        </td>
                                        <td>
                                            <p>Full</p>
                                        </td>



                                    </tr>
                                </tbody>
                            </table>
                       </div>
                </TabPanel>
                <TabPanel>
                    <div className='strategy__market_2'>
                        <div className='row_max'>
                            <div className='col-4'>
                                <div className='card'>
                                    <img src={avtar} alt="user_icon"/>
                                    <div className='card__prices'>
                                        <div className=''>
                                            <p>$27,560.78</p>
                                            <p>P&L from Strategy</p>
                                        </div>
                                    </div>

                                    <div className='card__prices_details'>
                                        <div className=''>
                                            <p>$2,068.9</p>
                                            <p className='card__prices_details_small'>Total Profit of Subscribers</p>
                                        </div>

                                        <div className=''>
                                            <p> <span>31</span>/150</p>
                                            <p className='card__prices_details_small'>Subscription Popularity</p>
                                        </div>
                                    </div>
                                    <Link  to="/login" className='btn'> Subscribe - 100 USDT </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </Tabs>

       </div>



    </div>
  );
}

export default CopyTrading;
