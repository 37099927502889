import React, { useEffect } from 'react'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const WithdrawInvalid = (props:any) => {
    useEffect(() => {
        document.title = props.title;

    },[])
  return (
   

    <section className='invalid-page'>
        <div className="container">
            <div className='row m-0'>


                <div className='col-md-12'>
                    <div className="text-container container_tabs">
                        <h2><SentimentVeryDissatisfiedIcon/></h2>
                        <h3 className='new__heading'>If you have withdraw than your withdraw already confirmed otherwise invalid request!</h3>
                        <div className="btn-container">
                        <button className="btn">
                            Back now
                        </button>
                        </div>
                    </div>

                </div>

            {/* <div className='col-md-6'>
                    <div className="icon-container container_tabs">
                        <SentimentVeryDissatisfiedIcon/>
                    </div>
                </div> */}

            </div>

           
           
        </div>
    </section>



  )
}

export default WithdrawInvalid