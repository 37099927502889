import React, { useEffect } from "react";
import { title } from "../config/config";
// import MarketSlider from "../Sections/Market-slider";
import MarketTrade from "../Sections/Market-trade";
const MarketPage = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    },[])
    return (
        <>
            {/* Market Slider Section */}
            {/* <MarketSlider /> */}

            {/* Market Trade Section */}
            < MarketTrade />
        </>
    )
}
export default MarketPage;