import React, { useEffect } from "react";
import { title } from "../config/config";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import btc_img from "../Assets/Images/btc.svg";
import usdc_img from "../Assets/Images/usdc.svg";
import usdtt_img from "../Assets/Images/usdt..svg";
import ufo_img from "../Assets/Images/btc-image.svg";
import card_img from "../Assets/Images/card___BG__1.png";
import card1_img from "../Assets/Images/card___BG__1.png";
import card2_img from "../Assets/Images/card___BG__1.png";
import door_img from "../Assets/Images/door.svg";

const ProtectionFund = (props:any) => {
  useEffect(() => {
    document.title = props.title;

}, [])
  return (
    <>
      <div className="pt__spacing Pro___fund">
        <section className="pro__banner">
          <div className="pro____container">
            <div className="card__banner__left">
              <h1>Your security, our priority</h1>
              <h3>{title} Protection Fund</h3>
              <p>
                We regularly monitor the {title} Protection Fund, as well as
                crypto's cybersecurity landscape, to make sure that our fund
                remains adequate for our customers.
              </p>
              {/* <button type='button' className='btn'>Our vision {">>"}</button> */}
            </div>
          </div>
        </section>

        <div className="second-section">
          <div className="pro____container">
            <h1>
              The fund is currently valued at US $300 million.
              <span>{title}</span>
            </h1>

            <div className="row">
              <div className="col-4">
                <div className="card-head">
                  <p>BTC</p>
                  <img src={btc_img} alt="logo" />
                </div>
                <h4>6500 BTC</h4>
                <div className="dropdown">
                  <a className="dropbtn" href="#">
                    wallet
                    <ArrowRightAltIcon />
                  </a>
                  <div className="dropdown-content">
                    <a href="#">Link 1</a>
                    <a href="#">Link 2</a>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card-head">
                  <p>USDT</p>
                  <img src={usdtt_img} alt="logo" />
                </div>
                <h4>160 million USDT</h4>
                <div className="dropdown">
                  <a className="dropbtn" href="#">
                    wallet
                    <ArrowRightAltIcon />
                  </a>
                  <div className="dropdown-content">
                    <a href="#">Link 1</a>
                    <a href="#">Link 2</a>
                    <a href="#">Link 3</a>
                    <a href="#">Link 4</a>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card-head">
                  <p>USDC</p>
                  <img src={usdc_img} alt="logo" />
                </div>
                <h4>40 million USDC</h4>
                <div className="dropdown">
                  <a className="dropbtn" href="#">
                    wallet
                    <ArrowRightAltIcon />
                  </a>
                  <div className="dropdown-content">
                    <a href="#">Link 1</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="third-section">
         <div className="pro____container">
         <h1>Why do we need a protection fund?</h1>
          <div className="row">
            <div className="col-12">
             <div className="card__A">
              <p>
                  The {title} Protection Fund gives our platform an extra layer of
                  resilience against cybersecurity threats.
                </p>
                <p>
                  In addition to our Proof of Reserves, the fund shows that we’re
                  committed to protecting your assets, and that we operate with
                  transparency and integrity.
                </p>
                <p>
                  If our customers have their accounts compromised or assets
                  stolen or lost - not due to their own actions or trading
                  behavior - then they may apply for a claim through the {title}{" "}
                  Protection Fund.
                </p>
             </div>
            </div>
            {/* <div className="col-6">
             <div className="card__MG__PRO">
                <img src={ufo_img} alt="" />
             </div>
            </div> */}
          </div>
         </div>
        </div>
        <div className="fourth-section">
         <div className="pro____container">
         <h1>Learn how to protect your assets.</h1>
          <p className="demo__sab_a">
            Read our Academy articles on how to protect your account on Web3.
          </p>

          <div className="row">
            <div className="col-4">
              <img src={card_img} alt="" />
              <div className="card-body">
                <div className="card-head">
                  <button type="submit">Cryptocrruncy</button>
                </div>
                <h4>$300M {title} Protection Fund to Protect User's Assets</h4>
                <div className="dropdown">
                  <a className="dropbtn" href="#">
                    December 22, 2022 ｜10 min
                  </a>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img src={card1_img} alt="" />
              <div className="card-body">
                <div className="card-head">
                  <button type="submit">{title}</button>
                </div>
                <h4>Solana's $6M Hack: How to Protect Your Money </h4>
                <div className="dropdown">
                  <a className="dropbtn" href="#">
                    December 12, 2022 ｜ 5 min
                  </a>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img src={card2_img} alt="" />
              <div className="card-body">
                <div className="card-head">
                  <button type="submit">Cryptocurrency</button>
                  <button type="submit">Trading</button>
                </div>
                <h4>Rookie Mistakes When Starting Out in Crypto</h4>
                <div className="dropdown">
                  <a className="dropbtn" href="#">
                    November 18, 2022 ｜ 10 min
                  </a>
                </div>
              </div>
            </div>
          </div>
         </div>
        </div>
        <div className="bottom-section">
          <div className="pro____container">
            <div className="row">
              <div className="col-8">
                <h1>{title} Protection Fund</h1>
                <h2>lorem lorem lorem lorem</h2>
                <button type="submit">Get started</button>
              </div>
              <div className="col-4">
                <img src={door_img} alt="" />
              </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProtectionFund;
