import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom'
import img from '../Assets/Images/blog-banner.jpg'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { title } from '../config/config';

const Blog_view = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, []);
    return (
        <>
            <div className="a_container">
                <div className="blog-view-section">
                   
                    <div className="main-blog-view">
                        <div className="col-8">
                            <div className="card" id='section1'>
                                <div className="card-img">
                                    <img src={img} alt="" />
                                </div>
                                <div className="card-text">
                                    <p className='p1'>The Commodity Futures Trading Commission’s commissioner, Christy Goldsmith Romero, second-guessed VCs and institutional investments for lack of record keeping and believing an auditor no one had heard about in the FTX case.</p>
                                    <p className='p2'>1. Although Norway is not a renowned crypto hub, it is racing to test and implement a Central Bank Digital Currency.</p>
                                    <p className='p2'>2. The CMI sectors' week-to-date performance showed weakness in the Computing (CPU), DeFi (DCF), and Culture and Entertainment (CNE) sectors.</p>
                                    <p className='p3'>The top cryptocurrencies in the crypto market continued their impressive start to 2023. Solana increased by 24% this past week, followed by PolkaDot, Bitcoin, and Ethereum, all hovering around the 10% mark. The market seemed unfazed by the announcement that crypto lender Genesis had filed for chapter 11, as seen in the unchanged trading volume across different assets.</p>
                                </div>
                            </div>
                            <div className="card" id='section2'>
                                <div className="card-img">
                                    <img src={img} alt="" />
                                </div>
                                <div className="card-text">
                                    <p className='p1'>The Commodity Futures Trading Commission’s commissioner, Christy Goldsmith Romero, second-guessed VCs and institutional investments for lack of record keeping and believing an auditor no one had heard about in the FTX case.</p>
                                    <p className='p2'>1. Although Norway is not a renowned crypto hub, it is racing to test and implement a Central Bank Digital Currency.</p>
                                    <p className='p2'>2. The CMI sectors' week-to-date performance showed weakness in the Computing (CPU), DeFi (DCF), and Culture and Entertainment (CNE) sectors.</p>
                                    <p className='p3'>The top cryptocurrencies in the crypto market continued their impressive start to 2023. Solana increased by 24% this past week, followed by PolkaDot, Bitcoin, and Ethereum, all hovering around the 10% mark. The market seemed unfazed by the announcement that crypto lender Genesis had filed for chapter 11, as seen in the unchanged trading volume across different assets.</p>
                                </div>
                            </div>
                            <div className="card" id='section3'>
                                <div className="card-img">
                                    <img src={img} alt="" />
                                </div>
                                <div className="card-text">
                                    <p className='p1'>The Commodity Futures Trading Commission’s commissioner, Christy Goldsmith Romero, second-guessed VCs and institutional investments for lack of record keeping and believing an auditor no one had heard about in the FTX case.</p>
                                    <p className='p2'>1. Although Norway is not a renowned crypto hub, it is racing to test and implement a Central Bank Digital Currency.</p>
                                    <p className='p2'>2. The CMI sectors' week-to-date performance showed weakness in the Computing (CPU), DeFi (DCF), and Culture and Entertainment (CNE) sectors.</p>
                                    <p className='p3'>The top cryptocurrencies in the crypto market continued their impressive start to 2023. Solana increased by 24% this past week, followed by PolkaDot, Bitcoin, and Ethereum, all hovering around the 10% mark. The market seemed unfazed by the announcement that crypto lender Genesis had filed for chapter 11, as seen in the unchanged trading volume across different assets.</p>
                                </div>
                            </div>
                        </div>
                        <div className="id-section">
                            <div className='position'>
                                <div className="heading">
                                    <h3>Share</h3>
                                </div>
                                <div className="idmain">

                                    <div className="tab-system">
                                        <a href="#section1">
                                            <CircleOutlinedIcon />
                                            Top Altcoin Gainers and Losers
                                        </a>
                                    </div>
                                    <div className="tab-system1">
                                        <a href="#section2">
                                            <CircleOutlinedIcon />
                                            Top Altcoin Gainers and Losers
                                        </a>
                                    </div>
                                    <div className="tab-system2">
                                        <a href="#section3">
                                            <CircleOutlinedIcon />
                                            Top Altcoin Gainers and Losers
                                        </a>
                                    </div>
                                    <div className="tab-system3">
                                        <a href="#">
                                            <CircleOutlinedIcon />
                                            Top Altcoin Gainers and Losers
                                        </a>
                                    </div>
                                    <div className="tab-system4">
                                        <a href="#">
                                            <CircleOutlinedIcon />
                                            Top Altcoin Gainers and Losers
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog_view
