import { title } from "../config/config";

const ReferralBanner = () => {
    return (
        <>
            <section className="sec_referral_banner s-primary-bg">
                <div className="container">
                    <div className="sec_content">
                        <h2 className="sec_heading s-normal-color">
                            {title} Earn
                        </h2>
                        <p className="sec_info ">
                            Earn stable <span className="profit">profits</span> with professional
                            asset management
                        </p>
                    </div>
                </div>
            </section>
        </>
    )

}
export default ReferralBanner;