import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// import InfoOutlinedIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
// import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { useParams } from 'react-router';
import _fetch from '../../config/api';
import { api_url } from '../../config/config';
import dateFormat, { masks } from "dateformat";
import toasted from '../../config/toast';
import LoaderCom from '../loader/LoaderCom';
import { ColorRing } from 'react-loader-spinner';

const OpenCompleteOrderNew = (props: any) => {

    const { id } = useParams();

    const [myorderTab, setmyorderTab] = useState('');

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [emptyData, setemptyData] = useState([]);
    const [btnDisable, setbtnDisable] = useState(false);
    const [totalAddPriceLoop, settotalAddPriceLoop] = useState(0);
    const [totalAmountLoop, settotalAmountLoop] = useState(0);

    const [completeemptyData, setcompleteemptyData] = useState([]);

    let tradingTab = (tab: string) => {
        if (window.screen.width! <= 768) {
            tab == 'my-orders' ? setmyorderTab('block') : setmyorderTab('none');
        }
    }
    const [myBuyOrders, setMyBuyOrders] = useState([])
    async function OpenOrders() {
        let data = await _fetch(`${api_url}/trade/getMyBuySellHistory?order_status=pending&trade_on=${id?.toLowerCase()}`, 'GET', {}, {});
        if (data?.status === 'success') {
            setMyBuyOrders(data?.data?.data);
            setemptyData(data)

        }
    }

    const [openMultiDataModal, setopenMultiDataModal] = useState({ amount: 0, buy_currency: '', coin: 0, coin_id: '', coin_price: 0, createdAt: 0, market_trade_type: '', order_id: '', order_status: '', order_type: { buy: '', sell: '' }, pending_coin: '', receive_coin: '', sell_currency: '', trade_type: '', updatedAt: '', username: '' });

    const openMuliOpenModal = (e: any) => {
        // console.log(e)
        setopenMultiDataModal({ amount: e.amount, buy_currency: e.buy_currency, coin: e.coin, coin_id: e.coin_id, coin_price: e.coin_price, createdAt: e.createdAt, market_trade_type: e.market_trade_type, order_id: e.order_id, order_status: e.order_status, order_type: { buy: e.order_type.buy, sell: e.order_type.sell }, pending_coin: e.pending_coin, receive_coin: e.receive_coin, sell_currency: e.sell_currency, trade_type: e.trade_type, updatedAt: e.updatedAt, username: e.username })
        onOpenModal();
        tradeMoreData(e.market_trade_type, e.order_id);

    }
    const [orderMoreData, setOrderMoreData] = useState([{
        buyerReceive: '',
        buyer_currency: '',
        buyer_orderId: '',
        buyer_username: '',
        coin: 0,
        coin_id: '',
        createdAt: '',
        current_price: 0,
        sellerReceive: '',
        seller_currency: '',
        seller_orderId: '',
        seller_username: '',
        status: '',
        trade_on: '',
        updatedAt: '',
        _id: '',
    }]);
    const [totalPriceTable, settotalPriceTable] = useState(0);
    const [totalAmountTable, settotalAmountTable] = useState(0);

    async function tradeMoreData(status: any, user: any) {
        // console.log('status', status, user)
        if (status === 'buy') {
            var status_id = 'buyer_orderId';
        } else {
            var status_id = 'seller_orderId';

        }

        var moreData = await _fetch(`${api_url}/trade/getMyOrderHistory?sort=createdAt&${status_id}=${user}`, 'get', {}, {});
        if (moreData?.status === 'success') {
            settotalPriceTable(moreData?.price)
            settotalAmountTable(moreData?.amount)
            setOrderMoreData(moreData.data.data);
            // console.log('moreData', moreData.data.data);
        }
    }
    // const [copyOrders, setCopyOrder] = useState({ amount: 0, coin: 0, coin_price: 0 })
    const [copyOrders, setCopyOrder] = useState('ss')

    const [completedOrders, setCompletedOrders] = useState([])
    async function CompletedOrders() {
        let data = await _fetch(`${api_url}/trade/getMyBuySellHistory?order_status=completed&order_status=cancelled&trade_on=${id?.toLowerCase()}`, 'GET', {}, {});
        if (data?.status === 'success') {
            setCompletedOrders(data?.data?.data);
            setcompleteemptyData(data);

        }


    }




    async function CancelOrder() {
        setbtnDisable(true);
        let data = await _fetch(`${api_url}/trade/cancel-trade`, 'POST', { id: cancelOrder }, {});
        if (data?.status === 'success') {
            OpenOrders()
            CompletedOrders()
            setcancel(false)
            toasted.success(data?.message);
            onCloseCModal()
            // window.location.reload()
            setbtnDisable(false);
            props.setsetLoadApi(props.LoadApi === 1 ? 0 : 1)

        } else {
            toasted.error(data?.message);
            setbtnDisable(false)

        }
    }

    useEffect(() => {
        OpenOrders()
        CompletedOrders()
    }, [props.LoadApi])



    const [cancelOrder, setCancelOrder] = useState('')
    const [cancel, setcancel] = useState(false);
    // const onCancelModal = () => setcancel(true);
    const onCloseCModal = () => setcancel(false);

    const onCancelModal = (orderId: any) => {
        setcancel(true)
        setCancelOrder(orderId)
    }

    // var getTotalPrice = orderMoreData.reduce((a:any,v:any) =>  a = a + v.current_price , 0 )
    // settotalAddPriceLoop(getTotalPrice);

    return (
        <>
            <div>
                <div className='order_table_1 trading_center_top__2' style={{ display: props.myorderTab }}>
                    <Tabs>
                        <div className='card' id='card__left__table_1'>
                            <div className='card-header'>
                                <TabList className="nav ul___left">
                                    <Tab>
                                        <a className="btn active"> Open Orders</a>
                                    </Tab>
                                    <Tab>
                                        <a className="btn "> Completed Orders</a>
                                    </Tab>
                                </TabList>
                            </div>
                            <div className='card-body'>
                                <TabPanel>
                                    <div className='order__right__table'>
                                        <table className="open_order_table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <h6 className="right text-uppercase">Pair</h6>
                                                    </th>
                                                    <th>
                                                        <h6 className="right text-uppercase">Amount</h6>
                                                    </th>
                                                    <th>
                                                        <h6 className="right text-uppercase">Price</h6>
                                                    </th>
                                                    <th>
                                                        <h6 className="right text-uppercase">Total</h6>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="buy">
                                                <LoaderCom condition={emptyData} text={'Getting latest Open Orders...'} />

                                                {myBuyOrders?.map((item: any, index: any, sellData: any) =>
                                                    <>

                                                        <tr className={(item.market_trade_type === 'buy') ? 'profit_coin' : 'less_coin'} key={index}>
                                                            <td>
                                                                <span className='coin__name'> {id}</span>
                                                                <br />
                                                                <small>USDT</small>
                                                            </td>
                                                            <td>
                                                                <span className='coin__amount'> {item.coin.toFixed(3)}</span>
                                                                <br />
                                                                <small>{item.amount.toFixed(3)}</small>
                                                            </td>
                                                            <td className="status-dot-cell-green"> {item.coin_price} </td>
                                                            <td>{item.coin.toFixed(3)}</td>
                                                            <div className='hover__section'>
                                                                <div className='section_hover_1' onClick={() => onCancelModal(item._id)} >
                                                                    <CancelPresentationIcon />
                                                                </div>
                                                                <div className='section_hover_1' onClick={() => props.copyOrder(item)}>
                                                                    <ContentCopyIcon />
                                                                </div>
                                                                <div className='section_hover_1'
                                                                    onClick={() => openMuliOpenModal(item)}
                                                                // onClick={onOpenModal}

                                                                >
                                                                    <OpenWithIcon />
                                                                </div>
                                                            </div>
                                                        </tr>
                                                    </>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className='order__right__table'>
                                        <table className="open_order_table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <h6 className="right text-uppercase">Pair</h6>
                                                    </th>
                                                    <th>
                                                        <h6 className="right text-uppercase">Amount</h6>
                                                    </th>
                                                    <th>
                                                        <h6 className="right text-uppercase">Price</h6>
                                                    </th>
                                                    <th>
                                                        <h6 className="right text-uppercase">Total</h6>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="buy">
                                                <LoaderCom condition={completeemptyData} text={'Getting latest Completed Orders...'} />

                                                {completedOrders?.map((item: any, index: any, sellData: any) =>

                                                    <>

                                                        <tr className={(item.market_trade_type === 'buy') ? (item.order_status === 'cancelled') ? 'cancel_coin' : 'profit_coin' : (item.order_status === 'cancelled') ? 'cancel_coin' : 'less_coin'}>
                                                            <td>
                                                                <span className='coin__name'> {id}</span>
                                                                <br />
                                                                <small>USDT</small>
                                                            </td>
                                                            <td>
                                                                <span className='coin__amount'> {item.coin.toFixed(3)}</span>
                                                                <br />
                                                                <small>{item.amount.toFixed(3)}</small>
                                                            </td>
                                                            <td className="status-dot-cell-green"> {item.coin_price} </td>
                                                            <td>{item.coin.toFixed(3)}</td>
                                                            <div className='hover__section'>
                                                                {/* <div className='section_hover_1'  >
                                                                <CancelPresentationIcon />
                                                                </div> */}
                                                                <div className='section_hover_1'  >
                                                                    {dateFormat(openMultiDataModal?.updatedAt, "IST:d mmm , h:MM:ss TT").replace('IthP:', '').replace('IthA:', '').replace('IstP:', '').replace('IstA:', '')}
                                                                    {/* 30 Nov, 5:17 */}
                                                                </div>
                                                                <div className='section_hover_1'  >
                                                                    {item.order_status.charAt(0)?.toUpperCase() + item.order_status.slice(1)}
                                                                </div>

                                                                <div className='section_hover_1' onClick={() => props.copyOrder(item)}>
                                                                    <ContentCopyIcon />
                                                                </div>
                                                                <div className='section_hover_1'
                                                                    onClick={() => openMuliOpenModal(item)}
                                                                // onClick={onOpenModal}

                                                                >
                                                                    <OpenWithIcon />
                                                                </div>
                                                            </div>
                                                        </tr>
                                                    </>
                                                )}


                                            </tbody>
                                        </table>
                                    </div>
                                </TabPanel>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>

            <div>
                <Modal open={open} onClose={onCloseModal}
                    classNames={{
                        overlay: 'Modal__trading',
                        modal: 'Modal__trading_main',
                    }} center>
                    <div className="card-header">
                        <h2> Order Details</h2>
                    </div>
                    <div id="Modal__details">
                        <div className="modal-body">
                            <div className="column__1 modal__colun-1">
                                <p className='coin_modal-name'> {openMultiDataModal?.sell_currency?.toUpperCase()}</p>
                                <p className='activity'> <span>{openMultiDataModal?.market_trade_type?.toUpperCase()}</span>  </p>
                            </div>
                            <div className="column__1">
                                <p> Type </p>
                                <p> {openMultiDataModal?.trade_type?.toUpperCase()}</p>
                            </div>


                            <div className="column__1">
                                <p> Placed on</p>
                                <p> {dateFormat(openMultiDataModal?.createdAt, "IST:d mmm , yyyy, h:MM:ss TT").replace('IthP:', '').replace('IthA:', '').replace('IstP:', '').replace('IstA:', '')}</p>
                            </div>





                            <div className="column__1">
                                <p className="Transaction-fees" > Coin</p>
                                <p> <span> {openMultiDataModal.coin.toFixed(3)} {openMultiDataModal?.sell_currency?.toUpperCase()}</span> </p>
                            </div>


                            <div className="column__1 column__1__icon">
                                <p> At Price</p>
                                <p>  <span> ${totalPriceTable}  </span> </p>
                            </div>

                            {(openMultiDataModal?.trade_type.toUpperCase() === "LIMIT" ? <div className="column__1 column__1__icon">
                                <p> Stop Price</p>
                                <p>  <span> ${openMultiDataModal?.coin_price?.toFixed(3)} </span> </p>
                            </div> : '')}


                            <div className="column__1 column__1__icon last_sc">
                                <p> Total </p>
                                <p className="" >  {totalAmountTable?.toFixed(3)} USDT</p>

                                {/* <p className="" >  {openMultiDataModal.amount?.toFixed(3)} USDT</p> */}
                            </div>

                            <div className="column__1">
                                <p> Fee</p>
                                <p> N/A</p>
                            </div>
                            <div className="column__1">
                                <p> TDS</p>
                                <p> N/A</p>

                            </div>
                            <div className='trading_details_table tra-order-table'>
                                <table>
                                    <thead>
                                        <tr>

                                            <th>Coin</th>
                                            <th>Seller ID</th>
                                            <th>Buyer ID</th>
                                            <th>Coin</th>
                                            <th>Price</th>
                                            <th>Amount</th>

                                            <th>Status</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            orderMoreData?.map((val: any) => {
                                                // console.log('orderMoreData',orderMoreData);
                                                // var add = totalAddPriceLoop + val.current_price;
                                                // () => settotalAddPriceLoop(add);
                                                return (
                                                    <tr>
                                                        <td>{val.trade_on.toUpperCase()}</td>
                                                        <td>{val.seller_orderId}</td>
                                                        <td>{val.buyer_orderId}</td>
                                                        <td>{val?.coin?.toFixed(3)}</td>
                                                        <td>{val?.current_price?.toFixed(3)}</td>
                                                        <td>{val.sellerReceive + ' ' + val?.seller_currency?.toUpperCase()}</td>

                                                        <td className={val.status === "completed" ? 'text-success' : 'text-danger'}>{val.status.toUpperCase()}</td>
                                                        <td>{dateFormat(val?.createdAt, "IST:d mmm , yyyy, h:MM:ss TT").replace('IthP:', '').replace('IthA:', '').replace('IstP:', '').replace('IstA:', '').replace('IrdP:', '')}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        <tr>
                                            <td>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </Modal>
            </div>




            <div>
                <Modal open={cancel} onClose={onCloseCModal} closeOnOverlayClick={true} closeOnEsc={true}
                    classNames={{
                        overlay: 'cancel-modal-overlay',
                        modal: 'cancel-modal',
                    }} center>

                    <div id="Modal__details">
                        <div className='card-header-body'>
                            <InfoOutlinedIcon />
                        </div>
                        <h3>Are you sure?</h3>
                        <p> You want to cancel this order.</p>
                        <div className='close_button'>
                            <button type='button' className='btn btn-1' onClick={CancelOrder} disabled={btnDisable}>

                                {btnDisable === true &&
                                    <div className="d-flex">
                                        <ColorRing
                                            visible={true}
                                            height="20"
                                            width="20"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                        />
                                        Please Wait...
                                    </div>
                                }
                                {btnDisable === false ? 'Yes' : ''}
                            </button>
                            <button type='button' className='btn btn-2' // </div>onOverlayClick={(e:any) =>onOverlay(e)}
                            > No  </button>
                        </div>
                    </div>
                </Modal>
            </div>

        </>
    )
}

export default OpenCompleteOrderNew