import { Link } from "react-router-dom";
const LetestCoin = () => {
    return (
        <>
            <section className="sec_letest_coin sec-py s-primary-bg">
                <div className="container">
                    <div className="sec_header">
                        <h2 className="heading s-normal-color">
                            Latest Coins
                        </h2>
                        <div className="header_form">
                            <form className="form" action="">
                                <label className="label" htmlFor="seach_coin_referral">
                                </label>
                                <input id="seach_coin_referral" type="text" placeholder="Search Coins" />
                            </form>
                            <div className="btn">
                                <Link to="/" className="s-normal-color" >
                                    OPUL
                                </Link>
                                <Link to="/" className="s-normal-color" >
                                    USDT
                                </Link>

                            </div>
                        </div>

                    </div>

                    <ul className="letest_card">
                        <li className="card">
                            <span className="circle-element"></span>
                            <span className="circle-element2"></span>
                            <div className="card_header">
                                <div className="card_logo">
                                    <img src={require("../Assets/Icons/referral-slider-icon.svg").default} alt="" />
                                    <span className="s-normal-color">USDT</span>
                                </div>
                                <h6 className="saving s-normal-color">
                                    Savings
                                </h6>
                            </div>
                            <div className="card_footer">
                                <div className="day_profit">
                                    <span className="day s-normal-color">
                                        7 - Day APY
                                    </span>
                                    <span className="price f-normal-color">
                                        7.06%
                                    </span>
                                </div>
                                <div className="daily_profit">
                                    <span className="daily s-normal-color">
                                        $ 10k Daily Profit
                                    </span>
                                    <span className="price s-normal-color">
                                        1.9342 USDT
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li className="card">
                            <span className="circle-element"></span>
                            <span className="circle-element2"></span>
                            <div className="card_header">
                                <div className="card_logo">
                                    <img src={require("../Assets/Icons/referral-slider-icon.svg").default} alt="" />
                                    <span className="s-normal-color">USDT</span>
                                </div>
                                <h6 className="saving s-normal-color">
                                    Savings
                                </h6>
                            </div>
                            <div className="card_footer">
                                <div className="day_profit">
                                    <span className="day s-normal-color">
                                        7 - Day APY
                                    </span>
                                    <span className="price f-normal-color">
                                        7.06%
                                    </span>
                                </div>
                                <div className="daily_profit">
                                    <span className="daily s-normal-color">
                                        $ 10k Daily Profit
                                    </span>
                                    <span className="price s-normal-color">
                                        1.9342 USDT
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li className="card">
                            <span className="circle-element"></span>
                            <span className="circle-element2"></span>
                            <div className="card_header">
                                <div className="card_logo">
                                    <img src={require("../Assets/Icons/referral-slider-icon.svg").default} alt="" />
                                    <span className="s-normal-color">USDT</span>
                                </div>
                                <h6 className="saving s-normal-color limited">
                                    Time Limited
                                </h6>
                            </div>
                            <div className="card_footer">
                                <div className="day_profit">
                                    <span className="day s-normal-color">
                                        7 - Day APY
                                    </span>
                                    <span className="price f-normal-color">
                                        7.06%
                                    </span>
                                </div>
                                <div className="daily_profit">
                                    <span className="daily s-normal-color">
                                        $ 10k Daily Profit
                                    </span>
                                    <span className="price s-normal-color">
                                        1.9342 USDT
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li className="card">
                            <span className="circle-element"></span>
                            <span className="circle-element2"></span>
                            <div className="card_header">
                                <div className="card_logo">
                                    <img src={require("../Assets/Icons/referral-slider-icon.svg").default} alt="" />
                                    <span className="s-normal-color">USDT</span>
                                </div>
                                <h6 className="saving s-normal-color limited">
                                    Time Limited
                                </h6>
                            </div>
                            <div className="card_footer">
                                <div className="day_profit">
                                    <span className="day s-normal-color">
                                        7 - Day APY
                                    </span>
                                    <span className="price f-normal-color">
                                        7.06%
                                    </span>
                                </div>
                                <div className="daily_profit">
                                    <span className="daily s-normal-color">
                                        $ 10k Daily Profit
                                    </span>
                                    <span className="price s-normal-color">
                                        1.9342 USDT
                                    </span>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </section>
        </>
    )
}
export default LetestCoin;