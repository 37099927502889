import react, { useEffect, useState } from 'react';
import { ColorRing } from 'react-loader-spinner';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import toasted from '../config/toast';
import { api_url } from '../config/config';
import _fetch from '../config/api';
const TransferModalCom = (props: any) => {

    const [btnDisable, setbtnDisable] = useState(false);
    const [chainId, setChainId] = useState('');
    const [symbolT, setSymbolT] = useState(props.symbolT);
    const [amountT, setAmountT] = useState(0);
    const [transerTo, setTransferTo] = useState('trade_account');
    const [transerFrom, setTransferFrom] = useState(props.accountType);
    const [toAccount, setToAccount] = useState('Trading Account')
    const [account, setAccount] = useState('Main Account');
    const [getBalance, setGetBalance] = useState(0)


    const [OpositAccount, setOpositAccount] = useState('Trade Account');
    const [FromOpositAccount, setFromOpositAccount] = useState('Main Account');


    const direction = async (e: any) => {
        let val = e.target.value;

        if (val === 'Main Account') {
            setAccount(val);
            setTransferFrom('main_account')
            CoinInfobyType(symbolT, "main_account");
            setOpositAccount("Trading Account");

        }
        else if (val === 'Trading Account') {
            setAccount(val);
            setTransferFrom('trade_account');
            CoinInfobyType(symbolT, "trade_account");
            setOpositAccount("Main Account");



        }
        else if (val === 'Funding Account') {
            setAccount(val);
            setTransferFrom('funding_account');
            CoinInfobyType(symbolT, "funding_account");
            setOpositAccount("Main Account");



        }


    }
    async function CoinInfobyType(symbol: any, from: any) {
        var d = await _fetch(`${api_url}/markets/getElpSingleMarketByType?symbol=${symbol?.toLowerCase()}&type=${from}`, 'GET', {}, {});
        if (d?.status === 'success') {
            setGetBalance(Number(d?.market[0].total_coin.toFixed(3)));
            setChainId(d?.market[0].coin_id);
            setSymbolT(d?.market[0].symbol);
            // console.log(d);
        }
    }

    const directionToAccount = (e: any) => {
        let val = e.target.value;

        if (val === 'Main Account') {
            setToAccount(val);
            setTransferTo("main_account");
        }
        else if (val === 'Trading Account') {
            setToAccount(val);
            setTransferTo('trade_account');
        }
        else if (val === 'Funding Account') {
            setToAccount(val);
            setTransferTo('funding_account');
        }

    }

    const  FundTransfer = async (e:any) => {
        e.preventDefault()
        setbtnDisable(true);
        let para =
        {
            chain_id: chainId,
            symbol: symbolT,
            amount: amountT,
            transfer_to: transerTo,
            transfer_from: transerFrom
        }
        let d = await _fetch(`${api_url}/incomes/transfer`, 'POST', para, {});
        if (d?.status === 'success') {
            toasted.success(d?.message);
            props.onCloseModalT()
            setbtnDisable(false);

        } else {
            setbtnDisable(false);
            toasted.error(d?.message);
        }
    }

    async function CoinInfo(symbol: any) {
        var d = await _fetch(`${api_url}/markets/getElpSingleMarketByType?symbol=${symbol?.toLowerCase()}&type=${transerFrom}`, 'GET', {}, {});
        if (d?.status === 'success') {
            setGetBalance(Number(d?.market[0].total_coin.toFixed(3)));
            setChainId(d?.market[0].coin_id);
            // setSymbolT(d?.market[0].symbol);
            // console.log(d);
        }
    }

    useEffect(() => {
        CoinInfo(symbolT)
    })
    return (
        <>
            <div className="card-header">
                <h3 className="title-modal">
                    Transfer Wallet
                    {/* {account} {toAccount ? ('To' + ' ' + toAccount) : null} */}
                </h3>
            </div>
            <div className="modail-data">
                <div className="modal-body">
                    <form onSubmit={FundTransfer} >
                        <div className="form-group" id="dropS_a">
                            <label htmlFor="usr">From:</label>
                            <div className="input-area dropdown">
                                <span>
                                    <FormatListNumberedIcon />
                                </span>
                                {/* <input list="Direction" name="browser" id="browser" onChange={direction} placeholder='Main Account' /> */}
                                <select className="form-control" name="browser" id="browser" onChange={direction}>
                                    <option value='Main Account' selected={props.accountType == 'main_account' ? true : false}> Main Account </option>
                                    <option value="Trading Account" selected={props.accountType == 'trade_account' ? true : false}> Trading Account </option>
                                    <option value="Funding Account" selected={props.accountType == 'funding_account' ? true : false}> Funding Account</option>
                                </select>
                            </div>
                            <small style={{ color: 'green', marginTop: '10px', display: 'block' }}> Your Balance is {(Number(getBalance) > 0) ? getBalance : 0} {symbolT.toLocaleUpperCase()} </small>
                        </div>

                        <div className="form-group form__margin" id="dropS_ab"  >
                            <label htmlFor="accountName">To:</label>
                            <div className="input-area dropdown">
                                <span >
                                    <FormatListNumberedRtlIcon />
                                </span>
                                {/* <input list="Direction" name="browser" id="browser" onChange={(e) => directionToAccount(e)} placeholder='Trading Account' /> */}
                                <select className="form-control" name="browser" id="browser" onChange={(e) => directionToAccount(e)}>
                                    <option value='Main Account'> Main Account </option>
                                    <option value="Trading Account" selected> Trading Account </option>
                                    <option value="Funding Account"> Funding Account</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group-12 form-group">
                            <label htmlFor="usr">Amount:</label>
                            <div className="input-area" >
                                <input type="number"
                                    value={amountT}
                                    onChange={(e) => setAmountT(Number(e.target.value))}
                                    className="form-control" id="usr" placeholder="Amount" />
                                <button type="button" className="btn_max" onClick={(e) => setAmountT(getBalance)}>Max </button>
                            </div>
                        </div>
                        <div className="btn__form">
                            <button type="submit" className="btn trade_btn active"  disabled={btnDisable}>
                                {btnDisable === true &&
                                    <div className="color-ring">
                                        <ColorRing
                                            visible={true}
                                            height="20"
                                            width="20"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                        />

                                        Please Wait...
                                    </div>
                                }
                                {btnDisable === false ? 'Submit' : ''}
                            </button>
                            {/* FundTransfer() */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default TransferModalCom;