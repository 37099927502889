import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _fetch from "../config/api";
import { title, logo, markit_url, api_url } from "../config/config";
import HerderData from "../config/privateHeader.json";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import ContrastIcon from '@mui/icons-material/Contrast';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import Dashboard from "../Sections/Dashborad";
type propsList = {
    userLogin: boolean;
    formHeader: Boolean;
};
const PrivateHeader = (props: propsList) => {
    let location = useLocation();

    const auth = localStorage.getItem("auth");
    let navigate = useNavigate();

    const [desktopHeader, setdesktopHeader] = useState(true);
    const [toggleNav, settoggleNav] = useState(true);
    const openMobileDropDown = (e: any) => {
        const activeMobileTab = document.querySelector(
            ".mobile_navlist .mobile_sub_navlist_item .nav_btn.nav_drp_dwn.active"
        );
        if (activeMobileTab) {
            activeMobileTab?.classList.remove("active");
            activeMobileTab?.nextElementSibling?.removeAttribute("style");
        }
        if (activeMobileTab === e.currentTarget) {
            e.currentTarget.classList.remove("active");
            e.currentTarget.nextElementSibling.removeAttribute("style");
        } else {
            e.currentTarget.classList.add("active");
            e.currentTarget.nextElementSibling.setAttribute(
                "style",
                `height:${e.currentTarget.nextElementSibling.scrollHeight}px;`
            );
        }
    };
    useEffect(() => {
        const CheckWidowWidth = () => {
            if (window.innerWidth <= 1024) {
                setdesktopHeader(false);
            } else {
                setdesktopHeader(true);
            }
        };

        CheckWidowWidth();
        window.addEventListener("resize", () => {
            CheckWidowWidth();
        });
    }, [props, auth]);

    useEffect(() => {
        const LoginToken = localStorage.getItem("accessToken");
        // close toggle
        settoggleNav(true);
        // console.log("LoginToken", LoginToken);

        // Close Dropdown
        const activeMobileTab = document.querySelector(
            ".mobile_navlist .mobile_sub_navlist_item .nav_btn.nav_drp_dwn.active"
        );
        if (activeMobileTab) {
            activeMobileTab?.classList.remove("active");
            activeMobileTab?.nextElementSibling?.removeAttribute("style");
        }

        if (auth == "true") {
            (async () => {
                const data1 = await _fetch(`${api_url}/user/userInfo/`, "GET", "", {
                    Authorization: "Bearer" + LoginToken,
                });
                if (data1?.data?.data) {

                } else {
                    localStorage.clear();
                    navigate("/login");
                }
            })();
        }
        // :''
    }, [location, auth]);

    // const  SetButton = () => {
    //     if(auth != 'true'){
    //         return(
    //             <>
    //                 <li className="header_navitem">
    //                     <Link to="/login" className="nav_btn s-normal-color header-btn font-w-500">
    //                         Log In
    //                     </Link>
    //                 </li>
    //                 <li className="header_navitem">
    //                     <Link to="/signup" className="nav_btn active s-normal-color header-btn font-w-500">
    //                         Sign Up
    //                     </Link>
    //                 </li>
    //             </>
    //         )
    //     }else{
    //         return(
    //             <>

    //                 <li className="header_navitem">
    //                     <Link to="" onClick={() => logOut()} className="nav_btn active s-normal-color header-btn font-w-500">
    //                         LogOut
    //                     </Link>
    //                 </li>
    //             </>
    //         )
    //     }
    // }
    const logOut = async () => {
        const passData = {
            currency: "USD",
            limit: 10,
            page: 1,
            // fields:'_id'
        };
        const data1 = await _fetch(`${api_url}/auth/logout`, "GET", passData, {});
        // const dataaa = await JSON.stringify(data1);
        localStorage.clear();
        window.location.reload();
    };
    const login = auth;
    // const login: Boolean = props.userLogin;

    const togglerBar = (id: any) => {
        const a: HTMLElement | any = document.body;
        a.classList.toggle("show_header__mobail");
        // console.log("okay");
    };

    const dropdown = (id: any) => {
        const a: HTMLElement | any = document.getElementById(id);
        a.classList.toggle("dropdown-show");
        // console.log("okay => show dropdown");
    };

    const modail_dropdown = (id: any) => {
        const a: HTMLElement | any = document.getElementById(id);
        a.classList.toggle("mobail-dropdown-show");
        // console.log("okay => show dropdown");
    };


    // const SwitchTheme = (id: any) => {
    //     const a: HTMLElement | any = document.getElementById(id);
    //     a.classList.toggle("light-theme");
    //     console.log("okay => show dropdown");
    // };

    const [theme, setTheme] = useState('DARK');
    const toggleTheme = () => {
        if (theme === 'LIGHT') {
            setTheme('DARK');
            localStorage.setItem("mode", 'DARK');
            localStorage.setItem("mode_chart", 'dark');
            //   window.location.reload( ); 
        } else {
            setTheme('LIGHT');
            localStorage.setItem("mode", 'LIGHT');
            localStorage.setItem("mode_chart", 'light');

            // window.location.reload( )
        }
    };
    // modeTheme 
    let modeTheme = localStorage.getItem("mode");

    useEffect(() => {
        const body: HTMLElement | any = document.body;
        body.classList = modeTheme;
        // document.body.addEventListener = modeTheme;
    }, [theme]);

    // const [activepath, setActivePath] = useState('Dashboard')

    window.scrollTo(0, document.body.scrollTop);







    return (
        <>
            <div className="header-pvt-dav">
                <div className="header-container">
                    <div className="nav-header">
                        <div className="pvt-h-data left-pvt-header">
                            <div className="header-logo">
                                <div className="">
                                    {/* Header Logo */}
                                    <Link to="/" className="header_logo">
                                        <img src={logo} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <ul className="nav" id="headerNav">
                                <li className="nav-item" >
                                    <Link to="/Dashboard" className={location.pathname == '/Dashboard' ? "nav-link active" : ' nav-link'}   >
                                        HOME
                                    </Link>
                                </li>
                                <li className="nav-item" >
                                    <Link to="Dashboard/Market" className={location.pathname == '/Dashboard/Market' ? "nav-link active" : ' nav-link'} >
                                        MARKETS
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link">
                                        PROFILE
                                    </Link>
                                    {/* <div className="dropdown_menu">
                                        <Link to="/kyc" className="dropdown-link"> KYC </Link>
                                    </div> */}
                                    <div className="dropdown_menu">
                                        <Link to="Dashboard/kyc" className="dropdown-link"> KYC </Link>
                                        {/* <Link to="Dashboard/referral-program" className="dropdown-link"> REFERRAL</Link> */}
                                        <Link to="Dashboard/withdrawHistory" className="dropdown-link"> Withdraw History</Link>
                                        <Link to="Dashboard/incomes-ledger" className="dropdown-link"> Income Ledger</Link>

                                        {/* <Link to="/" onClick={() => {
                                            logOut()
                                        }} className="dropdown-link"> Logout </Link> */}
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link to="#" className="nav-link">
                                        Withdraw
                                    </Link>
                                    {/* <div className="dropdown_menu">
                                        <Link to="/kyc" className="dropdown-link"> KYC </Link>
                                    </div> */}
                                    <div className="dropdown_menu">
                                        <Link to="Dashboard/withdrawHistory/Pending" className="dropdown-link"> Pending Withdraw History</Link>
                                        <Link to="Dashboard/withdrawHistory/Approved" className="dropdown-link">Approved Withdraw History</Link>
                                        <Link to="Dashboard/withdrawHistory/Rejected" className="dropdown-link">Rejected Withdraw History</Link>

                                    </div>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" className="nav-link">
                                        P2P
                                    </Link>
                                    {/* <div className="dropdown_menu">
                                        <Link to="/kyc" className="dropdown-link"> KYC </Link>
                                    </div> */}
                                    <div className="dropdown_menu">
                                        <Link to="Dashboard/p2pnew" className="dropdown-link"> P2P </Link>
                                        <Link to="Dashboard/add_post" className="dropdown-link"> Ads Post</Link>
                                        <Link to="Dashboard/adds-data" className="dropdown-link"> My Ads Post</Link>
                                    </div>
                                </li>

                                {/* <li className="nav-item">
                                    <Link to="Dashboard/p2pnew" className={location.pathname ==  '/Dashboard/p2pnew'  ? "nav-link active":' nav-link' }>
                                        P2P
                                    </Link>
                                </li> */}
                            </ul>
                        </div>

                        <div className="pvt-h-data right-pvt-header">
                            <ul className="nav">
                                <li className="nav-item">
                                    <Link to="Dashboard/referral-program" className={location.pathname == '/Dashboard/referral-program' ? "nav-link active" : ' nav-link'}>
                                        invite & eran 50% fees
                                    </Link>
                                </li>
                                <li className="nav-item dropdown-a" id="dropdown_section">
                                    <a className="nav-link" onClick={() => dropdown("dropdown_section")} >
                                        <PersonIcon />
                                        <span className="only__tab"> Account </span>
                                    </a>
                                    <div className="dropdown-menu-header">
                                        <Link to="/Dashboard/account-settings" className="dropdown-link">
                                            <img src={require("../Assets/Icons/manage-profile.svg").default} width="20px" />
                                            Account Settings
                                        </Link>
                                        {/* <a href="#" className="dropdown-link">
                                            <img src={require("../Assets/Icons/gift.svg").default} width="20px" />
                                            Crypto gifts
                                        </a>
                                        <a href="#" className="dropdown-link">
                                            <img src={require("../Assets/Icons/competition.svg").default} width="20px" /> 
                                            contest
                                        </a> */}
                                        <Link to="/" onClick={() => {
                                            logOut()
                                        }} className="dropdown-link">
                                           <img src={require("../Assets/Icons/logout.svg").default} width="20px" />  
                                            Log out
                                        </Link>
                                    </div>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="#" className="nav-link">
                                        <SystemUpdateIcon />
                                        <span className="only__tab">Download Apps </span>
                                    </Link>
                                </li> */}
                                <li className="nav-item img_s">
                                    {/* <Link to="#" className="nav-link" onClick={toggleTheme}> */}
                                    <a className="nav-link" onClick={toggleTheme}  /* onClick={() => SwitchTheme("light-theme")} */ >
                                        <ContrastIcon />
                                        <span className="only__tab">Switch Theme </span>
                                    </a>
                                    {/* </Link> */}
                                </li>
                                <li className="nav-item">
                                    <Link to="/Dashboard/helpCenter" className="nav-link">
                                        <HelpOutlineIcon />
                                        <span className="only__tab"> Help & Support</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-pvt-Mobail" id="pvt__header">
                <div className="header-container">
                    <div className="nav-header">
                        <div className="header-logo">
                            <div className="">
                                {/* Header Logo */}
                                <Link to="/" className="header_logo">
                                    <img src={logo} alt="" />
                                </Link>
                            </div>

                            <div className="bar___pvt" onClick={() => togglerBar("pvt__header")} >
                                {/* <MenuIcon /> */}
                                <span className="line-toggle"></span>
                                <span className="line-toggle"></span>
                                <span className="line-toggle"></span>
                            </div>
                        </div>
                        <div className="header_____data__mobail">
                            <div className="pvt-h-data left-pvt-header">
                                <ul className="nav">
                                    <li className="nav-item">
                                        <Link to="/Dashboard" className={location.pathname == '/Dashboard' ? "nav-link active" : ' nav-link'}>
                                            HOME
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="Dashboard/Market" className={location.pathname == '/Dashboard/Market' ? "nav-link active" : ' nav-link'}>
                                            MARKETS
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown" id="drop__drop_1">
                                        <a onClick={() => modail_dropdown("drop__drop_1")} className={location.pathname == '/Dashboard/' ? "nav-link active" : ' nav-link'}>
                                            <span> PROFILE</span>

                                            <span className="arrow"></span>

                                        </a>
                                        <div className="dropdown-menu">
                                            {/* <Link to="Dashboard/account-settings" className="nav-link">
                                                ACCOUNT SETTING
                                            </Link> */}
                                            <Link to="Dashboard/kyc" className="nav-link">
                                                KYC
                                            </Link>
                                            <Link to="Dashboard/withdrawHistory" className="nav-link">
                                                Withdraw History
                                            </Link>
                                            {/* <Link to="Dashboard/referral-program" className="nav-link">
                                                REFERRAL
                                            </Link> */}
                                            {/* <Link to="/" onClick={() => {
                                                logOut()
                                            }} className="nav-link"> LOGOUT </Link> */}
                                        </div>
                                    </li>

                                    <li className="nav-item dropdown" id="drop__p2p">
                                        <a onClick={() => modail_dropdown("drop__p2p")} className={location.pathname == '/Dashboard/' ? "nav-link active" : ' nav-link'}>
                                            <span> P2P</span>

                                            <span className="arrow"></span>

                                        </a>
                                        <div className="dropdown-menu">

                                            <Link to="Dashboard/p2pnew" className="nav-link"> P2P </Link>
                                            <Link to="Dashboard/add_post" className="nav-link"> Ads Post</Link>
                                            <Link to="Dashboard/adds-data" className="nav-link"> My Ads Post</Link>


                                        </div>
                                    </li>

                                    {/* <li className="nav-item">
                                        <Link to="Dashboard/p2pnew" className={location.pathname ==  'Dashboard/p2pnew'  ? "nav-link active":' nav-link' }>
                                            P2P
                                        </Link>
                                    </li> */}
                                    <li className="nav-item top___bar__line">
                                        <Link to="Dashboard/referral-program" className={location.pathname == '/Dashboard/referral-program' ? "nav-link active" : ' nav-link'}>
                                            invite & eran 50% fees
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown" id="drop__drop_2">
                                        <a className="nav-link" onClick={() => modail_dropdown("drop__drop_2")} >
                                            <div className="nav-link_d-flex">
                                                <PersonIcon />
                                                <span className="only__tab"> Account </span>
                                            </div>
                                            <span className="arrow"></span>
                                        </a>

                                        <div className="dropdown-menu">
                                            <Link to="Dashboard/account-settings" className="nav-link">
                                                <div className="nav-link_d-flex">

                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="32" height="32"><path d="M14 2c-3.302 0-6 2.698-6 6s2.698 6 6 6 6-2.698 6-6-2.698-6-6-6zm0 2c2.221 0 4 1.78 4 4 0 2.221-1.779 4-4 4s-4-1.779-4-4c0-2.22 1.779-4 4-4zm-3 12c-4.969 0-9 4.031-9 9v2a1 1 0 0 0 1 1h10.848a1 1 0 1 0 0-2H4v-1c0-3.895 3.105-7 7-7h4.016a1 1 0 1 0 0-2zm11.987 0a1.001 1.001 0 0 0-.99 1.014V18.1a4.977 4.977 0 0 0-1.754.737l-.768-.768c-.236-.242-.503-.334-.756-.32-.757.04-1.385 1.027-.658 1.734l.771.772a4.975 4.975 0 0 0-.732 1.753h-1.086c-1.352-.019-1.352 2.02 0 2h1.09c.13.631.385 1.218.73 1.739l-.777.777c-.97.943.472 2.384 1.414 1.414l.776-.775a4.977 4.977 0 0 0 1.75.734v1.088c-.02 1.352 2.019 1.352 2 0V27.9a4.974 4.974 0 0 0 1.748-.732l.78.78a1 1 0 1 0 1.414-1.413l-.777-.778a4.976 4.976 0 0 0 .734-1.747h1.09a.999.999 0 1 0 0-2H27.9a4.976 4.976 0 0 0-.736-1.764l.771-.771a.996.996 0 0 0 0-1.432 1.004 1.004 0 0 0-.71-.285 1 1 0 0 0-.7.303l-.773.773A4.974 4.974 0 0 0 24 18.1v-1.086A.998.998 0 0 0 22.99 16zM23 20c1.668 0 3 1.331 3 3 0 1.668-1.332 3-3 3-1.669 0-3-1.332-3-3 0-1.669 1.331-3 3-3z" color="#000" font-family="sans-serif" font-weight="400" overflow="visible" /></svg>
                                                    <span> Account Settings</span>
                                                </div>
                                            </Link>
                                            {/* <Link to="#" className="nav-link">
                                                <div className="nav-link_d-flex">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24"><g data-name="Layer 2"><path d="M4.64 15.27v4.82a.92.92 0 0 0 .92.91h5.62v-5.73zM12.82 21h5.62a.92.92 0 0 0 .92-.91v-4.82h-6.54zM20.1 7.09h-1.84a2.82 2.82 0 0 0 .29-1.23A2.87 2.87 0 0 0 15.68 3 4.21 4.21 0 0 0 12 5.57 4.21 4.21 0 0 0 8.32 3a2.87 2.87 0 0 0-2.87 2.86 2.82 2.82 0 0 0 .29 1.23H3.9c-.5 0-.9.59-.9 1.31v3.93c0 .72.4 1.31.9 1.31h7.28V7.09h1.64v6.55h7.28c.5 0 .9-.59.9-1.31V8.4c0-.72-.4-1.31-.9-1.31zm-11.78 0a1.23 1.23 0 1 1 0-2.45c1.4 0 2.19 1.44 2.58 2.45zm7.36 0H13.1c.39-1 1.18-2.45 2.58-2.45a1.23 1.23 0 1 1 0 2.45z" data-name="gift" /></g></svg>
                                                    <span>   Crypto gifts</span>
                                                </div>
                                            </Link>
                                            <Link to="#" className="nav-link">
                                                <div className="nav-link_d-flex">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" data-name="Layer 1" viewBox="0 0 64 64"><path d="M19 59.5A1.5 1.5 0 0 0 17.5 58H15V46.87c2.28-.6 4-3.23 4-6.37V37H9v3.5c0 3.14 1.72 5.77 4 6.37V58H10.5a1.5 1.5 0 0 0 0 3h7A1.5 1.5 0 0 0 19 59.5zM7 43V37c-1.93 0-4 1.34-4 3S5.07 43 7 43zM21 37v6c1.93 0 4-1.34 4-3S22.93 37 21 37z" /><circle cx="46" cy="31.07" r="5" /><polygon points="7 15.2 16.03 21.34 27.3 19.55 25.98 12.17 7 15.2" /><path d="M32.78,25.81l-2.33.39-.32,0a2,2,0,0,1-1.93-1.61l-.56-3.14-11.2,1.78L10,32l23.33-3.82Z" /><path d="M58,23a2.9,2.9,0,0,0-1.25.28c-.29-1.4-.58-2.79-.87-4.13L53,3,27.26,7.23l3,16.8L54,20.12c.29,1.38.6,2.82.91,4.28,0,.27.11.54.17.81A3,3,0,0,0,55,26v6c0,4.42-2.27,8-10,8h1.46C38.73,40,33,43.58,33,48V58a3,3,0,0,0,6,0V52.07h2V56.9A18,18,0,0,1,51.46,54H50a16,16,0,0,0,5-.69V44.38c3.59-2.32,6-5.74,6-8.38V26A3,3,0,0,0,58,23Z" /><rect width="3" height="2" x="7" y="48" /><rect width="5" height="2" x="4" y="51" /><rect width="4" height="2" x="3" y="54" /><rect width="3" height="2" x="18" y="48" /><rect width="5" height="2" x="19" y="51" /><rect width="4" height="2" x="21" y="54" /></svg>
                                                    <span>contest </span>
                                                </div>
                                            </Link> */}
                                            {/* <Link to="/" className="nav-link" onClick={() => { logOut() }} >
                                                <div className="nav-link_d-flex">
                                                    <img src={require("../Assets/Icons/logout.svg").default} width="20px" />
                                                    <span> Log out</span>
                                                </div>
                                            </Link> */}
                                        </div>

                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="#" className="nav-link">
                                            <div className="nav-link_d-flex">
                                                <SystemUpdateIcon />
                                                <span className="only__tab">Download Apps </span>
                                            </div>
                                        </Link>
                                    </li> */}
                                    <li className="nav-item" onClick={toggleTheme}>
                                        <a className="nav-link"   >
                                            <div className="nav-link_d-flex">
                                                <ContrastIcon />
                                                <span className="only__tab">Switch Theme </span>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link">
                                            <div className="nav-link_d-flex">
                                                <HelpOutlineIcon />
                                                <span className="only__tab"> Help & Support</span>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link" onClick={() => { logOut() }} >
                                            <div className="nav-link_d-flex">
                                                {/* <img src={require("../Assets/Icons/logout.svg").default} width="20px" /> */}
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 6.35 6.35"><path fill-rule="evenodd" d="M7.953.998a3.024 3.024 0 0 0-3.006 3.004V20a3.024 3.024 0 0 0 3.006 3.004h3.994A3.022 3.022 0 0 0 14.951 20v-4.002c0-1.334-2-1.334-2 0V20a.983.983 0 0 1-1.004 1.004H7.953A.983.983 0 0 1 6.95 20V4.002a.983.983 0 0 1 1.004-1.004h3.994a.983.983 0 0 1 1.004 1.004v4.002c0 1.334 2 1.334 2 0V4.002A3.022 3.022 0 0 0 11.947.998H7.953zM1.957 4.984a1 1 0 0 0-1.01 1.02v11.994a1 1 0 0 0 2 0V6.004a1 1 0 0 0-.982-1.02 1 1 0 0 0-.008 0zm16.037 2.004a1 1 0 0 0-.096.004 1 1 0 0 0-.6 1.713L19.595 11h-9.588a1.006 1.006 0 0 0-.104 0c-1.333.07-1.23 2.071.104 2.002h9.582l-2.29 2.287a1 1 0 1 0 1.411 1.418l4.002-4.002a1 1 0 0 0 0-1.41l-4.002-4a1 1 0 0 0-.715-.307z" color="#000" font-family="sans-serif" font-weight="400" overflow="visible" paint-order="stroke fill markers" transform="scale(.26458)" /></svg>
                                                <span> Log out</span>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default PrivateHeader;
