import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import _fetch from "../config/api";
import { title, logo, markit_url, api_url } from "../config/config";
import HerderData from "../config/privateHeader.json";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import ContrastIcon from '@mui/icons-material/Contrast';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
// import Dashboard from "../Sections/Dashborad";
type propsList = {
    userLogin: boolean;
    formHeader: Boolean;
};
const MarketHeader = (props: propsList) => {
    let location = useLocation();
    
    const auth = localStorage.getItem("auth");
    let navigate = useNavigate();

    const [desktopHeader, setdesktopHeader] = useState(true);
    const [toggleNav, settoggleNav] = useState(true);
    const openMobileDropDown = (e: any) => {
        const activeMobileTab = document.querySelector(
            ".mobile_navlist .mobile_sub_navlist_item .nav_btn.nav_drp_dwn.active"
        );
        if (activeMobileTab) {
            activeMobileTab?.classList.remove("active");
            activeMobileTab?.nextElementSibling?.removeAttribute("style");
        }
        if (activeMobileTab === e.currentTarget) {
            e.currentTarget.classList.remove("active");
            e.currentTarget.nextElementSibling.removeAttribute("style");
        } else {
            e.currentTarget.classList.add("active");
            e.currentTarget.nextElementSibling.setAttribute(
                "style",
                `height:${e.currentTarget.nextElementSibling.scrollHeight}px;`
            );
        }
    };
    useEffect(() => {
        const CheckWidowWidth = () => {
            if (window.innerWidth <= 1024) {
                setdesktopHeader(false);
            } else {
                setdesktopHeader(true);
            }
        };

        CheckWidowWidth();
        window.addEventListener("resize", () => {
            CheckWidowWidth();
        });
    }, [props, auth]);

    useEffect(() => {
        const LoginToken = localStorage.getItem("accessToken");
        // close toggle
        settoggleNav(true);
        // console.log("LoginToken", LoginToken);

        // Close Dropdown
        const activeMobileTab = document.querySelector(
            ".mobile_navlist .mobile_sub_navlist_item .nav_btn.nav_drp_dwn.active"
        );
        if (activeMobileTab) {
            activeMobileTab?.classList.remove("active");
            activeMobileTab?.nextElementSibling?.removeAttribute("style");
        }

        if (auth == "true") {
            (async () => {
                const data1 = await _fetch(`${api_url}/user/userInfo/`, "GET", "", {
                    Authorization: "Bearer" + LoginToken,
                });
                if (data1?.data?.data) {

                } else {
                    localStorage.clear();
                    navigate("/login");
                }
            })();
        }
        // :''
    }, [location, auth]);

    // const  SetButton = () => {
    //     if(auth != 'true'){
    //         return(
    //             <>
    //                 <li className="header_navitem">
    //                     <Link to="/login" className="nav_btn s-normal-color header-btn font-w-500">
    //                         Log In
    //                     </Link>
    //                 </li>
    //                 <li className="header_navitem">
    //                     <Link to="/signup" className="nav_btn active s-normal-color header-btn font-w-500">
    //                         Sign Up
    //                     </Link>
    //                 </li>
    //             </>
    //         )
    //     }else{
    //         return(
    //             <>

    //                 <li className="header_navitem">
    //                     <Link to="" onClick={() => logOut()} className="nav_btn active s-normal-color header-btn font-w-500">
    //                         LogOut
    //                     </Link>
    //                 </li>
    //             </>
    //         )
    //     }
    // }
    const logOut = async () => {
        const passData = {
            currency: "USD",
            limit: 10,
            page: 1,
            // fields:'_id'
        };
        const data1 = await _fetch(`${api_url}/auth/logout`, "GET", passData, {});
        // const dataaa = await JSON.stringify(data1);
        localStorage.clear();
        window.location.reload();
    };
    const login = auth;
    // const login: Boolean = props.userLogin;

    const togglerBar = (id: any) => {
        const a: HTMLElement | any = document.body;
        a.classList.toggle("show_header__mobail");
        // console.log("okay");
    };

    const dropdown = (id: any) => {
        const a: HTMLElement | any = document.getElementById(id);
        a.classList.toggle("dropdown-show");
        // console.log("okay => show dropdown");
    };

    const modail_dropdown = (id: any) => {
        const a: HTMLElement | any = document.getElementById(id);
        a.classList.toggle("mobail-dropdown-show");
        // console.log("okay => show dropdown");
    };


    // const SwitchTheme = (id: any) => {
    //     const a: HTMLElement | any = document.getElementById(id);
    //     a.classList.toggle("light-theme");
    //     console.log("okay => show dropdown");
    // };

    const [theme, setTheme] = useState('DARK');
    const toggleTheme = () => {
        if (theme === 'LIGHT') {
            setTheme('DARK');
            localStorage.setItem("mode", 'DARK');
            localStorage.setItem("mode_chart", 'dark');
            //   window.location.reload( ); 
        } else {
            setTheme('LIGHT');
            localStorage.setItem("mode", 'LIGHT');
            localStorage.setItem("mode_chart", 'light');

            // window.location.reload( )
        }
    };
    // modeTheme 
    let modeTheme = localStorage.getItem("mode");

    useEffect(() => {
        const body: HTMLElement | any = document.body;
        body.classList = modeTheme;
        // document.body.addEventListener = modeTheme;
    }, [theme]);

    // const [activepath, setActivePath] = useState('Dashboard')

    window.scrollTo(0, document.body.scrollTop);







    return (
        <>
            <div className="header-pvt-dav">
                <div className="header-container">
                    <div className="nav-header">
                        <div className="pvt-h-data left-pvt-header">
                            <div className="header-logo">
                                <div className="">
                                    {/* Header Logo */}
                                    <Link to="/" className="header_logo">
                                        <img src={logo} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <ul className="nav" id="headerNav">
                                <li className="nav-item" >
                                    <Link to="/Dashboard" className={location.pathname ==  '/'  ? "nav-link active":' nav-link' }   >
                                        HOME
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        {/* <div className="pvt-h-data right-pvt-header">
                            <ul className="nav">
                                <li className="nav-item img_s">
                                    <Link to="#" className="nav-link" onClick={toggleTheme}>
                                    <a className="nav-link" onClick={toggleTheme}   >
                                        <ContrastIcon />
                                        <span className="only__tab">Switch Theme </span>
                                    </a>
                                    </Link>
                                </li>
                               
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MarketHeader;
