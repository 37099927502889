import React, { useEffect } from 'react'
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import feedback_top from '../Assets/Images/feedback-top.svg'

import feedback_img from '../Assets/Images/feedback-img.svg'
import feedback_img1 from '../Assets/Images/feedback-img1.svg'
import feedback_img2 from '../Assets/Images/feedback-img2.svg'
import feedback_img3 from '../Assets/Images/feedback-img3.svg'
import gift1 from '../Assets/Images/gift1.png'
import gift2 from '../Assets/Images/gift2.png'
import gift3 from '../Assets/Images/gift3.png'
import how1 from '../Assets/Images/how_1.svg'
import how2 from '../Assets/Images/how_2.svg'
import how3 from '../Assets/Images/how_3.svg'
import { title } from '../config/config';




const Feedback = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
    return (
        <>

            <div className='feedback__img pt__spacing'>
                <section className='feedback__banner'>
                    <div className='feedback____container'>
                        <div className='card__banner__left'>
                            <h1>We love hearing from you!</h1>
                            <h3>Thank you for your feedback.</h3>
                            {/* <button type='button' className='btn'>Our vision {">>"}</button> */}
                        </div>
                    </div>
                </section>



                <div className="b_container_abc">
                    <div className="submit_feedback">
                        <h1>Submit Feedback</h1>
                        <div className="row">
                            <div className="col-6">
                                <div className="card">
                                    <div className="img-box">
                                        <img src={feedback_img} alt="" />
                                    </div>
                                    <div className="text-box">
                                        <h1>Safety & Security <ArrowRightIcon /></h1>
                                        <p>If you find any security flaws or bugs, please give us your feedback as soon as possible.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card">
                                    <div className="img-box">
                                        <img src={feedback_img1} alt="" />
                                    </div>
                                    <div className="text-box">
                                        <h1>Product Suggestions <ArrowRightIcon /></h1>
                                        <p>If you discover any product-related bugs or want to share advice on how to optimize our products, your feedback is welcome.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card">
                                    <div className="img-box">
                                        <img src={feedback_img2} alt="" />
                                    </div>
                                    <div className="text-box">
                                        <h1>Design & Layout<ArrowRightIcon /></h1>
                                        <p>Submit advice related to visual features or usability. Your feedback is always welcome.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card">
                                    <div className="img-box">
                                        <img src={feedback_img3} alt="" />
                                    </div>
                                    <div className="text-box">
                                        <h1>Operational Feedback<ArrowRightIcon /></h1>
                                        <p>If there are any misunderstandings in content related to events or announcements, your feedback is welcome.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="redeem">
                        <div className="head">
                            <h1>Redeem Gifts</h1>
                            <p>Once your feedback is accepted, you will be eligible for rewards of up to 2,000 USDT, as well as exclusive Bitget swag.</p>
                        </div>
                        <div className="reddem-gifts">
                            <div className="row">
                                <div className="col-4">
                                    <div className="top-img">
                                        <img src={gift1} alt="" />
                                    </div>
                                    <div className="info">
                                        <p>2000 USDT</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-img">
                                        <img src={gift2} alt="" />
                                    </div>
                                    <div className="info">
                                        <p>Official hoodie</p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="top-img">
                                        <img src={gift3} alt="" />
                                    </div>
                                    <div className="info">
                                        <p>Mug</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="works">
                        <div className="head">
                            <h1>How Does It Work?</h1>
                        </div>
                        <div className="reddem-gifts">
                            <div className="row">
                                <div className="col-4">
                                    <div className="top-img">
                                        <img src={how1} alt="" />
                                    </div>
                                    <div className="info">
                                        <p>Submit feedback</p>
                                    </div>
                                </div>
                                <span></span>
                                <div className="col-4">
                                    <div className="top-img">
                                        <img src={how2} alt="" />
                                    </div>
                                    <div className="info">
                                        <p>Team review</p>
                                    </div>
                                </div>
                                <span></span>
                                <div className="col-4">
                                    <div className="top-img">
                                        <img src={how3} alt="" />
                                    </div>
                                    <div className="info">
                                        <p>Redeem your gift</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="foot">
                            <p>Bitget serves users globally, and we regard the safe and stable development of the platform as our top priority. Your valuable feedback helps us improve our product and create a better future together.</p>
                            <p>Let's get started! <a href="#"> Click here to submit feedback.</a></p>
                        </div>
                    </div>

                    <div className="bottom_section">
                        <h1>We are proud to serve you</h1>
                        <div className="col-12">
                            <div className="table">
                                <div className="head">
                                    <h3> <WatchLaterOutlinedIcon />2022/04/28 18:29:27</h3>
                                    <h3>User: 979***9792</h3>
                                </div>
                                <div className="another">
                                    <p><span>Product Suggestions</span>  User feedback: <SentimentSatisfiedAltOutlinedIcon /> </p>
                                </div>
                                <div className="txt">
                                    <p>Hello, I would like to suggest adding a "Hide Full" function in copy trade, it will be more convenient for us to choose the traders, thanks!</p>
                                </div>
                                <div className="down-txt">
                                    <span></span>
                                    <div className="box">
                                        <p>Adopted</p>
                                    </div>
                                    <div className="last-txt">
                                        <p>Thank you for your feedback, we have added this function, hope that you can enjoy trading here!</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12">
                            <div className="table">

                                <div className="head">
                                    <h3> <WatchLaterOutlinedIcon />2021/03/10 14:21:15</h3>
                                    <h3>User: mochcholid****@gmail.com</h3>
                                </div>
                                <div className="another">
                                    <p><span>Share Your Thoughts</span> User feedback:  <SentimentSatisfiedAltOutlinedIcon /> </p>
                                </div>
                                <div className="txt">
                                    <p>I think in the Bitget application there should be a widget feature to help traders monitor price movements automatically</p>
                                </div>
                                <div className="down-txt">
                                    <span></span>
                                    <div className="box">
                                        <p>Adopted</p>
                                    </div>
                                    <div className="last-txt">
                                        <p>Widget for price quotes has already been added on App, please refer.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}

export default Feedback
