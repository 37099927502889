import React, { useEffect } from 'react';
import { title } from '../config/config';
import BG_imgs from '../Assets/Images/about.png'
import aboutUser from '../Assets/Icons/aboutUser.svg'
import aboutTransactions from '../Assets/Icons/trantionSpeed.svg'
import aboutSupport from '../Assets/Icons/supportAbout.svg'
import { Link } from 'react-router-dom';

const About = (props:any) => {
    useEffect(() => {
        document.title = props.title;

    }, [])
    
  return (
  <>
    <div className='about__E pt__spacing'>
        {/* Banenr  */}
        <div className='about__banenr' style={{ backgroundImage: `url(${BG_imgs})` }}>
            <div className='about__banenr__container'>
                <h1 className='heading_about'>Welcome to {title}</h1>
                <p className='pragraph__about'>At {title}, we believe that everyone should have the freedom to earn, hold, <br/> spend, share and give their money - no matter who you are or where you come from.</p>

                <div className='about___details'>
                    <div className='about___details_a'>
                        <img src={aboutUser} alt="about__user"/>
                            <div className=''>
                                <p>2.0 bn</p>
                                <h5> Average daily volume</h5>

                            </div>
                    </div>

                    <div className='about___details_a'>
                        <img src={aboutTransactions} alt="about__user"/>
                            <div className=''>
                                <p> 10,000 bn</p>
                                <h5> Transactions per second</h5>

                            </div>
                    </div>
                    <div className='about___details_a'>
                        <img src={aboutSupport} alt="about__user"/>
                            <div className=''>
                                <p>24/7</p>
                                <h5> Customer Support in 10+ languages</h5>

                            </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div className='EAC_container'>
            <div className='about___mistion'>
                <h2 className='about___mistion_heading' >Our Mission {title}</h2>
                <p className='about___mistion_pragraph'> Our platform is trusted by millions worldwide, and features an unmatched portfolio of financial product offerings.</p>
                
                <Link to="/login" className='btn'> Leran more</Link>
            </div>

            <section className='ECO__section'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='about___mistion_heading' >Our ecosystem</h2>
                        <p className='about___mistion_pragraph'> Today, {title} is the world’s leading blockchain ecosystem, with a product suite that includes the largest digital asset exchange. Our Mission is to be the infrastructure service provider in crypto.</p>
                    </div>
                    <div className='col-4'>
                        <div className='card'>
                            {/* <h4> </h4> */}
                            <img src={aboutUser} alt="card_img"/>
                            <h3>{title} Exchange</h3>
                            <p> {title} Exchange is the largest crypto exchange by trade volume.</p>
                        </div>
                    </div>


                    <div className='col-4'>
                        <div className='card'>
                            {/* <h4> </h4> */}
                            <img src={aboutUser} alt="card_img"/>
                            <h3>{title} Chain</h3>
                            <p> {title} Chain is a community-driven blockchain software system with developers and contributors from all over the world.</p>
                        </div>
                    </div>

                    <div className='col-4'>
                        <div className='card'>
                            {/* <h4> </h4> */}
                            <img src={aboutUser} alt="card_img"/>
                            <h3>Trust Wallet</h3>
                            <p> The official wallet of {title}, Trust Wallet, is a secure, decentralized wallet where users can send, receive and store their digital assets.</p>
                        </div>
                    </div>


                    <div className='col-4'>
                        <div className='card'>
                            {/* <h4> </h4> */}
                            <img src={aboutUser} alt="card_img"/>
                            <h3>Research</h3>
                            <p> {title} Research provides institutional-grade research reports, data-driven insights and analysis for investors in the crypto space.</p>
                        </div>
                    </div>

                    <div className='col-4'>
                        <div className='card'>
                            {/* <h4> </h4> */}
                            <img src={aboutUser} alt="card_img"/>
                            <h3>Launchpad</h3>
                            <p> {title} Exchange is the largest crypto exchange by trade volume.</p>
                        </div>
                    </div>


                    <div className='col-4'>
                        <div className='card'>
                            {/* <h4> </h4> */}
                            <img src={aboutUser} alt="card_img"/>
                            <h3> Labs</h3>
                            <p> {title} Labs is an infrastructure impact fund and an initiative to incubate, invest in, and empower blockchain projects.</p>
                        </div>
                    </div>
                </div>
            </section>



            <section className='putting__section'>
                <div className='row'>
                    <div className='col-6'>
                        
                    </div>
                </div>


            </section>

        </div>
    </div>
  </>
  );
}

export default About;
