import { Link } from "react-router-dom";
import { title } from "../config/config";
// type Servicecard = {
//     CrdHeading: string,
//     CrdInfo: string,
//     CrdBtn: string,
//     heighlightinfo: string,
//     CrdBtnImg: string,
// }
const SikkaService = () => {
    function ServiceCard(props: any) {
        return (<>
            <div className="card">
                <h3 className="card_heading t-primary-color">
                    {props.CrdHeading}
                </h3>
                <p className="card_info t-normal-color">
                    {props.CrdInfo}
                    <span>{props.heighlightinfo}</span>
                </p>
                <Link to="/" className="f-primary-color" >
                    {props.CrdBtn}
                    <img src={require(`../Assets/Icons/${props.CrdBtnImg}.svg`)} alt="" />
                </Link>
            </div>
        </>)
    }
    const CardData = [
        {
            CrdHeading: "High Yield",
            CrdInfo: title + ` Earn offers a pioneering staking platform that helps you boost your earnings through staking profits and POL income.`,
            heighlightinfo: "POL income is calculated by the POL mining power evaluation system.",
            CrdBtn: "Learn More",
            CrdBtnImg: "service-card-arrow",
        },
        {
            CrdHeading: "High Flexibility",
            CrdInfo: title + `Earn products are highly flexible and diversified. Choose the product that's best for you and trade freely on the market.`,
            CrdBtn: "Learn More",
            heighlightinfo: "",
            CrdBtnImg: "service-card-arrow",
        },
        {
            CrdHeading: "Multiple Staking Choices",
            CrdInfo: "Choose from a variety of products, including ATOM, TRX, ZRX, IOST, and more.",
            CrdBtn: "Learn More",
            heighlightinfo: "",
            CrdBtnImg: "service-card-arrow",
        },
    ]
    return (
        <>
            <section className="sec_sikka__service sec_hightloght sec-py s-secondary-bg">
                <div className="container">
                    <h2 className="sec_heading">
                        Highlights
                    </h2>
                    <div className="service__card">
                        {CardData.map(function (value, index) {
                            return (
                                <ServiceCard
                                    key={index}
                                    heighlightinfo={value.heighlightinfo}
                                    CrdHeading={value.CrdHeading}
                                    CrdInfo={value.CrdInfo}
                                    CrdBtn={value.CrdBtn}
                                    CrdBtnImg={value.CrdBtnImg}
                                />
                            )
                        })
                        }

                    </div>
                </div>
            </section>
        </>
    )
}
export default SikkaService;