
import Spotlightcard from "../Snippets/Spotlight-card";
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { title} from "../config/config";

const spotlightdata = [
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "In Progress",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        Cardheading: title,
        progress: "In Progress",
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "In Progress",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        Cardheading: title,
        progress: "In Progress",
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        Cardheading: title,
        progress: "In Progress",
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "In Progress",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "In Progress",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "In Progress",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    }
];

const spotlightEnddata = [
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        progress: "Ended",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        Cardheading: title,
        progress: "Ended",
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "Ended",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "Ended",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "Ended",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "Ended",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "Ended",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    },
    {
        image: "sport-icon",
        logigicon: "sport-logo",
        progress: "Ended",
        Cardheading: title,
        Cardbodyheading: "Spotlight",
        Crdprice: "TH",
        Crdpricenumber: "19",
        cardfooterheading: title + " Spotlight (South Asia Exclusive) - Gari Network (GARI)"

    }
]

const SpotlightPage = (props:any) => {
    useEffect(() => {
        document.title = props.title;

    },[])
    return (
        <>
            <section className="Sec_spotlight_pages s-primary-bg sec-py sec-py__new">
                <div className="container">
                    <div className="sec_header">
                        <h2 className="sec_heading s-normal-color">{title}</h2>
                        <h4 className="small-heading ">Token Launch Platform</h4>
                    </div>
                    <div className="sec_content">
                        <h2 className="sec_main_heading s-normal-color">In Progress Tokens</h2>
                        <ul className="sport_cards">
                            <Swiper
                                slidesPerView={4}
                                enabled={false}
                                spaceBetween={30}
                                breakpoints={{
                                    1440: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                        enabled: false
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                        enabled: false
                                    },
                                    767: {
                                        slidesPerView: 1.33,
                                        spaceBetween: 10,
                                        enabled: true

                                    },
                                    280: {
                                        slidesPerView: 1.33,
                                        spaceBetween: 10,
                                        enabled: true
                                    },
                                }}
                                className="mySwiper">
                                {

                                    spotlightdata.map((val, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <Spotlightcard
                                                    image={val.image}
                                                    Cardheading={val.Cardheading}
                                                    Crdprice={val.Crdprice}
                                                    progress={val.progress}
                                                    Crdpricenumber={val.Crdpricenumber}
                                                    logigicon={val.logigicon}
                                                    Cardbodyheading={val.Cardbodyheading}
                                                    cardfooterheading={val.cardfooterheading}
                                                />
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        </ul>
                        <h2 className="sec_main_heading s-normal-color">Ended Tokens</h2>
                        <ul className="sport_cards ended-tokens">
                            <Swiper
                                slidesPerView={4}
                                enabled={false}
                                spaceBetween={30}
                                breakpoints={{
                                    1440: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                        enabled: false
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                        enabled: false
                                    },
                                    767: {
                                        slidesPerView: 1.33,
                                        spaceBetween: 10,
                                        enabled: true

                                    },
                                    280: {
                                        slidesPerView: 1.33,
                                        spaceBetween: 10,
                                        enabled: true
                                    },
                                }}
                                className="mySwiper">
                                {
                                    spotlightEnddata.map((val, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <Spotlightcard
                                                    image={val.image}
                                                    Cardheading={val.Cardheading}
                                                    Crdprice={val.Crdprice}
                                                    progress={val.progress}
                                                    Crdpricenumber={val.Crdpricenumber}
                                                    logigicon={val.logigicon}
                                                    Cardbodyheading={val.Cardbodyheading}
                                                    cardfooterheading={val.cardfooterheading}
                                                />
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SpotlightPage;   