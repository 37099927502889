import React, { useEffect } from "react";
import { title } from "../config/config";
import FaqBanner from "../Sections/Faq-banner";
import Faqhelp from "../Sections/Faq-help";
const FaqPage = (props:any) => {
    useEffect(() => {

        document.title = props.title;
    },[])
    return (

        <>
            {/* Faq Banner Section */}
            <FaqBanner />
            {/* Faq Help Section */}
            <Faqhelp />
        </>
    )
}
export default FaqPage;