import { Link } from "react-router-dom";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { AiOutlineRise,AiOutlineFall } from "react-icons/ai";

// type TradeData = {
//     marketdata: boolean,
//     CoinIcon: string,
//     CoinName: string,
//     CoinShortName: string,
//     Lastupdateprice: string,
//     Priceh_changes: string,
//     TradeIcon: string,
//     BuyCoin: string,
//     TradeCoin: string,
//     daydata: string,
//     perioddata: string,
//     ClickEvent: any,
//     quotaprice: string,
//     quotainnerprice: string,
//     quotacoinprice: string,
//     status: string,
//     endin: string

// }

const CommonCoinData = (props: any) => {
    const auth =  localStorage.getItem("auth");

    return (
        <>
            {/* Common Card */}
            {props.marketdata ?
                <li onClick={props.ClickEvent}>
                    <ul>
                        <li >
                            <Link to="/signup" className="coin" >
                                <img className="coin_icon" style={{ width: '20%' }} src={props.CoinIcon} alt="" />
                                <div>
                                    <div className="coin_name">
                                        <span className="t-primary-color">{props.CoinShortName.toUpperCase()}</span>
                                        <span className="t-normal-color" style={{ 'fontSize': '12px' }} >{props.CoinName}</span>
                                    </div>
                                    <p className="m_price t-primary-color" >
                                        {props.Lastupdateprice}
                                    </p>
                                </div>

                            </Link>
                        </li>
                        <li className="last_price">
                            <Link to="/" className="t-primary-color" >
                                {props.Lastupdateprice}        
                                
                            </Link>
                        </li>
                        <li className="h_changes">
                            <Link to="/" className="f-normal-color" style={{color:props.Priceh_changes < 0 ? 'red' :'#24AE8F'}} >
                                {props.Priceh_changes?.toFixed(1)}% {props.Priceh_changes < 0 ? <AiOutlineFall /> :<AiOutlineRise />}
                            </Link>
                        </li>
                        <li>
                            {(() => {
                                if (props.Priceh_changes > 0) {
                                    return (
                                        <div><Sparklines data={props.TradeIcon}>
                                            <SparklinesLine color="green" />
                                        </Sparklines></div>
                                    )
                                } else {
                                    return (
                                        <div><Sparklines data={props.TradeIcon}>
                                            <SparklinesLine color="red" />
                                        </Sparklines></div>
                                    )
                                }
                            })()}

                            {/* <Link to="/">
                                <Sparklines data={[5, 10, 5, 20, 8, 15]} limit={5} width={100} height={20} margin={5}>
                                </Sparklines>
                                <img className="coin_icon" style={{ width: '20%' }} src={props.TradeIcon} alt="" />
                            </Link> */}
                        </li>
                        <li className="buy__trade__button">
                            <Link to="/signup" className="trade_btn" >{props.BuyCoin}</Link>
                            <Link to={`/trading/${props.CoinShortName.toUpperCase()}`} className="trade_btn" >{props.TradeCoin}</Link>
                        </li>
                    </ul>
                </li>
                :
                <li onClick={props.ClickEvent}>
                    <ul>
                        <li >
                            <Link to="/" className="coin" >
                                <img className="coin_icon" src={props.image} alt="" />
                                <div>
                                    <div className="coin_name">
                                        <span className="t-primary-color">{props.CoinShortName}</span>
                                    </div>
                                </div>

                            </Link>
                        </li>
                        <li className="last_price">
                            <Link to="/" className="t-primary-color" >
                                {props.Lastupdateprice}
                            </Link>
                        </li>
                        <li className="h_changes">
                            <Link to="/" className="f-normal-color" >
                                {props.Priceh_changes}
                            </Link>
                        </li>
                        <li>
                            <Link to="/">
                                <span className="day">{props.daydata}</span>
                                <span className="period">{props.perioddata}</span>
                            </Link>
                        </li>
                        <li className="quota_data">
                            <Link to="/">
                                <span className="quota_price">{props.quotaprice}</span>
                                <div className="quota">
                                    <span className="quota_inner_price">{props.quotainnerprice}</span>
                                    <span className="quota_coin_price">{props.quotacoinprice}</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/">
                                <span className="status">{props.status}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/">
                                <span className="endin">{props.endin}</span>
                            </Link>
                        </li>

                        <li className="buy__trade__button">
                            <Link to="/" className="trade_btn" >{props.TradeCoin}</Link>
                        </li>
                    </ul>
                </li>
            }


        </>
    )
}
export default CommonCoinData;