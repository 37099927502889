import React,{ Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import _fetch from "../config/api";
import { api_url, title } from "../config/config";
const selected = (id: any) => {
    const sa: HTMLElement | any = document.getElementById(id);
    sa.classList.add("show_form");
    const column: HTMLElement | any = document.getElementById('HideColumn');
    column.style.display = 'none';
    const btn: HTMLElement | any = document.getElementById('BtnHide');
    btn.style.display = 'none';
}
const Kyc = (props:any) => {
    let navigate = useNavigate();
    useEffect(() => {
		document.title = props.title ;

        (async () => {
            const passData = {};
            const data1 = await _fetch(`${api_url}/kyc/userKycInfo/`, 'GET', passData, {});
            const newData = data1.data.data;
            if (newData.kyc_status === 'Pending') {
                navigate("/Dashboard/Kyc/Pending");
            } else if (newData.kyc_status === 'Not Applied' || newData.kyc_status === 'Reject') {
                navigate("/Dashboard/Kyc");
            }
        })()
    }, [])

    return (
        <>
            <div className="contaienr_kyc margin_b">
                <div className="inner_contaienr">
                    <div className="card">
                        <div className="card-header">
                            <div className="check-box">
                                <div className="img-bg_a">
                                    <img src={require("../Assets/Icons/circle.svg").default} alt="eys icon" />
                                </div>
                                <span className="check-box-name" > KYC APPROVED</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <h4 style={{ color: '#04cdff' }}  >Congratulations!</h4>
                            <span style={{ fontSize: '14px' }} >Your {title} account is approved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Kyc;