import { Link } from "react-router-dom";
import { title } from "../config/config";

type CreateAccStep = {
    StepNumber: string,
    stepHeading: string,
    stepInfo: string,
}
const GetFirstCoind = () => {
    function CreateAccStep(props: CreateAccStep) {
        return (
            <>
                <div className="create_accc_step">
                    <div className="step_number s-normal-color">
                        {props.StepNumber}
                    </div>
                    <div className="step_info ">
                        <h3 className="s-normal-color">
                            {props.stepHeading}
                        </h3>
                        <span className="s-normal-color">
                            {props.stepInfo}
                        </span>
                    </div>
                </div>
            </>
        )
    }
    const StepData = [
        {
            StepNumber: "1",
            stepHeading: "Create " + title + " Account",
            stepInfo: " Register now to trade crypto anywhere anytime"
        },
        {
            StepNumber: "2",
            stepHeading: "Buy coins with a credit ca..",
            stepInfo: "Simply select a coin, scan the QR code or copy the deposit address."
        },
        {
            StepNumber: "3",
            stepHeading: "Low-Cost Trading",
            stepInfo: " Spot, Futures, and One-Click Copy Trading. We satisfy all your crypto trading needs."
        }
    ]
    return (
        <>
            <section className="sec_get_first_Coin sec-py">
                <div className="container">
                    <div className="sec_content">
                        <div className="sec_text">
                            <h2 className="sec_heading s-normal-color mobail-size">
                                Get Your First Coin in Minutes
                            </h2>
                            <p className="sec_info s-normal-color">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                            </p>
                            <Link to="/signup" className="cta_button">Get Started</Link>
                        </div>
                        <div className="sec_text">
                            {StepData.map(function (val: any, index: any) {
                                return (
                                    <div key={index} className="get__firsta__card">
                                        <CreateAccStep

                                            StepNumber={val.StepNumber}
                                            stepHeading={val.stepHeading}
                                            stepInfo={val.stepInfo}
                                        />
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default GetFirstCoind;