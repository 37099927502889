import React, { useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { title } from '../config/config';

const Fees = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, []);
    return (
        <>
            <div className="fees-sec">
                <div className="a_container">
                    <div className="top-sec">
                        <h1>TRADING, DEPOSIT & WITHDRAWAL FEES</h1>
                        <div className="inner-sec">
                            <h4>Important</h4>
                            <p>From 1 July 2022, TDS (Tax Deducted at Source) of 1% (5% in exceptional cases) of the trade value will be deducted on each trade as per the Government’s guidelines issued on Virtual Digital Asset (VDA) transfers. This TDS can be claimed while filing your ITR.</p>
                            <a href="#">Know more</a>
                        </div>
                    </div>
                    <div className="tab-sec">
                        <Tabs>
                            <TabList>
                                <Tab>Deposit & Withdrawal Fees</Tab>
                                <Tab>Trading Fees</Tab>
                            </TabList>

                            <TabPanel>
                                <div className='Tale___sec___WE'>
                                    <table className="customers">
                                        <tr>
                                            <th>ASSET</th>
                                            <th>NETWORK	</th>
                                            <th>CONFIRMATIONS	</th>
                                            <th>WITHDRAW FEE</th>
                                            <th>MIN. WITHDRAW AMOUNT</th>
                                            <th>MAX. WITHDRAW AMOUNT	</th>
                                            <th>DEPOSIT</th>
                                            <th>WITHDRAW</th>
                                        </tr>
                                        <tr>
                                            <td>1inch (1INCH)	</td>
                                            <td>Ethereum (ERC20)	</td>
                                            <td>30	</td>
                                            <td>30	</td>
                                            <td>27.06 1INCH	</td>
                                            <td>886.43 1INCH	</td>
                                            <td><div className='enable'><p>Enabled</p></div></td>
                                            <td><div className='enable'><p>Enabled</p></div></td>


                                        </tr>
                                        <tr>
                                            <td>Aave (AAVE)	 </td>
                                            <td>Ethereum (ERC20)	 </td>
                                            <td>30	</td>
                                            <td>0.0 AAVE	</td>
                                            <td>0.0 AAVE	</td>
                                            <td>54.545 AAVE	</td>
                                            <td><div className='enable'><p>Enabled</p></div></td>
                                            <td><div className='disable'><p>Disable</p></div></td>
                                        </tr>


                                    </table>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="inner-tabs">
                                    <Tabs>
                                        <TabList>
                                            <Tab>SPOT</Tab>
                                            <Tab>P2P</Tab>
                                        </TabList>

                                        <TabPanel>
                                        <div className='Tale___sec___WE'>
                                            <table className="customers">
                                                <tr>
                                                    <th>ASSET</th>
                                                    <th>NETWORK	</th>
                                                    <th>CONFIRMATIONS	</th>
                                                    <th>WITHDRAW FEE</th>
                                                    <th>MIN. WITHDRAW AMOUNT</th>
                                                    <th>MAX. WITHDRAW AMOUNT	</th>
                                                    <th>DEPOSIT</th>
                                                    <th>WITHDRAW</th>
                                                </tr>
                                                <tr>
                                                    <td>1inch (1INCH)	</td>
                                                    <td>Ethereum (ERC20)	</td>
                                                    <td>30	</td>
                                                    <td>30	</td>
                                                    <td>27.06 1INCH	</td>
                                                    <td>886.43 1INCH	</td>
                                                    <td><div className='enable'><p>Enabled</p></div></td>
                                                    <td><div className='enable'><p>Enabled</p></div></td>


                                                </tr>
                                                <tr>
                                                    <td>Aave (AAVE)	 </td>
                                                    <td>Ethereum (ERC20)	 </td>
                                                    <td>30	</td>
                                                    <td>0.0 AAVE	</td>
                                                    <td>0.0 AAVE	</td>
                                                    <td>54.545 AAVE	</td>
                                                    <td><div className='enable'><p>Enabled</p></div></td>
                                                    <td><div className='disable'><p>Disable</p></div></td>
                                                </tr>


                                            </table>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className='Tale___sec___WE'>
                                            <table className="customers">
                                                <tr>
                                                    <th>ASSET</th>
                                                    <th>NETWORK	</th>
                                                    <th>CONFIRMATIONS	</th>
                                                    <th>WITHDRAW FEE</th>
                                                    <th>MIN. WITHDRAW AMOUNT</th>
                                                    <th>MAX. WITHDRAW AMOUNT	</th>
                                                    <th>DEPOSIT</th>
                                                    <th>WITHDRAW</th>
                                                </tr>
                                                <tr>
                                                    <td>1inch (1INCH)	</td>
                                                    <td>Ethereum (ERC20)	</td>
                                                    <td>30	</td>
                                                    <td>30	</td>
                                                    <td>27.06 1INCH	</td>
                                                    <td>886.43 1INCH	</td>
                                                    <td><div className='enable'><p>Enabled</p></div></td>
                                                    <td><div className='enable'><p>Enabled</p></div></td>


                                                </tr>
                                                <tr>
                                                    <td>Aave (AAVE)	 </td>
                                                    <td>Ethereum (ERC20)	 </td>
                                                    <td>30	</td>
                                                    <td>0.0 AAVE	</td>
                                                    <td>0.0 AAVE	</td>
                                                    <td>54.545 AAVE	</td>
                                                    <td><div className='enable'><p>Enabled</p></div></td>
                                                    <td><div className='disable'><p>Disable</p></div></td>
                                                </tr>


                                            </table>
                                        </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Fees
