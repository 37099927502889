import site_logo from '../Assets/Icons/New_logo.png';
export const title = 'GTC';
export const logo = site_logo;
export const base_url = 'http://gtc.exchange';
export const api_url = 'https://api.gtc.exchange/demo/api/v1';
export const markit_url = 'https://market.gtc.exchange/api/v1';
// export const api_url = 'http://192.168.29.141:8001/demo/api/v1';



export const kycImage_path = 'https://api.gtc.exchange/demo/images/kyc/';

// module.exports = { success }
// export default success;
// export default toasted;
