import React, { useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import card_img from '../Assets/Images/Footer_IMG/help-center-card1.svg';
import { Link } from 'react-router-dom';
import { title } from '../config/config';

const HelpCenter = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, []);
  return (
    <>
    <div className="HelpCenter">
        <div className="a_container">
            <div className="top-sec">
                <div className="inner">
               <h1>Hi! How can we help?</h1>
               <div className="input">
                    <SearchIcon/>
                    <input type="text" placeholder='Search' />
               </div>

                </div>
            </div>
        </div>
    </div>
 
        <div className="HelpCenter_cards">
            <div className=''>
                <p>Categories</p>
                <div className="card-sec">
                    <div className="row">
                        <div className="col-4">
                            <Link to="/HelpData">

                            <div className="card">
                                <div className="inner">
                                    <div className="info">
                                        <div className="top-img">

                                        <img src={card_img} alt="" />
                                        </div>
                                        <h3>INR Deposit & Withdrawal</h3>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default HelpCenter
