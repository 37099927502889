import { Link } from "react-router-dom";
import { title, logo, base_url } from "../config/config";

const Footer = () => {
    return (
        <footer className="sec-py s-primary-bg">
            <div className="container">
                {/* footer Logo */}
                <Link to="/" className="footer_logo line-height-0">
                    <img src={title} alt="" />
                </Link>
                {/* footer Head */}
                <div className="footer_head">
                    <div className="footer_head_content">
                        <h2 className="sec_heading s-normal-color">
                            Start Your Crypto Journey Now! 
                        </h2>
                        <p className="sec_info s-normal-color">
                            With the {title} app and website, trading has never been easier.
                        </p>
                    </div>
                    <div className="txt-right">
                        <Link to="/signup" className="cta_button" >Sign Up Now</Link>
                    </div>
                </div>
                {/* footer navigation */}
                <nav className="footer_nav">
                    <ul className="footer_navlist">
                        {/* Footer Nav Item */}
                        <li className="footer_navitem">
                            <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                            Company
                            </h3>
                            <ul className="footer_sub_navlist">
                                <li className="footer_sub_navitem">
                                    <Link to="/about" className="footer_nav_link s-normal-color txt-ellipsis">About Us</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/protection-fund" className="footer_nav_link s-normal-color txt-ellipsis">Protection Fund</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/reserveCertificate" className="footer_nav_link s-normal-color txt-ellipsis">Proof of Reserves</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/tokenName" className="footer_nav_link s-normal-color txt-ellipsis">{title} Token</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/quick" className="footer_nav_link s-normal-color txt-ellipsis">Buy Crypto</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/careers" className="footer_nav_link s-normal-color txt-ellipsis">Careers</Link>
                                </li>

                            </ul>
                        </li>
                        {/* Footer Nav Item */}
                        {/* <li className="footer_navitem">
                            <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                                Products
                            </h3>
                            <ul className="footer_sub_navlist">
                                <li className="footer_sub_navitem">
                                    <Link to="/quick" className="footer_nav_link s-normal-color txt-ellipsis">Buy Crypto</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/" className="footer_nav_link s-normal-color txt-ellipsis">Futures</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/" className="footer_nav_link s-normal-color txt-ellipsis">Spot</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/copytrading" className="footer_nav_link s-normal-color txt-ellipsis">Copy Trading</Link>
                                </li>
                            </ul>
                        </li> */}
                        {/* Footer Nav Item */}
                        <li className="footer_navitem">
                            <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                                Service
                            </h3>
                            <ul className="footer_sub_navlist">
                                <li className="footer_sub_navitem">
                                    <Link to="/faq" className="footer_nav_link s-normal-color txt-ellipsis">{title} Academy</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/launchpad" className="footer_nav_link s-normal-color txt-ellipsis">{title} Launchpad</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/rewards" className="footer_nav_link s-normal-color txt-ellipsis">Promotions</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/referral" className="footer_nav_link s-normal-color txt-ellipsis">Referral Program</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/affiliates" className="footer_nav_link s-normal-color txt-ellipsis">Affiliate program</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/vipIntroduce" className="footer_nav_link s-normal-color txt-ellipsis">VIP Services</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/vipservice" className="footer_nav_link s-normal-color txt-ellipsis">Institutional Services</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/api" className="footer_nav_link s-normal-color txt-ellipsis">API Trading</Link>
                                </li>
                            </ul>
                        </li>
                        {/* Footer Nav Item */}
                        <li className="footer_navitem">
                            <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                                Legal & Disclosures
                            </h3>
                            <ul className="footer_sub_navlist">
                                <li className="footer_sub_navitem">
                                    <Link to="/complianceLicense" className="footer_nav_link s-normal-color txt-ellipsis">Regulatory License</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/privacy_policy" className="footer_nav_link s-normal-color txt-ellipsis">AML/KYC Policies </Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/privacy_policy" className="footer_nav_link s-normal-color txt-ellipsis">Privacy Policy </Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/privacy_policy" className="footer_nav_link s-normal-color txt-ellipsis">Terms of Use</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/privacy_policy" className="footer_nav_link s-normal-color txt-ellipsis">Legal Statement</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/privacy_policy" className="footer_nav_link s-normal-color txt-ellipsis">Risk Disclosure</Link>
                                </li>
                            </ul>
                        </li>

                        {/* Footer Nav Item */}
                        <li className="footer_navitem">
                            <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                            Support
                            </h3>
                            <ul className="footer_sub_navlist">
                                <li className="footer_sub_navitem">
                                    <Link to="/feedback" className="footer_nav_link s-normal-color txt-ellipsis">Feedback</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/schedule" className="footer_nav_link s-normal-color txt-ellipsis">Fee Schedule</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/verification" className="footer_nav_link s-normal-color txt-ellipsis">Official Verification Channel</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/buy-bitcoin" className="footer_nav_link s-normal-color txt-ellipsis">Buy Bitcoin</Link>
                                </li>
                            </ul>
                        </li>
                        {/* Footer Nav Item */}
                        <li className="footer_navitem">
                            <h3 className="footer_navitem_heading card_heading s-normal-color txt-ellipsis">
                                Copy Trading
                            </h3>
                            <ul className="footer_sub_navlist">
                                <li className="footer_sub_navitem">
                                    <Link to="/copytrading" className="footer_nav_link s-normal-color txt-ellipsis">Copy Trading</Link>
                                </li>
                                <li className="footer_sub_navitem">
                                    <Link to="/strategytrading" className="footer_nav_link s-normal-color txt-ellipsis">Strategy Trading</Link>
                                </li>   
                            </ul>
                        </li>
                    </ul>
                </nav>
                {/* footer bottom */}
                <div className="footer_bottom">
                    <div className="footer_bottom_left">
                        <p className="sec_info s-normal-color opacity-0-7">
                            CopyRight © {(new Date().getFullYear())} <Link className="sec_info s-normal-color " to="/">{title}</Link> All Rights Reserved.
                        </p>
                    </div>
                    {/* <div className="footer_bottom_right">
                        <Link to="/" className="footer_nav_link s-normal-color txt-ellipsis">Terms of Use</Link>
                        <Link to="/" className="footer_nav_link s-normal-color txt-ellipsis">Privacy Policy</Link>
                    </div> */}
                </div>
            </div>
        </footer>
    );
}
export default Footer;