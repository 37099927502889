import React, { useEffect } from 'react'
// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

const WithdrawConfirm = (props:any) => {
    useEffect(() => {
        document.title = props.title;

    },[])
    // const { width, height } = useWindowSize()

  return (
    <div className='new__Confirmed'>
         <Confetti  className='new-Confetti' />
        <h1>Withdraw Confirmed successfully!</h1>
    </div>
  )
}

export default WithdrawConfirm