import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const LoaderCom = (props:any) => {
    return (
        <tr className="d-value only-open-orders">
                {/* {props.condition != ' ' && */}
                 {props.condition == '' &&
                    <td colSpan={8} className="color-ring-market" >
                    <div className='color__ring_data'>
                        <ColorRing
                            visible={true}
                            height="40"
                            width="40"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />

                       <span>{ props.text }</span>
                        </div>
                    </td>
                }
            </tr>
    )
}

export default LoaderCom