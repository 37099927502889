import React, { useEffect } from 'react'
import { logo, title } from '../config/config'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';


const ApiTrading = (props: any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
    return (
        <>
            <div className='api_max'>
                <Tabs>
                    <div className='header_api'>
                        <div className='header__logo'>
                            <Link to="/">  <img src={logo} alt="logo/icon" /></Link>
                        </div>
                        <TabList>
                            <Tab>Spot</Tab>
                            <Tab>FuturesⓂ(USDT/Coin)</Tab>
                        </TabList>
                    </div>

                    <div className='body_api'>
                        <TabPanel>
                            <div className='api__data'>
                                <div className='api_sidebar'>
                                    <form>
                                        <label> <SearchIcon />
                                            <input type="search" placeholder='Search' />
                                        </label>
                                    </form>
                                    <ul className='nav' >
                                        <li className='nav-item'>
                                            <a href='#' className='nav-link'>  Welcome  </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a href='#' className='nav-link'>  Update log  </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a href='#' className='nav-link'>  WebSocket error codes  </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className='api__content__data'>
                                    <div className='row'>
                                        <div className='col-8'>
                                            <div className='api__content__txt'>
                                                <h4>Welcome</h4>
                                                <p>Welcome to {title} ApiDoc! Click here for quick start</p>
                                                <div className='ul__list_A'>
                                                    <h5>Jan 30, 2023 </h5>
                                                    <ul className=''>
                                                        <li>/api/mix/v1/plan/placePlan add param: 'reduceOnly'</li>
                                                        <li>/api/mix/v1/plan/modifyTPSLPlan param 'triggerPrice' is required</li>
                                                        <li>/api/mix/v1/trace/modifyTPSL desc update on param 'stopProfitPrice' & 'stopLossPrice'</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-4'>
                                            <div className='api__txt_console'>
                                                <div className='pre__code__header'> console</div>

                                                <div className='pre__code'>
                                                    <pre>
                                                        [
                                                        {
                                                            `- {
    "id" : <span className="value">  "1e200836-a037-4475-825e-f202dd0b0e92" </span>,
    "Status": <span className="value">"ACCOUNT_STATUS_ACTIVE"</span>,
    "type": <span className="value">ACCOUNT_TYPE_WALLET"</span>,
    "currency": <span className="value">"898849.3300"</span>,

    "blocked": <span className="value">"4581.9510"</span>
    }
    `
                                                        }
                                                        ]

                                                    </pre>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>



                    </div>

                </Tabs>
            </div>


        </>
    )
}

export default ApiTrading