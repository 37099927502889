import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import trading_banner_main from "../Assets/Images/Footer_IMG/schedule.jpg"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import icon_Img from "../Assets/Images/Footer_IMG/tether.png"

const Schedule = (props:any) => {
    useEffect(() => {
        document.title = props.title;
    }, [])
  return (
    <div className='max__contain max__contain_schedule pt__spacing'>
    <div className='schedule__banner'>
         <div className='row_max'>
             <div className='col-6 schedule__banner_left'>
                 <h1>Fee Schedule</h1>
                 <ul>
                     <li>Use BGB to deduct fees</li>
                     <li>Enjoy larger discounts and more privileges <Link to="/"> VIP Privileges</Link></li>
                 </ul>
             </div>
             <div className='col-6 schedule__banner_right'>
                 <img src={trading_banner_main} alt="schedule-1/icon" width="100%" height="200px" />
             </div>
         </div>
    </div>


    <div className='schedule__tabs'>
         <Tabs>
             <div className='schedule__tablist'>
                 <TabList>
                     <Tab> Spot Trading</Tab>
                     <Tab>Futures Trading</Tab>
                     <Tab>Withdrawals</Tab>
                 </TabList>
             </div>
             <TabPanel>
                    <div className='schedule__market_1'>
                         <table>
                             <thead>
                                 <tr>
                                     <th> Trading Pair</th>
                                     <th> Maker</th>
                                     <th> Taker</th>
                                     <th> Maker <span>  (Pay with BGB)</span></th>
                                     <th> Taker <span>  (Pay with BGB)</span></th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                     <td> 
                                         <p>LINK/USDT</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                     <td>
                                        <p>0.08%</p>
                                     </td>
                                     <td>
                                         <p> 0.08%</p>
                                     </td>
                                 </tr>

                                 <tr>
                                     <td> 
                                         <p>LINK/USDT</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                     <td>
                                        <p>0.08%</p>
                                     </td>
                                     <td>
                                         <p> 0.08%</p>
                                     </td>
                                 </tr>
                                 <tr>
                                     <td> 
                                         <p>LINK/USDT</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                     <td>
                                        <p>0.08%</p>
                                     </td>
                                     <td>
                                         <p> 0.08%</p>
                                     </td>
                                 </tr>
                                 <tr>
                                     <td> 
                                         <p>LINK/USDT</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                     <td>
                                        <p>0.08%</p>
                                     </td>
                                     <td>
                                         <p> 0.08%</p>
                                     </td>
                                 </tr>

                             </tbody>
                         </table>
                    </div>
             </TabPanel>

             <TabPanel>
                    <div className='schedule__market_1'>
                         <table>
                             <thead>
                                 <tr>
                                     <th> Futures</th>
                                     <th> Maker</th>
                                     <th> Taker</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                     <td> 
                                         <p>BTCUSDT</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                     <td>
                                         <p>0.1%</p>
                                     </td>
                                 </tr>

                             </tbody>
                         </table>
                    </div>
             </TabPanel>

             <TabPanel>
                    <div className='schedule__market_1'>
                         <table>
                             <thead>
                                 <tr>
                                     <th> Coin</th>
                                     <th> Network</th>
                                     <th> Withdrawal Fee</th>
                                     <th> Min. Withdrawal</th>
                                 </tr>
                             </thead>
                             <tbody>
                                 <tr>
                                     <td className='coin__img__sec'> 
                                        <div className='coin__img'>
                                            <img src={icon_Img} alt="icon"/>
                                            <p> BTC</p>
                                        </div>
                                     </td>
                                     <td>
                                         <p>BTC </p>
                                         <p>BTC 20</p>
                                     </td>
                                     <td>
                                         <p>0.0005</p>
                                         <p>0.0000051</p>
                                     </td>
                                     <td>
                                         <p>0.008</p>
                                         <p>0.001</p>
                                     </td>
                                 </tr>

                             </tbody>
                         </table>
                    </div>
             </TabPanel>



         </Tabs>

    </div>



 </div>
  );
}

export default Schedule;
