import React, { useEffect, useState } from "react";
import { useParams } from 'react-router';

const LivePriceBarNew = (props: any)  => {

    const { id } = useParams();
    // const [livePrice, setLivePrice] = useState([]);
    // const [currentLivePrice, setcurrentLivePrice] = useState(0);
    // const [oldLivePrice, setoldLivePrice] = useState(0);
    // const [previewPrice, setpreviewPrice] = useState(0);
    // const [countPreviewPrice, setcountPreviewPrice] = useState(1);
    // const [currentVolumn, setcurrentVolumn] = useState(0);
    // const [hourlyDownUp, sethourlyDownUp] = useState([{ nowHigh: 0, nowLow: 0 }]);
    // const [changePercentage, setchangePercentage] = useState([{ p: 0, P: 0 }]);
    const [textClas, settextClas] = useState('text-green');



    useEffect(() => {
        // const nowStream = `${props.symbol.toLowerCase()}@ticker`;
        // const nowStream = `${props.symbol.toLowerCase()}usdt@ticker`;

        // const streams = ['@ticker', '@depth20', '@trade'];
        // const ws = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${nowStream}`);

        // ws.onmessage = function (event) {
        //     const json = JSON.parse(event.data);
        //     try {
        //         if ((json)) {
        //             // console.log(json.stream)

        //             if (json.stream === nowStream) {
        //                 if (json) {
        //                     var d = json.data;
        //                     if (d.e === '24hrTicker') {
        //                         let priceN = parseFloat(d.c);
        //                         let priceL = parseFloat(d.b);
        //                         let pricenowHigh = parseFloat(d.h);
        //                         let pricenowLow = parseFloat(d.l);
        //                         let currentVolumn = parseFloat(d.v);

        //                         let currentp = parseFloat(d.p);
        //                         let currentP = parseFloat(d.P);




        //                         if (priceN > 0 && currentLivePrice !== priceN) {
        //                             if (currentLivePrice == 0) {
        //                                 setcurrentLivePrice(priceN);
        //                                 setpreviewPrice(priceN);
        //                                 setoldLivePrice(priceL);
        //                                 sethourlyDownUp([{ nowHigh: pricenowHigh, nowLow: pricenowLow }]);
        //                                 setchangePercentage([{ p: currentp, P: currentP }]);
        //                                 setcurrentVolumn(currentVolumn);
        //                             } else {
        //                                 setTimeout(() => {


        //                                     if (countPreviewPrice === 1) {
        //                                         if (priceN > currentLivePrice && Number(currentLivePrice) !== Number(priceN)) {
        //                                             setpreviewPrice(priceN);
        //                                         }
        //                                     } else {
        //                                         setcountPreviewPrice(0);
        //                                     }
                                        
        //                                     if (Number(currentLivePrice) != Number(priceN)) {
        //                                         setcurrentLivePrice(priceN);
        //                                     }
        //                                     setoldLivePrice(priceL);
        //                                     setcurrentVolumn(currentVolumn);
        //                                     setchangePercentage([{ p: currentp, P: currentP }]);
        //                                     sethourlyDownUp([{ nowHigh: pricenowHigh, nowLow: pricenowLow }]);


        //                                     if (currentLivePrice >= previewPrice) {
        //                                         settextClas('text-green');
        //                                     } else {
        //                                         settextClas('text-danger');

        //                                     }

        //                                 }, 10000)
        //                             }

        //                         }
        //                     }
                           
        //                 }
        //             }
        //         }
        //     } catch (err) {
        //     }
        // };



    }, [])
    // console.log(textClas)
    // console.log(hourlyDownUp)
  return (
        <>
   <div><div className='current-price'>
            <div className='current-price-column current-price-column-name'>
                <div className=''>
                    {/* <p>coin</p> */}
                    <h6>{id}</h6>
                </div>
            </div>
            <div className='current-price-column'>
                <div className=''>
                    {/* <p> Current price </p> */}
                    <h6 className={textClas}>{props.coinData.current_price}</h6>
                    <small>${props.coinData.current_price}</small>
                </div>
            </div>
            <div className='current-price-column'>
                <div className=''>
                    <p> 24h Change </p>
                    <h6 className='text-green'>{`${props.coinData.price_change_percentage_24h?.toFixed(2)??0}%`}</h6>
                </div>
            </div>
            <div className='current-price-column'>
                <div className=''>
                    <p> 24h High </p>
                    <h6 className='text-green'>{props.coinData.high_24h}</h6>
                </div>
            </div>
            <div className='current-price-column'>
                <div className=''>
                    <p> 24h Low </p>
                    <h6 className='text-red'>{props.coinData.low_24h}</h6>
                </div>
            </div>
            {/* <div className='current-price-column'>
                <div className=''>
                    <p> Volumn </p>
                    <h6>{currentVolumn.toFixed(1)}</h6>
                </div>
            </div> */}
        </div></div >
        </>
  )
}

export default LivePriceBarNew