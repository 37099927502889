import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Select from "react-select";
import img_icons from "../../Assets/Images/t.svg";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { api_url } from "../../config/config";
import _fetch from "../../config/api";
import ReactPaginate from 'react-paginate';
import { AiOutlineReload } from "react-icons/ai";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const MarketP2P = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionA, setSelectedOptionA] = useState(null);
    const [searchedVal, setSearchedVal] = useState("");
    const [searchedType, setSearchedType] = useState();
    const [sellCoinType, setsellCoinType] = useState("");
    const [buyFormSearch, setbuyFormSearch] = useState(props.props.search);
	const [ActiveTab, setActiveTab] = useState(0);

    // console.log('props',props.props)
    // P2p coins slect 
    const data = [
        {
            value: `bnb`,
            text: <span className="new__a"> BNB</span>,
            icon: <img src={img_icons} className="option-img-a" />
        },
        {
            value: `usdt`,
            text: <span className="new__a"> USDT</span>,
            icon: <img src={img_icons} className="option-img-a" />
        },
        {
            value: `eth`,
            text: <span className="new__a"> ETH</span>,
            icon: <img src={img_icons} className="option-img-a" />
        },
        {
            value: `xrp`,
            text: <span className="new__a"> XRP</span>,
            icon: <img src={img_icons} className="option-img-a" />
        },
    ];

    // handle onChange event of the dropdown/////////////////////////////////////////////////
    const handleChange = (e) => {
        setSelectedOption(e);
        setSearchedType('sell_currency');
        setsellCoinType(e.value)
    }

    // handle onChange event of the dropdown////////////////////////////////////////////////
    const handleChange2 = (e) => {
        setSelectedOptionA(e);
    }

    ////////////////////////////buy api////////////////////////////////////////////////////
    const [buyRecords, setbuyRecords] = useState(0);
    const [buyPageCount, setbuyPageCount] = useState(0);
    const [BuyItemOffset, setBuyItemOffset] = useState(1);
    const BuyhandlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setBuyItemOffset(newOffset);
    };
    const [allBuyData, setallBuyData] = useState([])
    async function getAllBuyData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }
        if (sellCoinType != "") {
            var typeData = `&sell_currency=${sellCoinType}`;
        } else {
            var typeData = "";
        }

        
        let d = await _fetch(`${api_url}/trade/p2p-pendingRequests?order_type=buy&page=${BuyItemOffset}&limit=10${searchData}${typeData}`, 'GET', {}, {});
        if (d?.status === 'success') {
            setallBuyData(d?.data.data);
            setbuyRecords(d.total);
        }
    }
    ////////////////////////////////////////////sell api /////////////////////////////
    const [records, setrecords] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(1);
    const handlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setItemOffset(newOffset);
    };
    const [allSellData, setallSellData] = useState([])
    async function getAllSellData() {
        if (searchedVal != "") {
            var searchData = `&search=${searchedType}&value=${searchedVal}`;
        } else {
            var searchData = "";
        }
        if (sellCoinType != "" ) {
            var typeData = `&sell_currency=${sellCoinType}`;
        } else {
            var typeData = "";
        }
        if(props.props.searchType === true ){
            var formType = props.props.search;
            setActiveTab(1)

        }else{
            var formType = "";
        }
        let d = await _fetch(`${api_url}/trade/p2p-pendingRequests?order_type=sell&page=${itemOffset}&limit=10${searchData}${typeData}${formType}`, 'GET', {}, {});

        if (d?.status === 'success') {
            setrecords(d.total);
            setallSellData(d?.data.data);
        }
    }
    useEffect(() => {
        getAllBuyData();
        getAllSellData();
    }, [searchedVal, itemOffset, sellCoinType,BuyItemOffset])

    return (
        <>
            <div className="new_market__p2p">
                <div className="card p2p_tab_card table__p2p__history_demo">
                    <Tabs selectedIndex={ActiveTab} onSelect={(index) => setActiveTab(index)}>
                        <div className="p2p_tab_card-header">
                            <div className="tab__p2">
                                <TabList>
                                    <Tab onClick={() => { setSearchedVal("");setsellCoinType("");setSearchedType("") }}>Buy</Tab>
                                    <Tab onClick={() => { setSearchedVal("");setsellCoinType("");setSearchedType("") }}>Sell</Tab>
                                </TabList>
                            </div>
                            <div className="coin__p2p_select">
                                <Select
                                    placeholder="Coins"
                                    value={selectedOption}
                                    className="select-option-s"
                                    options={data}
                                    onChange={handleChange}
                                    getOptionLabel={(e) => (
                                        <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                            {e.icon}
                                            <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="amount-p2p-input">
                                <form className="form__p2p">
                                    <input type="number" className="form-control" id="p2p_search" name="p2p_search" placeholder="Enter Amount" onChange={(e) => { setSearchedVal(e.target.value); setSearchedType('amount') }} />
                                    <Select
                                        placeholder="Coins"
                                        value={selectedOptionA}
                                        className="select-option-s"
                                        options={data}
                                        onChange={handleChange2}
                                        getOptionLabel={(e) => (
                                            <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                                                {e.icon}
                                                <span style={{ marginLeft: '5px' }}>{e.text}</span>
                                            </div>
                                        )}
                                    />
                                </form>
                            </div>
                            <div className="payment__methods">
                                <select className="selectpicker des" data-show-subtext="false" data-live-search="true" onChange={(e) => { setSearchedType('payment_method'); setSearchedVal(e.target.value) }}>
                                    <KeyboardArrowDownIcon />
                                    <option value="">All Payment Methods</option>
                                    <option value="bank">Bank</option>
                                    <option value="gpay">Google Pay</option>
                                    {/* <option value="">Ambattukavu</option> */}
                                </select>
                            </div>
                            <div className="last__btn_p2p">
                                <button type="button" className="btn" onClick={ () => window.location.reload()} > <RefreshIcon /> Refresh</button>
                            </div>
                        </div>
                        <div className="card-body">
                            <TabPanel>
                                <div className="table__p2p__history">
                                    <table className="table">
                                        <thead>
                                            <tr className="">
                                                <th>Advertiser</th>
                                                <th>Price</th>
                                                <th>Available
                                                    <span className="th__divider__span"></span>
                                                    Limits
                                                </th>
                                                {/* <th>Payment Method	</th> */}
                                                <th>Action <span className="zero__fees"> 0 fees</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                <td>
                                                    <div className="p2p-TD_1">
                                                        <div className="Img__user__p2p">A</div>
                                                        <div className="user__info__p2p">
                                                            <p>ByBIT</p>
                                                            <h6>65 Order(s)| 93 % </h6>
                                                            <h6>Active in the last 1 hour</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>444.00 <small>HUF</small></td>
                                                <td>
                                                    <div className="History___p2p__ad">
                                                        <div className="History___p2p__ad-data">
                                                            <p>Available</p>
                                                            <p>238.125928 USDT</p>
                                                        </div>
                                                        <div className="History___p2p__ad-data">
                                                            <p>Limits</p>
                                                            <p>800.00 ~ 80,000.00 HUF</p>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="mad-btn"> Bank Transfer </div>
                                                </td>
                                                <td>
                                                    <div className="btn_hit_p2p">
                                                        <button type="button" className="trade_btn active"> Buy USDT</button>
                                                    </div>
                                                </td>
                                            </tr> */}

                                            {allBuyData?.map((val) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="p2p-TD_1">
                                                                <div className="Img__user__p2p">A</div>
                                                                <div className="user__info__p2p">
                                                                    <p>{val.buy_currency?.toUpperCase()}</p>
                                                                    {/* <h6>65 Order(s)| 93 % </h6>
                                                                    <h6>Active in the last 1 hour</h6> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{val.price} <small>{val.sell_currency?.toUpperCase()}</small></td>
                                                        <td>
                                                            <div className="History___p2p__ad">
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Received</p>
                                                                    <p>{val.pending_amount} {val.buy_currency?.toUpperCase()}</p>
                                                                </div>
                                                                {/* <div className="History___p2p__ad-data">
                                                                    <p>Limits</p>
                                                                    <p>800.00 ~ 80,000.00 HUF</p>
                                                                </div> */}

                                                            </div>
                                                        </td>
                                                        {/* <td>
                                                            <div className="mad-btn"> Bank Transfer </div>
                                                        </td> */}
                                                        <td>
                                                            <div className="btn_hit_p2p">
                                                                <Link className="trade_btn active"  to={`/Dashboard/p2p/transaction/${val._id}`}> Sell</Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                            {/* <tr>
                                                <td>
                                                    <div className="p2p-TD_1">
                                                        <div className="Img__user__p2p">A</div>
                                                        <div className="user__info__p2p">
                                                            <p>ByBIT</p>
                                                            <h6>65 Order(s)| 93 % </h6>
                                                            <h6>Active in the last 1 hour</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>444.00 <small>HUF</small></td>
                                                <td>
                                                    <div className="History___p2p__ad">
                                                        <div className="History___p2p__ad-data">
                                                            <p>Available</p>
                                                            <p>238.125928 USDT</p>
                                                        </div>
                                                        <div className="History___p2p__ad-data">
                                                            <p>Limits</p>
                                                            <p>800.00 ~ 80,000.00 HUF</p>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="mad-btn"> Bank Transfer </div>
                                                </td>
                                                <td>
                                                    <div className="btn_hit_p2p">
                                                        <button type="button" className="trade_btn active"> Buy USDT</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            */}
                                        </tbody>
                                    </table>
                                    <div className="update_trending_coin_tabs">
                                        <div className="tabs_content active">
                                            <div className="content_inner_data"></div>
                                            {buyRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={BuyhandlePageClick}
                                                pageRangeDisplayed={1}
                                                pageCount={buyRecords / 10}
                                                previousLabel="<"

                                            // renderOnZeroPageCount={null}
                                            />}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    {/* <div className="pagination__container">
                                        <ul className="pagination">
                                            <li className="page-item"><a className="page-link " href="#">Previous</a></li>
                                            <li className="page-item"><a className="page-link active" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="table__p2p__history">
                                    <table className="table">
                                        <thead>
                                            <tr className="">
                                                <th>Advertiser</th>
                                                <th>Price</th>
                                                <th>Available
                                                    <span className="th__divider__span"></span>
                                                    Limits
                                                </th>
                                                <th>Payment Method	</th>
                                                <th>Action <span className="zero__fees"> 0 fees</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                <td>
                                                    <div className="p2p-TD_1">
                                                        <div className="Img__user__p2p">A</div>
                                                        <div className="user__info__p2p">
                                                            <p>ByBIT</p>
                                                            <h6>65 Order(s)| 93 % </h6>
                                                            <h6>Active in the last 1 hour</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>444.00 <small>HUF</small></td>
                                                <td>
                                                    <div className="History___p2p__ad">
                                                        <div className="History___p2p__ad-data">
                                                            <p>Available</p>
                                                            <p>238.125928 USDT</p>
                                                        </div>
                                                        <div className="History___p2p__ad-data">
                                                            <p>Limits</p>
                                                            <p>800.00 ~ 80,000.00 HUF</p>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="mad-btn"> Bank Transfer </div>
                                                </td>
                                                <td>
                                                    <div className="btn_hit_p2p">
                                                        <button type="button" className="trade_btn active"> Buy USDT</button>
                                                    </div>
                                                </td>
                                            </tr> */}

                                            {allSellData?.map((val) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="p2p-TD_1">
                                                                <div className="Img__user__p2p">A</div>
                                                                <div className="user__info__p2p">
                                                                    <p>{val.sell_currency?.toUpperCase()}</p>
                                                                    {/* <h6>65 Order(s)| 93 % </h6>
                                                                    <h6>Active in the last 1 hour</h6> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{val.price} <small>{val.buy_currency?.toUpperCase()}</small></td>
                                                        <td>
                                                            <div className="History___p2p__ad">
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Available</p>
                                                                    <p>{val.pending_amount} {val.sell_currency?.toUpperCase()}</p>
                                                                </div>
                                                                <div className="History___p2p__ad-data">
                                                                    <p>Limits</p>
                                                                    <p>{val?.minimum_limit} ~ {val?.pending_amount}</p>
                                                                </div>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            {val?.payment_method_type?.map((method_val) => {
                                                                return (<div className="mad-btn"> {method_val} </div>)
                                                            })}

                                                        </td>
                                                        <td>
                                                            <div className="btn_hit_p2p">
                                                                <Link to={`/Dashboard/p2p/transaction/${val._id}`} className="trade_btn active"> Buy </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}

                                            {/* <tr>
                                                <td>
                                                    <div className="p2p-TD_1">
                                                        <div className="Img__user__p2p">A</div>
                                                        <div className="user__info__p2p">
                                                            <p>ByBIT</p>
                                                            <h6>65 Order(s)| 93 % </h6>
                                                            <h6>Active in the last 1 hour</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>444.00 <small>HUF</small></td>
                                                <td>
                                                    <div className="History___p2p__ad">
                                                        <div className="History___p2p__ad-data">
                                                            <p>Available</p>
                                                            <p>238.125928 USDT</p>
                                                        </div>
                                                        <div className="History___p2p__ad-data">
                                                            <p>Limits</p>
                                                            <p>800.00 ~ 80,000.00 HUF</p>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="mad-btn"> Bank Transfer </div>
                                                </td>
                                                <td>
                                                    <div className="btn_hit_p2p">
                                                        <button type="button" className="trade_btn active"> Buy USDT</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            */}
                                        </tbody>
                                    </table>
                                    {/* <div className="pagination__container">
                                        <ul className="pagination">
                                            <li className="page-item"><a className="page-link " href="#">Previous</a></li>
                                            <li className="page-item"><a className="page-link active" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                        </ul>
                                    </div> */}
                                    <div className="update_trending_coin_tabs">
                                        <div className="tabs_content active">
                                            <div className="content_inner_data"></div>
                                            {records > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"

                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={1}
                                                pageCount={records / 10}
                                                previousLabel="<"

                                            // renderOnZeroPageCount={null}
                                            />}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </Tabs>
                </div>
            </div >
        </>
    );
};

export default MarketP2P;
