/* Common Section Css Code  */
import { useState } from 'react';
import "./Assets/Scss/style.scss";
import Header from './Header-Footer/Header';
import IndexPage from './Templates/IndexPage';
import FaqPage from './Templates/FaqPage';
import SportLightPage from './Templates/Spotlight-page';
import Sikkareferral from './Templates/Sikka-referral-page';
import Cryptoearn from './Templates/Crypto-earn-page';

// import Kycproof from './Templates/Kycproof';


import MarketPage from './Templates/Market-page';
import LoginSignup from './Templates/Login-Signup-page';
import Footer from './Header-Footer/Footer';
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import AuthGuard from './routes/AuthGuard';
// import Kycproof from './Sections/Kycproof';
import Kyc from './Sections/Kyc';
import KycPending from './Sections/KycPending';
import KycApproved from './Sections/KycApproved';
// import ChangePassword from './Sections/Change_password';
import Dashboard from './Sections/Dashborad';
// import TradingPage2 from './Sections/chart/TradingPage';
import PrivateHeader from './Private/PrivateHeader';
import PrivateFooter from './Private/PrivateFooter';
import AccountForm from './Templates/account-form';
import ReferalProgram from './Templates/referral-program';
import AccountSettings from './Templates/account-settings';
// import DepositHistory from './Sections/DepositHistory';
import Transfer from './Sections/Transfer';
import P2P from './Sections/chart/P2P';
import Blog from './Templates/blog';
// import TradeSocket from './Sections/chart/TradeSocket';
import TradingPage from './Sections/chart/TradingPage';
import WithdrawalAddress from './Sections/withdrawalAddress';
import TradingPageNew from './Sections/MenualChart/TradingPageNew';
import WithdrawHistory from './Sections/withdraw/WithdrawHistory';
import IncomeReports from './Sections/reports/IncomeReports';
import WalletReports from './Sections/reports/WalletReports';
import DepositHistory from './Sections/reports/DepositHistory';
// import TradingManualChart from './Sections/MenualChart/TradingManualChart';
import P2PNew from './Sections/p2p-new/P2PNew';
import TransactionCompleted from './Sections/p2p-new/TransactionCompleted';
import Add_post from './Sections/p2p-new/Add_post';
import About from './Templates/About';
import Careers from './Templates/Careers';
import BuyCoin from './Templates/BuyCoin';
import Privacy from './Templates/Privacy';
import Adds_postData from './Sections/p2p-new/Adds_postData';
import ViewAdsPostData from './Sections/p2p-new/ViewAdsPostData';
import Security from './Templates/Security';
import Blog_view from './Templates/Blog_view';
import ProtectionFund from './FooterTemplates/ProtectionFund';
import Quick from './FooterTemplates/Quick';
import Strategy from './FooterTemplates/Strategy';
import CopyTrading from './FooterTemplates/CopyTrading';
import Buybitcoin from './FooterTemplates/Buybitcoin';
import Schedule from './FooterTemplates/Schedule';
import Verification from './FooterTemplates/Verification';
import ComplianceLicense from './FooterTemplates/ComplianceLicense';
import PublicCareers from './FooterTemplates/PublicCareers';
import Job from './FooterTemplates/Job';
import Feedback from './FooterTemplates/Feedback';
import ApiTrading from './FooterTemplates/ApiTrading';
import Vipservice from './FooterTemplates/Vipservice';
import Affiliates from './FooterTemplates/Affiliates';
import Launchpad from './FooterTemplates/Launchpad';
import ReserveCertificate from './FooterTemplates/ReserveCertificate';
import LaunchpadData from './FooterTemplates/LaunchpadData';
import Rewards from './FooterTemplates/Rewards';
import Public_Policy from './FooterTemplates/Public_Policy';
import TokenName from './FooterTemplates/TokenName';
import Referral from './FooterTemplates/Referral';
import VipIntroduce from './FooterTemplates/VipIntroduce';
import HelpCenter from './Templates/HelpCenter';
import HelpCenter_inner from './Templates/HelpCenter_inner';
import Fees from './Templates/Fees';
import BuyEth from './Templates/BuyEth';
import BuyUsd from './Templates/BuyUsd';
import BuyTron from './Templates/BuyTron';
import BitcoinWhat from './Templates/BitcoinWhat';
import PublicTrading from './PublicMarket/PublicTrading';
import MarketHeader from './PublicMarket/MarketHeader';
import { title } from './config/config';
import WithdrawHistoryByType from './Sections/withdraw/WithdrawHistoryByType';
import IncomeLedger from './Sections/reports/IncomeLedger';
import WithdrawConfirm from './Templates/withdraw/WithdrawConfirm';
import WithdrawInvalid from './Templates/withdraw/WithdrawInvalid';
import VerificationID from './Templates/VerificationID';
import ForgotPass from './Templates/ForgotPass';



function App() {
  let location = useLocation();
  let formHeader: Boolean = false;
  if (location.pathname !== '/forgot-password' && location.pathname !== '/signup' && location.pathname !== '/login' && location.pathname !== '/login-qr') {
    formHeader = false
  } else {
    formHeader = true;
  }
  const [userLogin, setUserLogin] = useState(false);
  const [tradeCoinPrice, settradeCoinPrice] = useState(0);


  // const checkUser = async () => {
  //   try {
  //     const fetchData = await fetch("/usercheck", {
  //       method: "GET",
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       credentials: 'include',
  //     });
  //     const fetchJson = await fetchData.json();
  //     setUserLogin(fetchJson.login);
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }
  // }

  function MainLayout() {
    return (
      <>
        <div className='Header__lgoin'>
          <Header formHeader={formHeader} userLogin={userLogin} />
          <Outlet />
          {formHeader ? null : <Footer />}

        </div>
      </>
    )
  }


  function BasicLayout() {
    return (
      <>
        <Header formHeader={formHeader} userLogin={userLogin} />
        <Outlet />
        {formHeader ? null : <Footer />}

      </>
    )
  }

  function NewLayout() {
    return (
      <>
        <Outlet />

      </>
    )
  }

  function AdminLayout() {
    return (
      <>
        <PrivateHeader formHeader={formHeader} userLogin={userLogin} />

        <Outlet />
        {formHeader ? null : <PrivateFooter />}

      </>);
  }

  function MarketLayout() {
    return (
      <>
        <PrivateHeader formHeader={formHeader} userLogin={userLogin} />
        <Outlet />
      </>);
  }

  function MarketLayout_trade() {
    return (
      <>
        <div className='market__Layout__inner__data'>
          <PrivateHeader formHeader={formHeader} userLogin={userLogin} />
          <Outlet />
        </div>

      </>);
  }

  function PublicMarketLayout() {
    return (
      <>
        <div className='public___trade__layout'>
          <MarketHeader formHeader={formHeader} userLogin={userLogin} />
          <Outlet />
          {formHeader ? null : <Footer />}
        </div>


      </>);
  }




  return (
    <>
      <main id="mainContent" >
        <Routes>

          <Route element={<MainLayout />} >
            <Route path="/" element={<IndexPage title={ title + ` EXCHANGE`} />} />
            <Route path="*" element={<IndexPage title={ `Go back `}  />} />
          </Route>


          <Route element={<BasicLayout />} >
            <Route path="/signup/:sponser" element={<LoginSignup formType="signup" title={`SIGNUP | ` + title + ` EXCHANGE`} setUserLogin={setUserLogin} />} />
            <Route path="/signup" element={<LoginSignup formType="signup" title={`SIGNUP | ` + title + ` EXCHANGE`} setUserLogin={setUserLogin} />} />
            <Route path="/login" element={<LoginSignup formType="login" title={`LOGIN | ` + title + ` EXCHANGE`} setUserLogin={setUserLogin} />} />
            <Route path="/login-qr" element={<LoginSignup formType="login-qr" title={`LOGIN QR | ` + title + ` EXCHANGE`} setUserLogin={setUserLogin} />} />
            {/* <Route path="/forgot-password" element={<LoginSignup formType="forgot-password" title={`FORGOT PASSWORD | ` + title + ` EXCHANGE`} setUserLogin={setUserLogin} />} /> */}
            <Route path="/verificationID/:token" element={<VerificationID formType="forgot-password" title={`VERIFICATIONID | ` + title + ` EXCHANGE`} setUserLogin={setUserLogin} />} />
            <Route path="/forgot-password" element={<ForgotPass formType="forgot-password" title={`VERIFICATIONID | ` + title + ` EXCHANGE`} setUserLogin={setUserLogin} />} />


            <Route path="/faq" element={<FaqPage title={title + ` ACADEMY` + ` | ` + title + ` EXCHANGE`} />} />
            <Route path="/Spotlight" element={<SportLightPage title={`SPORTLIGHT | ` + title + ` EXCHANGE`} />} />
            <Route path="/sikka-referral" element={<Sikkareferral title={`SIKKA-REFERRAL | ` + title + ` EXCHANGE`} />} />
            <Route path="/crypto-earn" element={<Cryptoearn title={`CRYPTO-EARN | ` + title + ` EXCHANGE`} />} />
            {/* Footer Pages...   */}
            <Route path="/about" element={<About title={`ABOUT | ` + title + ` EXCHANGE`} />} />
            <Route path="/protection-fund" element={<ProtectionFund title={`PROTECTION FUND | ` + title + ` EXCHANGE`} />} />
            <Route path="/quick" element={<Quick title={`BUY CRYPTO | ` + title + ` EXCHANGE`} />} />
            <Route path="/strategytrading" element={<Strategy title={`STRATEGY | ` + title + ` EXCHANGE`} />} />
            <Route path="/copytrading" element={<CopyTrading title={`COPY TRADING | ` + title + ` EXCHANGE`} />} />
            <Route path="/buy-bitcoin" element={<Buybitcoin title={`BUY BITCOIN | ` + title + ` EXCHANGE`} />} />
            <Route path="/schedule" element={<Schedule title={`FEE SCHEDULE | ` + title + ` EXCHANGE`} />} />
            <Route path="/verification" element={<Verification title={`OFFICIAL VERIFICATION CHANNEL | ` + title + ` EXCHANGE`} />} />
            <Route path="/complianceLicense" element={<ComplianceLicense title={`REGULATORY LICENSE | ` + title + ` EXCHANGE`} />} />
            <Route path="/reserveCertificate" element={<ReserveCertificate title={`PROOF OF RESERVES | ` + title + ` EXCHANGE`} />} />
            <Route path="/launchpad" element={<Launchpad title={title + ` Launchpad | ` + title + ` EXCHANGE`} />} />
            <Route path="/vipservice" element={<Vipservice title={`INSTITUTIONAL SERVICES | ` + title + ` EXCHANGE`} />} />
            <Route path="/launchpad_data" element={<LaunchpadData title={title + ` Launchpad | ` + title + ` EXCHANGE`}  />} />
            <Route path="/feedback" element={<Feedback title={`FEEDBACK | ` + title + ` EXCHANGE`} />} />
            <Route path="/rewards" element={<Rewards title={`REWARDS | ` + title + ` EXCHANGE`} />} />
            <Route path="/privacy_policy" element={<Public_Policy title={`PRIVACY POLICY | ` + title + ` EXCHANGE`} />} />
            <Route path="/tokenName" element={<TokenName  title={title + `TOCKEN | ` + title + ` EXCHANGE`} />} />
            <Route path="/referral" element={<Referral title={`REFERRAL PROGRAM | ` + title + ` EXCHANGE`} />} />
            <Route path="/affiliates" element={<Affiliates title={`AFFILIATE PROGRAM | ` + title + ` EXCHANGE`} />} />
            <Route path="/vipIntroduce" element={<VipIntroduce title={`VIP SERVICES | ` + title + ` EXCHANGE`} />} />
            <Route path="/withdraw-confirm" element={<WithdrawConfirm title={`Withdraw Confirm | ` + title + ` EXCHANGE`} />} />
            <Route path="/withdraw-invalid" element={<WithdrawInvalid title={`Withdraw Invalid | ` + title + ` EXCHANGE`} />} />




            {/* <Route path="/Kycproof" element={<Kycproof />} /> */}
          </Route>



          <Route element={<NewLayout />} >
            <Route path="/careers" element={<PublicCareers title={`CAREERS | ` + title + ` EXCHANGE`} />} />
            <Route path="/jobs" element={<Job title={`Job | ` + title + ` EXCHANGE`} />} />
            <Route path="/api" element={<ApiTrading title={`API | ` + title + ` EXCHANGE`} />} />
          </Route>


          <Route element={<PublicMarketLayout />} >
            {/* <Route path="/trading" element={<PublicTrading />} /> */}
            <Route path="/trading/:id" element={<PublicTrading  title={`TRADING PRICE ${tradeCoinPrice} | ` + title + ` EXCHANGE`} settradeCoinPrice={settradeCoinPrice} />} />
          </Route>




          {/* private linkes */}
          <Route element={<AuthGuard />} >
            <Route element={<AdminLayout />}>
              <Route path="Dashboard/referral-program" element={<ReferalProgram title={`REFERRAL-PROGRAM | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/market" element={<MarketPage  title={`Market | ` + title + ` EXCHANGE`}/>} />
              <Route path="Dashboard/account-form" element={<AccountForm  title={`ACCOUNT-FORM | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard" element={<Dashboard title={`DASHBOARD | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/Kyc" element={<Kyc title={`KYC | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/DepositHistory" element={<DepositHistory title={`DEPOSIT HISTORY | ` + title + ` EXCHANGE`} />} />
              {/* <Route path="Dashboard/Change_password" element={<ChangePassword />} /> */}
              <Route path="Dashboard/Kyc/Pending" element={<KycPending title={`PENDING KYC | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/Kyc/Approved" element={<KycApproved title={`APPROVED KYC | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/Transfer" element={<Transfer  title={`FUND TRANSFER | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/WithdrawalAddress/:coin_id" element={<WithdrawalAddress title={` WITHDRAW | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/withdrawHistory" element={<WithdrawHistory title={`WITHDRAW HISTORY | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/withdrawHistory/:type" element={<WithdrawHistoryByType title={`WITHDRAW HISTORY | ` + title + ` EXCHANGE`} />} />

              <Route path="/Dashboard/incomesHistory/:accountType" element={<IncomeReports  title={` HISTORY | ` + title + ` EXCHANGE`} />} />
              <Route path="/Dashboard/incomes-ledger" element={<IncomeLedger  title={`INCOME LEDGER HISTORY | ` + title + ` EXCHANGE`} />} />

              <Route path="/Dashboard/WalletReport/:accountType" element={<WalletReports title={`REPORT | `+ title + ` EXCHANGE`} />} />
              {/* <Route path="/Dashboard/testChat" element={<TestChart />} /> */}
              {/* <Route path='/Dashboard/MenualChart' element={} /> */}
              {/* <Route path="Dashboard/Trade-socket" element={<TradeSocket />} /> */}
              {/* <Route path="test/crypto-earn" element={<Cryptoearn />} /> */}
              <Route path="Dashboard/careers" element={<Careers title={`CAREERS | `+ title + ` EXCHANGE`}  />} />
              <Route path="Dashboard/coin" element={<BuyCoin title={title + ` EXCHANGE`} />} />
              <Route path="Dashboard/eth" element={<BuyEth title={title + ` EXCHANGE`} />} />
              <Route path="Dashboard/USDT" element={<BuyUsd title={title + ` EXCHANGE`} />} />
              <Route path="Dashboard/tron" element={<BuyTron title={title + ` EXCHANGE`} />} />
              <Route path="Dashboard/privacy" element={<Privacy title={`PRIVACY POLICY | ` + title + ` EXCHANGE`} />}  />
              <Route path="Dashboard/security" element={<Security title={`SECURITY | ` + title + ` EXCHANGE`} />} />
              <Route path="/HelpData" element={<HelpCenter_inner  title={`HELP CENTER | ` + title + ` EXCHANGE`}/>} />
              <Route path="Dashboard/Blog" element={<Blog title={`BLOG | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/fees" element={<Fees  title={`FEE | ` + title + ` EXCHANGE`}/>} />
              <Route path="Dashboard/helpCenter" element={<HelpCenter  title={`HELP CENTER | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/Blog/Blog_view" element={<Blog_view  title={`BLOG | ` + title + ` EXCHANGE`}/>} />
              <Route path="Dashboard/bitcoin" element={<BitcoinWhat title={`BUY BITCOIN FROM BUY BITCOIN FROM ${title} | ` + title + ` EXCHANGE`} />} />


            </Route>
          </Route>

          {/* Trader market */}
          <Route element={<AuthGuard />} >
            <Route element={<MarketLayout_trade />}>
              <Route path='Dashboard/trade/:id' element={<TradingPage  title={`TRADING PRICE ${tradeCoinPrice} | ` + title + ` EXCHANGE`}  settradeCoinPrice={settradeCoinPrice}/>}  />
              <Route path='Dashboard/P2P' element={<P2P title={`P2P | ` + title + ` EXCHANGE`} />} />
              <Route path='Dashboard/trading/:id' element={<TradingPageNew title={`TRADING PRICE ${tradeCoinPrice} | ` + title + ` EXCHANGE`} settradeCoinPrice={settradeCoinPrice} />} />


            </Route>
          </Route>

          <Route element={<AuthGuard />} >
            <Route element={<MarketLayout />}>
              <Route path="Dashboard/account-settings" element={<AccountSettings title={`ACCOUNT SETTING | ` + title + ` EXCHANGE`} />} />
              <Route path="Dashboard/account-settings/:name" element={<AccountSettings title={`ACCOUNT SETTING | ` + title + ` EXCHANGE`} />} />

              <Route path='Dashboard/add_post' element={<Add_post title={`ADD POST | ` + title + ` EXCHANGE`} />} />
              <Route path='Dashboard/adds-data' element={<Adds_postData title={`P2P | ` + title + ` EXCHANGE`} />} />
              <Route path='Dashbaord/view-adds-data/:order_type/:id' element={<ViewAdsPostData title={`ADD POST | ` + title + ` EXCHANGE`} />} />
              <Route path='Dashboard/p2pnew' element={<P2PNew title={`P2P | ` + title + ` EXCHANGE`} />} />
              <Route path='Dashboard/p2p/transaction/:id' element={<TransactionCompleted title={`P2P | ` + title + ` EXCHANGE`} />} />
            </Route>
          </Route>
        </Routes>
        <Toaster gutter={15}
          position="top-right"
          containerClassName="toasterAddClass_container"


          containerStyle={{
            top: 50,
            left: 20,
            bottom: 100,
            right: 20,

          }}
          toastOptions={{
            className: 'toasterAddClass',
            // duration: 40000,
            icon: '',
            success: {
              style: {
                // animation : '',
                background: '#28a745',
                color: '#fff',
              },
            },
            error: {
              style: {
                animation: '',
                background: '#f6685e',
                color: '#fff',
              },
            },
          }} />
      </main>
    </>
  );
}

export default App;


