import { Link, useNavigate } from "react-router-dom";
// import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { useEffect, useState } from "react";

// import { ReactCountryDropdown } from 'react-country-dropdown'
// import 'react-country-dropdown/dist/index.css'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import _fetch from "../config/api";
import { api_url, kycImage_path, title } from "../config/config";
import toasted from "../config/toast";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ColorRing } from "react-loader-spinner";
import dateFormat from "dateformat";

const selected = (id: any) => {
    const sa: HTMLElement | any = document.getElementById(id);
    sa.classList.add("show_form");
    const column: HTMLElement | any = document.getElementById('HideColumn');
    column.style.display = 'none';
    const btn: HTMLElement | any = document.getElementById('BtnHide');
    btn.style.display = 'none';
}


const Kyc = (props:any) => {
    const [loading, setLoading] = useState(true);

    const [data, setdata] = useState([]);
    const [status, setstatus] = useState('');
    const [country, setcountry] = useState('');
    const [region, setregion] = useState('');
    const [documentFront, setdocumentFront] = useState('');
    const [documentFrontImg, setdocumentFrontImg] = useState('');
    const [documentBack, SetdocumentBack] = useState('');
    const [documentBackImg, SetdocumentBackImg] = useState('');
    const [pan, Setpan] = useState('');
    const [panImage, SetpanImage] = useState('');
    const [selfie_image, Setselfie_image] = useState('');
    const [selfie_imageGet, Setselfie_imageGet] = useState('');
    const [document_type, Setdocument_type] = useState('Aadhar');
    const [first_name, Setfirst_name] = useState('');
    const [last_name, Setlast_name] = useState('');
    const [dob, Setdob] = useState('');
    const [state, Setstate] = useState('');
    const [city, Setcity] = useState('');
    const [pan_number, Setpan_number] = useState('');
    const [re_enter_pan_number, Setre_enter_pan_number] = useState('');
    const [document_number, Setdocument_number] = useState('');
    const [re_enter_document_number, Setre_enter_document_number] = useState('');
    const [pin_code, Setpin_code] = useState('');
    const [btnDisable, setbtnDisable] = useState(false);
    const [comment, Setcomment] = useState('');

    function selectCountry(val: any) {
        setcountry(val);
    }
    function selectRegion(val: any) {
        setregion(val);
    }

    const submitForm = async (e: any) => {
        setbtnDisable(true);
        e.preventDefault();
        let formData = new FormData();
        formData.append('pan_image', pan);
        formData.append('document_front_image', documentFront);
        formData.append('document_back_image', documentBack);
        formData.append('selfie_image', selfie_image);
        formData.append('document_type', document_type);
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('dob', dob);
        formData.append('country', country);
        formData.append('state', region);
        formData.append('city', city);
        formData.append('pin_code', pin_code);
        formData.append('pan_number', pan_number);
        formData.append('re_enter_pan_number', re_enter_pan_number);
        formData.append('document_number', document_number);
        formData.append('re_enter_document_number', re_enter_document_number);
        formData.append('comment', comment);
        var d = await _fetch(`${api_url}/kyc/updateKyc/`, 'PATCH', formData, {});

        if (d?.status == 'success') {
            toasted.success(d?.message);
            setbtnDisable(false);
        } else {
            toasted.error(d?.message);
            setbtnDisable(false);
        }
    }


    let navigate = useNavigate();
    useEffect(() => {
		document.title = props.title;

        (async () => {
            const passData = {};
            const data1 = await _fetch(`${api_url}/kyc/userKycInfo/`, 'GET', passData, {});
            setdata(data1.data.data.document_type);
            const newData = data1.data.data;
            if (newData.kyc_status === 'Pending') {
                navigate("/Dashboard/Kyc/Pending");
            } else if (newData.kyc_status === 'Approved') {
                navigate("/Dashboard/Kyc/Approved");
            }
            // setcountry(newData.country)
            // setregion(newData.state)
            // setdocumentFront(newData.document_back_image)
            // SetdocumentBack(newData.document_front_image)
            // Setpan(newData.pan_image)
            // Setselfie_image(newData.selfie_image)
            // Setdocument_type(newData.document_type)
            // Setfirst_name(newData.first_name)
            // Setlast_name(newData.last_name)
            // var date = dateFormat(newData.dob, "IST:yyyy-mm-dd").replace('IthP:', '').replace('IthA:', '').replace('IstP:', '').replace('IstA:', '');
            // Setdob(date)
            // Setcity(newData.city)
            // Setpan_number(newData.pan_number)
            // Setdocument_number(newData.document_number)
            // Setpin_code(newData.pin_code)
            // Setcomment(newData.comment)
            setstatus(newData.kyc_status)
            if (newData.kyc_status == 'Pending' || newData.kyc_status == 'Approved') {
                selected('card__2')
            }
        })()
    }, [btnDisable])

    const checkConditions = (storeData: any) => {
        if (document_type == 'Aadhar' || document_type == 'Passport') {
            if (storeData?.length > 11) {

                if (storeData?.length === 12) {

                    Setdocument_number(storeData);
                } else {
                    toasted.error('Please enter Maximum 12 characters!');

                }
            } else {
                toasted.error('Please enter minimum 12 characters!');

            }
        } else if (document_type == 'DrivingLicense') {
            if (storeData?.length > 15) {

                if (storeData?.length === 16) {

                    Setdocument_number(storeData);
                } else {
                    toasted.error('Please enter Maximum 16 characters!');

                }
            } else {
                toasted.error('Please enter minimum 16 characters!');

            }
        }
    }

    return (
        <>
            <div className="contaienr_kyc">
                <div className="inner_contaienr">
                    <div className="card">
                        <div className="card-header">
                            <div className="check-box check-box-1">
                                <div className="img-bg">
                                    <CheckCircleIcon />
                                </div>
                                <span className="check-box-name" > EMAIL</span>
                            </div>
                            <div className="check-box check-box-1">
                                <div className="img-bg">
                                    <CheckCircleIcon />
                                </div>
                                <span className="check-box-name" > SECURITY</span>
                            </div>
                            <div className="check-box">
                                <div className="img-bg_a">
                                    <img src={require("../Assets/Icons/circle.svg").default} alt="eys icon" />
                                </div>
                                <span className="check-box-name" > WELCOME</span>
                            </div>
                        </div>
                        {/* } */}
                        <div className="card-body">
                            <h3 className="card-title"> SELECT YOUR COUNTRY</h3>
                            <form action="#" onSubmit={submitForm} >
                                <div className="form-group">
                                    <label htmlFor="sel1">COUNTRY</label>
                                    <CountryDropdown
                                        value={country}
                                        onChange={(val) => selectCountry(val)} classes="form-control" />
                                </div>
                                <div className="form__column" id="HideColumn">
                                    <div className="column column_a">
                                        <div className="form-title">
                                            WITHOUT KYC
                                        </div>
                                        <ul>
                                            <li> <CancelIcon />  Deposit Crypto </li>
                                            <li> <CancelIcon />  Deposit INR </li>
                                            <li> <CancelIcon />  Trade</li>
                                            <li> <CancelIcon />  P2P </li>
                                            <li> <CancelIcon />  Withdraw </li>
                                        </ul>
                                    </div>
                                    <div className="column column_b">
                                        <div className="form-title">
                                            WITH KYC
                                        </div>
                                        <ul className="kyc__check">
                                            <li>   <CheckCircleIcon /> Deposit Crypto </li>
                                            <li>   <CheckCircleIcon /> Deposit INR </li>
                                            <li>   <CheckCircleIcon /> Trade</li>
                                            <li>   <CheckCircleIcon /> P2P </li>
                                            <li>   <CheckCircleIcon /> Withdraw </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="btn_area" id="BtnHide">
                                    <Link to="/Dashboard" className="btn btn-a"> Skip For Now </Link>
                                    <a className="btn" onClick={() => {if(country !== undefined && country !== ''){selected('card__2')}else{toasted.error('Please select your country!');}}}> Complete KYC </a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <form action="#" onSubmit={submitForm} >
                        <div className="card card-2" id="card__2" style={{ marginTop: '30px' }}  >
                            <div className="card-header">
                                <h3> Personal Info</h3>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="usr">FIRST NAME *</label>
                                    <input type="text" className="form-control" value={first_name} onChange={(e: any) => { Setfirst_name(e.target.value) }} id="usr" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="usr">LAST NAME</label>
                                    <input type="text" className="form-control" id="usr" value={last_name} onChange={(e: any) => { Setlast_name(e.target.value) }} required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="usr">DATE OF BIRTH (DD-MM-YYYY) *</label>
                                    <input type="date" className="form-control" placeholder="DD-MM-YYYY" id="usr" value={dob} onChange={(e: any) => { Setdob(e.target.value) }}  required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="comment">Comment:</label>
                                    <textarea className="form-control" rows={5} id="comment" onChange={(e: any) => { Setcomment(e.target.value) }} value={comment} required>{comment}</textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="sel1">STATE *</label>
                                    <RegionDropdown
                                        country={country}
                                        value={region}
                                        onChange={(val: any) => selectRegion(val)} classes="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="usr">CITY *</label>
                                    <input type="text" className="form-control" id="usr" value={city} onChange={(e: any) => { Setcity(e.target.value) }} required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="usr">PIN/ZIP CODE *</label>
                                    <input type="text" className="form-control" id="usr" value={pin_code} onChange={(e: any) => { Setpin_code(e.target.value) }} required/>
                                </div>
                                {/* pan -card  */}
                                <div className="pan-card">
                                    <div className="card-header card-header-2">
                                        <h3> PAN | TAX Number</h3>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="usr">PAN | TAX Number*</label>
                                        <input type="text" className="form-control" id="usr"  value={pan_number} onChange={(e: any) => { Setpan_number(e.target.value) }} required/>
                                    </div>
                                    {status != 'Pending' && status != 'Approved' &&
                                        <div className="form-group">
                                            <label htmlFor="usr">RE-ENTER PAN | TAX Number*</label>
                                            <input type="text" className="form-control" id="usr"  onChange={(e: any) => { Setre_enter_pan_number(e.target.value) }} required/>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <div className="file__box" >
                                            {status != 'Pending' && status != 'Approved' && status != 'Rejected' &&
                                                <img src={panImage == '' ? require("../Assets/Icons/proofStyle.svg").default : panImage} width={'80%'} alt="" />
                                            }
                                            {status === 'Pending' || status === 'Approved' || status === 'Rejected' &&
                                                <img src={panImage == '' ? require("../Assets/Icons/proofStyle.svg").default : panImage} width={'80%'} alt="" />
                                            }
                                        </div>
                                        {status != 'Pending' && status != 'Approved' &&
                                            <>
                                                <div className="label__div">
                                                    <label htmlFor="documentFront">
                                                        <a className="upload-btn"> Upload Document   </a>
                                                    </label>
                                                </div>
                                                <input type="file" id="documentFront" onChange={(e: any) => { if(e.target.files[0].type === "image/png"|| e.target.files[0].type === "image/Webp" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg" ){Setpan(e.target.files[0]); SetpanImage(URL.createObjectURL(e.target.files[0]))}else{ toasted.error('Please select valid image!');} }} style={{ display: 'none' }} />
                                            </>
                                        }
                                    </div>
                                </div>
                                {/* Document Type */}
                                <div className="pan-card">
                                    <div className="card-header card-header-2">
                                        <h3> Document Type</h3>
                                    </div>
                                    <div className="form-group">
                                        <select className="form-control" id="sel1" onChange={(e: any) => { Setdocument_type(e.target.value) }}>
                                            <option value='Aadhar' selected={document_type == 'Aadhar' ? true : false}>National ID</option>
                                            <option value='Passport' selected={document_type == 'Passport' ? true : false}>Passport</option>
                                            <option value='DrivingLicense' selected={document_type == 'DrivingLicense' ? true : false}>Driving license</option>
                                        </select>
                                    </div>
                                    <div className="" id="Aadhar">
                                        {
                                            document_type == 'Aadhar' && <div className="form-group">
                                                <label htmlFor="usr">National ID NUMBER  *</label>
                                                <input type="text" className="form-control" id="usr"   onBlur={(e: any) => { Setdocument_number(e.target.value) }} required/>
                                            </div>
                                        }
                                        {
                                            document_type == 'Passport' && <div className="form-group">
                                                <label htmlFor="usr">Passport NUMBER *</label>
                                                <input type="text" className="form-control" id="usr"   onBlur={(e: any) => { Setdocument_number(e.target.value) }}  required/>
                                            </div>
                                        }
                                        {
                                            document_type == 'DrivingLicense' && <div className="form-group">
                                                <label htmlFor="usr">Driving License NUMBER *</label>
                                                <input type="text" className="form-control" id="usr"   onBlur={(e: any) => { Setdocument_number(e.target.value) }} required/>
                                            </div>
                                        }
                                        {status != 'Pending' && status != 'Approved' &&
                                            <div className="form-group">
                                                <label htmlFor="usr">RE-ENTER {document_type == 'Aadhar' ?'National ID': document_type}  NUMBER *</label>
                                                <input type="text" className="form-control" id="usr"  onChange={(e: any) => { Setre_enter_document_number(e.target.value) }} required/>
                                            </div>
                                        }
                                        <div className="form-group">
                                            <h3> {document_type == 'Aadhar' ?'National ID': document_type}   Front </h3>
                                            <div className="file__box" >
                                                {status != 'Pending' && status != 'Approved' && status != 'Rejected' &&
                                                    <img src={documentFrontImg == '' ? require("../Assets/Icons/proofStyle.svg").default : documentFrontImg} width={'80%'} alt="" />
                                                }
                                                {(status === 'Pending' || status === 'Approved' || status === 'Rejected') &&
                                                    <img src={documentFrontImg == '' ? require("../Assets/Icons/proofStyle.svg").default : documentFrontImg} width={'80%'} alt="" />
                                                }
                                            </div>
                                            {status != 'Pending' && status != 'Approved' &&
                                                <>
                                                    <div className="label__div" >
                                                        <label htmlFor="documentFront1">
                                                            <a className="upload-btn"> Upload Document   </a>
                                                        </label>
                                                    </div>
                                                    <input type="file" id="documentFront1" className="upload-btn" data-bv-field="documentFront" name='documentFront' onChange={(e: any) => {if(e.target.files[0].type === "image/png"|| e.target.files[0].type === "image/Webp" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg" ){ setdocumentFront(e.target.files[0]); setdocumentFrontImg(URL.createObjectURL(e.target.files[0]))}else{ toasted.error('Please select valid image!');} }} style={{ display: 'none' }} accept="image/png, image/Webp, image/jpeg"  />
                                                </>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <h3> {document_type == 'Aadhar' ?'National ID': document_type}   Back </h3>
                                            <div className="file__box" >
                                                {(status != 'Pending' && status != 'Approved' && status != 'Rejected') &&
                                                    <img src={documentBackImg == '' ? require("../Assets/Icons/proofStyle.svg").default : documentBackImg} width={'80%'} alt="" />
                                                }
                                                {(status == 'Pending' || status == 'Approved' || status == 'Rejected') &&
                                                    <img src={documentBackImg == '' ? require("../Assets/Icons/proofStyle.svg").default : documentBackImg} width={'80%'} alt="" />
                                                }
                                            </div>
                                            {status != 'Pending' && status != 'Approved' &&
                                                <>
                                                    <div className="label__div">
                                                        <label htmlFor="documentFront2">
                                                            <a className="upload-btn"> Upload Document   </a>
                                                        </label>
                                                    </div>
                                                    <input type="file" id="documentFront2" onChange={(e: any) => { if(e.target.files[0].type === "image/png"|| e.target.files[0].type === "image/Webp" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg" ){ SetdocumentBack(e.target.files[0]); SetdocumentBackImg(URL.createObjectURL(e.target.files[0]))}else{toasted.error('Please select valid image!');} }} style={{ display: 'none' }} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Document Type */}
                                <div className="pan-card">
                                    <div className="card-header card-header-2">
                                        <h3> Selfie</h3>
                                    </div>
                                    <div className="">
                                        <div className="form-group">
                                            <div className="file__box" >
                                                {(status != 'Pending' && status != 'Approved' && status != 'Rejected') &&
                                                    <img src={selfie_imageGet == '' ? require("../Assets/Icons/selfie.svg").default : selfie_imageGet} width={'80%'} alt="" />
                                                }
                                                {(status === 'Pending' || status === 'Approved' || status === 'Rejected') &&
                                                    <img src={selfie_imageGet == '' ? require("../Assets/Icons/selfie.svg").default : selfie_imageGet} width={'80%'} alt="" />
                                                }
                                            </div>
                                            {(status != 'Pending' && status != 'Approved') &&
                                                <>
                                                    <div className="label__div">
                                                        <label htmlFor="selfie">
                                                            <a className="upload-btn"> Upload Selfie</a>
                                                        </label>
                                                    </div>
                                                    <input type="file" id="selfie" onChange={(e: any) => {if(e.target.files[0].type === "image/png"|| e.target.files[0].type === "image/Webp" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg"  ){Setselfie_image(e.target.files[0]); Setselfie_imageGet(URL.createObjectURL(e.target.files[0]))}else{toasted.error('Please select valid image!');}}} style={{ display: 'none' }} />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(status != 'Pending' && status != 'Approved') &&
                                <div className="card card-3" >
                                    <h3 className="title_a">Ready to submit your application?</h3>
                                    <p>Please verify the details you’re submitting. Once submitted, you won’t be able to change it unless you
                                        <a href="#" target={"_blank"}> Contact us</a>
                                    </p>
                                    {status != 'Pending' && status != 'Approved' &&
                                        <div className="">
                                            <button type="submit" className="btn" disabled={btnDisable}>
                                                {btnDisable === true &&
                                                    <div className="color-ring">
                                                        <ColorRing
                                                            visible={true}
                                                            height="20"
                                                            width="20"
                                                            ariaLabel="blocks-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass="blocks-wrapper"
                                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                                        />
                                                        Please Wait...
                                                    </div>
                                                }
                                                {btnDisable === false ? 'Submit for verification' : ''}
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Kyc;